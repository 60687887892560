import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconPlayCircle} from '@autocut/designSystem/components/atoms/Icon/media/IconPlayCircle';
import {Select} from '@autocut/designSystem/components/atoms/Select/Select';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {BleepFileEnum, bleepSounds} from '@autocut/enums/bleepFile.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {preload} from '@autocut/types/ElectronPreload';
import {getBlobUrlFromFilepath} from '@autocut/utils/previewFrame';
import {getRessourceSignedUrl} from '@autocut/utils/resources/downloadableRessources.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {EditBleepSoundsModal} from '../EditBleepSoundsModal/EditBleepSoundsModal';

export type BleepEntry = {
  name: string;
  fullpath: string;
};

const BleepSoundChoiceStep = () => {
  const intl = useIntl();
  const {bleepFile} = useAutoCutStore(state => state.ui.parameters.swear_word);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [loadedBleeps, setLoadedBleeps] = useState<BleepEntry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {fs, path, electron} = preload;

  useEffect(() => {
    bleepSounds[0].label = intl.formatMessage({
      id: 'modes_swearWords_parameters_bleepSoundSelector_mute',
      defaultMessage: 'Mute',
    });
  }, [intl]);

  const displayedBleeps = useMemo(
    () => [
      ...bleepSounds,
      ...loadedBleeps.map(bleep => ({
        label: bleep.name,
        value: bleep.fullpath,
        modelOrder: 0,
      })),
    ],
    [loadedBleeps],
  );

  const playBleep = async () => {
    if (bleepFile === BleepFileEnum.Muted) return;
    if (audio) {
      audio.pause();
      setAudio(null);
    } else {
      let soundUrl: string;
      if (
        bleepFile === BleepFileEnum.BleepSound1 ||
        bleepFile === BleepFileEnum.BleepSound2
      ) {
        soundUrl = await getRessourceSignedUrl(
          resourcesConfigs()[bleepFile]().fileName,
        );
      } else {
        soundUrl = getBlobUrlFromFilepath(bleepFile);
      }

      setAudio(() => {
        const tempAudio = new Audio(soundUrl);
        tempAudio.play().catch(() => setAudio(null));
        tempAudio.addEventListener('ended', () => setAudio(null));
        return tempAudio;
      });
    }
  };

  const loadBleeps = useCallback(() => {
    setIsLoading(true);
    const appDataPath = electron.getPath('appData');
    const bleepsDir = path.join(appDataPath, 'userBleeps');

    if (!fs.existsSync(bleepsDir)) {
      fs.mkdirSync(bleepsDir, {
        recursive: true,
      });
    }

    const bleepEntries = fs
      .readdirSync(bleepsDir, {withFileTypes: true})
      .map(dirent => ({
        name: dirent.name,
        fullpath: path.join(dirent.parentPath, dirent.name),
      }));
    setLoadedBleeps(bleepEntries);
    setIsLoading(false);
  }, [fs, path, electron]);

  useEffect(() => {
    void loadBleeps();
  }, [loadBleeps]);

  return (
    <>
      <EditBleepSoundsModal
        isOpen={isEditModalOpened}
        isLoading={isLoading}
        loadBleeps={loadBleeps}
        loadedBleeps={loadedBleeps}
        onClose={() => setIsEditModalOpened(false)}
      />
      <FormSection
        title={intl.formatMessage({
          id: 'modes_swearWords_parameters_bleepSoundSelector_helper',
          defaultMessage: 'Select bleep sound effect',
        })}
      >
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <FlexContainer
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            <ActionIcon
              size={24}
              color={
                bleepFile === BleepFileEnum.Muted
                  ? 'grey'
                  : audio
                    ? colors.primary600
                    : 'inherit'
              }
              onClick={playBleep}
            >
              <IconPlayCircle />
            </ActionIcon>

            <Select
              options={Object.values(displayedBleeps)}
              onChange={option => {
                if (option) {
                  setAutocutStore('ui.parameters.swear_word.bleepFile', option);
                }
              }}
              selected={bleepFile}
              fullWidth
            />
          </FlexContainer>
          <Button
            variant="tertiary"
            onClick={() => setIsEditModalOpened(true)}
            style={{
              width: 'fit-content',
            }}
          >
            {intl.formatMessage({
              id: 'modes_swearWords_parameters_bleepSoundManager_button',
              defaultMessage: 'Manage & add sounds',
            })}
          </Button>
        </FlexContainer>
      </FormSection>
    </>
  );
};

export default BleepSoundChoiceStep;
