import type {ColorKeys} from '@autocut/designSystem/colors';
import type React from 'react';

import type {TextVariant} from './variants';

import {colors} from '@autocut/designSystem/colors';
import {forwardRef} from 'react';

import css from './Text.module.scss';

export type TextType = {
  children: React.ReactNode;
  variant?: TextVariant;
  color?: ColorKeys | string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Text = forwardRef<any, TextType>(
  (
    {
      children,
      variant = 'textMd',
      color = colors.gray300,
      className,
      style,
      ...props
    },
    ref?,
  ) => {
    return (
      <div
        ref={ref}
        data-variant={variant}
        className={`${css.root} ${className}`}
        style={{color, ...style}}
        {...props}
      >
        {children}
      </div>
    );
  },
);
