import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import type {AutoCutState} from '@autocut/utils/zustand/zustand';

type RecursiveCaptionParametersKeys<T, D extends number = 10> = [D] extends [
  never,
]
  ? never
  : {
      [K in keyof T]: T[K] extends object
        ? K | `${K & string}.${RecursiveCaptionParametersKeys<T[K], Prev[D]> & string}`
        : K & string;
    }[keyof T];

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export type CaptionParametersKeys =
  RecursiveCaptionParametersKeys<CaptionsParameters>;

const INFLUENT_PARAMETERS_KEYS: CaptionParametersKeys[] = [
  'text.font.label',
  'text.fontSize',
  'outline.enabled',
  'outline.width',
  'formating.uppercase',
  'formating.italic',
  'formating.nbLines',
  'formating.maxWidth',
  'formating.removePunctuation',
];

export const chunkNeedsUpdate = (
  originalParameters: AutoCutState['ui']['parameters']['caption'],
  newParameters: AutoCutState['ui']['parameters']['caption'],
): boolean => {
  for (const key of INFLUENT_PARAMETERS_KEYS) {
    if (!key) continue;
    const splittedKey = key.split('.');
    const originalValue = splittedKey.reduce(
      (acc, current) => (acc as any)[current],
      originalParameters,
    );
    const newValue = splittedKey.reduce(
      (acc, current) => (acc as any)[current],
      newParameters,
    );
    if (originalValue !== newValue) {
      return true;
    }
  }

  return false;
};
