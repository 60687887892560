import {InfoText} from '@autocut/components/InfoText/InfoText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {closeModal} from '@autocut/utils/modal/closeModal';

export const PProExtensionRenamedModal = () => {
  return (
    <Modal
      isBlocking
      title={
        <Text
          variant="displaySm.bold"
          color="white"
          style={{
            marginBottom: Spacing.s4,
          }}
        >
          <TranslatedMessage id="modals_pproRename_title" />
        </Text>
      }
      footer={
        <FlexContainer
          justifyContent="space-evenly"
          gap={Spacing.s4}
        >
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            style={{
              flexShrink: 0.7,
            }}
          >
            <TranslatedMessage id="modals_pproRename_buttons_davinci" />
          </Button>
          <Button
            variant="primary"
            color={colors.primary600}
            onClick={() => {
              closeModal();
            }}
            style={{
              flexShrink: 0.4,
            }}
          >
            <TranslatedMessage id="modals_pproRename_buttons_ppro" />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={32}
      >
        <Text
          variant="textSm.medium"
          color={colors.gray300}
        >
          <TranslatedMessage id="modals_pproRename_body" />
        </Text>

        <InfoText style="warning">
          <TranslatedMessage id="modals_pproRename_warning" />
        </InfoText>
      </FlexContainer>
    </Modal>
  );
};
