import type {Locale} from '@autocut/contexts/LocaleContext';
import type {Font} from '@autocut/types/font';
import type {IntlShape} from 'react-intl';

import {autocutStoreVanilla} from './zustand/zustand';

const ARABIC_MAX_WORDS_PER_CHUNK = 15;

export type TranscriptLanguage = {
  label: string;
  value: string;
  modelOrder: number;
  textDirection?: string;
  unsupportedFeatures?: (
    font?: Font,
  ) => Record<string, {disabled: boolean; showWarning: boolean}>;
  maxWordsPerChunk?: number;
};

export const getLanguages = (intl: IntlShape): TranscriptLanguage[] =>
  [
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_french',
        defaultMessage: 'French',
      }),
      value: 'fr',
      modelOrder: 0,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_english',
        defaultMessage: 'English',
      }),
      value: 'en',
      modelOrder: 0,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_englishUS',
        defaultMessage: 'English - United States',
      }),
      value: 'en-US',
      modelOrder: 0,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicDZ',
        defaultMessage: 'Arabic - Algeria',
      }),
      value: 'ar-DZ',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicBH',
        defaultMessage: 'Arabic - Bahrain',
      }),
      value: 'ar-BH',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicEG',
        defaultMessage: 'Arabic - Egypt',
      }),
      value: 'ar-EG',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicIQ',
        defaultMessage: 'Arabic - Iraq',
      }),
      value: 'ar-IQ',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicIL',
        defaultMessage: 'Arabic - Israel',
      }),
      value: 'ar-IL',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicJO',
        defaultMessage: 'Arabic - Jordan',
      }),
      value: 'ar-JO',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicKW',
        defaultMessage: 'Arabic - Kuwait',
      }),
      value: 'ar-KW',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicLB',
        defaultMessage: 'Arabic - Lebanon',
      }),
      value: 'ar-LB',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicMR',
        defaultMessage: 'Arabic - Mauritania',
      }),
      value: 'ar-MR',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicMA',
        defaultMessage: 'Arabic - Morocco',
      }),
      value: 'ar-MA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicOM',
        defaultMessage: 'Arabic - Oman',
      }),
      value: 'ar-OM',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicQA',
        defaultMessage: 'Arabic - Qatar',
      }),
      value: 'ar-QA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicSA',
        defaultMessage: 'Arabic - Saudi Arabia',
      }),
      value: 'ar-SA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicPS',
        defaultMessage: 'Arabic - Palestine',
      }),
      value: 'ar-PS',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicTN',
        defaultMessage: 'Arabic - Tunisia',
      }),
      value: 'ar-TN',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicAE',
        defaultMessage: 'Arabic - United Arab Emirates',
      }),
      value: 'ar-AE',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_arabicYE',
        defaultMessage: 'Arabic - Yemen',
      }),
      value: 'ar-YE',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_german',
        defaultMessage: 'German',
      }),
      value: 'de',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_hindi',
        defaultMessage: 'Hindi',
      }),
      value: 'hi',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_portugal',
        defaultMessage: 'Portuguese',
      }),
      value: 'pt',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_brazil',
        defaultMessage: 'Portuguese - Brazil',
      }),
      value: 'pt-BR',
      modelOrder: 1,
    },

    {
      label: intl.formatMessage({
        id: 'globals_language_spanish',
        defaultMessage: 'Spanish',
      }),
      value: 'es',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_spanish419',
        defaultMessage: 'Spanish - Latin America',
      }),
      value: 'es-419',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_danish',
        defaultMessage: 'Danish',
      }),
      value: 'da',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_dutch',
        defaultMessage: 'Dutch',
      }),
      value: 'nl',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_flamish',
        defaultMessage: 'Flemish',
      }),
      value: 'nl',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_italian',
        defaultMessage: 'Italian',
      }),
      value: 'it',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_japanese',
        defaultMessage: 'Japanese',
      }),
      value: 'ja',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_korean',
        defaultMessage: 'Korean',
      }),
      value: 'ko',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_norwegian',
        defaultMessage: 'Norwegian',
      }),
      value: 'no',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_polish',
        defaultMessage: 'Polish',
      }),
      value: 'pl',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_swedish',
        defaultMessage: 'Swedish',
      }),
      value: 'sv',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_tamil',
        defaultMessage: 'Tamil',
      }),
      value: 'ta',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_chinese',
        defaultMessage: 'Chinese',
      }),
      value: 'zh',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_russian',
        defaultMessage: 'Russian',
      }),
      value: 'ru',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_turkish',
        defaultMessage: 'Turkish',
      }),
      value: 'tr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_catalan',
        defaultMessage: 'Catalan',
      }),
      value: 'ca',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_indonesian',
        defaultMessage: 'Indonesian',
      }),
      value: 'id',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_finnish',
        defaultMessage: 'Finnish',
      }),
      value: 'fi',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_vietnamese',
        defaultMessage: 'Vietnamese',
      }),
      value: 'vi',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'globals_language_hebrew',
        defaultMessage: 'Hebrew',
      }),
      value: 'he',
      modelOrder: 3,
      textDirection: 'rtl',
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsHebrew, showWarning: true},
      }),
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_ukrainian',
        defaultMessage: 'Ukrainian',
      }),
      value: 'uk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_greec',
        defaultMessage: 'Greek',
      }),
      value: 'el',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_malay',
        defaultMessage: 'Malay',
      }),
      value: 'ms',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_czech',
        defaultMessage: 'Czech',
      }),
      value: 'cs',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_romanian',
        defaultMessage: 'Romanian',
      }),
      value: 'ro',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_hungarian',
        defaultMessage: 'Hungarian',
      }),
      value: 'hu',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_thai',
        defaultMessage: 'Thai',
      }),
      value: 'th',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_urdu',
        defaultMessage: 'Urdu',
      }),
      value: 'ur',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_croatian',
        defaultMessage: 'Croatian',
      }),
      value: 'hr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_bulgarian',
        defaultMessage: 'Bulgarian',
      }),
      value: 'bg',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_lithuanian',
        defaultMessage: 'Lithuanian',
      }),
      value: 'lt',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_maori',
        defaultMessage: 'Maori',
      }),
      value: 'mi',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_malayalam',
        defaultMessage: 'Malayalam',
      }),
      value: 'ml',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_welsch',
        defaultMessage: 'Welsh',
      }),
      value: 'cy',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_slovak',
        defaultMessage: 'Slovak',
      }),
      value: 'sk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_telugu',
        defaultMessage: 'Telugu',
      }),
      value: 'te',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_persian',
        defaultMessage: 'Persian',
      }),
      value: 'fa',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_latvian',
        defaultMessage: 'Latvian',
      }),
      value: 'lv',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_bengali',
        defaultMessage: 'Bengali',
      }),
      value: 'bn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_serbian',
        defaultMessage: 'Serbian',
      }),
      value: 'sr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_azerbaijani',
        defaultMessage: 'Azerbaijani',
      }),
      value: 'az',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_slovenian',
        defaultMessage: 'Slovenian',
      }),
      value: 'sl',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_kannada',
        defaultMessage: 'Kannada',
      }),
      value: 'kn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_estonian',
        defaultMessage: 'Estonian',
      }),
      value: 'et',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_macedonian',
        defaultMessage: 'Macedonian',
      }),
      value: 'mk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_breton',
        defaultMessage: 'Breton',
      }),
      value: 'br',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_basque',
        defaultMessage: 'Basque',
      }),
      value: 'eu',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_icelandic',
        defaultMessage: 'Icelandic',
      }),
      value: 'is',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_armenian',
        defaultMessage: 'Armenian',
      }),
      value: 'hy',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_nepali',
        defaultMessage: 'Nepali',
      }),
      value: 'ne',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_mongolian',
        defaultMessage: 'Mongolian',
      }),
      value: 'mn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_bosnia',
        defaultMessage: 'Bosnian',
      }),
      value: 'bs',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_kazakh',
        defaultMessage: 'Kazakh',
      }),
      value: 'kk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_albanian',
        defaultMessage: 'Albanian',
      }),
      value: 'sq',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_swahili',
        defaultMessage: 'Swahili',
      }),
      value: 'sw',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_galician',
        defaultMessage: 'Galician',
      }),
      value: 'gl',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_marathi',
        defaultMessage: 'Marathi',
      }),
      value: 'mr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_punjabi',
        defaultMessage: 'Punjabi',
      }),
      value: 'pa',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_sinhala',
        defaultMessage: 'Sinhala',
      }),
      value: 'si',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_khmer',
        defaultMessage: 'Khmer',
      }),
      value: 'km',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_shona',
        defaultMessage: 'Shona',
      }),
      value: 'sn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_yoruba',
        defaultMessage: 'Yoruba',
      }),
      value: 'yo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_somali',
        defaultMessage: 'Somali',
      }),
      value: 'so',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_afrikaans',
        defaultMessage: 'Afrikaans',
      }),
      value: 'af',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_occitan',
        defaultMessage: 'Occitan',
      }),
      value: 'oc',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_georgian',
        defaultMessage: 'Georgian',
      }),
      value: 'ka',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_belarusian',
        defaultMessage: 'Belarusian',
      }),
      value: 'be',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_tajik',
        defaultMessage: 'Tajik',
      }),
      value: 'tg',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_sindhi',
        defaultMessage: 'Sindhi',
      }),
      value: 'sd',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_gujarati',
        defaultMessage: 'Gujarati',
      }),
      value: 'gu',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_amharic',
        defaultMessage: 'Amharic',
      }),
      value: 'am',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_yiddish',
        defaultMessage: 'Yiddish',
      }),
      value: 'yi',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_lao',
        defaultMessage: 'Lao',
      }),
      value: 'lo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_uzbeck',
        defaultMessage: 'Uzbek',
      }),
      value: 'uz',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_faroese',
        defaultMessage: 'Faroese',
      }),
      value: 'fo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_haitian',
        defaultMessage: 'Haitian Creole',
      }),
      value: 'ht',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_pashto',
        defaultMessage: 'Pashto',
      }),
      value: 'ps',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_turkmen',
        defaultMessage: 'Turkmen',
      }),
      value: 'tk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_norwegianNynorsk',
        defaultMessage: 'Norwegian Nynorsk',
      }),
      value: 'nn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_maltese',
        defaultMessage: 'Maltese',
      }),
      value: 'mt',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_sanskrit',
        defaultMessage: 'Sanskrit',
      }),
      value: 'sa',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_luxembourgish',
        defaultMessage: 'Luxembourgish',
      }),
      value: 'lb',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_burmese',
        defaultMessage: 'Burmese',
      }),
      value: 'my',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_tibetan',
        defaultMessage: 'Tibetan',
      }),
      value: 'bo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_tagalog',
        defaultMessage: 'Tagalog',
      }),
      value: 'tl',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_malagasay',
        defaultMessage: 'Malagasy',
      }),
      value: 'mg',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_assamese',
        defaultMessage: 'Assamese',
      }),
      value: 'as',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_tatar',
        defaultMessage: 'Tatar',
      }),
      value: 'tt',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_hawaiian',
        defaultMessage: 'Hawaiian',
      }),
      value: 'haw',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_lingala',
        defaultMessage: 'Lingala',
      }),
      value: 'ln',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_hausa',
        defaultMessage: 'Hausa',
      }),
      value: 'ha',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_bashkir',
        defaultMessage: 'Bashkir',
      }),
      value: 'ba',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_javanese',
        defaultMessage: 'Javanese',
      }),
      value: 'jw',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'globals_language_sundanese',
        defaultMessage: 'Sundanese',
      }),
      value: 'su',
      modelOrder: 3,
    },
  ].sort((a, b) =>
    a.modelOrder > b.modelOrder
      ? 1
      : a.modelOrder < b.modelOrder
        ? -1
        : a.label.localeCompare(b.label),
  );

export const getPProLanguageCode = (language: Locale) => {
  switch (language) {
    case 'ES':
      return 'es_ES';
    case 'FR':
      return 'fr_FR';
    case 'JP':
      return 'ja_JP';
    case 'KR':
      return 'ko_KR';
    case 'PT':
      return 'pt_BR';
    default:
      return 'en_US';
  }
};

export const getHostNameForTranslation = () => {
  const hostName = autocutStoreVanilla().ui.host;
  switch (hostName) {
    case 'davinci':
      return 'DaVinci';
      break;
    case 'ppro':
      return 'Premiere Pro';
      break;
    default:
      return 'Unknown Host';
      break;
  }
};
