import type {Location} from 'react-router';

import type {StepperProps, StepperStep} from '../Stepper';

import {preload} from '@autocut/types/ElectronPreload';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import {useEffect, useMemo} from 'react';
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router';

import Stepper from '../Stepper';

export type StepperRouterStep<T> = {
  path: string;
  index?: boolean;
  element: JSX.Element;
  clickable?: boolean;
} & Omit<StepperStep<T>, 'onClick'>;

export type StepperRouterProps<T> = {
  steps: StepperRouterStep<T>[];
  basePath?: string;
} & Omit<StepperProps<T>, 'activeIndex' | 'steps'>;

const StepperRouter = <T,>({steps, basePath}: StepperRouterProps<T>) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const activeIndex = useMemo(() => {
    const res =
      steps.findIndex(step => {
        return matchPath(
          preload.path.posix.join(basePath ?? '', step.path),
          pathname,
        );
      }) - 1;
    return res >= -1 ? res : steps.findIndex(step => step.index) - 1;
  }, [basePath, pathname, steps]);

  const indexStep = useMemo(
    () => steps.find(step => step.index) || steps[0],
    [steps],
  );

  useEffect(() => {
    setAutocutStore('ui.currentSteps', steps);

    return () => {
      setAutocutStore('ui.currentSteps', []);
    };
  }, []);

  return (
    <>
      <Stepper
        activeIndex={activeIndex}
        steps={steps.map(step => ({
          ...step,
          onClick: step.clickable ? () => navigate(step.path) : undefined,
        }))}
      />
      <Routes>
        <Route
          path=""
          element={
            <>
              <Navigate to={indexStep.path} />
            </>
          }
        />
        {steps.map(step => (
          <Route
            key={step.path}
            {...step}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={indexStep.path} />}
        />
      </Routes>
    </>
  );
};

export default StepperRouter;

export const getPreviousStepPath = (location: Location) => {
  const currentSteps = autocutStoreVanilla().ui.currentSteps;
  if (!currentSteps) return '/homepage';

  const previousIndex =
    currentSteps.findIndex(step =>
      location.pathname.endsWith('/' + step.path),
    ) - 1;

  if (previousIndex < 0) return '/homepage';

  const path = currentSteps[previousIndex]?.path;
  return path ? path : '/homepage';
};
