import type {XMLDocument} from '../class/_XMLDocument';
import type {XMLTrack} from '../class/_XMLTrack';
import type {TimelinePpro} from '../temp/_pproTimeline.temp';

import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import {localScaleEffectName} from '@autocut/enums/videoEffect.enum';

import {getCaptionsTemplateTracks} from './_getCaptionsTemplateTrack';
import {getFactors} from './_getFactors';

export const resizeTemplateClip = (
  track: XMLTrack,
  sequenceWidth: number,
  sequenceHeight: number,
  params?: {clipIndex?: number; withMasterClip?: boolean},
) => {
  const clip = track.getClips()[params?.clipIndex || 0];
  clip.resize([sequenceWidth, sequenceHeight]);
  if (params?.withMasterClip) {
    clip.masterClip?.resize([sequenceWidth, sequenceHeight]);
  }

  return clip;
};

export const resizeTemplates = (
  tracks: [XMLTrack, {withMasterClip: boolean; withLocalScale: boolean}][],
  sequenceWidth: number,
  sequenceHeight: number,
  optimalScale: number,
) => {
  return tracks.map(([track, params]) => {
    const clip = resizeTemplateClip(track, sequenceWidth, sequenceHeight, {
      withMasterClip: params.withMasterClip,
    });

    if (params.withLocalScale) {
      clip.updateMotionParam(
        localScaleEffectName[CAPTIONS_XML_PROJECT_LOCALE],
        optimalScale,
      );
    }

    return clip;
  });
};

export const resizeXmlTemplates = async (
  xmlDoc: XMLDocument,
  timeline: TimelinePpro,
  optimalScale: number,
) => {
  const {
    textBackgroundTrack,
    wordBackgroundTrack,
    captionsTrack,
    effectsTrack,
    floatingTextEffectTrack,
  } = getCaptionsTemplateTracks(xmlDoc);

  const sequenceWidth = timeline.settings.width;
  const sequenceHeight = timeline.settings.height;

  const factors = await getFactors({
    sequenceWidth,
    sequenceHeight,
    optimalScale,
  });

  const [
    captionsClip,
    floatingTextEffectClip,
    textBackgroundClip,
    wordBackgroundClip,
  ] = resizeTemplates(
    [
      [captionsTrack, {withMasterClip: true, withLocalScale: false}],
      [
        floatingTextEffectTrack,
        {
          withMasterClip: true,
          withLocalScale: true,
        },
      ],
      [textBackgroundTrack, {withMasterClip: false, withLocalScale: true}],
      [wordBackgroundTrack, {withMasterClip: false, withLocalScale: true}],
    ],
    sequenceWidth,
    sequenceHeight,
    factors.optimalScale,
  );

  const [effectsClip] = resizeTemplates(
    [[effectsTrack, {withMasterClip: true, withLocalScale: true}]],
    sequenceWidth,
    sequenceHeight,
    300,
  );

  return {
    captionsClip,
    floatingTextEffectClip,
    textBackgroundClip,
    wordBackgroundClip,
    effectsClip,
    factors,
  };
};

export type ResizedXMLTemplates = Awaited<
  ReturnType<typeof resizeXmlTemplates>
>;
