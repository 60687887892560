import type {AutoCutApiError} from './AutoCutApiError';

import {IncrementalError} from './IncrementalError';

// const isIncrementalError = (error: any): error is IncrementalError => {
//   return (error as IncrementalError).fingerprint !== undefined;
// };

// const isAutoCutApiError = (error: any): error is AutoCutApiError => {
//   return (error as AutoCutApiError).status !== undefined;
// };

// const isComputeError = (error: any): error is ComputeError => {
//   return (error as ComputeError).taskId !== undefined;
// };

const isError = (error: any): error is Error => {
  return (error as IncrementalError).fingerprint === undefined;
};

export const FingerprintErrorFactory = (
  baseError: string | Error | IncrementalError | AutoCutApiError,
  fingerprint: string,
) => {
  if (typeof baseError === 'string' || isError(baseError)) {
    return new IncrementalError(baseError, fingerprint);
  } else {
    const fingerprintError: IncrementalError = baseError;
    fingerprintError.fingerprint = `${fingerprint} - ${fingerprintError.fingerprint}`;

    return fingerprintError;
  }
};
