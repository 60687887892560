import type {TranslationKeys} from '@autocut/contexts/LocaleContext';
import type {ComputeRequestId} from '@autocut/utils/compute.utils';
import type {DeepPartial} from '@autocut/utils/type.utils';

import {ModalType} from '@autocut/enums/modals.enum';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import {nanoid} from 'nanoid';

import {closeModal} from '../modal/closeModal';
import {displayPriorityModal} from '../modal/displayPriorityModal';

export type ProcessStep = {
  id: string;
  translationKey: TranslationKeys;
  countFor?: number;
  progress:
    | {
        finished?: boolean;
        computeTaskId: ComputeRequestId;
        currentStep?: undefined;
        nbSteps?: undefined;
      }
    | {
        finished?: boolean;
        computeTaskId?: undefined;
        currentStep: number;
        nbSteps: number;
      };
};
export type ProcessProgress = {
  id: string;
  processNameKey: TranslationKeys;
  steps: ProcessStep[];
};

export const initProcessProgress = (
  id: string,
  processNameKey: TranslationKeys,
): ProcessProgress => {
  return {
    id,
    processNameKey,
    steps: [],
  };
};

export const addProcessStep = (
  processProgress: ProcessProgress,
  step: Omit<ProcessStep, 'id'> & Partial<Pick<ProcessStep, 'id'>>,
) => {
  const newStep = {
    ...step,
    id: step.id || nanoid(10),
    countFor: step.countFor || 1,
    progress: {...step.progress, finished: false},
  };

  processProgress.steps = [...processProgress.steps, newStep];

  if (
    autocutStoreVanilla().onGoingProcess.progress?.id === processProgress.id
  ) {
    setAutocutStore('onGoingProcess.progress', processProgress);
  }

  return newStep;
};

export const addTaskProcessStep = (
  processProgress: ProcessProgress,
  translationKey: TranslationKeys,
  taskId: ComputeRequestId,
  opt?: {countFor?: number; id?: string},
) => {
  return addProcessStep(processProgress, {
    id: opt?.id,
    translationKey,
    countFor: opt?.countFor,
    progress: {
      computeTaskId: taskId,
    },
  });
};

export const addStepProcessStep = (
  processProgress: ProcessProgress,
  translationKey: TranslationKeys,
  nbSteps: number,
  opt?: {countFor?: number; id?: string},
) => {
  return addProcessStep(processProgress, {
    id: opt?.id,
    translationKey,
    countFor: opt?.countFor,
    progress: {
      currentStep: 1,
      nbSteps,
    },
  });
};

export const addProcessSteps = (
  processProgress: ProcessProgress,
  steps: {
    id?: string;
    translationKey: TranslationKeys;
    progress: number | ComputeRequestId;
    countFor?: number;
  }[],
) => {
  return steps.map(step => {
    if (typeof step.progress === 'number') {
      return addStepProcessStep(
        processProgress,
        step.translationKey,
        step.progress,
        {id: step.id, countFor: step.countFor},
      );
    } else {
      return addTaskProcessStep(
        processProgress,
        step.translationKey,
        step.progress,
        {id: step.id, countFor: step.countFor},
      );
    }
  });
};

export const updateProcessStep = (
  processProgress: ProcessProgress,
  stepId: string,
  step: Omit<DeepPartial<ProcessStep>, 'id'>,
) => {
  const stepIndex = processProgress.steps.findIndex(step => step.id === stepId);
  const currentStep = processProgress.steps[stepIndex];
  const beforeSteps = processProgress.steps.slice(
    0,
    stepIndex,
  ) as ProcessStep[];
  const afterSteps = processProgress.steps.slice(
    stepIndex + 1,
  ) as ProcessStep[];

  const newStep = {
    ...(currentStep || {}),
    ...step,
    progress: {
      ...(currentStep || {}).progress,
      ...step.progress,
    },
  };

  processProgress.steps = [
    ...beforeSteps,
    newStep,
    ...afterSteps,
  ] as ProcessStep[];

  if (
    autocutStoreVanilla().onGoingProcess.progress?.id === processProgress.id
  ) {
    setAutocutStore('onGoingProcess.progress', processProgress);
  }

  return newStep;
};

export const endProcessStep = (
  processProgress: ProcessProgress,
  stepId: string,
) => {
  return updateProcessStep(processProgress, stepId, {
    progress: {
      finished: true,
    },
  });
};

export const startProcessProgress = (processProgress: ProcessProgress) => {
  setAutocutStore('onGoingProcess.progress', processProgress);
  displayPriorityModal(ModalType.Processing);
};

export const endCurrentProcessProgress = () => {
  if (autocutStoreVanilla().modal.openedModalName === ModalType.Processing) {
    closeModal();
  }
  setAutocutStore('onGoingProcess.progress', undefined);
};
