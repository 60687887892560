import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconBarChart04 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3 11v10m12-10v10M9 3v18M21 3v18"
      />
    </IconComponent>
  );
};
