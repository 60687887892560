import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {PProExtensionRenamedModal} from '@autocut/modals/PProExtensionRenamedModal/PProExtensionRenamedModal';
import {RestartPremiereModal} from '@autocut/modals/RestartPremiereModal/RestartPremiereModal';
import UsageStatsModal from '@autocut/modals/UsageStatsModal/UsageStatsModal';
import {DeletePresetsModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPresets/DeletePresetsModal/DeletePresetsModal';
import {SearchPublicPresetModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPresets/SearchPublicPresetModal/SearchPublicPresetModal';
import {CreatePresetModal} from '@autocut/pages/modes/captions/customization/parts/CreatePresetModal/CreatePresetModal';
import {CreateResizePresetModal} from '@autocut/pages/modes/resize/customization/steps/ResizePresetStep/CreateResizePresetModal';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useEffect, useMemo} from 'react';

import {AppStoreDvModal} from './AppStoreDvModal/AppStoreDvModal';
import {BetaFeedbackModal} from './BetaFeedbackModal/BetaFeedbackModal';
import {BetaOnboardingModal} from './BetaOnboardingModal/BetaOnboardingModal';
import {BRollCreditsModal} from './BRollCreditsModal/BRollCreditsModal';
import ContactUsModal from './ContactUsModal/ContactUsModal';
import {DeepgramErrorModal} from './DeepgramErrorModal/DeepgramErrorModal';
import {ElectronVersionModal} from './ElectronVersionModal/ElectronVersionModal';
import {EndCaptionsProcessModal} from './EndProcessModal/captions/EndCaptionsProcessModal';
import {EndProcessModal} from './EndProcessModal/EndProcessModal';
import ErrorModal from './ErrorModal/ErrorModal';
import FeatureRequestModal from './FeatureRequestModal/FeatureRequestModal';
import {FeedbackModal} from './FeedbackModal/FeedbackModal';
import {FontNotFoundModal} from './FontNotFoundModal/FontNotFoundModal';
import {GamingTrialModal} from './GamingTrialModal/GamingTrialModal';
import {KeyExpiredModal} from './KeyExpiredModal/KeyExpiredModal';
import {LaunchErrorModal} from './LaunchErrorModal/LaunchErrorModal';
import {ManageSwearWordsModal} from './ManageSwearWordsModal/ManageSwearWordsModal';
import {ManualUpdateModal} from './ManualUpdateModal/ManualUpdateModal';
import {NoLinkModal} from './NoLinkModal/NoLinkModal';
import {NotEnoughTracksModal} from './NotEnoughTracksModal/NotEnoughTracksModal';
import {OnboardingStatModal} from './OnboardingStatModal/OnboardingStatModal';
import {ProcessModal} from './ProcessModal/ProcessModal';
import {ReportIssueModal} from './ReportIssueModal/ReportIssueModal';
import {TrialExpiredModal} from './TrialExpiredModal/TrialExpiredModal';
import {UpdateElectronModal} from './UpdateElectronModal/UpdateElectronModal';
import {UpdateHostModal} from './UpdateHostModal/UpdateHostModal';
import {UpdateTimeoutModal} from './UpdateTimeoutModal/UpdateTimeoutModal';
import {UsageModal} from './UsageModal/UsageModal';

const ModalManager = () => {
  const {openedModalName, modalQueue} = useAutoCutStore(state => ({
    openedModalName: state.modal.openedModalName,
    modalQueue: state.modal.modalQueue,
  }));

  useEffect(() => {
    console.log(
      '[MODAL] OpenedModalName: ',
      openedModalName,
      ' ModalQueue: ',
      modalQueue,
    );
    console.log({openedModalName});
    if (!openedModalName && modalQueue.length > 0) {
      const newModal = modalQueue.splice(0, 1);
      setAutocutStore('modal.openedModalName', newModal[0]);
      setAutocutStore('modal.modalQueue', modalQueue);
    }
  }, [openedModalName, modalQueue]);

  const ModalComponent = useMemo(() => {
    switch (openedModalName) {
      // Global modals
      case ModalType.Feedback:
        return FeedbackModal;
      case ModalType.TrialExpired:
        return TrialExpiredModal;
      case ModalType.Processing:
        return ProcessModal;
      case ModalType.Onboarding:
        return OnboardingStatModal;
      case ModalType.KeyExpired:
        return KeyExpiredModal;
      case ModalType.UsageStats:
        return UsageStatsModal;
      case ModalType.BetaOnboarding:
        return BetaOnboardingModal;
      case ModalType.BetaFeedback:
        return BetaFeedbackModal;
      case ModalType.NoLink:
        return NoLinkModal;
      case ModalType.GamingTrial:
        return GamingTrialModal;
      case ModalType.AppStoreDv:
        return AppStoreDvModal;
      case ModalType.UpdateElectron:
        return UpdateElectronModal;
      case ModalType.UpdateHost:
        return UpdateHostModal;
      case ModalType.PProExtensionRenamed:
        return PProExtensionRenamedModal;
      case ModalType.RestartPremierePro:
        return RestartPremiereModal;
      case ModalType.ManualUpdate:
        return ManualUpdateModal;

      // Header modals
      case ModalType.ContactUs:
        return ContactUsModal;
      case ModalType.FeatureRequest:
        return FeatureRequestModal;
      case ModalType.ReportIssue:
        return ReportIssueModal;

      // Process modals
      case ModalType.EndProcess:
        return EndProcessModal;

      // Modes modals
      case ModalType.CreateResizePreset:
        return CreateResizePresetModal;
      case ModalType.EndCaptionsProcess:
        return EndCaptionsProcessModal;
      case ModalType.BRollCredits:
        return BRollCreditsModal;
      case ModalType.NotEnoughTracks:
        return NotEnoughTracksModal;
      case ModalType.ManageSavedSwearWords:
        return ManageSwearWordsModal;

      // Captions modals
      case ModalType.CreatePresetModal:
        return CreatePresetModal;
      case ModalType.SearchPreset:
        return SearchPublicPresetModal;
      case ModalType.DeletePresets:
        return DeletePresetsModal;

      // Errors modals
      case ModalType.Error:
        return ErrorModal;
      case ModalType.UsageLimit:
        return UsageModal;
      case ModalType.DeepgramError:
        return DeepgramErrorModal;
      case ModalType.FontNotFound:
        return FontNotFoundModal;
      case ModalType.ElectronVersionModal:
        return ElectronVersionModal;
      case ModalType.LaunchError:
        return LaunchErrorModal;
      case ModalType.UpdateTimeout:
        return UpdateTimeoutModal;

      default:
        return null;
    }
  }, [openedModalName]);

  return ModalComponent ? <ModalComponent /> : null;
};

export default ModalManager;
