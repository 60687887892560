import type {CaptionChunk} from '@autocut/types/Captions';
import type {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';

import {FingerprintErrorFactory} from '@autocut/utils/errors/FingerprintErrorFactory';
import {autocutApi} from '@autocut/utils/http.utils';
import {manageError} from '@autocut/utils/manageError';
import {getWordValue} from '@autocut/utils/process/captions/prepareCaptions/getWordValue';

import {getTextLineBreak} from './processCaptionsChunks';

export const formatNumbersFromChunks = async ({
  transcription,
}: {
  transcription: CaptionChunk[];
}) => {
  const lineBreak = getTextLineBreak();

  const newTranscription = [...transcription];
  const wordsArray = transcription.flatMap(chunk => chunk.lines.flat());
  const text = wordsArray
    .map(word => getWordValue(word))
    .join(' ')
    .replace(lineBreak, ' ');
  const {
    data = [],
  }: {
    data: {
      identified_word: string;
      formatted_word: string;
    }[];
  } = await autocutApi
    .post('openAI/formatNumbersFromChunks', {
      text,
    })
    .catch((error: AutoCutApiError) => {
      manageError({
        error: FingerprintErrorFactory(error, 'formatNumbersFromChunks'),
      });

      throw error;
    });

  let replacedWords = 0;

  data.forEach(({identified_word, formatted_word}) => {
    newTranscription.forEach(chunk => {
      if (!chunk.text.includes(identified_word)) return;

      chunk.text = chunk.text.replaceAll(identified_word, formatted_word);

      chunk.lines.forEach(line =>
        line.forEach(word => {
          if (!word.word.includes(identified_word)) return;
          const newWord = word.word.replaceAll(identified_word, formatted_word);
          const newPunctuatedWord =
            word.punctuated_word?.replaceAll(identified_word, formatted_word) ??
            newWord;
          word.word = newWord;
          word.punctuated_word = newPunctuatedWord;
        }),
      );
      let newIndex: null | number = null;
      chunk.highlight.forEach(highlight => {
        if (!highlight.word.includes(identified_word) && !newIndex) return;
        const newWord = highlight.word.replaceAll(
          identified_word,
          formatted_word,
        );
        const indexStart = newIndex ?? highlight.indexStart;
        const indexEnd = newIndex
          ? newIndex + newWord.length
          : indexStart + newWord.length;

        highlight.word = newWord;
        highlight.indexStart = indexStart;
        highlight.indexEnd = indexEnd;
        newIndex = indexEnd + 1;

        replacedWords += 1;
      });
    });
  });

  return {newTranscription, replacedWords};
};
