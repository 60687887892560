import type {
  ResourceManagerElementConfig,
  ResourceScope,
} from '@autocut/types/ResourceManager';

import logLevel from '@autocut/enums/logLevel.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';

import {downloadFile} from '../files.utils';
import {autocutApi} from '../http.utils';
import {logger} from '../logger';

const logMessage = async (
  elementName: string,
  level: logLevel,
  message = 'log',
  objects = {},
) => {
  logger(
    `defaultResourceManagerState [${elementName}]`,
    level,
    message,
    objects,
  );
};

export const getRessourceSignedUrl = async (fileName: string) => {
  const signedUrlResponse = await autocutApi.post(
    `/downloadableRessources/fileUrl`,
    {
      fileName,
    },
  );
  const signedUrl = signedUrlResponse.data;

  return signedUrl as string;
};

// ressourceName should correspond to a key in resourcesConfigs
export const downloadRessource = async ({
  bucketFileName,
  outputFilePath,
}: {
  bucketFileName: string;
  outputFilePath: string;
}) => {
  const signedUrl = await getRessourceSignedUrl(bucketFileName);

  if (!preload.fs.existsSync(outputFilePath)) {
    preload.fs.mkdirSync(preload.path.dirname(outputFilePath), {
      recursive: true,
    });
  }

  await downloadFile(signedUrl, outputFilePath, true);
  await logMessage(
    bucketFileName,
    logLevel.info,
    `${bucketFileName} downloaded`,
  );

  return outputFilePath;
};

export const getResourceConfig =
  ({
    fileName,
    folderPath = 'global',
    scope = 'global',
    additionalData = {
      requestOnInit: true,
    },
  }: {
    fileName: string;
    folderPath?: string;
    scope?: ResourceScope;
    additionalData?: any;
  }): (() => ResourceManagerElementConfig) =>
  () => ({
    fileName,
    downloadMethod: async () => {
      const filePath = preload.path.join(
        getResourceFolderPath(),
        folderPath,
        fileName,
      );
      return downloadRessource({
        bucketFileName: fileName,
        outputFilePath: filePath,
      });
    },
    existCheck: async () => {
      const filePath = preload.path.join(
        getResourceFolderPath(),
        folderPath,
        fileName,
      );

      return {
        isExist: preload.fs.existsSync(filePath),
        outputFilePath: filePath,
      };
    },
    scope,
    ...additionalData,
  });

export const requestResourceOnInit =
  (
    resourceConfig: ResourceManagerElementConfig,
  ): (() => ResourceManagerElementConfig) =>
  () => ({
    ...resourceConfig,
    requestOnInit: true,
  });

export type PlatformResourcePathsConfig = {
  folderPath: string;
  extractSubfolder?: string;
  versionFilename?: string;
  downloadUrl: string;
};
export const getInstallResourceAtSpecificPathConfig = ({
  mac,
  windows,
  resourceName,
  createFolderIfNotExist = true,
  isOutdated,
}: {
  mac: PlatformResourcePathsConfig;
  windows: PlatformResourcePathsConfig;
  resourceName: string;
  createFolderIfNotExist?: boolean;
  isOutdated?: (versionFileContent: string) => Promise<boolean>;
}) => {
  const platform = preload.os.platform() === 'win32' ? 'windows' : 'mac';

  const platforms = {
    mac,
    windows,
  };

  return requestResourceOnInit({
    fileName: resourceName,
    downloadMethod: async () => {
      return (
        (await preload.resources.download(platforms[platform].downloadUrl)) ||
        ''
      );
    },
    move: async (downloadedFilePath: string) => {
      if (platforms[platform].extractSubfolder) {
        const extractPath = preload.path.join(
          platforms[platform].folderPath,
          platforms[platform].extractSubfolder || '',
        );
        if (preload.fs.existsSync(extractPath)) {
          try {
            const timestamp = Date.now();
            const deleteFolder = `${extractPath}-to-delete-${timestamp}`;
            await preload.fs.renameSync(extractPath, deleteFolder);
            await preload.fs.rmSync(deleteFolder, {recursive: true});
          } catch (error) {
            console.error(
              `Error while deleting ${extractPath} before unzipping ${resourceName}`,
            );
          }
        }
      }

      const unzipFunction = async () =>
        await preload.resources.unzip(
          downloadedFilePath,
          preload.path.join(
            platforms[platform].folderPath,
            platforms[platform].extractSubfolder || '',
          ),
        );
      try {
        await unzipFunction();
      } catch (error) {
        if (
          error instanceof Error &&
          (error.message.includes('EPERM') ||
            error.message.includes('EACCES') ||
            error.message.includes('Permission denied'))
        ) {
          console.debug(
            '[Resources]',
            resourceName,
            'Fail to unzip. Updating permissions...',
          );
          const consentGrant = await preload.childProcess.sudo_exec(
            `chmod -R 777 "${platforms[platform].folderPath}"`,
          );
          if (consentGrant) {
            console.debug(
              '[Resources]',
              resourceName,
              'Permissions updated, retrying...',
            );
            await unzipFunction();
          } else {
            console.error(
              '[Resources]',
              resourceName,
              'User refused to update permissions',
            );
            throw new IncrementalError(
              'User refused to update permissions',
              'requestResourceOnInit.move',
            );
          }
        } else {
          console.debug(
            '[Resources]',
            resourceName,
            'Fail to unzip',
            `${error}`,
          );
          throw error;
        }
      }

      return platforms[platform].folderPath;
    },
    existCheck: async () => {
      console.debug(resourceName, 'existCheck');
      if (
        !preload.fs.existsSync(platforms[platform].folderPath) &&
        !createFolderIfNotExist
      ) {
        //We return true to avoid downloading the script if the folder doesn't exist (which mean that DV is not installed)
        console.debug(
          resourceName,
          'folder',
          platforms[platform].folderPath,
          'does not exist',
        );
        return {isExist: true, outputFilePath: platforms[platform].folderPath};
      }
      if (!platforms[platform].versionFilename || !isOutdated) {
        console.debug(resourceName, 'no version filename or isOutdated', {
          versionFilename: platforms[platform].versionFilename,
          isOutdated: !!isOutdated,
        });
        return {isExist: false, outputFilePath: platforms[platform].folderPath};
      }
      const versionFilePath = preload.path.join(
        platforms[platform].folderPath,
        platforms[platform].extractSubfolder || '',
        platforms[platform].versionFilename || '',
      );
      if (!preload.fs.existsSync(versionFilePath)) {
        console.debug(
          resourceName,
          'version file does not exist',
          versionFilePath,
        );
        return {isExist: false, outputFilePath: versionFilePath};
      }
      const versionFileContent = preload.fs
        .readFileSync(versionFilePath, 'utf-8')
        .trim();

      const outdated = await isOutdated(versionFileContent);
      if (outdated) {
        console.debug(resourceName, 'outdated');
        return {isExist: false, outputFilePath: versionFilePath};
      }
      console.debug(resourceName, 'up to date');
      return {isExist: true, outputFilePath: versionFilePath};
    },
    scope: 'global',
  });
};
