import type {CardProps} from '../Card/Card';

import {forwardRef} from 'react';

import {Card} from '../Card/Card';
import {Text} from '../Text/Text';

export type BadgeProps = CardProps;

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({children, ...props}, ref) => (
    <Card
      ref={ref}
      {...props}
    >
      <Text
        variant="textXs.bold"
        color="white"
      >
        {children}
      </Text>
    </Card>
  ),
);
