import logLevel from '@autocut/enums/logLevel.enum';
import {preload} from '@autocut/types/ElectronPreload';

import {logger} from './logger';

/**
 * @deprecated Do not use the local storage, use zustand synced with cookies instead
 */
export const setLocalStorage = (
  variableName: string,
  value: any,
  log = false,
) => {
  if (value === undefined || value === null) return;

  if (log) {
    logger('localStorageUtils', logLevel.debug, 'Saving in localStorage', {
      variableName,
      value,
    });
  }

  localStorage.setItem(variableName, JSON.stringify(value));
  console.debug('[LocalStorage]', 'Set', variableName);
};

/**
 * @deprecated Do not use the local storage, use zustand synced with cookies instead
 */
export const getLocalStorage = (variableName: string, log = true): any => {
  const value = localStorage.getItem(variableName);

  if (log)
    logger('localStorageUtils', logLevel.debug, 'Loading from localStorage', {
      variableName,
      value,
    });

  console.debug('[LocalStorage]', 'Get', variableName);
  return value ? JSON.parse(value) : undefined;
};

/**
 * @deprecated Do not use the local storage, use zustand synced with cookies instead
 */
export const getAllLocalStorage = (): any => {
  const keys = Object.keys(localStorage);
  const items: {[key: string]: any} = {};

  for (const key of keys) {
    items[key] = getLocalStorage(key, false);
  }

  return items;
};

/**
 * @deprecated Do not use the local storage, use zustand synced with cookies instead
 */
export const removeAllLocalStorage = () => {
  localStorage.clear();
};

/**
 * @deprecated Only for V1 migration
 */
export const getV1LogFolder = () => {
  const {fs, os, path} = preload;

  const homeDir = os.homedir();
  const platform = os.platform();
  let logPath;

  switch (platform) {
    case 'darwin': {
      logPath = path.join(homeDir, 'Library', 'Logs', 'fr.toolpad.autoCut');
      break;
    }

    case 'win32': {
      logPath = path.join(homeDir, 'AppData', 'Roaming', 'fr.toolpad.autoCut');
      break;
    }

    case 'linux': {
      logPath = path.join(homeDir, '.logs', 'fr.toolpad.autoCut');
      break;
    }

    default:
      logPath = path.join(homeDir, '.logs', 'fr.toolpad.autoCut');
      break;
  }

  try {
    if (!fs.existsSync(logPath)) {
      fs.mkdirSync(logPath);
    }
  } catch (err: any) {
    console.log(logLevel.crit, `Failed command:  + fs.mkdirSync(${logPath})`);
    console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
  }

  return logPath;
};

/**
 * @deprecated Only for V1 migration
 */
const getPersistanceStorageFilePath = () => {
  const folder = getV1LogFolder();
  const filePath = preload.path.join(folder, 'persistenceStorage.log');

  return filePath;
};

/**
 * @deprecated Only for V1 migration
 */
export const fillLocalStorageWithPersistanceStorage = () => {
  const {fs} = preload;

  const filePath = getPersistanceStorageFilePath();
  console.debug('[persistenceStorage] filePath', filePath);

  if (!fs.existsSync(filePath)) return;

  let data: any = {};

  try {
    // READ PERSISTANCE STORAGE FILE
    const fileData = fs.readFileSync(filePath, 'utf8');
    data = JSON.parse(fileData);
    console.debug('[persistenceStorage] data', data);
    for (const property in data) {
      console.debug(
        '[persistenceStorage] setLocalStorage',
        property,
        data[property],
      );
      setLocalStorage(property, data[property], false);
    }
  } catch (error: any) {
    // Do nothing if can't read persistance storage
  }
};
