import type {AutocutModeIds} from '@autocut/enums/modes.enum';

import {isPreloadReady, preload} from '@autocut/types/ElectronPreload';
import {
  fillLocalStorageWithPersistanceStorage,
  getLocalStorage,
} from '@autocut/utils/localStorage.utils';
import {zSync_Backup} from '@autocut/utils/zustand/backup.zustand';
import {zSync_Dev} from '@autocut/utils/zustand/dev.zustand';
import {zSync_Game} from '@autocut/utils/zustand/game.zustand';
import {zSync_Misc} from '@autocut/utils/zustand/misc.zustand';
import {zSync_OnGoingProcess} from '@autocut/utils/zustand/onGoingProcess.zustand';
import {zSync_UI} from '@autocut/utils/zustand/ui.zustand';
import {zSync_User} from '@autocut/utils/zustand/user.zustand';
import {
  deepFind,
  deepSet,
} from '@autocut/utils/zustand/utils/objectManagement.zustand.utils';
import {
  type AutoCutStateKeys,
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import {parametersValidationSchema} from '@autocut/validationSchemas/parameters.validationSchema';
import {cloneDeep, merge} from 'lodash';

export type ZustandSyncConfig<Keys extends string> = {
  [K in Keys]?: {
    cookieName: string;
    defaultValue?: any;
  };
};

// To synchronise a field with local storage, add it to this object with the name of the local storage key
const cookiesSyncKeys: () => ZustandSyncConfig<AutoCutStateKeys> = () => ({
  ...zSync_Backup,
  ...zSync_Dev,
  ...zSync_Game,
  ...zSync_Misc,
  ...zSync_OnGoingProcess,
  ...zSync_UI,
  ...zSync_User,
});

//Set the value of the local storage with the value of the store
export const syncToCookies = (key: AutoCutStateKeys) => {
  if (!isPreloadReady()) throw new Error('Preload not ready');
  void preload.cookies?.setCookie(
    cookiesSyncKeys()[key]?.cookieName ?? '',
    deepFind(autocutStoreVanilla(), key),
  );
};

//Set the value of the store with the value of the local storage (if exists)
export const syncStoreFromCookies = async (key: AutoCutStateKeys) => {
  if (!isPreloadReady()) throw new Error('Preload not ready');

  let value = await preload.cookies?.getCookie(
    cookiesSyncKeys()[key]?.cookieName ?? '',
  );
  // Migrate from local storage to cookies
  if (value === undefined || value === null) {
    value = getLocalStorage(cookiesSyncKeys()[key]?.cookieName ?? '', false);
    if (value)
      await preload.cookies?.setCookie(
        cookiesSyncKeys()[key]?.cookieName ?? '',
        value,
      );
  }

  if (value != undefined && value != null && typeof value != 'object') {
    setAutocutStore(key, value);
    return;
  }

  const defaultValue = cookiesSyncKeys()[key]?.defaultValue;
  if (defaultValue === undefined || defaultValue === null) {
    return;
  }
  const defaultValueClone = cloneDeep(defaultValue);
  let mergedParams = merge(defaultValueClone, value ?? {});

  // Fix the parameters if they are not valid
  if (key.startsWith('ui.parameters')) {
    const modeId = key.substring('ui.parameters.'.length) as AutocutModeIds;
    const paramValidator = parametersValidationSchema[modeId];
    try {
      paramValidator.parse(mergedParams);
    } catch (error: any) {
      if (modeId === 'caption') {
        mergedParams = defaultValue;
        error.issues = [];
      }

      for (const issue of error.issues) {
        const issuePath = issue.path as string[];
        const issuePathString = issuePath.join('.');
        const paramDefaultValue = deepFind(defaultValue, issuePathString);

        try {
          mergedParams = deepSet(mergedParams, issuePath, paramDefaultValue);
        } catch (setError) {
          mergedParams = defaultValue;
        }
      }
    }
  }

  setAutocutStore(key, mergedParams);
};

//Set the value of the store with the value of the local storage (if exists)
export const syncAllStoreFromCookies = async () => {
  const isCookieExist = !!(await preload.cookies?.getCookie('ui.versions.aea'));

  if (!isCookieExist) {
    console.debug('[CookieSync]', 'Migrating v1 to v2');
    // If 0, default variable set by electron make it false
    // Migrate v1 to v2
    await fillLocalStorageWithPersistanceStorage();
  }

  await Promise.all(
    Object.keys(cookiesSyncKeys()).map(key =>
      syncStoreFromCookies(key as AutoCutStateKeys),
    ),
  );
};
