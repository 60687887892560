import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import {z as zod} from 'zod';

import {fontValidationSchema} from './font.validationSchema';

const featureObject = zod.object({
  disabled: zod.boolean().optional(),
  showWarning: zod.boolean().optional(),
});

const unsupportedFeaturesObject = zod.object({
  uppercase: featureObject,
  wordBox: featureObject,
  chapters: featureObject,
  viralClips: featureObject,
});

export const languageOfTranscriptionValidationSchema = zod.object({
  value: zod
    .string()
    .min(
      1,
      'modes_general_error_validation_languageOfTranscription' as TranslationKeys,
    )
    .default('en'),
  label: zod.string().min(1).default('English'),
  textDirection: zod.enum(['ltr', 'rtl']).optional().default('ltr'),
  unsupportedFeatures: zod
    .function()
    .args(fontValidationSchema.optional())
    .returns(unsupportedFeaturesObject)
    .optional(),
  maxWordsPerChunk: zod.number().optional(),
  isBeta: zod.boolean().optional(),
});
