import {FingerprintErrorFactory} from './errors/FingerprintErrorFactory';
import {host} from './host';
import {manageError} from './manageError';
import {autocutStoreVanilla, setAutocutStore} from './zustand/zustand';

export const handleBackup = async () => {
  try {
    setAutocutStore('backup.isRestorable', false);
    const hostName = autocutStoreVanilla().ui.host;
    if (hostName === 'davinci') return;

    const {backupId, originalId} = await host.timeline.backup();
    setAutocutStore('backup.lastId', backupId);
    setAutocutStore('backup.originalId', originalId);
    setAutocutStore('backup.isRestorable', true);
  } catch (err: any) {
    throw FingerprintErrorFactory(err, 'handleBackup');
  }
};

export const handleRestore = async (showError = true) => {
  try {
    const hostName = autocutStoreVanilla().ui.host;
    if (hostName === 'davinci') return;

    const {
      lastId: backupId,
      isRestorable,
      originalId,
    } = autocutStoreVanilla().backup;

    if (backupId !== '' && isRestorable) {
      await host.timeline.restore(backupId, originalId);
      setAutocutStore('backup.isRestorable', false);
    }
  } catch (e: any) {
    manageError({
      error: FingerprintErrorFactory(e, 'handleRestore'),
      disableModal: !showError,
    });
  }
};
