import type {BRoll} from '@autocut/types/BRolls';
import type {CaptionChunk} from '@autocut/types/Captions';
import type {Utterance, WordBase} from '@autocut/types/Deepgram';
import type {JSONTimeline} from '@autocut/types/JSONTimeline';
import type {SwearWord} from '@autocut/types/SwearWords';
import type {ProcessProgress} from '@autocut/utils/process/progress';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {
  GenerateZustandKeys,
  ProgressState,
} from '@autocut/utils/zustand/zustand';

import {type ZustandSyncConfig} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_OnGoingProcess = 'onGoingProcess';

export type ZType_OnGoingProcess = {
  //Global
  nbStepTotal?: number;
  //Cuts
  startDate?: Date;
  timelineBasedSilencesTotal?: number[][];
  sequenceParams: {
    userSequenceVideoDisplayFormat?: number;
  };
  workerTimeoutReport?: Worker;
  //SwearWords
  swearWords: {
    utterances: Utterance[];
    gptWords: WordBase[];
    swearWords: WordBase[];
  };
  //Captions
  caption: {
    transcriptFileName?: string;
    chunks?: CaptionChunk[];
  };
  //B-Roll
  bRolls?: BRoll[];
  //Chapters
  chapters?: Chapter[];
  //Repeat
  repeatUtterances?: Utterance[][];
  //Viral clips
  viralClips?: ViralClip[];
  //Repeat
  delimitedTranscript?: Utterance[][];
  CEPProgressCallback?: (progress: ProgressState) => void;
  progress?: ProcessProgress;
  preview: {
    isProcessing: boolean;
  };
  timelineInfos?: JSONTimeline;
  timelineInfosHash?: string;
  audioFilepath?: string;
  timelineId: string;
  isSelectionValid: boolean;
};

export type ZKeys_OnGoingProcess = GenerateZustandKeys<
  ZBaseKey_OnGoingProcess,
  ZType_OnGoingProcess
>;

export const zDefaultValues_OnGoingProcess: ZType_OnGoingProcess = {
  sequenceParams: {},
  preview: {
    isProcessing: false,
  },
  caption: {
    chunks: [],
    transcriptFileName: undefined,
  },
  timelineId: '',
  isSelectionValid: true,
  swearWords: {
    gptWords: [],
    utterances: [],
    swearWords: [],
  },
};

export const zSync_OnGoingProcess: ZustandSyncConfig<ZKeys_OnGoingProcess> = {};

export const zSubscribers_OnGoingProcess: AutoCutStateSubscriberConfig<ZKeys_OnGoingProcess> =
  {};
