import Modal from '@autocut/components/ModalLayout/BaseModal';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {resizePresetValidationSchema} from '@autocut/validationSchema/resizePreset.validationSchema';
import {useState} from 'react';

export const CreateResizePresetModal = () => {
  const {customPresets} = useAutoCutStore(state => ({
    customPresets: state.ui.resize.customPresets,
  }));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [name, setName] = useState<string>('');
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const handleCreatePreset = async () => {
    setIsLoading(true);

    const newPreset = {
      name,
      width,
      height,
    };

    if (customPresets.some(preset => preset.name === newPreset.name)) {
      setValidationErrors(['validation_error_resize_preset_name_unique']);
      setIsLoading(false);
      return;
    }

    try {
      const preset = resizePresetValidationSchema.parse(newPreset);
      setAutocutStore('ui.resize.customPresets', [...customPresets, preset]);
      closeModal();
      if (await handleTrialTask('preset')) {
        addModalToQueue(ModalType.GamingTrial);
      }
      setValidationErrors([]);
    } catch (error: any) {
      setValidationErrors(error.issues.map((issue: any) => issue.message));
      console.error(`Error in new format: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title="Create a new preset">
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <FormSection
          title="Name"
          description="Choose a name for your custom preset, it needs to be unique."
        >
          <Input
            value={name}
            onChange={value => setName(value)}
            placeholder="Name of the preset"
          />
        </FormSection>
        <FlexContainer
          flexDirection="row"
          alignItems="flex-end"
          gap={Spacing.s2}
        >
          <FormSection title="Width">
            <Input
              type="number"
              value={width}
              onChange={value => setWidth(Number(value))}
              unit="px"
            />
          </FormSection>
          <Text
            variant="textSm.semibold"
            color="white"
            style={{paddingBottom: 6}}
          >
            x
          </Text>

          <FormSection title="Height">
            <Input
              value={height}
              onChange={value => setHeight(Number(value))}
              unit="px"
            />
          </FormSection>
        </FlexContainer>
        <FlexContainer
          alignItems="center"
          flexDirection="column"
          gap={Spacing.s1}
        >
          {validationErrors.map((error, index) => (
            <Text
              key={index}
              variant="text2Xs.semibold"
              color={colors.error600}
            >
              {error}
            </Text>
          ))}
          <Button
            isLoading={isLoading}
            variant="primary"
            color={colors.primary600}
            onClick={handleCreatePreset}
          >
            Create
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
