import type React from 'react';

import type {FlexContainerProps} from '../../molecules/FlexContainer';
import type {FormSectionVariant} from './variants';

import {colors} from '@autocut/designSystem/colors';
import {FontSize} from '@autocut/designSystem/enums/fontSize.enum';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

import {Text} from '../../atoms/Text/Text';
import FlexContainer from '../../molecules/FlexContainer';

export namespace FormSection {
  export type Props = {
    title?: string | React.ReactNode;
    description?: string | React.ReactNode;
    children?: React.ReactNode;
    variant?: FormSectionVariant;
  } & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> &
    FlexContainerProps;
}

export const FormSection = ({
  title,
  description,
  children,
  variant,
  style,
  ...props
}: FormSection.Props) => {
  return (
    <FlexContainer
      flexDirection="column"
      gap={Spacing.s2}
      style={{width: '100%', ...style}}
      {...props}
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s1}
      >
        {title ? (
          <Text
            variant={
              variant === 'primary'
                ? 'textMd.bold'
                : variant === 'secondary'
                  ? 'textSm.semibold'
                  : 'textMd.bold'
            }
            color={colors.gray300}
          >
            {title}
          </Text>
        ) : null}
        {description && (
          <Text
            variant="textXs"
            color={colors.gray400}
            style={{lineHeight: FontSize.textXs}}
          >
            {description}
          </Text>
        )}
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};
