import {host} from '@autocut/utils/host';
import {range} from '@autocut/utils/math.utils';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';

export const handleSelectedClips = async () => {
  const union = (ranges: {startFrame: number; endFrame: number}[]) => {
    ranges.sort((a, b) => a.startFrame - b.startFrame);

    const merged = [];

    for (const range of ranges) {
      if (
        merged.length === 0 ||
        merged[merged.length - 1].endFrame < range.startFrame
      ) {
        merged.push(range);
      } else {
        merged[merged.length - 1].endFrame = Math.max(
          merged[merged.length - 1].endFrame,
          range.endFrame,
        );
      }
    }

    return merged;
  };

  const timelineId = await host.timeline.getUniqueId();
  const selection = await host.timeline.getSelection();
  const unionSelection = union(selection);
  const selectedSections = unionSelection.map(selection => [
    selection.startFrame,
    selection.endFrame,
  ]);
  setAutocutStore('ui.selectedSections', selectedSections);
  setAutocutStore('onGoingProcess.timelineId', timelineId);
};

export const handleInOut = async () => {
  const timelineId = await host.timeline.getUniqueId();
  const inOut = await host.timeline.getInOut();
  if (!inOut.set) {
    return -1;
  }
  const selectedSection = [[inOut.inPoint, inOut.outPoint]];
  setAutocutStore('ui.selectedSections', selectedSection);
  setAutocutStore('onGoingProcess.timelineId', timelineId);
};

export const handleEntireTimeline = async () => {
  const timelineInfos = autocutStoreVanilla().onGoingProcess.timelineInfos;

  if (!timelineInfos) return;

  const timelineId = timelineInfos.id;
  const selectedSection = [
    [timelineInfos.start, timelineInfos.start + timelineInfos.duration],
  ];
  setAutocutStore('ui.selectedSections', selectedSection);
  setAutocutStore('onGoingProcess.timelineId', timelineId);
  setAutocutStore('onGoingProcess.timelineInfos', timelineInfos);
};

export const computePositionValues = ({
  section,
  timelineEndFrame,
  timelineStartFrame,
}: {
  timelineStartFrame: number;
  timelineEndFrame: number;
  section: number[];
}) => {
  const sectionDurationFrame = section[1] - section[0];
  const timelineDurationFrame = timelineEndFrame - timelineStartFrame;
  const sectionStartPercentage = range(
    timelineStartFrame,
    timelineEndFrame,
    0,
    100,
    section[0],
  );
  const sectionLengthPercentage = Math.min(
    range(0, timelineDurationFrame, 0, 100, sectionDurationFrame),
    100 - sectionStartPercentage,
  );

  return {
    sectionStartPercentage,
    sectionLengthPercentage,
  };
};

export const resetSelectedSections = () => {
  setAutocutStore('onGoingProcess.timelineInfos', undefined);
  setAutocutStore('onGoingProcess.timelineId', '');
  setAutocutStore('onGoingProcess.isSelectionValid', true);
  setAutocutStore('ui.selectedSections', []);
};
