import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconAlertTriangle} from '@autocut/designSystem/components/atoms/Icon/alerts/IconAlertTriangle';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {useIntl} from 'react-intl';

export const UpdateTimeoutModal = () => {
  const intl = useIntl();

  const {
    electron: {openLinkInBrowser},
  } = preload;

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modals_updateTimeout_title',
        defaultMessage: 'The update timed out',
      })}
      isBlocking
      icon={
        <IconAlertTriangle
          size={24}
          color="white"
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
      >
        <Text>
          <TranslatedMessage
            id="modals_updateTimeout_text_download"
            defaultMessage="You can try relaunching AutoCut. If it still doesn’t work, please download and install the update manually from here: "
          />
          <Button
            variant="tertiary"
            fullWidth={false}
            onClick={() =>
              openLinkInBrowser('https://www.autocut.com/download/')
            }
          >
            <Text>https://www.autocut.com/download/</Text>
          </Button>
        </Text>
        <Text>
          <TranslatedMessage
            id="modals_updateTimeout_text_contact"
            defaultMessage="If the issue still persists, please contact us: "
          />
          <Button
            variant="tertiary"
            fullWidth={false}
            onClick={() => openLinkInBrowser('mailto:contact@autocut.fr')}
          >
            <Text>contact@autocut.fr</Text>
          </Button>
        </Text>
      </FlexContainer>
    </Modal>
  );
};
