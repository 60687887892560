import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {IconMicrophone01} from '@autocut/designSystem/components/atoms/Icon/media/IconMicrophone01';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {PodcastCustomization} from './customization/PodcastCustomization';

export const PodcastMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={IconMicrophone01}
      text={intl.formatMessage({
        id: 'modes_podcast_title',
        defaultMessage: 'Auto Podcast',
      })}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/podcast"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_podcast_steps_select_label',
              defaultMessage: 'Select timeline',
            }),
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="podcast"
                onValidateSections={() => navigate('./customization')}
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_podcast_steps_customization_label',
              defaultMessage: 'Customize',
            }),
            path: 'customization',
            element: <PodcastCustomization />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
