import type {ColorKeys} from '@autocut/designSystem/colors';
import type {ComponentPropsWithoutRef, CSSProperties} from 'react';

export type IconComponentProps = Omit<
  ComponentPropsWithoutRef<'svg'>,
  'xmlns'
> & {
  size?: CSSProperties['height'];
  color?: CSSProperties['color'] | ColorKeys;
};

export const MANTINE_COLORS_REGEX = /([a-zA-Z]+)\.{1}(\d)/gm;

export const IconComponent = ({
  size,
  color,
  children,
  className,
  ...props
}: IconComponentProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      height={size}
      width={size}
      {...props}
      color={color}
    >
      {children}
    </svg>
  );
};
