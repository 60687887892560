import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {preload} from '@autocut/types/ElectronPreload';
import {useIntl} from 'react-intl';

export const LaunchErrorModal = () => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'launchManager_steps_resources_modal_title',
        defaultMessage: 'Something went wrong',
      })}
      footer={
        <Button
          color={colors.primary600}
          onClick={() => preload.electron.reload()}
        >
          <TranslatedMessage
            id="launchManager_steps_resources_modal_cta"
            defaultMessage="Restart AutoCut"
          />
        </Button>
      }
      isBlocking
    >
      <Text
        variant="textSm"
        color={colors.gray200}
      >
        <TranslatedMessage
          id="launchManager_steps_resources_modal_body"
          defaultMessage="AutoCut is unable to start, some ressources cannot be installed or you are not connected to the internet. Check you disk space and be sure to allow AutoCut to have access to others apps data to continue. If the problem persists, please contact us at"
        />
        <Button
          variant="tertiary"
          color={colors.gray200}
          onClick={() =>
            preload.electron.openLinkInBrowser('mailto:contact@autocut.fr')
          }
          fullWidth={false}
        >
          contact@autocut.fr
        </Button>
      </Text>
    </Modal>
  );
};
