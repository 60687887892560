import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {closeModal} from '@autocut/utils/modal/closeModal';

export const UpdateHostModal = () => {
  const hostName = useAutoCutStore(
    state => state.modal.data.hostUpdate.hostName,
  );

  return (
    <Modal
      noHeader
      footer={
        <Button
          color={colors.primary600}
          onClick={() => closeModal()}
        >
          <TranslatedMessage id="globals_ok" />
        </Button>
      }
    >
      <FlexContainer
        flexDirection="column"
        justifyContent="space-between"
        gap={8}
        style={{padding: `0px ${Spacing.s4}`}}
      >
        <h1 style={{textAlign: 'center', marginBottom: Spacing.s6}}>
          <TranslatedMessage
            id={`launchManager_steps_hostIPC_modals_${hostName}_title`}
          />
        </h1>
        <h2>
          <TranslatedMessage
            id={`launchManager_steps_hostIPC_modals_${hostName}_subtitle`}
          />
        </h2>
        <p>
          <TranslatedMessage
            id={`launchManager_steps_hostIPC_modals_${hostName}_body`}
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};
