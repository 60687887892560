import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {LANGUAGE_TO_MODEL} from '@autocut/constants/languageToModel';
import {colors} from '@autocut/designSystem/colors';
import {Alert} from '@autocut/designSystem/components/atoms/Alert/Alert';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconInfoCircle} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoCircle';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {BleepFileEnum} from '@autocut/enums/bleepFile.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useCheckDeactivatedTracks} from '@autocut/hooks/useCheckDeactivatedTracks';
import {preload} from '@autocut/types/ElectronPreload';
import {compute} from '@autocut/utils/compute.utils';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';

import BleepSoundChoiceStep from './Parts/BleepSoundChoiceStep/BleepSoundChoiceStep';
import {CensorWordsStep} from './Parts/CensorWordsStep/CensorWordsStep';
import {TranscriptStep} from './Parts/TranscriptStep/TranscriptStep';

export const SwearWordsCustomization = () => {
  const intl = useIntl();

  const {parameters, swearWords} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.swear_word,
    swearWords: state.onGoingProcess.swearWords.swearWords,
  }));

  const handleClick = async () => {
    await handleProcessBase(
      {
        executeProcess: async (_, progress) => {
          let bleepFilePath;
          let bleepFileName;
          if (parameters.bleepFile !== BleepFileEnum.Muted) {
            // Default sounds
            if (
              parameters.bleepFile === BleepFileEnum.BleepSound1 ||
              parameters.bleepFile === BleepFileEnum.BleepSound2
            ) {
              const bleepRessource = resourcesConfigs()[parameters.bleepFile]();
              bleepFileName = bleepRessource.fileName;
              const {isExist, outputFilePath} =
                await bleepRessource.existCheck();
              if (isExist) {
                bleepFilePath = outputFilePath;
              } else {
                bleepFilePath = await bleepRessource.downloadMethod();
              }
              // User sounds
            } else {
              bleepFileName = preload.path.basename(parameters.bleepFile);
              bleepFilePath = parameters.bleepFile;
            }
          }

          const swearWordsProcess = compute.process.swearWords.handleSwearWords(
            {
              swearWords,
              bleepFilePath,
              bleepFileName,
            },
          );

          updateProcessStep(progress, 'swearWords_mainProcess', {
            progress: {
              computeTaskId: swearWordsProcess.requestId,
            },
            countFor: 5,
          });

          const result = await swearWordsProcess;

          endProcessStep(progress, 'swearWords_mainProcess');

          return result;
        },
      },
      {
        processTitleNameKey: 'modes_swearWords_title',
        processSteps: [
          {
            id: 'swearWords_mainProcess',
            translationKey: 'progress_steps_swearWords_mainProcess',
            progress: '',
          },
        ],
      },
    )(intl);

    await handleTrialTask('swear_word');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <Button
              ref={buttonRef}
              isLoading={isLoading}
              disabled={isDisabled}
              color={colors.primary600}
              onClick={() => handleClickWithValidation(handleClick)}
            >
              <TranslatedMessage
                id="modes_swearWords_footerButton"
                defaultMessage="Remove profanities"
              />
            </Button>
          )}
        />
      }
    >
      <FlexContainer
        style={{paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <BleepSoundChoiceStep />
        <CensorWordsStep />
        <TranscriptStep />
      </FlexContainer>
    </ModeLayout>
  );
};
