import type {Locale} from '@autocut/contexts/LocaleContext';
import {useLocale} from '@autocut/hooks/useLocale';
import {Select} from '@autocut/designSystem/components/atoms/Select/Select';

export const SelectLang = () => {
  const {locale, setAndSaveLocale, availableLocales} = useLocale();

  return (
    <Select
      variant="secondary"
      isSearchable={false}
      style={{zIndex: 2}}
      options={availableLocales.map(
        (currentLocale: (typeof availableLocales)[number]) => ({
          value: currentLocale,
          label: currentLocale,
        }),
      )}
      allowMultiple={false}
      selected={locale}
      onChange={option => {
        if (!option) return null;
        console.log('Changing locale', {option});
        setAndSaveLocale(option as Locale);
      }}
    />
  );
};
