import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconAlertTriangle = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8v4m0 4h0M10.6 2.9 2.4 17c-.5.8-.7 1.2-.7 1.5 0 .3.2.5.4.7.3.2.8.2 1.7.2h16.4c1 0 1.4 0 1.7-.2l.4-.7c0-.3-.2-.7-.7-1.5L13.4 2.9c-.5-.8-.7-1.2-1-1.3a1 1 0 0 0-.8 0c-.3.1-.5.5-1 1.3Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
