import type {ModeTitleProps} from '@autocut/components/ModeTitle/ModeTitle';
import type * as React from 'react';
import type {IconType} from 'react-icons';

import {ModeTitle} from '@autocut/components/ModeTitle/ModeTitle';

export type DefaultWrapperProps = {
  Icon?: IconType;
  text: string;
  children: React.ReactNode;
  customHomeButtonHandler?: ModeTitleProps['customHomeButtonHandler'];
};

export const DefaultWrapper = ({
  Icon,
  text,
  children,
  customHomeButtonHandler,
}: DefaultWrapperProps) => {
  return (
    <>
      <ModeTitle
        Icon={Icon}
        text={text}
        customHomeButtonHandler={customHomeButtonHandler}
      />
      <div className="dashboardContainer">{children}</div>
    </>
  );
};
