import type {CanvasObject} from './canvas.class.utils';

import {FingerprintErrorFactory} from '@autocut/utils/errors/FingerprintErrorFactory';

export type CanvasCachedObject = {
  object: CanvasObject;
  canvas: HTMLCanvasElement;
};

export type CanvasCacheDatabase = Record<
  CanvasObject['id'],
  CanvasCachedObject
>;

/**
 * Cache for canvas objects.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas#pre-render_similar_primitives_or_repeating_objects_on_an_offscreen_canvas
 */
export class CanvasCache {
  private cache: CanvasCacheDatabase = {};

  public clear() {
    this.cache = {};
  }

  public get(...args: Parameters<typeof CanvasCache.prototype.set>) {
    const object = args[0];
    const cacheKey = object.id;

    let cached: CanvasCachedObject | undefined = this.cache[cacheKey];

    if (cached) {
      let isCacheValid = true;
      if (cached.object.x !== object.x || cached.object.y !== object.y) {
        if (this.cache.debug) {
          console.log(
            object.name,
            cached.object.name,
            'Cache invalidated (position)',
          );
        }
        isCacheValid = false;
      }
      if (
        isCacheValid &&
        (cached.object.width !== object.width ||
          cached.object.height !== object.height)
      ) {
        if (this.cache.debug) {
          console.log(
            object.name,
            cached.object.name,
            'Cache invalidated (size)',
          );
        }
        isCacheValid = false;
      }

      if (isCacheValid && object.customCacheCheck) {
        isCacheValid = object.customCacheCheck(args[2]);
        if (!isCacheValid && this.cache.debug) {
          console.log(
            object.name,
            cached.object.name,
            'Cache invalidated (custom)',
          );
        }
      }

      if (!isCacheValid) {
        cached = undefined;
      }
    }

    return {
      canvas: cached?.canvas ?? this.set(...args),
      isCached: !!cached,
    };
  }

  public set(
    object: CanvasObject,
    originalCanvas: HTMLCanvasElement,
    args: any,
  ) {
    const cached = this.cache[object.id];

    const virtualCanvas = cached?.canvas ?? document.createElement('canvas');
    virtualCanvas.width = originalCanvas.width;
    virtualCanvas.height = originalCanvas.height;

    const virtualContext = virtualCanvas.getContext('2d');
    if (!virtualContext)
      throw FingerprintErrorFactory(
        'Virtual canvas context not found',
        'CanvasCache.set',
      );

    virtualContext.clearRect(0, 0, virtualCanvas.width, virtualCanvas.height);
    object.draw(virtualContext, object.x, object.y, args);

    this.cache[object.id] = {object: {...object}, canvas: virtualCanvas};

    return virtualCanvas;
  }
}
