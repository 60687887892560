import type {Preset} from '@autocut/components/PresetCard/PresetCard';

import {PresetCard} from '@autocut/components/PresetCard/PresetCard';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {ZoomTypes} from '@autocut/types/ZoomType';
import {useIntl} from 'react-intl';

const zoomPresets: Preset<'zoom'>[] = [
  {
    id: 'calm',
    parameters: {
      totalZoomPercentage: 0.5,
      dynamicZoomPercentage: 0.05,
      smoothZoomPercentage: 0.95,
      nervousness: 0,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
  {
    id: 'paced',
    parameters: {
      totalZoomPercentage: 0.6,
      dynamicZoomPercentage: 0.1,
      smoothZoomPercentage: 0.9,
      nervousness: 0.3,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
  {
    id: 'energetic',
    parameters: {
      totalZoomPercentage: 0.72,
      dynamicZoomPercentage: 0.15,
      smoothZoomPercentage: 0.85,
      nervousness: 0.6,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
  {
    id: 'hyperactive',
    parameters: {
      totalZoomPercentage: 0.85,
      dynamicZoomPercentage: 0.2,
      smoothZoomPercentage: 0.8,
      nervousness: 1,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
];

export const ZoomPresetStep = () => {
  const intl = useIntl();

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_zoom_parameters_defaultsPresets_title',
        defaultMessage: 'Default presets',
      })}
      style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s4}}
    >
      <PaddedSection>
        <FlexContainer
          style={{width: 'min-content'}}
          gap={Spacing.s4}
        >
          {zoomPresets.map(preset => (
            <PresetCard
              mode="zoom"
              preset={preset}
              key={preset.id}
            />
          ))}
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
