import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {InputLabel} from '@autocut/designSystem/components/molecules/InputLabel/InputLabel';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {Navigate} from 'react-router-dom';

import {CaptionsStyleStep} from './parts/CaptionsStyleStep/CaptionsStyleStep';
import {CaptionsTranscriptEditor} from './parts/CaptionsTranscriptEditorStep/CaptionsTranscriptEditorStep';
import {CaptionsTranscriptStepContainer} from './parts/CaptionsTranscriptStepContainer';
import {useEditTranscriptModal} from './parts/EditTranscriptModal/EditTranscriptModalContext';
import {useHandleAddCaptions} from './useHandleAddCaptions';

const CaptionsCustomization = () => {
  const {addCaptions} = useHandleAddCaptions();
  const {captionsChunks = [], isPreviewProcessing} = useAutoCutStore(state => ({
    captionsChunks: state.onGoingProcess.caption?.chunks,
    isPreviewProcessing: state.onGoingProcess.preview.isProcessing,
  }));

  const {currentTranscriptName, checkTranscriptModal, hasCheckedTranscript} =
    useEditTranscriptModal();

  if (!captionsChunks) {
    return null;
  }

  const hasChunks = (captionsChunks ?? []).length > 0;

  return captionsChunks.length > 0 ? (
    <>
      <ModeLayout
        footer={
          <ModeFooter
            renderButton={({
              buttonRef,
              isLoading,
              isDisabled,
              handleClickWithValidation,
            }) => (
              <Button
                ref={buttonRef}
                disabled={isDisabled || isLoading || !hasChunks}
                isLoading={isLoading || isPreviewProcessing}
                onClick={async () => {
                  handleClickWithValidation(async () => {
                    if (hasCheckedTranscript) {
                      await addCaptions();
                    } else {
                      checkTranscriptModal.open();
                    }
                  });
                }}
                color={colors.primary600}
              >
                <TranslatedMessage
                  id={'modes_caption_steps_customization_button'}
                  defaultMessage="Add captions"
                />
              </Button>
            )}
          />
        }
      >
        <FlexContainer
          flexDirection="column"
          gap={8}
        >
          <CaptionsTranscriptStepContainer
            title={
              <TranslatedMessage
                id={'modes_caption_steps_customization_parts_style_title'}
                defaultMessage="Captions style"
              />
            }
          >
            <CaptionsStyleStep />
          </CaptionsTranscriptStepContainer>

          <Divider />

          <CaptionsTranscriptStepContainer
            title={
              <InputLabel
                title={
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_transcription_title'
                    }
                    defaultMessage="Transcription"
                  />
                }
                description={` - ${currentTranscriptName}`}
              />
            }
          >
            <CaptionsTranscriptEditor />
          </CaptionsTranscriptStepContainer>
        </FlexContainer>
      </ModeLayout>
    </>
  ) : (
    <Navigate
      to="../language"
      replace={true}
    />
  );
};

export default CaptionsCustomization;
