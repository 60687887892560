import type {CSSProperties} from 'react';

import {colors} from '@autocut/designSystem/colors';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {convertSecondsToHMSString} from '@autocut/utils/date.utils';
import {Fragment, useEffect, useRef, useState} from 'react';

import {computePositionValues} from '../../sections.utils';
import css from './TimelineSectionnedGraduated.module.scss';

// in pixels
const MARKER_WIDTH = 8;

type TimelineSectionnedProps = {
  graduationWidth?: number;
  style?: CSSProperties;
};

export const TimelineSectionnedGraduated = ({
  graduationWidth = 50,
  style,
}: TimelineSectionnedProps) => {
  const {selectedSections, timelineInfos} = useAutoCutStore(state => ({
    selectedSections: state.ui.selectedSections,
    timelineInfos: state.onGoingProcess.timelineInfos,
  }));
  const [totalGraduations, setTotalGraduations] = useState(0);
  const [mergedSections, setMergedSections] = useState<number[][]>([]);
  const axisRef = useRef<HTMLDivElement>(null);

  const timelineStartFrame = timelineInfos ? timelineInfos.start : 0;
  const timelineEndFrame = timelineInfos
    ? timelineInfos.start + timelineInfos.duration
    : 0;

  useEffect(() => {
    const calculateGraduations = () => {
      if (axisRef.current) {
        const barWidth = axisRef.current.offsetWidth;
        const graduations = Math.floor(barWidth / graduationWidth);
        setTotalGraduations(graduations);
      }
    };

    const calculateMergedSections = () => {
      if (axisRef.current) {
        const barWidth = axisRef.current.offsetWidth;
        const mergedSectionsTmp = [];
        const distanceFrame =
          (MARKER_WIDTH * 2 * (timelineEndFrame - timelineStartFrame)) /
          barWidth;
        for (const section of selectedSections) {
          if (
            mergedSectionsTmp.length === 0 ||
            mergedSectionsTmp[mergedSectionsTmp.length - 1][1] + distanceFrame <
              section[0]
          ) {
            mergedSectionsTmp.push([...section]);
          } else {
            mergedSectionsTmp[mergedSectionsTmp.length - 1][1] = Math.max(
              mergedSectionsTmp[mergedSectionsTmp.length - 1][1],
              section[1],
            );
          }
        }

        setMergedSections(mergedSectionsTmp);
      }
    };

    const onResize = () => {
      calculateGraduations();
      calculateMergedSections();
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [graduationWidth, selectedSections, timelineEndFrame, timelineStartFrame]);

  if (!timelineInfos) return null; //Maybe a skeleton

  const seconds = [
    timelineInfos.start / timelineInfos.settings.framerate,
    (timelineInfos.start + timelineInfos.duration) /
      timelineInfos.settings.framerate,
  ];
  const timelineTimecodeLabels = [
    convertSecondsToHMSString(seconds[0]),
    convertSecondsToHMSString(seconds[1]),
  ];

  return (
    <div
      className={css.container}
      style={style}
    >
      <>
        <FlexContainer
          flex="row"
          justifyContent="space-between"
          className={css.textContainer}
        >
          <Text variant="text2Xs">{timelineTimecodeLabels[0]}</Text>
          <Text variant="text2Xs">{timelineTimecodeLabels[1]}</Text>
        </FlexContainer>
        <div
          className={css.axis}
          ref={axisRef}
        />
        {Array.from({length: totalGraduations}).map((_, index) => {
          const positionPercentage = (index / (totalGraduations - 1)) * 100;
          // Accounting for the width of the graduation (2px)
          const offset = (positionPercentage * 2) / 100;

          return (
            <div
              key={index}
              className={css.graduation}
              style={{left: `calc(${positionPercentage}% - ${offset}px)`}}
              data-variant={
                index === 0 || index === totalGraduations - 1 ? 'limit' : ''
              }
            />
          );
        })}
      </>
      {mergedSections.map((section, _) => {
        const {sectionLengthPercentage, sectionStartPercentage} =
          computePositionValues({
            section,
            timelineEndFrame,
            timelineStartFrame,
          });

        return (
          <div
            key={section[0]}
            className={css.section}
            style={{
              left: `${sectionStartPercentage}%`,
              width: `${sectionLengthPercentage}%`,
            }}
          />
        );
      })}
      {mergedSections.map(section => {
        const {sectionLengthPercentage, sectionStartPercentage} =
          computePositionValues({
            section,
            timelineEndFrame,
            timelineStartFrame,
          });

        return (
          <Fragment key={section[0]}>
            <svg
              className={css.marker}
              viewBox="0 0 6 14"
              style={{
                left: `${sectionStartPercentage}%`,
                transform: 'translateX(-100%)',
              }}
            >
              <path
                d="M6,14 V0 L0,5 V14 H5 Z"
                fill={colors.gray300}
                stroke={colors.gray800}
              />
            </svg>

            <svg
              height={18}
              className={css.marker}
              viewBox="0 0 6 14"
              style={{
                left: `${sectionStartPercentage + sectionLengthPercentage}%`,
              }}
            >
              <path
                d="M0,14 V0 L6,5 V14 H5 Z"
                fill={colors.gray300}
                stroke={colors.gray800}
              />
            </svg>
          </Fragment>
        );
      })}
    </div>
  );
};
