import type {PopupPortalProps} from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';
import type {CSSProperties} from 'react';

import PopupPortal from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';
import {useRef, useState} from 'react';

import css from './Tooltip.module.scss';

type TooltipProps = {
  children: React.ReactNode;
  tooltipComponent: React.ReactNode;
  childrenContainerStyle?: CSSProperties;
  tooltipPosition?: PopupPortalProps['position'];
  variant?: 'default' | 'warning';
};

export const Tooltip = ({
  children,
  tooltipComponent,
  childrenContainerStyle,
  tooltipPosition = 'top',
  variant = 'default',
}: TooltipProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);

  return (
    <>
      {isTooltipDisplayed && (
        <PopupPortal
          offset={8}
          className={css.tooltipContainer}
          data-variant={variant}
          target={rootRef}
          position={tooltipPosition}
        >
          {tooltipComponent}
        </PopupPortal>
      )}

      <div
        ref={rootRef}
        onMouseEnter={() => setIsTooltipDisplayed(true)}
        onMouseLeave={() => setIsTooltipDisplayed(false)}
        style={{...childrenContainerStyle}}
        className={css.childrenContainer}
      >
        {children}
      </div>
    </>
  );
};
