import type {ZustandSyncConfig} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {GenerateZustandKeys} from '@autocut/utils/zustand/zustand';

export type ZBaseKey_Backup = 'backup';

export type ZType_Backup = {
  originalId: string;
  lastId: string;
  isRestorable: boolean;
};

export type ZKeys_Backup = GenerateZustandKeys<ZBaseKey_Backup, ZType_Backup>;

export const zDefaultValues_Backup: ZType_Backup = {
  originalId: '',
  lastId: '',
  isRestorable: false,
};

export const zSync_Backup: ZustandSyncConfig<ZKeys_Backup> = {};

export const zSubscribers_Backup: AutoCutStateSubscriberConfig<ZKeys_Backup> =
  {};
