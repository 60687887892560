import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {useEffect} from 'react';

export const ChaptersLanguage = () => {
  const {handleProcessTranscript, loading} =
    useHandleProcessTranscript('chapters');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({isLoading, isDisabled}) => (
            <Button
              onClick={() => handleProcessTranscript()}
              disabled={loading || isLoading || isDisabled}
              color={colors.primary600}
            >
              <TranslatedMessage
                id="modes_chapters_steps_language_cta"
                defaultMessage="Generate Chapters"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId="chapters" />
    </ModeLayout>
  );
};
