import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {ChaptersEditor} from './editor/ChaptersEditor';
import {ChaptersLanguage} from './language/ChaptersLanguage';

export const ChaptersMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AutocutModes.Chapters.icon}
      text={AutocutModes.Chapters.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/chapters"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            clickable: true,
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="chapters"
                onValidateSections={() => navigate('./language')}
              />
            ),
          },
          {
            clickable: true,
            label: 'Select clips & language',
            path: 'language',
            element: <ChaptersLanguage />,
          },
          {
            clickable: false,
            label: 'Edit Chapters',
            path: 'editor',
            element: <ChaptersEditor />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
