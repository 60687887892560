import type {TextVariant} from '@autocut/designSystem/components/atoms/Text/variants';
import type {FileWithPath} from '@autocut/designSystem/components/molecules/FileInput/FileInput';

import {DISCORD_LINK} from '@autocut/components/DiscordCta/DiscordCta';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {TextArea} from '@autocut/designSystem/components/atoms/TextArea/TextArea';
import {FileInput} from '@autocut/designSystem/components/molecules/FileInput/FileInput';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload} from '@autocut/types/ElectronPreload';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useState} from 'react';
import {useIntl} from 'react-intl';

import {sendFeedback} from './sendFeedback.utils';

const textVariant: TextVariant = 'textSm.medium';

export const BETA_FEEDBACK_MODAL_STEPS = [
  {
    title: (
      <TranslatedMessage
        id="modals_feedback_steps_welcome_title"
        defaultMessage="Help us shape the future of video editing 🎬"
      />
    ),
    footerLabel: (
      <TranslatedMessage
        id="modals_feedback_steps_welcome_footer"
        defaultMessage="Report my feedback 🫡"
      />
    ),
    onSubmit: async () => setAutocutStore('user.isBetaFeedbackOnboarded', true),
    body: () => (
      <FlexContainer
        flexDirection="column"
        gap={12}
        key="welcome"
        className="appear"
      >
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_welcome_text1"
            defaultMessage="Your insights are invaluable to us and with your feedback, you can play a pivotal role in shaping the future of AutoCut. 💪"
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_welcome_text2"
            defaultMessage="Tell us what’s working, what’s not, what new features you’d love to see - help us build your AI video editing assistant."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_welcome_text3"
            defaultMessage="Finally, if you want to join the community, you can join our <a>Discord</a> community of 2000+ friendly video editors like you 🌍"
            values={{
              a: text => (
                <a
                  style={{
                    textDecoration: 'underline',
                    color: colors.primary600,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    void preload.electron.openLinkInBrowser(DISCORD_LINK);
                  }}
                >
                  {text}
                </a>
              ),
            }}
          />
        </Text>
      </FlexContainer>
    ),
  },
  {
    title: (
      <TranslatedMessage
        id="modals_feedback_steps_report_title"
        defaultMessage="Your feedback"
      />
    ),
    footerLabel: (
      <TranslatedMessage
        id="modals_feedback_steps_report_footer"
        defaultMessage="Submit my feedback"
      />
    ),
    onSubmit: sendFeedback,
    body: (props: FeedbackFormProps) => (
      <FlexContainer
        flexDirection="column"
        gap={12}
        key="report"
        className="appear"
      >
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_report_text1"
            defaultMessage="The more information you give us about your problem, the easier it is to resolve it 💡"
          />
        </Text>
        <FeedbackForm {...props} />
      </FlexContainer>
    ),
  },
  {
    title: (
      <TranslatedMessage
        id="modals_feedback_steps_submitted_title"
        defaultMessage="Thank you 🙏"
      />
    ),
    footerLabel: (
      <TranslatedMessage
        id="modals_feedback_steps_submitted_footer"
        defaultMessage="Close modal"
      />
    ),
    body: () => (
      <FlexContainer
        flexDirection="column"
        gap={12}
        key="submitted"
        className="appear"
      >
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_submitted_text1"
            defaultMessage="Thank you for your feedback, we truly appreciate it. 🙏"
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_submitted_text2"
            defaultMessage="We will carefully take a look at your comments and keep the community updated about the evolution."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_feedback_steps_submitted_text3"
            defaultMessage="Stay tuned for more updates, and feel free to reach out anytime. Your input is invaluable, and we're grateful to have you with us on this journey."
          />
        </Text>
      </FlexContainer>
    ),
  },
];

type FeedbackFormProps = {
  onTextChange: (textInput: string) => void;
  onFilesChange: (file: FileWithPath[]) => void;
};

const FeedbackForm = ({onTextChange, onFilesChange}: FeedbackFormProps) => {
  const intl = useIntl();
  const [text, setText] = useState('');
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const handleTextChange = (value: string) => {
    setText(value);
    onTextChange(value);
  };
  const handleFilesChange = (files: FileWithPath[]) => {
    setFiles(files);
    onFilesChange(files);
  };

  return (
    <FlexContainer
      flexDirection="column"
      gap={8}
    >
      <TextArea
        onChange={handleTextChange}
        value={text}
        placeholder={intl.formatMessage({
          id: 'modals_feedback_steps_report_form_textInput_placeholder',
          defaultMessage:
            'Describe your feedback. Precise the mode and the language used. \n\nIf you are reporting an issue, put the steps to reproduce it.\n\nIf you have tried a workaround (another video, another language, etc.), please let us know.',
        })}
        rows={10}
      />

      <Text variant={textVariant}>
        <TranslatedMessage
          id="modals_feedback_steps_report_form_fileInput_title"
          defaultMessage="You can add a screenshot of your AutoCut settings and your timeline"
        />
      </Text>
      <FileInput
        buttonText={intl.formatMessage({
          id: 'modals_feedback_steps_report_form_fileInput_cta',
          defaultMessage: 'Add screenshot',
        })}
        onChange={handleFilesChange}
        defaultFiles={files}
        multiple
      />
    </FlexContainer>
  );
};
