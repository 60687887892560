import type {XMLDocument} from '../class/_XMLDocument';
import type {XMLSequence} from '../class/_XMLSequence';

export const handleRename = ({
  xmlDoc,
  xmlSequence,
  xmlName,
}: {
  xmlDoc: XMLDocument;
  xmlSequence: XMLSequence;
  xmlName: string;
}) => {
  const rootItem = xmlDoc.rootItems.find(item => item.name === 'CAPTIONS');
  if (rootItem) rootItem.rename(xmlName);

  xmlSequence.rename(xmlName);
};
