import type {
  AutocutMode,
  AutocutModeIds,
  AutocutModesKeys,
  AutocutModeState,
} from '@autocut/enums/modes.enum';
import type {StatMode} from '@autocut/enums/statCategory.enum';

import type {IfAny} from './type.utils';

import {AutocutModes} from '@autocut/enums/modes.enum';
import {deepFind} from '@autocut/utils/zustand/utils/objectManagement.zustand.utils';

import {autocutStoreVanilla} from './zustand/zustand';

export const getModeById = (id: AutocutModeIds): AutocutMode | undefined => {
  if ((id as string) === 'legacy') return AutocutModes.Legacy;

  const originalModeKey = Object.keys(AutocutModes).find(
    key => AutocutModes[key as AutocutModesKeys].id === id,
  ) as AutocutModesKeys;
  if (!originalModeKey) return undefined;
  return AutocutModes[originalModeKey];
};

export const getModeStatTrait = <T extends AutocutModeState = AutocutMode>(
  mode: T,
): StatMode => mode.statCategory;

export const getParametersByMode = <T extends AutocutModeState = AutocutMode>(
  mode: T,
) => {
  const autocutState = autocutStoreVanilla();
  const originalMode = getModeById(mode.id as AutocutModeIds);
  if (!originalMode) return undefined;
  return deepFind(autocutState, mode.parametersPath) as IfAny<
    T['defaultParameters'],
    (typeof originalMode)['defaultParameters'],
    T['defaultParameters']
  >;
};

export const getDefaultParametersByMode = <
  T extends AutocutModeState = AutocutMode,
>(
  mode: T,
) => mode.defaultParameters;

export const getParametersPathByMode = <
  T extends AutocutModeState = AutocutMode,
>(
  mode: T,
) => mode.parametersPath as AutocutMode['parametersPath'];

export const getModeByLocation = (location: string) => {
  const mode = Object.keys(AutocutModes).find((mode: string) => {
    const path = AutocutModes[mode as AutocutModesKeys].path;
    return location.startsWith(path);
  });

  if (!mode) return undefined;

  return AutocutModes[mode as AutocutModesKeys];
};
