import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconCamera02 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 7.7C2 6.2 3.2 5 4.7 5c.8 0 1.5-.5 1.7-1.2l.1-.3v-.2A2 2 0 0 1 8.4 2H15.7a2 2 0 0 1 1.7 1.3l.1.2v.3c.3.7 1 1.2 1.8 1.2C20.8 5 22 6.2 22 7.7v8.5c0 1.7 0 2.5-.3 3.2a3 3 0 0 1-1.3 1.3c-.7.3-1.5.3-3.2.3H6.8c-1.7 0-2.5 0-3.2-.3a3 3 0 0 1-1.3-1.3c-.3-.7-.3-1.5-.3-3.2V7.7Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
      />
    </IconComponent>
  );
};
