import type {TrialTask} from '@autocut/types/TrialTask';

import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {MdOutlineWavingHand} from 'react-icons/md';
import {useIntl} from 'react-intl';

import css from './GamingTrialModal.module.css';
import {TaskList} from './TaskList/TaskList';
import {Timeline} from './Timeline/Timeline';

export const GamingTrialModal = () => {
  const intl = useIntl();

  const {daysLeft, tasks} = useAutoCutStore(state => ({
    daysLeft: state.user.license?.left,
    tasks: state.game.trial.tasks,
  }));

  const totalElements = tasks
    .map(task => task.amount)
    .reduce((acc: number, amount: number) => {
      return acc + amount;
    }, 0);
  const completedElements = tasks
    .filter(task => task.completed)
    .map(task => task.amount)
    .reduce((acc: number, amount: number) => {
      return acc + amount;
    }, 0);
  const animateLast = tasks.some(task => task.isAnimated);

  const handleOnClose = () => {
    setAutocutStore(
      'game.trial.tasks',
      tasks.map(task => ({...task, isAnimated: false})),
    );
    setAutocutStore('game.trial.displayModal', false);
    closeModal();
  };

  return (
    <Modal
      fullScreen
      title={intl.formatMessage({
        id: 'modals_gamifiedTrial_title',
        defaultMessage: 'Start your AutoCut Adventure',
      })}
      icon={<MdOutlineWavingHand size={24} />}
      closeModalFunction={handleOnClose}
    >
      <FlexContainer
        gap={24}
        flexDirection="column"
      >
        <FlexContainer
          gap={12}
          flexDirection="column"
          className={css.header}
        >
          <p>
            <TranslatedMessage
              id="modals_gamifiedTrial_description_firstLine"
              defaultMessage="Your creative journey begins with a 14-day free trial of AutoCut, you unlocked {completedElements} more and you've got <b>{daysLeft} days</b> left to explore!"
              values={{
                completedElements,
                daysLeft,
              }}
            />
          </p>

          <p>
            <TranslatedMessage
              id="modals_gamifiedTrial_description_secondLine"
              defaultMessage="But why stop there? Embark on a quest to unlock <b>an additional {bonusDays} days</b> and fully unleash the power of AutoCut."
              values={{
                bonusDays: totalElements - completedElements,
              }}
            />
          </p>
        </FlexContainer>
        <Timeline
          leftDescription={intl.formatMessage({
            id: 'modals_gamifiedTrial_timeline_left',
            defaultMessage: '14 days free trial',
          })}
          rightDescription={intl.formatMessage({
            id: 'modals_gamifiedTrial_timeline_right',
            defaultMessage: '24 days free trial',
          })}
          totalElements={totalElements}
          currentElement={completedElements}
          animateLast={animateLast}
        />
        <TaskList tasks={tasks as TrialTask[]} />
      </FlexContainer>
    </Modal>
  );
};
