import type {CaptionChunk} from '@autocut/types/Captions';
import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import type {TimelinePpro} from '../temp/_pproTimeline.temp';
import type {ResizedXMLTemplates} from './_resizeTemplate';

import {handleHighlightBox} from './_handleHighlightBox';
import {handleHighlightText} from './_handleHighlightText';

export const handleHighlights = async ({
  chunk,
  parameters,
  templates,
  timeline,
  wordIndex,
}: {
  chunk: CaptionChunk;
  parameters: CaptionsParameters;
  templates: ResizedXMLTemplates;
  timeline: TimelinePpro;
  wordIndex: number;
}) => {
  for (const [highlightIndex, highlight] of chunk.highlight.entries()) {
    const highlightStart = highlight.start;
    const highlightEnd =
      chunk.highlight[highlightIndex + 1]?.start ?? highlight.end;
    const secondTimes = {
      start: highlightStart,
      end: highlightEnd,
    };

    handleHighlightText({
      chunk,
      parameters,
      secondTimes,
      templates,
      wordIndex: highlightIndex + wordIndex,
      highlight,
    });

    await handleHighlightBox({
      chunk,
      highlight,
      parameters,
      templates,
      secondTimes,
      highlightIndex: highlightIndex + wordIndex,
      timeline,
    });
  }

  return chunk.highlight.length;
};
