import {preload} from '@autocut/types/ElectronPreload';

export const xmlToPrProjFile = async (
  initialFilePath: string,
  newFilePath: string,
) => {
  const tempGzipFile = `${initialFilePath}.gz`;

  const command =
    preload.os.platform() === 'darwin'
      ? `gzip -c "${initialFilePath}" > "${tempGzipFile}" && mv "${tempGzipFile}" "${newFilePath}"`
      : `tar -czf "${newFilePath}" "${initialFilePath}"`;
  try {
    await preload.childProcess.node_exec_async(command);
  } catch (e) {
    return false;
  }

  return true;
};
