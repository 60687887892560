import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {forwardRef} from 'react';
import {useIntl} from 'react-intl';

import css from './silencesFooterButton.module.css';

export type FooterButtonProps = {
  isDisabled?: boolean;
  onClick: (...args: any) => Promise<void> | void;
  isLoading?: boolean;
};

export const SilencesFooterButton = forwardRef<
  HTMLButtonElement,
  FooterButtonProps
>(({isDisabled = false, isLoading, onClick: handleCutEvent}, ref) => {
  const intl = useIntl();

  const {silences, transition} = useAutoCutStore(state => ({
    silences: state.ui.parameters.silence.silences,
    transition: state.ui.parameters.silence.transistions,
  }));

  return (
    <div
      id="legacy-step8"
      className={css.container}
    >
      <Button
        ref={ref}
        disabled={isDisabled || isLoading}
        onClick={async () => await handleCutEvent(intl)}
        color={colors.primary600}
        isLoading={isLoading}
      >
        <TranslatedMessage
          id={`modes_silence_steps_cta_${silences ? silences : 'delete'}`}
          defaultMessage="Cut and delete silences"
          values={{
            withTransition:
              transition !== 'none'
                ? intl.formatMessage({
                    id: 'modes_silence_steps_cta_withTransition',
                    defaultMessage: '',
                  })
                : '',
          }}
        />
      </Button>
    </div>
  );
});
