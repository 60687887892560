import {ModalType} from '@autocut/enums/modals.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {reportSentryError} from '@autocut/utils/sentry.utils';

import {getErrorInfos} from './errors/errors.util';
import {replaceCurrentModal} from './modal/replaceCurrentModal';
import {sendStats} from './stats.utils';
import {setAutocutStore} from './zustand/zustand';

//Remove selectionInfos from the parameters
export const manageError = ({
  error,
  additionalData = null,
  disableSentryReport = false,
  disableModal = false,
}: {
  error: Error;
  additionalData?: any;
  disableSentryReport?: boolean;
  disableModal?: boolean;
}) => {
  const selectionInfos = 'Not implemented on DaVinci'; //autocutStoreVanilla().sequence.infos;
  const errorInfos = getErrorInfos(error);
  setAutocutStore('ui.currentErrorId', errorInfos.id);

  if (error.name) {
    void sendStats({
      type: StatType.ERROR,
      value: error.name,
    });
  }

  if (!errorInfos.disableSentry && !disableSentryReport) {
    if (error instanceof IncrementalError) {
      error.reportToSentry();
    } else {
      reportSentryError({
        fingerPrint: error.message,
        error: error as Error,
        context: {
          error,
          selectionInfos,
          additionalData,
        },
      });
    }
  }

  // If we come across a situation where a known error can occur
  // and we want to display an ErrorModal followed by a ReportModal
  // we should add a new property in IdentifiedErrorType and a onClose
  // function in ErrorModal
  if (!disableModal && (error as any)?.code !== 'EBUSY') {
    replaceCurrentModal(errorInfos.customModal ?? ModalType.Error);

    return true;
  }
};
