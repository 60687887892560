import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import hostScreenshotPath from '@autocut/assets/images/hostScript.png';
import davinciLogoPath from '@autocut/assets/images/logoDaVinci.png';
import pproLogoPath from '@autocut/assets/images/logoPPro.png';
import {DiscordIcon} from '@autocut/components/DiscordCta/DiscordIcon/DiscordIcon';
import {LoaderAutocut} from '@autocut/components/LoaderAutocut/LoaderAutocut';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing, SpacingPx} from '@autocut/designSystem/enums/spacing.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {preload} from '@autocut/types/ElectronPreload';
import {markAutoCutV2, markAutoCutV2Renamed} from '@autocut/utils/init/markV2';
import {isDvAppStore} from '@autocut/utils/isDvAppStore';
import {manageError} from '@autocut/utils/manageError';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {displayPriorityModal} from '@autocut/utils/modal/displayPriorityModal';
import {ffmpegSetup} from '@autocut/utils/setup/ffmpeg.setup';
import {waitForHost} from '@autocut/utils/setup/host.setup';
import {preloadWait} from '@autocut/utils/setup/preload.setup';
import {resourcesSetup} from '@autocut/utils/setup/resources.setup';
import {
  checkForUpdates,
  checkForUpdatesErrors,
} from '@autocut/utils/setup/update.setup';
import {versionValidation} from '@autocut/utils/setup/versionValidation.setup';
import {sendStats} from '@autocut/utils/stats.utils';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import {useEffect, useMemo, useState} from 'react';
import {HiOutlineAcademicCap} from 'react-icons/hi';
import {useIntl} from 'react-intl';
const hostScreenshotUrl = new URL(hostScreenshotPath, import.meta.url).href;
const pproLogoUrl = new URL(pproLogoPath, import.meta.url).href;
const davinciLogoUrl = new URL(davinciLogoPath, import.meta.url).href;
export type LaunchStepHandler = (
  setProgress?: (progress: number) => Promise<void>,
) => Promise<boolean | string>;

type LaunchStep = {
  title: TranslationKeys;
  handler: LaunchStepHandler;
  failHandler?: (error?: string) => Promise<void> | void;
  retry?: boolean;
};

const getLaunchSteps = (): LaunchStep[] => [
  {
    title: 'launchManager_steps_preload_title',
    handler: preloadWait,
  },
  {
    title: 'launchManager_steps_versionValidation_title',
    handler: versionValidation,
    failHandler: (error?: string) => {
      if (error === 'invalidVersion') {
        displayPriorityModal(ModalType.ElectronVersionModal);
        manageError({
          error: new Error('launchManager_steps_versionValidation_fail'),
          disableModal: true,
          additionalData: {error},
        });
      } else {
        displayPriorityModal(ModalType.LaunchError);
      }
    },
  },
  {
    title: 'launchManager_steps_update_title',
    handler: checkForUpdates,
    failHandler: async (error?: string) => {
      if (error === checkForUpdatesErrors.TIMEOUT) {
        displayPriorityModal(ModalType.UpdateTimeout);
        await sendStats({
          type: StatType.UPDATE_TIMEOUT,
          value: 1,
        });
      } else if (error === checkForUpdatesErrors.FAIL) {
        manageError({
          error: new Error('launchManager_steps_update_fail'),
          disableModal: true,
          additionalData: {error},
        });
        displayPriorityModal(ModalType.ManualUpdate);
      } else {
        displayPriorityModal(ModalType.UpdateElectron);
      }
      throw new Error('launchManager_steps_update_fail');
    },
  },
  {
    title: 'launchManager_steps_ffmpeg_title',
    handler: ffmpegSetup,
  },
  {
    title: 'launchManager_steps_resources_title',
    handler: resourcesSetup,
    failHandler: (error?: string) => {
      displayPriorityModal(ModalType.LaunchError);
      manageError({
        error: new Error('launchManager_steps_resources_fail'),
        disableModal: true,
        additionalData: {error},
      });
    },
  },
  {
    title: 'launchManager_steps_computeIPC_title',
    handler: async setProgress => {
      // Fin install
      try {
        await preload.computeNodeIpc.connect();
        await setProgress?.(50);
        const computeVersion = await preload.computeNodeIpc.startTask<string>({
          domain: {
            slug: 'version',
            target: 'common',
          },
          priority: 0,
        }).promise;
        setAutocutStore('ui.versions.compute', computeVersion);
        await setProgress?.(95);
        await sendStats({
          type: StatType.SETUP_COMPLETED,
          value: 1,
        });
        await setProgress?.(100);
        return true;
      } catch {
        throw new Error('launchManager_errors_compute');
      }
    },
  },
  {
    title: 'launchManager_steps_hostIPC_title',
    handler: async setProgress => {
      // Fin install
      try {
        await preload.hostNodeIpc.connect();
        await setProgress?.(33);

        const isAppStore = await isDvAppStore();

        if (isAppStore) addModalToQueue(ModalType.AppStoreDv);

        await markAutoCutV2();
        await markAutoCutV2Renamed();

        return waitForHost(setProgress);
      } catch {
        throw new Error('launchManager_errors_davinci');
      }
    },
    failHandler: (error?: string) => {
      if (error === 'renaming') {
        const currentModal = autocutStoreVanilla().modal.openedModalName;
        if (currentModal !== ModalType.PProExtensionRenamed)
          displayPriorityModal(ModalType.RestartPremierePro);
      } else if (typeof error === 'string') {
        setAutocutStore(
          'modal.data.hostUpdate.hostName',
          error as 'ppro' | 'davinci',
        );
        displayPriorityModal(ModalType.UpdateHost);
      }
    },
    retry: true,
  },
];

export const LaunchManager = ({onComplete}: {onComplete: () => void}) => {
  const intl = useIntl();
  const appUUID = useAutoCutStore(state => state.ui.appUUID);

  const [stepIndex, setStepIndex] = useState(0);
  const [error, setError] = useState<TranslationKeys | null>(null);

  const launchSteps = useMemo(() => {
    return getLaunchSteps();
  }, []);

  useEffect(() => {
    const process = async () => {
      await sendStats({
        type: StatType.SETUP_STARTED,
        value: 1,
      });

      const runStep = async (
        index: number,
        step: LaunchStep,
        tryCount: number = 0,
      ): Promise<boolean> => {
        const handlerResult = (await new Promise(async resolve =>
          resolve(
            await step.handler().catch(e => {
              manageError({
                error: e,
                disableModal: true,
                additionalData: {stepIndex: index, stepTitle: step.title},
              });
              setError(e.message);
              return JSON.stringify(e);
            }),
          ),
        )) as boolean | string;

        if (handlerResult === true) {
          if (index + 1 === launchSteps.length) {
            onComplete();
          }

          setStepIndex(index + 1);

          // Wait for the page to be updated
          await new Promise(resolve => setTimeout(() => resolve(true), 50));

          return true;
        } else {
          await step.failHandler?.(
            typeof handlerResult === 'string' ? handlerResult : undefined,
          );

          if (step.retry) {
            return await runStep(index, step, tryCount + 1);
          } else {
            throw new Error('launchManager_error');
          }
        }
      };

      for (const [indexStr, step] of Object.entries(launchSteps)) {
        console.log('step', step);
        try {
          const res = await runStep(parseInt(indexStr), step);
          console.log('res', res);
        } catch {
          break;
        }
      }
    };

    void process();
  }, []);

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="space-between"
      gap={SpacingPx.p16}
      style={{height: '100%'}}
    >
      {stepIndex === launchSteps.length - 1 ? (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={SpacingPx.p16}
          style={{margin: SpacingPx.p16, marginTop: 0}}
        >
          <h2>
            <TranslatedMessage
              id="launchManager_steps_hostIPC_title"
              defaultMessage="Installation / Update"
            />
          </h2>
          <div
            style={{
              position: 'relative',
              maxWidth: '100vw',
              padding: `${SpacingPx.p8} 0`,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '82px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '55.5%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: SpacingPx.p4,
                  paddingBottom: SpacingPx.p8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: SpacingPx.p8,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 26,
                  }}
                >
                  <img
                    src={pproLogoUrl}
                    alt="ppro_logo"
                    width={25}
                  />
                  <h2>Premiere Pro</h2>
                </div>
                <p>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_ppro_part1"
                    defaultMessage="Launch AutoCut extension from :"
                  />
                </p>
                <p style={{color: 'white', fontWeight: 'bolder'}}>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_ppro_part2"
                    defaultMessage="Window > extensions > AutoCut V2"
                  />
                </p>
              </div>
              <div
                style={{
                  width: '44.5%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingBottom: SpacingPx.p8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: SpacingPx.p8,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 26,
                  }}
                >
                  <img
                    src={davinciLogoUrl}
                    alt="davinci_logo"
                    width={25}
                  />
                  <h2>DaVinci</h2>
                </div>
                <p>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_davinci_part1"
                    defaultMessage="Launch script from :"
                  />
                </p>
                <p style={{color: 'white', fontWeight: 'bolder'}}>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_davinci_part2"
                    defaultMessage="Workspace > Scripts > AutoCut"
                  />
                </p>
              </div>
            </div>
            <img
              src={hostScreenshotUrl}
              alt="host_screenshot"
              style={{
                maxWidth: '100vw',
                objectFit: 'cover',
              }}
            />
          </div>
          <h3 style={{textAlign: 'center'}}>
            <TranslatedMessage id={'launchManager_steps_hostIPC_subtitle'} />
          </h3>
          <p style={{textAlign: 'center'}}>
            <TranslatedMessage
              id={'launchManager_steps_hostIPC_stillNot'}
              valuePamareters={{
                copyText: intl.formatMessage({id: 'globals_contactMail'}),
              }}
            />
          </p>
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={SpacingPx.p16}
            style={{margin: `${SpacingPx.p32} ${SpacingPx.p16} 0`}}
          >
            <h1>
              <TranslatedMessage
                id="launchManager_title"
                defaultMessage="Installation / Update"
              />
            </h1>
            <h3 style={{textAlign: 'center'}}>
              {stepIndex}/{launchSteps.length} -
              <TranslatedMessage id={launchSteps[stepIndex].title} />
            </h3>
            <LoaderAutocut height={150} />

            {error ? (
              <>
                <p style={{color: 'red', textAlign: 'center'}}>
                  <TranslatedMessage id={error} />
                </p>
                <Button
                  color={colors.error600}
                  onClick={() => preload.electron.reload()}
                >
                  <TranslatedMessage id="launchManager_errors_cta" />
                </Button>
              </>
            ) : null}
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={SpacingPx.p16}
            style={{margin: `0 ${SpacingPx.p16} ${SpacingPx.p16}`}}
          >
            <Button
              variant="tertiary"
              color={colors.gray300}
              onClick={() =>
                preload.electron.openLinkInBrowser('https://discord.gg/autocut')
              }
              fullWidth={false}
            >
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                gap={Spacing.s2}
              >
                <DiscordIcon
                  size={24}
                  color="white"
                />
                <TranslatedMessage
                  id="launchManager_footer_discord"
                  defaultMessage="Click here to join the AutoCut Discord!"
                />
              </FlexContainer>
            </Button>
            <Button
              variant="tertiary"
              color={colors.gray300}
              onClick={() =>
                preload.electron.openLinkInBrowser(
                  'https://www.autocut.com/help-center/',
                )
              }
              fullWidth={false}
            >
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                gap={Spacing.s2}
              >
                <HiOutlineAcademicCap
                  size={24}
                  color="white"
                />
                <TranslatedMessage
                  id="launchManager_footer_learnMore"
                  defaultMessage="Click here to discover tips on mastering all AutoCut features!"
                />
              </FlexContainer>
            </Button>
            <Text variant="textXs.semibold">APP UUID: {appUUID}</Text>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};
