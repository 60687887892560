import type {TextVariant} from '@autocut/designSystem/components/atoms/Text/variants';

import {DISCORD_LINK} from '@autocut/components/DiscordCta/DiscordCta';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload} from '@autocut/types/ElectronPreload';

const textVariant: TextVariant = 'textSm.medium';

export const ONBOARDING_MODAL_STEPS = [
  {
    title: (
      <TranslatedMessage
        id="modals_onboarding_steps_welcome_title"
        defaultMessage="Welcome to the AutoCut beta"
      />
    ),
    label: (
      <TranslatedMessage
        id="modals_onboarding_steps_welcome_stepper"
        defaultMessage="Welcome"
      />
    ),
    footerLabel: (
      <TranslatedMessage
        id="modals_onboarding_steps_welcome_footer"
        defaultMessage="Next"
      />
    ),
    body: (
      <FlexContainer
        flexDirection="column"
        gap={12}
        key="welcome"
        className="appear"
      >
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_welcome_text1"
            defaultMessage="Thank you for being a part of this journey ! 🚀 We're thrilled to present you this enhanced version of AutoCut.{br}We can’t wait to let you test this revamped version of our fellow AI video editing assistant."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_welcome_text2"
            defaultMessage="<b>Enhanced user interface</b> to help AutoCut be a part of your editing workflow 🎬{br}<b>+80% increase in stability</b> - say goodbye to unexpected crashes or interruptions 🧘{br}<b>+50% performance boost</b> with faster response times, smoother result and greater efficiency 🚀"
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_welcome_text3"
            defaultMessage="And that’s not it, but we will let you discover it along the way. Let’s get started!"
          />
        </Text>
      </FlexContainer>
    ),
  },
  {
    title: (
      <TranslatedMessage
        id="modals_onboarding_steps_whyAreYouHere_title"
        defaultMessage="Why are you here ?"
      />
    ),
    label: (
      <TranslatedMessage
        id="modals_onboarding_steps_whyAreYouHere_stepper"
        defaultMessage="Why are you here ?"
      />
    ),
    footerLabel: (
      <TranslatedMessage
        id="modals_onboarding_steps_whyAreYouHere_footer"
        defaultMessage="Next"
      />
    ),
    body: (
      <FlexContainer
        flexDirection="column"
        gap={12}
        key="whyAreYouHere"
        className="appear"
      >
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_whyAreYouHere_text1"
            defaultMessage="If you are here, it is because we <b>need</b> your help 🫵"
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_whyAreYouHere_text2"
            defaultMessage="You have proven that you are one of our more faithful user, and we thank you for that, that is why we have chosen you to be part of this exclusive beta."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_whyAreYouHere_text3"
            defaultMessage="But <i>with great power comes great responsibility.</i>"
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_whyAreYouHere_text4"
            defaultMessage="We need your feedback to make AutoCut the ultimate tool for you to have in your video editor skills collection. Let’s build together the tool to put away all the repetitive tasks and let you focus on what you do best : your creativity."
          />
        </Text>
      </FlexContainer>
    ),
  },
  {
    title: (
      <TranslatedMessage
        id="modals_onboarding_steps_weNeedYou_title"
        defaultMessage="We need you 🫵"
      />
    ),
    label: (
      <TranslatedMessage
        id="modals_onboarding_steps_weNeedYou_stepper"
        defaultMessage="We need you!"
      />
    ),
    footerLabel: (
      <TranslatedMessage
        id="modals_onboarding_steps_weNeedYou_footer"
        defaultMessage="Let's dive in"
      />
    ),
    body: (
      <FlexContainer
        flexDirection="column"
        gap={12}
        key="weNeedYou"
        className="appear"
      >
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_weNeedYou_text1"
            defaultMessage="You will find on the bottom-right of the homepage a new feedback bubble that will allow you to report any bug that occurs in this new version."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_weNeedYou_text2"
            defaultMessage="Resolving those issues is our top 1 priority, that’s why each of your comment will be duly reviewed."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_weNeedYou_text3"
            defaultMessage="It is also a channel through which you will be able to suggest features that are to you an indispensable."
          />
        </Text>
        <Text variant={textVariant}>
          <TranslatedMessage
            id="modals_onboarding_steps_weNeedYou_text4"
            defaultMessage="You may also want to join our <a>Discord</a> community of 2000+ friendly video editors like you and get an exclusive sneak peek at the future of AutoCut 🌍"
            values={{
              a: text => (
                <a
                  style={{
                    textDecoration: 'underline',
                    color: colors.primary600,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    void preload.electron.openLinkInBrowser(DISCORD_LINK);
                  }}
                >
                  {text}
                </a>
              ),
            }}
          />
        </Text>
      </FlexContainer>
    ),
  },
];
