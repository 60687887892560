import type {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';

import {preload} from '@autocut/types/ElectronPreload';

export const ffmpegSetup: LaunchStepHandler = async (
  setProgress,
): Promise<boolean> => {
  try {
    await setProgress?.(0);
    const path = preload.ffmpegSetup.getFFMPEGPath();

    if (!!path) {
      await setProgress?.(100);
      return true;
    }

    const ffmpegVersion = '116759-g40dda881d6';
    const ffmpegBuildTime = '2024-08-25';
    await setProgress?.(30);

    console.log(
      'downloadFFMPEG',
      preload.ffmpegSetup.downloadFFMPEG(ffmpegVersion, ffmpegBuildTime),
    );
    await setProgress?.(100);
    console.log('OK');
    return true;
  } catch (e: any) {
    throw new Error('Error while installing ffmpeg' + e.message);
  }
};
