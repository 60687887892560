import type {AutocutModeIds} from '@autocut/enums/modes.enum';

import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {NotificationContext} from '@autocut/contexts/NotificationProvider.tsx/NotificationProvider';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {isHost} from '@autocut/utils/host';
import {range} from '@autocut/utils/math.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {Fragment, useContext, useEffect, useState} from 'react';
import {HiOutlineMapPin} from 'react-icons/hi2';

import {
  handleEntireTimeline,
  handleInOut,
  handleSelectedClips,
} from '../../sections.utils';
import {TimelineLegend} from '../TimelineLegend/TimelineLegend';
import {TimelineSectionned} from '../TimelineSectionned/TimelineSectionned';
import {TimelineSectionnedGraduated} from '../TimelineSectionnedGraduated/TimelineSectionnedGraduated';
import css from './SetSectionsStep.module.scss';

type TSetMarkerStep = {
  mode: AutocutModeIds;
};

const availableModes = {
  [`${AutocutModes.Legacy.id}`]: {
    clipSelectionAvailable: true,
  },
};

export const SetSectionsStep = ({mode}: TSetMarkerStep) => {
  const {displayNotification} = useContext(NotificationContext);

  const isClipSelectionAvailable =
    !isHost('davinci') &&
    availableModes[mode] &&
    availableModes[mode].clipSelectionAvailable;
  const {deactivatedTrackIndexes, selectedSections, timelineInfos} =
    useAutoCutStore(state => ({
      deactivatedTrackIndexes: state.ui.deactivatedTrackIndexes,
      selectedSections: state.ui.selectedSections,
      timelineInfos: state.onGoingProcess.timelineInfos,
    }));
  const [isLoading, setIsLoading] = useState(true);

  const numberOfTracks = timelineInfos?.audioTracks.length ?? 0;

  useEffect(() => {
    if (timelineInfos) {
      setIsLoading(false);
    }
  }, [timelineInfos]);

  useEffect(() => {
    if (!timelineInfos || selectedSections.length === 0) return;

    let foundAtLeastOneClip = false;

    trackLoop: for (
      let trackIndex = 0;
      trackIndex < numberOfTracks;
      trackIndex++
    ) {
      if (deactivatedTrackIndexes.includes(trackIndex + 1)) continue trackLoop;

      const track = timelineInfos.audioTracks[trackIndex];
      for (const [sectionStart, sectionEnd] of selectedSections) {
        if (track.start < sectionEnd && track.end > sectionStart) {
          foundAtLeastOneClip = true;
          break trackLoop;
        }
      }
    }

    setAutocutStore('onGoingProcess.isSelectionValid', foundAtLeastOneClip);
  }, [
    selectedSections,
    deactivatedTrackIndexes,
    timelineInfos,
    numberOfTracks,
  ]);

  return (
    <FormSection
      title={
        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          gap={8}
        >
          <HiOutlineMapPin
            size={24}
            color={colors.gray300}
            strokeWidth={2}
          />
          <Text
            variant="textMd.bold"
            color={colors.gray300}
          >
            <TranslatedMessage
              id="modes_general_steps_select_title"
              defaultMessage={'Set markers'}
            />
          </Text>
        </FlexContainer>
      }
      description={
        <Text variant="textSm.medium">
          <TranslatedMessage
            id="modes_general_steps_select_description"
            defaultMessage={
              'Please set the markers position for your selection. You can set them manually or use one of the button below.'
            }
          />
        </Text>
      }
    >
      {isLoading ? (
        <FlexContainer
          justifyContent="center"
          alignItems="center"
        >
          <LoaderInfinity height={300} />
        </FlexContainer>
      ) : (
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s5}
        >
          <FlexContainer
            flexDirection="row"
            gap={6}
          >
            <Button
              onClick={handleEntireTimeline}
              variant="secondary"
            >
              <TranslatedMessage
                id="modes_general_steps_select_entire"
                defaultMessage={'Entire timeline'}
              />
            </Button>
            <Button
              onClick={async () => {
                const res = await handleInOut();
                if (res === -1) {
                  displayNotification(
                    <TranslatedMessage
                      id="modes_general_steps_select_inOut_error"
                      defaultMessage={
                        'No In/Out points have been detected in your timeline. {br}Please set them in your timeline and try again.'
                      }
                    />,
                  );
                }
              }}
              variant="secondary"
            >
              <TranslatedMessage
                id="modes_general_steps_select_inOut_label"
                defaultMessage={'In/Out points'}
              />
            </Button>
            {isClipSelectionAvailable && (
              <Button
                onClick={handleSelectedClips}
                variant="secondary"
              >
                <TranslatedMessage
                  id="modes_general_steps_select_selected"
                  defaultMessage={'Selected clips'}
                />
              </Button>
            )}
          </FlexContainer>
          <div
            className={css.grid}
            style={{
              //@ts-ignore
              '--number-of-rows': numberOfTracks,
            }}
          >
            <TimelineSectionnedGraduated
              style={{
                gridRow: '1 / 2',
                gridColumn: '2 / 3',
              }}
            />
            {timelineInfos
              ? selectedSections.flat().map(section => {
                  const sectionStartPercentage = range(
                    timelineInfos.start,
                    timelineInfos.start + timelineInfos.duration,
                    0,
                    100,
                    section,
                  );

                  return (
                    <div
                      key={section}
                      className={css.border}
                      style={{
                        left: `${sectionStartPercentage}%`,
                        gridRow: `2 / ${numberOfTracks + 2}`,
                      }}
                    />
                  );
                })
              : null}
            {Array.from({length: numberOfTracks}).map((_, trackIndex) => {
              return (
                <Fragment key={trackIndex}>
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="normal"
                    alignItems="center"
                    style={{
                      gridRow: `${trackIndex + 2} / ${trackIndex + 3}`,
                      gridColumn: '1 / 2',
                    }}
                    gap={Spacing.s2}
                  >
                    <CheckBox
                      size={20}
                      checked={
                        !deactivatedTrackIndexes.includes(trackIndex + 1)
                      }
                      onChange={v => {
                        if (v) {
                          const newDeactivatedTrackIndexes =
                            deactivatedTrackIndexes.filter(
                              trackIndexFilter =>
                                trackIndexFilter != trackIndex + 1,
                            );
                          setAutocutStore('ui.deactivatedTrackIndexes', [
                            ...newDeactivatedTrackIndexes,
                          ]);
                        } else {
                          deactivatedTrackIndexes.push(trackIndex + 1);
                          setAutocutStore('ui.deactivatedTrackIndexes', [
                            ...deactivatedTrackIndexes,
                          ]);
                        }
                      }}
                    />
                    <Text variant="textXs">{`A${trackIndex + 1}`}</Text>
                  </FlexContainer>
                  <TimelineSectionned
                    style={{
                      gridRow: `${trackIndex + 2} / ${trackIndex + 3}`,
                      gridColumn: '2 / 3',
                    }}
                    disabled={deactivatedTrackIndexes.includes(trackIndex + 1)}
                  />
                </Fragment>
              );
            })}
            <TimelineLegend
              style={{
                width: '100%',
                margin: `${Spacing.s4} auto 0 auto`,
                gridRow: `${numberOfTracks + 2} / ${numberOfTracks + 3}`,
                gridColumn: '2 / 3',
              }}
            />
          </div>
        </FlexContainer>
      )}
    </FormSection>
  );
};
