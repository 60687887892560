import type {IdentifiedErrorKeys} from '@autocut/enums/errors.enum';

import {IdentifiedErrors} from '@autocut/enums/errors.enum';

const ERROR_NO_AUDIO = 0;
const ERROR_MORE_THAN_ONE_GROUP = 1;
const ERROR_MULTIPLE_AUDIO = 2;

export const getCutError = (errorNumber: number) => {
  switch (errorNumber) {
    case ERROR_NO_AUDIO:
      return 'AutoCut need an audio clip to detect silences.';
    case ERROR_MORE_THAN_ONE_GROUP:
      return 'Please select only one clip group';
    case ERROR_MULTIPLE_AUDIO:
      return 'AutoCut does not support multitrack audio at the moment.';
    default:
      return 'An unexpected error occured, please contact us.';
  }
};

export const getErrorInfos = (err: Error) => {
  for (const error of Object.values(IdentifiedErrors)) {
    if (error.testFunction(err)) {
      return error;
    }
  }

  return IdentifiedErrors.UNKNOWN;
};

export const getErrorInfosById = (errorId: IdentifiedErrorKeys) => {
  return IdentifiedErrors[errorId];
};

export const runPromiseWithFallback = async <T>(
  process: () => Promise<T>,
  fallbackValue: T,
): Promise<T> => {
  try {
    return await process();
  } catch {
    return fallbackValue;
  }
};
