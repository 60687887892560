import type {ComponentPropsWithoutRef} from 'react';

export type IconComponentProps = Omit<
  ComponentPropsWithoutRef<'svg'>,
  'xmlns'
> & {size: number};

export const AnimationIcon = ({
  size,
  color,
  children,
  className,
  strokeWidth,
  ...props
}: IconComponentProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 43 24"
      className={className}
      height={`${(size as number) / 16}rem`}
      width={`${(size as number) / 16}rem`}
      color={color || '#5865F2'}
      {...props}
    >
      <path
        d="M2 23.9999C2 -2.83325 21.8 -3.29591 21.8 23.9999C21.8 23.9999 21.8 3.70117 32.6 3.70117"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <circle
        cx="39"
        cy={'4'}
        r={strokeWidth}
        fill="currentColor"
      />
    </svg>
  );
};
