import type {AutoCutApiError} from '../errors/AutoCutApiError';
import type {EmojiCategory} from './getEmojisCategories';

import {FingerprintErrorFactory} from '../errors/FingerprintErrorFactory';
import {autocutApi} from '../http.utils';
import {manageError} from '../manageError';

export const getAnimatedEmojis = async (
  category?: EmojiCategory,
): Promise<
  {
    url: string;
    name: string;
  }[]
> => {
  const endpoint = category
    ? `emojis/animated?category=${category}`
    : 'emojis/animated';

  const {
    data = [],
  }: {
    data: {
      url: string;
      name: string;
    }[];
  } = await autocutApi.get(endpoint).catch((error: AutoCutApiError) => {
    manageError({
      error: FingerprintErrorFactory(error, 'getAnimatedEmojis'),
    });

    return {} as never;
  });

  return data;
};
