import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconOpenedBook = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 20H5.2c-1.1 0-1.7 0-2.1-.2a2 2 0 0 1-.9-.9c-.2-.4-.2-1-.2-2.1V7.2c0-1.1 0-1.7.2-2.1.2-.4.5-.7.9-.9C3.5 4 4 4 5.2 4h.4c2.2 0 3.4 0 4.2.4a4 4 0 0 1 1.8 1.8c.4.8.4 2 .4 4.2m0 9.6v-9.6m0 9.6h6.8c1.1 0 1.7 0 2.1-.2.4-.2.7-.5.9-.9.2-.4.2-1 .2-2.1V7.2c0-1.1 0-1.7-.2-2.1a2 2 0 0 0-.9-.9c-.4-.2-1-.2-2.1-.2h-.4c-2.2 0-3.4 0-4.2.4a4 4 0 0 0-1.8 1.8c-.4.8-.4 2-.4 4.2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
