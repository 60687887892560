import {InputRangeParameter} from '@autocut/components/InputRangeParamater/InputRangeParameter';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {PreviewFrame} from '@autocut/components/PreviewFrame/PreviewFrame';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useIntl} from 'react-intl';

export const PreviewZoomStep = () => {
  const {dynamicMinZoomIntensity, autoZoomMaxCoef} = useAutoCutStore(
    state => state.ui.parameters.zoom,
  );
  const autoZoomMinCoef = 1 + dynamicMinZoomIntensity * (autoZoomMaxCoef - 1);
  const intl = useIntl();

  return (
    <FormSection
      style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s4}}
      title={intl.formatMessage({
        id: 'modes_zoom_parameters_preview_title',
        defaultMessage: 'Zoom preview',
      })}
      description={intl.formatMessage({
        id: 'modes_zoom_parameters_preview_description',
        defaultMessage: 'Define the anchor point',
      })}
    >
      <FlexContainer
        style={{width: '80%', margin: '0 auto', paddingBottom: Spacing.s2}}
      >
        <PreviewFrame
          interfaces={[
            {
              type: 'anchor',
              additionalProps: {
                coeffZoomMin: autoZoomMinCoef,
                coeffZoomMax: autoZoomMaxCoef,
                onChange: (
                  pproAnchorPosPercentageMinCoef: {
                    xPercentage: number;
                    yPercentage: number;
                  },
                  pproAnchorPosPercentageMaxCoef: {
                    xPercentage: number;
                    yPercentage: number;
                  },
                  davinciAnchorPosPercentage: {
                    xPercentage: number;
                    yPercentage: number;
                  },
                ) => {
                  setAutocutStore(
                    'ui.parameters.zoom.pproAnchorPosMinCoef',
                    pproAnchorPosPercentageMinCoef,
                  );
                  setAutocutStore(
                    'ui.parameters.zoom.pproAnchorPosMaxCoef',
                    pproAnchorPosPercentageMaxCoef,
                  );
                  setAutocutStore(
                    'ui.parameters.zoom.davinciAnchorPosPercentage',
                    davinciAnchorPosPercentage,
                  );
                },
              },
            },
          ]}
        />
      </FlexContainer>

      <ParameterLayout
        title={
          <TranslatedMessage
            id="modes_zoom_parameters_preview_maximumZoom"
            defaultMessage="Maximum zoom"
          />
        }
      >
        <InputRangeParameter
          value={autoZoomMaxCoef}
          step={0.05}
          min={1}
          max={2}
          unit="%"
          unitFormatting={value => `${(value * 100).toFixed()}`}
          onChange={value =>
            setAutocutStore(
              'ui.parameters.zoom.autoZoomMaxCoef',
              parseFloat(value),
            )
          }
        />
      </ParameterLayout>
    </FormSection>
  );
};
