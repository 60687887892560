import type {ModalType} from '@autocut/enums/modals.enum';

import {autocutStoreVanilla, setAutocutStore} from '../zustand/zustand';

/**
 * This function adds a modal to the start of the queue.
 * It will display the modal immediately.
 * If there is a modal already displayed, it will be pushed to the start of the queue.
 */
export const displayPriorityModal = (modal: ModalType) => {
  console.log('[MODAL] Displaying priority modal: ', modal);
  const currentModal = autocutStoreVanilla().modal.openedModalName;

  if (currentModal === modal) return;

  setAutocutStore('modal.openedModalName', modal);
  if (currentModal)
    setAutocutStore('modal.modalQueue', s => [
      currentModal,
      ...s.modal.modalQueue,
    ]);
};
