import {setAutocutStore} from '@autocut/utils/zustand/zustand';

import {unmuteSelectedTracks} from './selectedTracks';

export const handleProcessFinally = async () => {
  //Reset states
  setAutocutStore('ui.process.isProcessing', false);
  setAutocutStore('ui.process.isPaused', false);
  setAutocutStore('onGoingProcess.CEPProgressCallback', undefined);

  await unmuteSelectedTracks();
};
