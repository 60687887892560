import {preload} from '@autocut/types/ElectronPreload';

import {getInstallResourceAtSpecificPathConfig} from '../downloadableRessources.utils';

export const getPproExtensionV1 = () => {
  return getInstallResourceAtSpecificPathConfig({
    resourceName: 'ppro_extension_v1',
    mac: {
      folderPath: preload.path.join(
        '/',
        'Library',
        'Application Support',
        'Adobe',
        'CEP',
        'extensions',
        'fr.toolpad.autoCut',
      ),
      downloadUrl: 'https://static.autocut.com/fr.toolpad.autoCut.zxp',
    },
    windows: {
      folderPath: preload.path.join(
        preload.os.homedir(),
        'AppData',
        'Roaming',
        'Adobe',
        'CEP',
        'extensions',
        'fr.toolpad.autoCut',
      ),
      downloadUrl: 'https://static.autocut.com/fr.toolpad.autoCut.zxp',
    },
    createFolderIfNotExist: false,
  });
};
