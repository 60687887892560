import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconTypeStrikethrough01} from '@autocut/designSystem/components/atoms/Icon/editor/IconTypeStrikthrough01';
import {IconSave03} from '@autocut/designSystem/components/atoms/Icon/general/IconSave03';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {CensoredWordsGrid} from '@autocut/pages/modes/swearWords/Parts/CensorWordsStep/CensoredWordsGrid';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useState} from 'react';
import {useIntl} from 'react-intl';

export const ManageSwearWordsModal = () => {
  const intl = useIntl();
  const {savedSwearWords} = useAutoCutStore(state => ({
    savedSwearWords: state.ui.swearWords.swearWordsDictionary,
  }));

  const [currentWord, setCurrentWord] = useState('');
  const [dictionary, setDictionary] = useState<string[]>(savedSwearWords);

  const addSwearWord = () => {
    setDictionary(Array.from(new Set([...dictionary, currentWord])));
    setCurrentWord('');
  };

  const deleteSwearWord = (wordToDelete: string) =>
    setDictionary(prev => prev.filter(word => word !== wordToDelete));

  const saveSwearWords = () => {
    setAutocutStore('ui.swearWords.swearWordsDictionary', dictionary);
    closeModal();
  };

  return (
    <Modal
      isBlocking
      title={intl.formatMessage({
        id: 'modals_manageSwearWords_title',
        defaultMessage: 'Saved swear words list',
      })}
      icon={<IconTypeStrikethrough01 size={24} />}
      footer={
        <FlexContainer gap={Spacing.s6}>
          <Button
            variant="secondary"
            onClick={() => closeModal()}
          >
            <TranslatedMessage
              id="modals_manageSwearWords_cta_secondary"
              defaultMessage="Close"
            />
          </Button>
          <Button
            variant="primary"
            color={colors.primary600}
            onClick={saveSwearWords}
          >
            <TranslatedMessage
              id="modals_manageSwearWords_cta_primary"
              defaultMessage="Save"
            />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
      >
        <FlexContainer gap={Spacing.s1}>
          <Input
            type="text"
            width="100%"
            placeholder="Swear word"
            rightIcon={
              <ActionIcon
                variant="filled"
                color={colors.gray800}
                size={24}
                onClick={addSwearWord}
              >
                <IconSave03 size={18} />
              </ActionIcon>
            }
            onChange={value => setCurrentWord(value)}
            value={currentWord}
            onKeyDown={e => {
              if (e.key === 'Enter') addSwearWord();
            }}
          />
        </FlexContainer>
        <CensoredWordsGrid
          words={dictionary}
          onDeleteWord={deleteSwearWord}
        />
      </FlexContainer>
    </Modal>
  );
};
