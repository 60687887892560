import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Badge} from '@autocut/designSystem/components/atoms/Badge/Badge';
import {IconXClose} from '@autocut/designSystem/components/atoms/Icon/general/IconXClose';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

type CensoredWordsGrid = {
  words: string[];
  onDeleteWord?: (word: string) => void;
};

export const CensoredWordsGrid = ({words, onDeleteWord}: CensoredWordsGrid) => {
  return (
    <FlexContainer
      flexWrap="wrap"
      gap={Spacing.s2}
    >
      {words.map(word => (
        <Badge key={word}>
          <FlexContainer
            alignItems="center"
            gap={Spacing.s1}
          >
            {word}
            {onDeleteWord && (
              <ActionIcon
                variant="subtle"
                onClick={() => onDeleteWord(word)}
                size={18}
              >
                <IconXClose size={14} />
              </ActionIcon>
            )}
          </FlexContainer>
        </Badge>
      ))}
    </FlexContainer>
  );
};
