import {InfoText} from '@autocut/components/InfoText/InfoText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconInfoSquare} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoSquare';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {preload} from '@autocut/types/ElectronPreload';

export const ManualUpdateModal = () => {
  return (
    <Modal
      title={
        <TranslatedMessage
          id="modals_manualUpdate_title"
          defaultMessage="You need to update manually'"
        />
      }
      isBlocking
      icon={
        <IconInfoSquare
          size={24}
          color="white"
        />
      }
      footer={
        <Button
          color={colors.primary600}
          onClick={() =>
            preload.electron.openLinkInBrowser(
              'https://www.autocut.com/download/',
            )
          }
        >
          <TranslatedMessage
            id="modals_manualUpdate_cta"
            defaultMessage="Download Update"
          />
        </Button>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
      >
        <TranslatedMessage
          id="modals_manualUpdate_body"
          defaultMessage="The automatic update was unsuccessful. Please update the software manually to continue using it. For assistance, contact our support team."
        />

        <InfoText style="warning">
          <TranslatedMessage id="modals_manualUpdate_info" />
        </InfoText>
      </FlexContainer>
    </Modal>
  );
};
