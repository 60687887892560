import type {Font} from '@autocut/types/font';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {GenerateZustandKeys} from '@autocut/utils/zustand/zustand';

import {
  syncToCookies,
  type ZustandSyncConfig,
} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_Misc = 'misc';

export type ZType_Misc = {
  hasSeenTrialPromo: boolean;
  trialPromoEnd: number | undefined;
  intervals: NodeJS.Timer[];
  fonts: Font[];
  lastSeenStartUpModals: Date;
  isFirstCut: boolean;
  lastCut: string;
};

export type ZKeys_Misc = GenerateZustandKeys<ZBaseKey_Misc, ZType_Misc>;

export const zDefaultValues_Misc: ZType_Misc = {
  hasSeenTrialPromo: false,
  trialPromoEnd: undefined,
  intervals: [],
  fonts: [],
  lastSeenStartUpModals: new Date(),
  isFirstCut: false,
  lastCut: '',
};

export const zSync_Misc: ZustandSyncConfig<ZKeys_Misc> = {
  'misc.hasSeenTrialPromo': {cookieName: 'hasSeenTrialPromo'},
  'misc.trialPromoEnd': {cookieName: 'trialPromoEnd'},
  'misc.lastSeenStartUpModals': {cookieName: 'lastSeenStartUpModals'},
};

export const zSubscribers_Misc: AutoCutStateSubscriberConfig<ZKeys_Misc> = {
  'misc.hasSeenTrialPromo': k => syncToCookies(k),
  'misc.trialPromoEnd': k => syncToCookies(k),
  'misc.lastSeenStartUpModals': k => syncToCookies(k),
};
