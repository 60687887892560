import {autocutStoreVanilla} from '@autocut/utils/zustand/zustand';
import * as Sentry from '@sentry/react';
import {createRoot} from 'react-dom/client';
import TagManager from 'react-gtm-module';
import {BrowserRouter} from 'react-router-dom';

import json from '../package.json';
import './index.css';
import App from './App';
import {AUTOCUT_CONSTANTS} from './constants/configs';
import {GameProvider} from './contexts/GameProvider';
import {LocaleProvider} from './contexts/LocaleContext';
import {CURRENT_ENV, IS_RUNNING_ON_ELECTRON} from './utils/currentEnv.utils';
import {getHostName} from './utils/system/hostname.system.utils';
import {getUUID} from './utils/system/uuid.system.utils';

// This function allows to stringify an Error object
// It will be used for Sentry and the logger
// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
if (!('toJSON' in Error.prototype))
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function () {
      const alt: any = {};

      Object.getOwnPropertyNames(this).forEach(function (key: any) {
        // @ts-ignore
        alt[key] = this[key];
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

const tagManagerArgs = {
  gtmId: AUTOCUT_CONSTANTS[CURRENT_ENV].GTM_ID,
};

const SENTRY_ENV = CURRENT_ENV as string;

Sentry.init({
  dsn: 'https://683bddefdf80f828fdbcb22dfbfe4c11@o4504764662087680.ingest.us.sentry.io/4507921711955968',
  environment: SENTRY_ENV,
  enabled: ['development', 'production', 'qa-staging', 'staging'].includes(
    SENTRY_ENV,
  ),
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: ['development', 'qa-staging', 'staging'].includes(
    SENTRY_ENV,
  )
    ? 1.0
    : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: ['development', 'qa-staging', 'staging'].includes(
    SENTRY_ENV,
  )
    ? 1.0
    : 0.1,
  release: `autocut-extension-v3@${json.version}`,
  async beforeSend(event, hint) {
    // Setting default tags
    event.tags = {
      ...event.tags,
      'AutoCut Version': json.version,
      key: autocutStoreVanilla().user.key,
      UUID: await getUUID(),
      Host: await getHostName(),
      // 'PPro.Version': getPproVersion(),
      FingerPrint:
        event.fingerprint && event.fingerprint?.length > 0
          ? event.fingerprint[0]
          : 'No FingerPrint',
    };

    const containInvalidDataError =
      hint.attachments &&
      hint.attachments.length > 0 &&
      hint.attachments[0].data
        .toString()
        .match(/Invalid data found when processing input/gim) !== null;
    if (containInvalidDataError) {
      return null;
    }

    const containNoSuchFileOrDirectoryError =
      hint.attachments &&
      hint.attachments.length > 0 &&
      hint.attachments[0].data
        .toString()
        .match(/no such file or directory/gim) !== null;
    if (containNoSuchFileOrDirectoryError) {
      return null;
    }

    const isFingerPrintMatch =
      event.fingerprint &&
      event.fingerprint.includes('Error while sending statistics');
    const isAxiosBadRequestError =
      hint.attachments &&
      hint.attachments.length > 0 &&
      hint.attachments[0].data
        .toString()
        .match(/Request failed with status code (403|400)/gim) !== null;

    // Do not report expired token error
    const isExpiredTokenError = isFingerPrintMatch && isAxiosBadRequestError;
    if (isExpiredTokenError) {
      return null;
    }

    return event;
  },
  ignoreErrors: [
    /.*Reference QT Chapter track not found.*/,
    /.*co located POCs unavailable.*/,
  ],

  // integrations: [new Sentry.Replay()],
});

TagManager.initialize(tagManagerArgs);

const root = createRoot(document.getElementById('root') as HTMLElement);

if (IS_RUNNING_ON_ELECTRON) {
  root.render(
    <BrowserRouter>
      <LocaleProvider>
        <GameProvider>
          <App />
        </GameProvider>
      </LocaleProvider>
    </BrowserRouter>,
  );
} else {
  window.location.href = 'autocut://onboarding';
  window.location.href = 'https://www.autocut.com';
}
