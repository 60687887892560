import type {Font} from '@autocut/types/font';

import {OldButton} from '@autocut/components/Button/Button';
import {CustomSelectText} from '@autocut/components/CustomSelectText/CustomSelectText';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {defaultFont, loadUserLocalFonts} from '@autocut/utils/fonts.utils';
import {useEffect, useState} from 'react';
import {FaSyncAlt} from 'react-icons/fa';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

export const FontSelect = () => {
  const [loading, setLoading] = useState(false);
  const [fonts, setFonts] = useState<Font[]>([]);
  const {fonts: preloadedFonts} = useAutoCutStore(state => ({
    fonts: state.misc.fonts,
  }));

  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();
  const paramFont = value.text.font ?? defaultFont;

  const [selectedFont, setSelectedFont] = useState<Font>(
    paramFont ?? defaultFont,
  );

  useEffect(() => {
    if (!!preloadedFonts.length) {
      return setFonts(preloadedFonts);
    }
    const process = async () => {
      setLoading(true);
      setTimeout(async () => {
        setFonts(await loadUserLocalFonts());
        setLoading(false);
      }, 50);
    };

    void process();
  }, [preloadedFonts]);

  return (
    <FlexContainer
      gap={4}
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
    >
      <CustomSelectText
        customization="bleepSelect"
        isSearchable
        options={fonts.map(font => ({
          value: font.postscriptName,
          label: font.label,
        }))}
        handleSelectChange={font => {
          const correspondingFont = fonts.find(
            fontItem => fontItem.postscriptName === font?.value,
          );
          setSelectedFont(correspondingFont ?? defaultFont);
          setValue('text.font', correspondingFont ?? defaultFont);
        }}
        initialValue={{
          value: selectedFont.postscriptName,
          label: selectedFont.label,
        }}
        isLoading={loading}
        portalTarget={document.body}
      />
      <OldButton
        onClickFunction={loadUserLocalFonts}
        compact={true}
        color="transparent"
      >
        <FlexContainer
          style={{
            width: '16px',
            height: '16px',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <FaSyncAlt
            size={16}
            color="#F2F4F7"
          />
        </FlexContainer>
      </OldButton>
    </FlexContainer>
  );
};
