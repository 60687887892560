import {host} from '../host';
import {autocutStoreVanilla, setAutocutStore} from '../zustand/zustand';

export const muteSelectedTracks = async () => {
  const autocutState = autocutStoreVanilla();
  const deactivatedTrackIndexes = autocutState.ui.deactivatedTrackIndexes;

  for (const trackIndex of deactivatedTrackIndexes) {
    await host.track.disable('audio', trackIndex);
  }
};

export const unmuteSelectedTracks = async (cleanState = true) => {
  const autocutState = autocutStoreVanilla();
  const deactivatedTrackIndexes = autocutState.ui.deactivatedTrackIndexes;

  for (const trackIndex of deactivatedTrackIndexes) {
    await host.track.enable('audio', trackIndex);
  }
  if (cleanState) {
    setAutocutStore('ui.deactivatedTrackIndexes', []);
  }
};
