import type {WordBase} from '@autocut/types/Deepgram';

import {colors} from '@autocut/designSystem/colors';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {SelectableText} from '@autocut/designSystem/components/molecules/SelectableText/SelectableText';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import css from './TranscriptStep.module.scss';

export const TranscriptStep = () => {
  const intl = useIntl();
  const {gptWords, dictionary, utterances, languageOfTranscription} =
    useAutoCutStore(state => ({
      gptWords: state.onGoingProcess.swearWords.gptWords,
      dictionary: state.ui.swearWords.swearWordsDictionary,
      utterances: state.onGoingProcess.swearWords.utterances,
      languageOfTranscription:
        state.ui.parameters.swear_word.languageOfTranscription,
    }));

  // White list: words that are removed from the swearWords
  const [swearWordsWhiteList, setSwearWordsWhiteList] = useState<
    (WordBase & {isSelected: boolean})[]
  >([]);
  // Black list: words that are added to the swearWords
  const [swearWordsBlackList, setSwearWordsBlackList] = useState<
    (WordBase & {isSelected: boolean})[]
  >([]);

  const isWordASwearWord = (word: WordBase) => {
    const isWordInBlackList = swearWordsBlackList.some(
      wordInBlackList => wordInBlackList.id === word.id,
    );
    if (isWordInBlackList) return true;

    const isWordInWhiteList = swearWordsWhiteList.some(
      wordInWhiteList => wordInWhiteList.id === word.id,
    );

    if (isWordInWhiteList) return false;

    const isDictionaryWord = dictionary.includes(word.word.toLowerCase());

    if (isDictionaryWord) return true;

    const isGptWord = gptWords.some(gptWord => gptWord.word === word.word);

    if (isGptWord) return true;

    return false;
  };

  const words = useMemo(() => {
    const selectedWords: WordBase[] = [];
    const words = utterances.flatMap(utterance =>
      utterance.words.map(word => {
        const isSelected = isWordASwearWord(word);
        if (isSelected) selectedWords.push(word);
        return {
          ...word,
          isSelected,
        };
      }),
    );

    setAutocutStore('onGoingProcess.swearWords.swearWords', selectedWords);

    return words;
  }, [
    gptWords,
    utterances,
    swearWordsWhiteList,
    swearWordsBlackList,
    dictionary,
  ]);

  const handleSelectionChange = ([firstIndex, lastIndex]: [number, number]) => {
    const selectedWords = words.slice(firstIndex, lastIndex + 1);

    if (selectedWords.some(word => !word.isSelected)) {
      setSwearWordsBlackList(prev =>
        Array.from(new Set([...prev, ...selectedWords])),
      );
      return;
    }

    setSwearWordsBlackList(prev => {
      const filteredList = prev.filter(
        word =>
          !selectedWords.some(selectedWord => selectedWord.id === word.id),
      );

      return filteredList;
    });
    setSwearWordsWhiteList(prev =>
      Array.from(new Set([...prev, ...selectedWords])),
    );
  };

  return (
    <FormSection
      description={intl.formatMessage({
        id: 'modes_swearWords_parameters_censorWords_selectInfo',
        defaultMessage:
          'Add and remove words from the censored list. You can also manage your saved list for future transcriptions.',
      })}
    >
      <SelectableText
        words={words.map(word => () => ({
          children: word.punctuated_word,
          className: word.isSelected ? css.curseWord : undefined,
          color: word.isSelected ? colors.error600 : undefined,
        }))}
        onSelectionChange={handleSelectionChange}
        textDirection={languageOfTranscription.textDirection}
      />
    </FormSection>
  );
};
