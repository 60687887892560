import type {FileWithPath} from '@autocut/designSystem/components/molecules/FileInput/FileInput';

import type {BleepEntry} from '../BleepSoundChoiceStep/BleepSoundChoiceStep';

import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import cssButton from '@autocut/designSystem/components/atoms/Button/Button.module.scss';
import {IconEdit02} from '@autocut/designSystem/components/atoms/Icon/general/IconEdit02';
import {IconPlus} from '@autocut/designSystem/components/atoms/Icon/general/IconPlus';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useIntl} from 'react-intl';

import {BleepSoundEntryItem} from '../BleepSoundEntryItem/BleepSoundEntryItem';

type EditBleepSoundsModalProps = {
  isOpen: boolean;
  loadedBleeps: BleepEntry[];
  loadBleeps: () => void;
  isLoading: boolean;
  onClose: () => void;
};

export const EditBleepSoundsModal = ({
  isOpen,
  loadedBleeps,
  loadBleeps,
  isLoading,
  onClose,
}: EditBleepSoundsModalProps) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modes_swearWords_parameters_bleepSoundManager_title',
        defaultMessage: 'Manage sounds',
      })}
      icon={<IconEdit02 size={18} />}
      fullWidth
      closeModalFunction={() => {
        () => setAutocutStore('modal.openedModalName', '');
        onClose();
      }}
      footer={<EditBleepSoundsModalFooter loadBleeps={loadBleeps} />}
    >
      <div style={{minHeight: 200}}>
        {isLoading ? (
          <FlexContainer
            alignItems="center"
            justifyContent="center"
          >
            <LoaderInfinity height={32} />
          </FlexContainer>
        ) : loadedBleeps.length > 0 ? (
          <PaddedSection gap={Spacing.s2}>
            {loadedBleeps.map(bleep => (
              <BleepSoundEntryItem
                key={bleep.fullpath}
                bleep={bleep}
                onBleepUpdate={() => loadBleeps()}
              />
            ))}
          </PaddedSection>
        ) : (
          <TranslatedMessage
            id={'modes_swearWords_parameters_bleepSoundManager_noSounds'}
            defaultMessage={"You haven't added any sounds yet."}
          />
        )}
      </div>
    </Modal>
  );
};

type EditBleepSoundsModalFooterProps = {
  loadBleeps: () => void;
};

const EditBleepSoundsModalFooter = ({
  loadBleeps,
}: EditBleepSoundsModalFooterProps) => {
  const intl = useIntl();

  const {fs, path, electron} = preload;

  return (
    <>
      <label
        htmlFor="bleep-upload"
        //  cssButton exist
        // eslint-disable-next-line css-modules/no-undef-class
        className={`${cssButton.root}`}
        data-variant={'secondary.dashed'}
        data-size={'md'}
        style={{
          //@ts-ignore --color is css var in cssButton
          '--color': 'white',
          display: 'block',
        }}
      >
        <FlexContainer
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={Spacing.s2}
        >
          {' '}
          <IconPlus size={18} />
          {intl.formatMessage({
            id: 'modes_swearWords_parameters_bleepSoundManager_addButton',
            defaultMessage: 'Add a new sound',
          })}
        </FlexContainer>
      </label>
      <input
        id="bleep-upload"
        type="file"
        style={{display: 'none'}}
        accept="audio/*"
        onChange={e => {
          const file = e.target.files
            ? (e.target.files[0] as FileWithPath)
            : null;
          if (!file) return;

          const fileName = path.basename(file.path);
          const appDataPath = electron.getPath('appData');
          const bleepsDir = path.join(appDataPath, 'userBleeps');
          const destFullpath = path.join(bleepsDir, fileName);
          fs.copyFileSync(file.path, destFullpath);
          loadBleeps();
        }}
      />
    </>
  );
};
