import {AUTOCUT_CONTACT} from '@autocut/constants/constants';
import {ModalType} from '@autocut/enums/modals.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {host} from '@autocut/utils/host';
import {sendStats} from '@autocut/utils/stats.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import axios from 'axios';

import {FingerprintErrorFactory} from '../errors/FingerprintErrorFactory';
import {closeModal} from '../modal/closeModal';

export const waitForHost = async (
  setProgress?: (progress: number) => Promise<void>,
): Promise<true | string> => {
  await new Promise(async (resolve, reject) => {
    try {
      console.log('waiting for host');
      let isConnected = (await host.misc.isConnected()).isConnected;
      while (!isConnected) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        isConnected = (await host.misc.isConnected()).isConnected;
      }
      resolve(true);
    } catch (e: any) {
      reject(e);
    }
  }).catch(e => {
    console.log({e});
    const err = FingerprintErrorFactory(e.error, 'waiting_for_host');
    err.reportToSentry();

    alert(
      `${err.message}\n\nPlease send us a screenshot of this error on discord or by email at ${AUTOCUT_CONTACT}.`,
    );
  });

  await setProgress?.(66);

  const {serverVersion, clientVersion, hostName} = await host.misc.version();
  const hostVersion = await host.misc.hostVersion();
  setAutocutStore('ui.versions.hostClient', clientVersion);
  setAutocutStore('ui.versions.hostServer', serverVersion);
  setAutocutStore('ui.versions.host', hostVersion);
  setAutocutStore('ui.host', hostName);

  if (hostName === 'ppro') {
    try {
      const name = await host.misc.name();
      const nameWithoutEnv = name.replace(/ *- *(STAGING|DEV) *$/, '').trim();
      if (nameWithoutEnv !== 'AutoCut') {
        throw new Error('Wrong name, must restart PPro');
      }
    } catch {
      await host.misc.close();
      return 'renaming';
    }
  }

  const lastVersion = (
    await axios.get(
      preload.path.joinUrl(
        preload.electron.getSharedVariables()[
          hostName === 'ppro' ? 'PPRO_DOWNLOAD_URL' : 'DAVINCI_DOWNLOAD_URL'
        ],
        '/version',
      ),
    )
  )?.data;

  console.log({lastVersion, clientVersion});

  if (
    CURRENT_ENV === Env.Production &&
    clientVersion.replace(/^v/, '') !== lastVersion.replace(/^v/, '')
  ) {
    await sendStats({
      type: StatType.HOST_CONNECTED,
      value: 'false',
    });
    await host.misc.close();

    return hostName;
  }
  await sendStats({
    type: StatType.HOST_CONNECTED,
    value: 'true',
  });
  await setProgress?.(100);

  closeModal(ModalType.RestartPremierePro);
  closeModal(ModalType.PProExtensionRenamed);

  return true;
};
