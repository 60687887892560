import type {BRoll, BRollVideo} from '@autocut/types/BRolls';

import type {PexelVideoRef} from '../PexelPreview/PexelPreview';

import {Input} from '@autocut/components/Inputs/Input';
import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {debounce} from '@autocut/utils/debounce';
import {autocutApi} from '@autocut/utils/http.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {modifyVideoSize} from '../../../utils/modifyVideoSize';
import {PexelPreview} from '../PexelPreview/PexelPreview';
import css from './BRollChoiceModal.module.css';

export type BRollChoiceModalProps = {
  bRoll: BRoll;
  closeModalFunction: () => void;
};

export const BRollsChoiceModal = ({
  bRoll,
  closeModalFunction,
}: BRollChoiceModalProps) => {
  const intl = useIntl();
  const lastKeywords = useRef<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentKeywords, setCurrentKeywords] = useState<string>(
    bRoll.description || '',
  );
  const [videos, setVideos] = useState<BRoll['video'][]>([]);
  const {timelineInfos} = useAutoCutStore(state => state.onGoingProcess);

  const debouncedGetVideo = useCallback(
    debounce(async (description: string) => {
      const videos = await getVideos(description);
      setVideos(videos);

      setAutocutStore('onGoingProcess.bRolls', state =>
        (state.onGoingProcess.bRolls as BRoll[]).map(currentBRoll => {
          if (currentBRoll.id === bRoll.id) {
            return {
              ...currentBRoll,
              description: description,
            };
          }

          return currentBRoll;
        }),
      );
    }, 2000),
    [],
  );

  useEffect(() => {
    if (currentKeywords === '' || lastKeywords.current === currentKeywords)
      return;

    void debouncedGetVideo(currentKeywords);
  }, [currentKeywords]);

  useEffect(() => {
    const handleFirstVideoFetch = async () => {
      const videos = await getVideos(bRoll.description);

      setVideos(videos);
    };
    if (currentKeywords === '') return;

    void handleFirstVideoFetch();
  }, []);

  const getVideos = async (description: string) => {
    if (!timelineInfos) {
      throw new Error('timelineInfos is empty');
    }

    setIsLoading(true);
    lastKeywords.current = description;

    const {data: videos} = await autocutApi.post('/pexel/videos', {
      description: description,
      minDuration: Number(bRoll.end - bRoll.start),
      minWidth: timelineInfos.settings.width,
      minHeight: timelineInfos.settings.height,
      count: 20,
    });

    setIsLoading(false);

    return videos;
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modes_broll_steps_transcript_choiceModal_title',
        defaultMessage: 'Choose B-Roll',
      })}
      closeModalFunction={closeModalFunction}
      fullScreen
    >
      <FlexContainer
        flexDirection="column"
        gap={8}
      >
        <Input
          type="text"
          placeholder={intl.formatMessage({
            id: 'modes_broll_steps_transcript_choiceModal_inputPlaceholder',
            defaultMessage: 'Type to search for B-Rolls',
          })}
          value={currentKeywords}
          onChange={e => setCurrentKeywords(e.target.value)}
          inputSize="sm"
          width={'100%'}
        />

        <FlexContainer
          flexWrap="wrap"
          gap={8}
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <FlexContainer
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <LoaderInfinity height={200} />
              <TranslatedMessage
                id="modes_broll_steps_transcript_choiceModal_searching"
                defaultMessage="Searching for B-Rolls..."
              />
            </FlexContainer>
          ) : (
            videos.map((video: any) => (
              <VideoContainerDiv
                key={video.id}
                video={video}
                bRoll={bRoll}
                onBrollPick={closeModalFunction}
              />
            ))
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

const VideoContainerDiv = ({
  video,
  bRoll,
  onBrollPick,
}: {
  video: BRollVideo;
  bRoll: BRoll;
  onBrollPick: () => void;
}) => {
  const videoRef = useRef<PexelVideoRef>(null);

  const changeVideo = (video: BRoll['video']) => {
    setAutocutStore('onGoingProcess.bRolls', state =>
      (state.onGoingProcess.bRolls as BRoll[]).map(currentBRoll => {
        if (currentBRoll.id === bRoll.id) {
          return {
            ...currentBRoll,
            video: video,
          };
        }
        return currentBRoll;
      }),
    );
  };

  const onMouseEnter = async () => {
    await videoRef.current?.play();
  };

  const onMouseLeave = () => {
    videoRef.current?.pause();
  };

  return (
    <div
      className={css.videoContainer}
      key={video.id}
      onClick={() => {
        changeVideo(video);
        onBrollPick();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PexelPreview
        ref={videoRef}
        picture={video.previewPictures[0].picture}
        videoSrc={modifyVideoSize(video?.downloadUrl as string, 150, 85)}
      />
    </div>
  );
};
