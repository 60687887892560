import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconRefreshCw02 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2 14s.1.8 3.6 4.4A9 9 0 0 0 20.8 14M2 14v6m0-6h6m14-4s-.1-.8-3.6-4.4A9 9 0 0 0 3.2 10M22 10V4m0 6h-6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
