import type {CaptionsPreset} from '@autocut/validationSchemas/caption.validationSchema';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

export const PresetPreview = ({
  selectedPreset,
}: {
  selectedPreset: CaptionsPreset | null;
}) => {
  if (selectedPreset === null)
    return (
      <TranslatedMessage
        id={'modes_caption_steps_customization_parts_presets_noPresetSelected'}
        defaultMessage="Select preset to see preview"
      />
    );

  if (!!selectedPreset.videoUrl)
    return (
      <video
        src={selectedPreset.videoUrl}
        autoPlay
        loop
      />
    );

  return (
    <TranslatedMessage
      id={'modes_caption_steps_customization_parts_presets_noPreviewAvailable'}
      defaultMessage="No preview available for this preset"
    />
  );
};
