import type {
  CaptionChunk,
  CaptionChunkHighlight,
} from '@autocut/types/Captions';
import type {WordBase} from '@autocut/types/Deepgram';

import {getTextLineBreak} from './processCaptionsChunks';

export const removeWord = (
  chunks: CaptionChunk[],
  chunkIndex: number,
  wordIndex: number,
): CaptionChunk[] => {
  const newChunks = [...chunks];
  const chunk = newChunks[chunkIndex];
  if (!chunk) return newChunks;

  // Get all words in the chunk
  const allWords = chunk.lines.flat();
  const wordToRemove = allWords[wordIndex];

  if (!wordToRemove) return newChunks;

  const previousWord = allWords[wordIndex - 1];

  // Merge timings of the wordToRemove with the previous word
  const updatedPreviousWord: WordBase | null = previousWord
    ? {
        ...previousWord,
        end: wordToRemove.end,
      }
    : null;

  const removedWordLength =
    (wordToRemove.punctuated_word ?? wordToRemove.word).length + 1;

  // Update the chunk lines by removing the word and merging timings with the previous word
  const updatedLines: CaptionChunk['lines'] = chunk.lines.map(line => {
    const updatedLine = line.filter(word => word.id !== wordToRemove.id);

    // Update the timing for the merged word
    return updatedLine.map(word => {
      if (word.id === updatedPreviousWord?.id) {
        return updatedPreviousWord;
      }
      return word;
    });
  });

  const lineBreak = getTextLineBreak();

  // Update text from updated lines
  const newText = updatedLines
    .map(words =>
      words.map(word => word.punctuated_word ?? word.word).join(' '),
    )
    .join(lineBreak);

  // Update highlights
  const updatedHighlights: CaptionChunkHighlight[] = chunk.highlight
    .map((highlight, i) => {
      // If highlight corresponds to the previous word, update its end time
      if (i === wordIndex - 1) {
        return {
          ...highlight,
          end: wordToRemove.end,
        };
      }

      // If highlight corresponds to word being removed, remove it
      if (i === wordIndex) return null;

      // Adjust indices for all highlights after the removed word
      if (i > wordIndex) {
        return {
          ...highlight,
          indexStart: highlight.indexStart - removedWordLength,
          indexEnd: highlight.indexEnd - removedWordLength,
          lineBeforeWord: highlight.lineBeforeWord.replace(
            (wordToRemove.punctuated_word ?? wordToRemove.word) + ' ',
            '',
          ),
        };
      }

      return highlight;
    })
    .filter(Boolean) as CaptionChunkHighlight[];

  // Replace the updated chunk in the array
  const updatedChunk: CaptionChunk = {
    ...chunk,
    text: newText,
    lines: updatedLines,
    nbLines: updatedLines.length,
    highlight: updatedHighlights,
  };
  newChunks[chunkIndex] = updatedChunk;

  return newChunks;
};
