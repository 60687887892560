import type {ComponentProps, PointerEvent} from 'react';

import {colors} from '@autocut/designSystem/colors';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {MouseEventButton} from '@autocut/types/MouseEvent.d';
import {useCallback, useRef, useState} from 'react';

import FlexContainer from '../FlexContainer';

export namespace SelectableText {
  export type Props = {
    words: Word[];
    onSelectionChange?: (selectedItems: [number, number]) => void;
    textDirection?: 'ltr' | 'rtl';
  };
  export type Word = ({
    isSelected,
  }: {
    isSelected: boolean;
  }) => ComponentProps<typeof Text>;
}

export const SelectableText = ({
  words,
  onSelectionChange,
  textDirection = 'ltr',
}: SelectableText.Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedItems, setSelectedItems] = useState<[number, number]>([
    -1, -1,
  ]);

  const handlePointerDown = (event: React.PointerEvent) => {
    if (event.button !== MouseEventButton.LEFT) return;

    const containerRect = event.currentTarget.getBoundingClientRect();
    const hoveredItem = getHoveredItem(
      event.clientX - containerRect.x,
      event.clientY - containerRect.y,
    );
    setSelectedItems([hoveredItem, hoveredItem]);

    event.currentTarget.setPointerCapture(event.pointerId);

    setIsDragging(true);
  };

  const handlePointerMove = (e: PointerEvent) => {
    if (!isDragging) return;

    updateSelectedItems(e);
  };

  const handlePointerUp = () => {
    const finalSelection: [number, number] =
      selectedItems[0] > selectedItems[1]
        ? [selectedItems[1], selectedItems[0]]
        : selectedItems;

    setSelectedItems(finalSelection);

    setIsDragging(false);

    if (onSelectionChange) {
      onSelectionChange(finalSelection);
      setSelectedItems([-1, -1]);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const updateSelectedItems = useCallback(function updateSelectedItems(
    event: PointerEvent,
  ) {
    if (containerRef.current == null) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const hoveredItem = getHoveredItem(
      event.clientX - containerRect.x,
      event.clientY - containerRect.y,
    );

    setSelectedItems(prev => {
      if (prev[0] === -1 || prev[1] === -1) return [hoveredItem, hoveredItem];

      return [prev[0], hoveredItem];
    });
  }, []);

  const isWordSelected = (index: number) =>
    selectedItems[0] > selectedItems[1]
      ? selectedItems[1] <= index && index <= selectedItems[0]
      : selectedItems[0] <= index && index <= selectedItems[1];

  const getHoveredItem = (x: number, y: number) => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!containerRect) return -1;

    const items = containerRef.current?.querySelectorAll('[data-item]');
    if (!items) return -1;

    const hoveredItem = Array.from(items).find((el: Element) => {
      if (!(el instanceof HTMLElement)) return false;

      const itemRect = el.getBoundingClientRect();
      const xItem = itemRect.x - containerRect.x;
      const yItem = itemRect.y - containerRect.y;

      return (
        xItem <= x &&
        x <= xItem + itemRect.width &&
        yItem <= y &&
        y <= yItem + itemRect.height
      );
    });

    return hoveredItem ? Number(hoveredItem.getAttribute('data-item')) : -1;
  };

  const isFirstWord = (index: number) =>
    selectedItems[0] > selectedItems[1]
      ? index === selectedItems[1]
      : index === selectedItems[0];
  const isLastWord = (index: number) =>
    selectedItems[0] > selectedItems[1]
      ? index === selectedItems[0]
      : index === selectedItems[1];

  const isRtl = textDirection === 'rtl';

  return (
    <FlexContainer
      ref={containerRef}
      flexWrap="wrap"
      flexDirection={isRtl ? 'row-reverse' : 'row'}
      gap={0}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      style={{position: 'relative', cursor: 'text'}}
    >
      {words.map((word, index) => {
        const {children, ...props} = word({isSelected: isWordSelected(index)});
        return (
          <Text
            key={index}
            variant="textSm.medium"
            color={colors.gray300}
            data-item={index}
            {...props}
            style={{
              position: 'relative',
              paddingInline: 4,
              paddingBlock: 2,

              ...(isFirstWord(index) &&
                (isRtl
                  ? {
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }
                  : {
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                    })),
              ...(isLastWord(index) &&
                (isRtl
                  ? {
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                    }
                  : {
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    })),
              ...(isWordSelected(index) && {
                backgroundColor: 'rgba(255,255,255,0.2)',
              }),
              ...props.style,
            }}
          >
            {children}
          </Text>
        );
      })}
    </FlexContainer>
  );
};
