import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {NotificationContext} from '@autocut/contexts/NotificationProvider.tsx/NotificationProvider';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useContext} from 'react';
import {BsEmojiSmile} from 'react-icons/bs';
import {RxRocket} from 'react-icons/rx';
import {TbNumbers} from 'react-icons/tb';

import {formatNumbersFromChunks} from '../../../utils/formatNumbersFromChunks.utils';
import {generateAnimatedEmojisFromTranscription} from '../../../utils/generateAnimatedEmojisFromTranscription.utils';
import {generateStaticEmojisFromTranscription} from '../../../utils/generateStaticEmojisFromTranscription.utils';
import {useEditTranscriptModal} from '../EditTranscriptModal/EditTranscriptModalContext';

export const CaptionsTranscriptButtons = () => {
  const hostName = useAutoCutStore(state => state.ui.host);
  const {
    editTranscriptModal: {
      isLoading,
      setIsLoading,
      localeCaptionChunks,
      setLocaleCaptionChunks,
    },
  } = useEditTranscriptModal();

  const actionWrapper = async (action: () => Promise<void>) => {
    setIsLoading(true);
    await action();
    setIsLoading(false);
  };

  const {displayNotification} = useContext(NotificationContext);

  return (
    <FlexContainer
      flexDirection="row"
      alignItems="center"
      gap={8}
    >
      <Tooltip
        tooltipComponent={
          <TranslatedMessage
            id="modes_caption_steps_customization_parts_transcription_modal_actions_staticEmojis_tooltip"
            defaultMessage={'Generate static emojis'}
          />
        }
      >
        <ActionIcon
          variant="subtle"
          onClick={() => {
            void actionWrapper(async () => {
              await generateStaticEmojisFromTranscription({
                transcription: localeCaptionChunks,
              }).then(newTranscription => {
                displayNotification(
                  <TranslatedMessage
                    id="modes_caption_steps_customization_parts_transcription_modal_actions_staticEmojis_notification"
                    defaultMessage="Static emojis have been added to your transcript"
                  />,
                  3000,
                );

                setLocaleCaptionChunks(newTranscription);
              });
            });
          }}
          size={40}
          disabled={isLoading}
        >
          <BsEmojiSmile size={24} />
        </ActionIcon>
      </Tooltip>
      {hostName === 'ppro' && (
        <Tooltip
          tooltipComponent={
            <TranslatedMessage
              id="modes_caption_steps_customization_parts_transcription_modal_actions_animatedEmojis_tooltip"
              defaultMessage={'Generate animated emojis'}
            />
          }
        >
          <ActionIcon
            variant="subtle"
            onClick={() => {
              void actionWrapper(async () => {
                await generateAnimatedEmojisFromTranscription({
                  transcription: localeCaptionChunks,
                }).then(newTranscription => {
                  displayNotification(
                    <TranslatedMessage
                      id="modes_caption_steps_customization_parts_transcription_modal_actions_animatedEmojis_notification"
                      defaultMessage="Animated emojis have been added to your transcript"
                    />,
                    3000,
                  );

                  setLocaleCaptionChunks(newTranscription);
                });
              });
            }}
            size={40}
            disabled={isLoading}
          >
            <RxRocket size={24} />
          </ActionIcon>
        </Tooltip>
      )}
      <Tooltip
        tooltipComponent={
          <TranslatedMessage
            id="modes_caption_steps_customization_parts_transcription_modal_actions_formatNumbers_tooltip"
            defaultMessage={'Replace numbers'}
          />
        }
      >
        <ActionIcon
          variant="subtle"
          onClick={() => {
            void actionWrapper(async () => {
              await formatNumbersFromChunks({
                transcription: localeCaptionChunks,
              }).then(({newTranscription, replacedWords}) => {
                displayNotification(
                  <TranslatedMessage
                    id="modes_caption_steps_customization_parts_transcription_modal_actions_formatNumbers_notification"
                    defaultMessage="{count, plural, one {# number has been formatted} other {# numbers have been formatted}}"
                    values={{count: replacedWords}}
                  />,
                  3000,
                );

                setLocaleCaptionChunks(newTranscription);
              });
            });
          }}
          size={40}
          disabled={isLoading}
        >
          <TbNumbers size={24} />
        </ActionIcon>
      </Tooltip>
    </FlexContainer>
  );
};
