import type {LicenceKeyType} from '@autocut/types/IsKeyValidResponse';

export const orderedLicenceKeyTypes = ['basic', 'ai', 'pro', 'expert'] as const;

export const isKeyTypeEqualsOrGreaterThan = (
  keyType: LicenceKeyType,
  comparedTo: LicenceKeyType,
) => {
  const indexKeyType = orderedLicenceKeyTypes.indexOf(keyType);
  const indexComparedKeyType = orderedLicenceKeyTypes.indexOf(comparedTo);

  return indexKeyType >= indexComparedKeyType;
};
