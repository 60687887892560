import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconMicrophone01 = (props: IconComponentProps) => (
  <IconComponent
    {...props}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 10v2a7 7 0 0 1-7 7m-7-9v2a7 7 0 0 0 7 7m0 0v3m-4 0h8m-4-7a3 3 0 0 1-3-3V5a3 3 0 1 1 6 0v7a3 3 0 0 1-3 3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconComponent>
);
