import type {Engine} from 'tsparticles-engine';

import {useCallback, useEffect, useState} from 'react';
import Particles from 'react-tsparticles';
import {loadFull} from 'tsparticles';

import ConfettiOption from './ConfettiOptions/ConfettiOption';
import FireWorksOptions from './ConfettiOptions/FireWorksOption';

const ConfettiBackground = () => {
  const [options, setOptions] = useState<any>();

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async () => {}, []);

  useEffect(() => {
    setOptions(
      Math.floor(Math.random() * 2) === 0 ? FireWorksOptions : ConfettiOption,
    );
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={options}
    />
  );
};

export default ConfettiBackground;
