import type {TranscriptFile} from '@autocut/utils/transcript/transcriptFiles.utils';

import {EditableText} from '@autocut/components/EditableText/EditableText';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {InputLabel} from '@autocut/designSystem/components/molecules/InputLabel/InputLabel';
import {StatType} from '@autocut/enums/statType.enum';
import {processTranscriptFunctions} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {ConfirmModal} from '@autocut/modals/ConfirmModal/ConfirmModal';
import {sendStats} from '@autocut/utils/stats.utils';
import {
  deleteTranscriptFile,
  saveTranscript,
} from '@autocut/utils/transcript/transcriptFiles.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useRef, useState} from 'react';
import {LuFileDown, LuPencil, LuTrash2} from 'react-icons/lu';
import {useNavigate} from 'react-router-dom';

export const PreviousTranscriptRow = ({
  transcript,
  fileName,
  onDelete,
}: {
  transcript: TranscriptFile;
  fileName: string;
  onDelete: () => void;
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [transcriptName, setTranscriptName] = useState(transcript.name);
  const textRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleimport = async () => {
    setAutocutStore('onGoingProcess.caption.chunks', transcript.chunks);
    setAutocutStore('onGoingProcess.caption.transcriptFileName', fileName);
    setAutocutStore('onGoingProcess.audioFilepath', undefined);
    navigate(processTranscriptFunctions.caption.navigateTo);

    await sendStats({
      type: StatType.CAPTIONS_LOAD_PREVIOUS_TRANSCRIPT,
      value: 1,
    });
  };
  const handleDelete = () => {
    deleteTranscriptFile(fileName);
    onDelete();
  };
  const handleEdit = () => {
    if (!textRef.current) return;

    textRef.current.focus();
    const range = document.createRange();
    range.selectNodeContents(textRef.current);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const formattedDate = new Date(transcript.modifiedAt).toLocaleString(
    'en-GB',
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
  );

  return (
    <FlexContainer
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      style={{width: '100%'}}
    >
      {isConfirmModalOpen && (
        <ConfirmModal
          onConfirm={handleDelete}
          onCancel={() => setIsConfirmModalOpen(false)}
          title="Are you sure you want to delete this transcript?"
          body={
            <Text variant="textSm.medium">
              <TranslatedMessage
                id="modes_caption_steps_language_previousTranscription_confirmModal_text"
                defaultMessage="This action is irreversible. You will no longer be able to use the transcript once deleted."
              />
            </Text>
          }
          confirmButtonProps={{
            color: colors.error600,
            children: (
              <Text variant="textSm.medium">
                <TranslatedMessage
                  id="modes_caption_steps_language_previousTranscription_confirmModal_confirm"
                  defaultMessage="Delete transcription"
                />
              </Text>
            ),
          }}
        />
      )}
      <InputLabel
        title={
          <EditableText
            ref={textRef}
            initialValue={transcriptName}
            onChange={async name => {
              if (!name.length) return;

              const newTranscript = {
                ...transcript,
                name,
              };

              saveTranscript(newTranscript);
              setTranscriptName(name);
            }}
            validate={value => value.length > 0}
          />
        }
        description={formattedDate}
      />
      <FlexContainer
        flexDirection="row"
        gap={0}
      >
        <Tooltip
          tooltipPosition="left"
          tooltipComponent={
            <TranslatedMessage
              id="modes_caption_steps_language_previousTranscription_load"
              defaultMessage="Load transcript"
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          }
        >
          <ActionIcon
            onClick={handleimport}
            variant="subtle"
            style={{padding: 8}}
          >
            <LuFileDown />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          tooltipPosition="left"
          tooltipComponent={
            <TranslatedMessage
              id="modes_caption_steps_language_previousTranscription_edit"
              defaultMessage="Edit transcript name"
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          }
        >
          <ActionIcon
            onClick={handleEdit}
            variant="subtle"
            style={{padding: 8}}
          >
            <LuPencil />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          tooltipPosition="left"
          variant="warning"
          tooltipComponent={
            <TranslatedMessage
              id="modes_caption_steps_language_previousTranscription_delete"
              defaultMessage="Delete"
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          }
        >
          <ActionIcon
            color={colors.error600}
            onClick={() => setIsConfirmModalOpen(true)}
            variant="subtle"
            style={{padding: 8}}
          >
            <LuTrash2 />
          </ActionIcon>
        </Tooltip>
      </FlexContainer>
    </FlexContainer>
  );
};
