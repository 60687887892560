import type {SelectionCoordinatesType} from '@autocut/utils/repeat/selection';

import {
  getSelectionDirection,
  SelectionDirection,
} from '@autocut/utils/repeat/selection';
import * as React from 'react';

export const useSelection = (isHoveringButton: boolean) => {
  const [selectionCoordinates, setSelectionCoordinates] = React.useState<
    SelectionCoordinatesType | undefined
  >(undefined);
  const [contextualMenuVisibility, setContextualMenuVisibility] =
    React.useState(false);
  const [contextualMenuPosition, setContextualMenuPosition] = React.useState({
    top: 0,
    left: 0,
  });

  const resetSelection = () => {
    setContextualMenuVisibility(false);
    setSelectionCoordinates(undefined);
  };

  const getNearestWord = (node: HTMLElement) => {
    let previousScissorsAndSentenceContainer = node.previousElementSibling;
    if (!previousScissorsAndSentenceContainer) {
      const currentGroup = node.parentElement as HTMLElement;
      const previousSeparator =
        currentGroup.previousElementSibling as HTMLElement;
      const previousGroup =
        previousSeparator.previousElementSibling as HTMLElement;

      previousScissorsAndSentenceContainer =
        previousGroup.children[previousGroup.children.length - 1];
    }

    const previousSentence = previousScissorsAndSentenceContainer.children[1];
    const previousWord =
      previousSentence.children[previousSentence.children.length - 1];
    return previousWord as HTMLElement;
  };

  const getCoordinatesFromWordNode = (dataNode: HTMLElement) => {
    const groupIndex = parseFloat(dataNode.dataset.groupIndex as string);
    const utteranceIndex = parseFloat(
      dataNode.dataset.utteranceIndex as string,
    );
    const wordIndex = parseFloat(dataNode.dataset.wordIndex as string);

    return {groupIndex, utteranceIndex, wordIndex};
  };

  const handleOnMouseUp = React.useCallback(() => {
    if (isHoveringButton) {
      return;
    }
    const selection = window.getSelection();

    // If the selection is empty
    if (
      !selection ||
      (selection &&
        (selection.type === 'None' || selection.type === 'Caret')) ||
      !selection.anchorNode ||
      !selection.focusNode
    ) {
      resetSelection();
      return;
    }

    // We're going for the parentElements (spans around the raw text) because we want to access their dataset (containing the indexes of corresponding words)
    let dataAnchorNode = selection.anchorNode.parentElement as HTMLElement;
    let dataFocusNode = selection.focusNode.parentElement as HTMLElement;

    // If the selection is not inside the edit transcript area
    if (
      !dataAnchorNode.parentElement ||
      !(
        dataAnchorNode.className.includes('word') ||
        dataAnchorNode.className.includes('sentenceAndScissors') ||
        dataAnchorNode.id.includes('space')
      ) ||
      !(
        dataFocusNode.className.includes('word') ||
        dataFocusNode.className.includes('sentenceAndScissors') ||
        dataFocusNode.id.includes('space')
      )
    ) {
      selection.empty();
      resetSelection();
      return;
    }

    // Below are corrections used to snap the data selection to the spans element in the DOM containing the datasets according to how
    // the visual selection behave

    // If the selection start on a space, we're moving it to the closest word in the same direction
    if (dataAnchorNode.id.includes('space')) {
      const selectionDirection =
        dataAnchorNode.compareDocumentPosition(dataFocusNode);
      switch (selectionDirection) {
        case Node.DOCUMENT_POSITION_FOLLOWING:
          dataAnchorNode = dataAnchorNode.nextElementSibling as HTMLElement;
          break;

        case Node.DOCUMENT_POSITION_PRECEDING:
          dataAnchorNode = dataAnchorNode.previousElementSibling as HTMLElement;
      }
    }

    // If the selection end on a space, we're moving it to the closest word in the same direction
    if (dataFocusNode.id.includes('space')) {
      const selectionDirection =
        dataAnchorNode.compareDocumentPosition(dataFocusNode);
      switch (selectionDirection) {
        case Node.DOCUMENT_POSITION_FOLLOWING:
          dataFocusNode = dataFocusNode.previousElementSibling as HTMLElement;
          break;

        case Node.DOCUMENT_POSITION_PRECEDING:
          dataFocusNode = dataFocusNode.nextElementSibling as HTMLElement;
      }
    }

    // Another weird behavior of the native selection : when the selection start or end at the beginning or end of a sentence, the corresponding node (anchor or focus) is not
    // the first or last word but the "sentence" node as whole. We then have to fix that by assigning the right node to the anchor or focus node, depending on the situation.

    // If the selection start on the next group of sentences or a separator, we're moving it back to the previous word
    if (dataAnchorNode.className.includes('sentenceAndScissors')) {
      const nearestWord = getNearestWord(dataAnchorNode);
      dataAnchorNode = nearestWord;
    }

    // If the selection end on the next group of sentences or a separator, we're moving it back to the previous word
    if (dataFocusNode.className.includes('sentenceAndScissors')) {
      const nearestWord = getNearestWord(dataFocusNode);
      dataFocusNode = nearestWord;
    }

    const startWordCoordinates = getCoordinatesFromWordNode(dataAnchorNode);
    const endWordCoordinates = getCoordinatesFromWordNode(dataFocusNode);

    // We're snapping the visual selection to the words selected (only for visual purposes)
    const direction = getSelectionDirection({
      startWordCoordinates,
      endWordCoordinates,
    });
    if (direction === SelectionDirection.FORWARD) {
      selection.setBaseAndExtent(
        selection.anchorNode,
        0,
        selection.focusNode,
        (selection.focusNode as any).length,
      );
    } else {
      selection.setBaseAndExtent(
        selection.focusNode,
        0,
        selection.anchorNode,
        (selection.anchorNode as any).length,
      );
    }

    setSelectionCoordinates({
      startWordCoordinates,
      endWordCoordinates,
    });
    setContextualMenuPosition({
      top: dataFocusNode.offsetTop,
      left: dataFocusNode.offsetLeft,
    });
    setContextualMenuVisibility(true);
  }, [isHoveringButton]);

  // There is a weird behavior with the native selection : it gets emptied on mouse down if the mouse if far enough
  // but when it's not it gets emptied on mouse up (like a drag and drop), leading to a race condition with the on mouse up handler.
  // With that we're ensuring it's empty by the time handleOnMouseUp gets called
  const handleOnMouseDown = () => {
    const selection = window.getSelection();
    selection?.empty();
  };

  React.useEffect(() => {
    document.addEventListener('mouseup', handleOnMouseUp);
    document.addEventListener('mousedown', handleOnMouseDown);

    return () => {
      document.removeEventListener('mouseup', handleOnMouseUp);
      document.removeEventListener('mousedown', handleOnMouseDown);
    };
  }, [handleOnMouseUp]);

  return {
    selectionCoordinates,
    setSelectionCoordinates,
    contextualMenuVisibility,
    setContextualMenuVisibility,
    contextualMenuPosition,
    setContextualMenuPosition,
  };
};
