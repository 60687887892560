import type {WordBase} from '@autocut/types/Deepgram';
import type {SelectionCoordinatesType} from '@autocut/utils/repeat/selection';

import {TranscriptWord} from '@autocut/pages/modes/repeat/Steps/Transcript/Parts/TranscriptWord';
import * as React from 'react';

import css from './TranscriptSentence.module.css';

type TranscriptSentenceProps = {
  words: WordBase[];
  groupIndex: number;
  utteranceIndex: number;
  selectionCoordinates: SelectionCoordinatesType | undefined;
};

export const TranscriptSentence = ({
  words,
  groupIndex,
  utteranceIndex,
}: TranscriptSentenceProps) => {
  const sentence = [
    <TranscriptWord
      key={0}
      word={words[0]}
      groupIndex={groupIndex}
      utteranceIndex={utteranceIndex}
      wordIndex={0}
    />,
  ];

  for (let i = 1; i < words.length; i++) {
    const lastWord = words[i - 1];
    const currentWord = words[i];
    const wordToPush = (
      <React.Fragment key={i}>
        <span
          key={`space${i}`}
          id="space"
          className={`${
            lastWord.isCut && currentWord.isCut ? css.marked : ''
          } ${css.overrideSelectionStyle}`}
        >
          &nbsp;
        </span>
        <TranscriptWord
          key={`word${i}`}
          word={currentWord}
          groupIndex={groupIndex}
          utteranceIndex={utteranceIndex}
          wordIndex={i}
        />
      </React.Fragment>
    );

    sentence.push(wordToPush);
  }

  return <>{sentence}</>;
};
