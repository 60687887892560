import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useForceUpdate} from '@autocut/hooks/useForceUpdate';
import {useEffect, useMemo, useRef} from 'react';
import {FaCheck} from 'react-icons/fa6';

import css from './SuccessLoader.module.css';

export type SuccessLoaderProps = {
  size?: number;
  isLoading?: boolean;
  isSuccess?: boolean;
  onSuccess?: () => void;
};

export const SuccessLoader = ({
  size = 80,
  isLoading = true,
  isSuccess = false,
  onSuccess,
}: SuccessLoaderProps) => {
  const forceUpdate = useForceUpdate();

  const rotation = useRef(0);
  const sectionSize = useRef(25);
  const isLoadingRef = useRef(isLoading);
  const isSuccessRef = useRef(isSuccess);

  const [radius, circumference, strokeWidth, strokeOffset] = useMemo(() => {
    const strokeWidth = (4 / 80) * size;
    const radius = size / 2 - strokeWidth;
    const circumference = 2 * Math.PI * radius;

    const strokeOffset = (1 / 4) * circumference;

    return [radius, circumference, strokeWidth, strokeOffset];
  }, [size]);

  const strokeDashArray = useRef((sectionSize.current / 100) * circumference);

  const handleSuccess = () => {
    if (sectionSize.current < 100) {
      if (sectionSize.current === 99 && onSuccess) onSuccess();

      sectionSize.current = sectionSize.current + 1;
      strokeDashArray.current = (sectionSize.current / 100) * circumference;
      forceUpdate();
      setTimeout(() => {
        handleSuccess();
      }, 3);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoadingRef.current && !isSuccessRef.current) {
        rotation.current = (rotation.current + 2) % 360;
        forceUpdate();
      }
    }, 3);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      handleSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isLoadingRef.current !== isLoading) isLoadingRef.current = isLoading;
    if (isSuccessRef.current !== isSuccess) isSuccessRef.current = isSuccess;
  }, [isLoading, isSuccess]);

  return (
    <div
      className={css.container}
      style={{width: `${size}px`, height: `${size}px`}}
    >
      <svg
        className={css.circle}
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fill="transparent"
        style={{
          transform: `rotate(${rotation.current}deg)`,
        }}
      >
        <circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          stroke={isSuccessRef.current ? '#40C057' : '#e65019'}
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeDashArray.current}, ${
            circumference - strokeDashArray.current
          }`}
          strokeDashoffset={strokeOffset}
        />
      </svg>

      {sectionSize.current >= 100 && (
        <FlexContainer
          className={css.labelContainer}
          justifyContent="center"
          alignItems="center"
        >
          <FaCheck
            className={css.checkMark}
            color="#40C057"
            strokeWidth={strokeWidth / 2}
          />
        </FlexContainer>
      )}
    </div>
  );
};
