import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconSave03} from '@autocut/designSystem/components/atoms/Icon/general/IconSave03';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {CensoredWordsGrid} from './CensoredWordsGrid';

export const CensorWordsStep = () => {
  const intl = useIntl();
  const {gptWords, swearWordsDictionary, utterances} = useAutoCutStore(
    state => ({
      gptWords: state.onGoingProcess.swearWords.gptWords,
      utterances: state.onGoingProcess.swearWords.utterances,
      swearWordsDictionary: state.ui.swearWords.swearWordsDictionary,
    }),
  );

  const currentDictionaryWords = useMemo(() => {
    return swearWordsDictionary.filter(word =>
      utterances.some(utterance =>
        utterance.words.some(utteranceWord => utteranceWord.word === word),
      ),
    );
  }, [swearWordsDictionary]);

  const currentGptWords = new Set(gptWords.map(gptWord => gptWord.word));

  const saveNewSwearWordsToDictionary = () => {
    const savedSwearWordsSet = new Set(swearWordsDictionary);

    for (const currentGptWord of currentGptWords) {
      savedSwearWordsSet.add(currentGptWord);
    }

    setAutocutStore(
      'ui.swearWords.swearWordsDictionary',
      Array.from(savedSwearWordsSet),
    );
    setAutocutStore('onGoingProcess.swearWords.gptWords', []);
  };

  const deleteSwearWordFromNewSwearWords = (word: string) =>
    setAutocutStore(
      'onGoingProcess.swearWords.gptWords',
      gptWords.filter(gptWord => gptWord.word !== word),
    );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_swearWords_parameters_censorWords_title',
        defaultMessage: 'Censor Words',
      })}
      description={intl.formatMessage({
        id: 'modes_swearWords_parameters_censorWords_description',
        defaultMessage:
          'Add and remove words from the censored list. You can also manage your dictionary.',
      })}
    >
      <FlexContainer flexDirection="column">
        <FlexContainer
          flexDirection="row"
          gap={Spacing.s2}
          alignItems="flex-start"
          justifyContent="center"
        >
          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow
            flexShrink
            flexBasis="0px"
            gap={Spacing.s1}
          >
            <Text
              variant="textXs.medium"
              style={{fontStyle: 'italic'}}
            >
              <TranslatedMessage
                id="modes_swearWords_parameters_censorWords_newWords_title"
                defaultMessage="New words detected"
              />
            </Text>
            <CensoredWordsGrid
              words={Array.from(currentGptWords)}
              onDeleteWord={deleteSwearWordFromNewSwearWords}
            />
          </FlexContainer>
          <FlexContainer
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
          >
            <Tooltip
              tooltipPosition="top"
              tooltipComponent={
                <TranslatedMessage
                  id="modes_swearWords_parameters_censorWords_saveButton"
                  defaultMessage="Save new words to list"
                />
              }
            >
              <ActionIcon
                variant="subtle"
                size={24}
                onClick={saveNewSwearWordsToDictionary}
              >
                <IconSave03 size={18} />
              </ActionIcon>
            </Tooltip>
          </FlexContainer>

          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow
            flexShrink
            flexBasis="0px"
            gap={Spacing.s1}
          >
            <Text
              variant="textXs.medium"
              style={{fontStyle: 'italic'}}
            >
              <TranslatedMessage
                id="modes_swearWords_parameters_censorWords_savedWords_title"
                defaultMessage="Detected words from your saved list"
              />
            </Text>
            <CensoredWordsGrid words={currentDictionaryWords} />
            <Button
              variant="tertiary"
              fullWidth={false}
              onClick={() => addModalToQueue(ModalType.ManageSavedSwearWords)}
            >
              <TranslatedMessage
                id="modes_swearWords_parameters_censorWords_savedWords_cta"
                defaultMessage="Manage saved list"
              />
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FormSection>
  );
};
