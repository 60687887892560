import type {CSSProperties} from 'react';

import styled from '@emotion/styled';

export interface FlexContainerProps {
  column?: string;
  flexBasis?: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  flex?: string;
  gap?: CSSProperties['gap'];
  rowGap?: CSSProperties['rowGap'];
  columnGap?: CSSProperties['columnGap'];
  flexShrink?: boolean;
  flexGrow?: boolean;
  alignSelf?: CSSProperties['alignSelf'];
}

const FlexContainer = styled.div((props: FlexContainerProps) => ({
  display: 'flex',
  flex: props.flex ? props.flex : '0 1 auto',
  flexBasis: props.flexBasis ? props.flexBasis : 'auto',
  flexDirection: props.flexDirection ? props.flexDirection : 'row',
  flexWrap: props.flexWrap ? props.flexWrap : 'nowrap',
  justifyContent: props.justifyContent ? props.justifyContent : 'flex-start',
  alignItems: props.alignItems ? props.alignItems : 'stretch',
  gap: props.gap ? props.gap : '0',
  rowGap: props.rowGap ? props.rowGap : undefined,
  columnGap: props.columnGap ? props.columnGap : undefined,
  flexShrink:
    props.flexShrink === undefined ? undefined : props.flexShrink ? 1 : 0,
  flexGrow: props.flexGrow === undefined ? undefined : props.flexGrow ? 1 : 0,
  alignSelf: props.alignSelf ? props.alignSelf : 'auto',
}));

export default FlexContainer;
