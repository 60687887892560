import type {AutoCutStateKeys} from '@autocut/utils/zustand/zustand';

import {zSubscribers_Backup} from '@autocut/utils/zustand/backup.zustand';
import {zSubscribers_Dev} from '@autocut/utils/zustand/dev.zustand';
import {zSubscribers_Game} from '@autocut/utils/zustand/game.zustand';
import {zSubscribers_Misc} from '@autocut/utils/zustand/misc.zustand';
import {zSubscribers_OnGoingProcess} from '@autocut/utils/zustand/onGoingProcess.zustand';
import {zSubscribers_UI} from '@autocut/utils/zustand/ui.zustand';
import {zSubscribers_User} from '@autocut/utils/zustand/user.zustand';

export type AutoCutStateSubscriberAction<Keys extends string> = (
  key: Keys,
) => void;
export type AutoCutStateSubscriberConfig<
  Keys extends AutoCutStateKeys = AutoCutStateKeys,
> = {
  [K in Keys]?:
    | AutoCutStateSubscriberAction<Keys>
    | AutoCutStateSubscriberAction<Keys>[];
};

export const onStateUpdateConfigs: AutoCutStateSubscriberConfig = {
  ...(zSubscribers_Backup as any),
  ...(zSubscribers_Dev as any),
  ...(zSubscribers_Game as any),
  ...(zSubscribers_Misc as any),
  ...(zSubscribers_OnGoingProcess as any),
  ...(zSubscribers_UI as any),
  ...(zSubscribers_User as any),
};
