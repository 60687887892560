import type {ModalType} from '@autocut/enums/modals.enum';

import {setAutocutStore} from '../zustand/zustand';

/**
 * This function replaces the current modal with the given modal.
 * It will display the modal immediately. If there is a modal already displayed, it will be lost.
 */
export const replaceCurrentModal = (modal: ModalType) => {
  console.log('[MODAL] Replacing current modal: ', modal);
  setAutocutStore('modal.openedModalName', modal);
};
