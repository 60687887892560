import type {Licence} from '@autocut/types/IsKeyValidResponse';

import {isKeyValid} from '../auth.utils';
import {handleTrialTask} from '../game/trialGamfication.util';
import {initInterceptors, setTokens} from '../http.utils';
import {createOrUpdateUser} from '../stats.utils';
import {autocutStoreVanilla, setAutocutStore} from '../zustand/zustand';

export const login = async ({
  key,
  license,
  additionalInformations,
  accessToken,
  refreshToken,
}: {
  key: string;
  license: Licence;
  additionalInformations?: {
    displayOnboarding?: boolean;
    latestInvoice?: string;
    scoreRef?: string;
    hasTestimonial?: boolean;
  };
  accessToken: string;
  refreshToken: string;
}) => {
  setAutocutStore('user.key', key);

  setAutocutStore('user.isLogged', true);

  setAutocutStore('user.license', license);
  setAutocutStore('user.additionalInformations', s => ({
    ...s.user.additionalInformations,
    ...additionalInformations,
  }));

  setTokens({accessToken, refreshToken});
  initInterceptors();
  setAutocutStore('user.accessToken', '');

  if (additionalInformations?.hasTestimonial) {
    await handleTrialTask('testimonial', false);
  }

  await createOrUpdateUser();
};

export const loginFromCookies = async () => {
  const key = autocutStoreVanilla().user.key;
  if (key) {
    const keyCheckResponse = await isKeyValid(key);

    if (keyCheckResponse.result) {
      const license = {
        ...keyCheckResponse.licence,
        key,
      };
      await login({
        ...keyCheckResponse.accessTokens,
        key,
        license,
        additionalInformations: keyCheckResponse.additionalInformations,
      });
    } else {
      setAutocutStore('user.key', '');
    }
  }
};
