import logLevel from '@autocut/enums/logLevel.enum';

import {reportSentryError} from '../sentry.utils';

export class IncrementalError extends Error {
  fingerprint = '';

  constructor(baseError: Error | string, fingerprint: string) {
    const error =
      typeof baseError === 'string' ? new Error(baseError) : baseError;

    super(error.message);
    this.cause = error.cause;
    this.name = error.name;
    this.stack = error.stack;
    this.message = error.message;
    this.fingerprint = fingerprint;

    console.trace(
      'NEW IncrementalError : ',
      this.fingerprint,
      this.message,
      this.stack,
    );
  }

  reportToSentry() {
    console.log('REPORTING SENTRY ERROR', this.fingerprint, this.toJSON());

    reportSentryError({
      fingerPrint: this.fingerprint
        .replace(/\(/g, '( - ')
        .replace(/\)/g, ' - )')
        .split(' - ')
        .filter(Boolean)
        .map(fingerprint => fingerprint.trim() + ' '),
      error: this,
      context: this.toJSON(),
      level: logLevel.error,
      scopeModifier: scope => {
        scope.setTransactionName(this.name);
      },
    });
  }

  toJSON() {
    return {
      fingerprint: this.fingerprint,
      cause: this.cause,
      name: this.name,
      stack: this.stack,
      message: this.message,
    };
  }

  toString() {
    return `IncrementalError : [${this.fingerprint}] ${this.message}\n${this.stack}`;
  }
}
