import type {FileWithPath} from '@autocut/designSystem/components/molecules/FileInput/FileInput';

import logLevel from '@autocut/enums/logLevel.enum';
import {FingerprintErrorFactory} from '@autocut/utils/errors/FingerprintErrorFactory';
import {uploadFile} from '@autocut/utils/files.utils';
import {isMac} from '@autocut/utils/host';
import {autocutApi} from '@autocut/utils/http.utils';
import {autocutStoreVanilla} from '@autocut/utils/zustand/zustand';

export const sendFeedback = async (form: {
  text: string;
  files: FileWithPath[];
}) => {
  if (form.text.length < 15) {
    throw new Error('modals_feedback_steps_report_error_feedbackTooShort');
  }
  const feedbackData = generateFeedbackData(form);
  const response = await autocutApi
    .post('/modals/beta-feedback', {
      ...feedbackData,
      os: isMac() ? 'mac' : 'windows',
    })
    .catch((error: any) => {
      console.error(logLevel.error, 'Beta feedback send failed.', {error});
    });

  if (!response || response.data.message !== 'OK') {
    throw FingerprintErrorFactory('Failed to send feedback', 'sendFeedback');
  }

  try {
    const screenshotIndex = 0;

    for (const screenshot of form.files) {
      const fileUploadUrl =
        response.data.screenshots[
          Object.keys(response.data.screenshots)[screenshotIndex]
        ].uploadUrl;

      await uploadFile(
        // @ts-ignore
        screenshot.path,
        fileUploadUrl,
        console.log,
        true,
        screenshot.type,
      );
    }
  } catch (error: any) {
    console.error(logLevel.error, 'Daily log send failed.', {error});
    return;
  }
};

const generateFeedbackData = ({
  text,
  files,
}: {
  files: FileWithPath[];
  text: string;
}): {
  screenshots: {
    name: string;
    type: string;
  }[];
  description: string;
  key: string;
} => {
  const license = autocutStoreVanilla().user.license;

  const screenshotsData = files.map(screenshot => ({
    name: screenshot.name,
    type: screenshot.type,
  }));

  return {
    screenshots: screenshotsData,
    description: text,
    key: license?.key ?? '',
  };
};
