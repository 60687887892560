import type {CanvasFontParams, CaptionChunk} from '@autocut/types/Captions';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconRefreshCcw01} from '@autocut/designSystem/components/atoms/Icon/arrows/IconRefreshCcw01';
import {IconTrash01} from '@autocut/designSystem/components/atoms/Icon/general/IconTrash01';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {host} from '@autocut/utils/host';
import {useEffect, useState} from 'react';

import {addWord} from '../../../utils/addWord.utils';
import {removeWord} from '../../../utils/removeWord.utils';
import {splitChunk} from '../../../utils/splitChunk.utils';
import {useEditTranscriptModal} from '../EditTranscriptModal/EditTranscriptModalContext';
import {EmojiSelector} from '../EmojiSelector/EmojiSelector';
import {TranscriptChunkContainer} from '../TranscriptChunkContainer/TranscriptChunkContainer';
import css from './CustomizeChunkCard.module.scss';
import {EditableWord} from './EditableWord/EditableWord';
import {onTextChange} from './utils';

export const CustomizeChunkCard = ({
  selectedChunkIndex,
}: {
  selectedChunkIndex: number | null;
}) => {
  const {
    editTranscriptModal: {
      localeCaptionChunks: chunks,
      setLocaleCaptionChunks: setChunks,
    },
  } = useEditTranscriptModal();

  const onChange = (newChunks: CaptionChunk[]) => {
    if (!selectedChunk) return;

    setChunks(newChunks);
  };

  const [sequenceWidth, setSequenceWidth] = useState(0);
  const selectedChunk =
    selectedChunkIndex === null ? null : chunks[selectedChunkIndex];

  useEffect(() => {
    const init = async () => {
      setSequenceWidth(
        parseInt((await host.timeline.getTimelineFormat()).width),
      );
    };

    void init();
  }, [selectedChunkIndex]);

  const {params} = useAutoCutStore(state => ({
    params: state.ui.parameters.caption,
  }));

  const fontParameters: CanvasFontParams = {
    italic: params.formating.italic,
    fontSize: params.text.fontSize,
    fontFamily: params.text.font.fontFamily,
  };
  const allowedWidth = (params.formating.maxWidth / 100) * sequenceWidth;

  const isRtl = params.languageOfTranscription.textDirection === 'rtl';

  const handleChunkDelete = () => {
    if (!selectedChunk) return;

    const updatedChunk: CaptionChunk = {
      ...selectedChunk,
      deleted: !selectedChunk.deleted,
    };

    const newChunks = chunks.map((chunk, index) =>
      index === selectedChunkIndex ? updatedChunk : chunk,
    );

    onChange(newChunks);
  };

  return (
    <TranscriptChunkContainer
      flexDirection="row"
      gap={12}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      className={css.container}
    >
      <FlexContainer
        flexDirection="column"
        gap={12}
        alignItems="flex-start"
        style={{
          width: '100%',
        }}
      >
        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          gap={16}
        >
          <EmojiSelector
            selectedEmoji={
              selectedChunk?.emoji && selectedChunk?.emojiUrl
                ? {
                    name: selectedChunk?.emoji,
                    url: selectedChunk?.emojiUrl,
                  }
                : undefined
            }
            onEmojiSelect={emoji => {
              if (!selectedChunk) {
                return;
              }
              const updatedChunk: CaptionChunk = {
                ...selectedChunk,
                emoji: emoji.name,
                emojiUrl: emoji.url,
                emojiSize: emoji.size,
              };

              const newChunks = chunks.map((chunk, index) =>
                index === selectedChunkIndex ? updatedChunk : chunk,
              );

              onChange(newChunks);
            }}
            onDeleteEmoji={() => {
              if (!selectedChunk) return;
              const updatedChunk: CaptionChunk = {
                ...selectedChunk,
                emoji: undefined,
                emojiUrl: undefined,
                emojiSize: undefined,
              };

              const newChunks = chunks.map((chunk, index) =>
                index === selectedChunkIndex ? updatedChunk : chunk,
              );

              onChange(newChunks);
            }}
            disabled={!selectedChunk}
          />
        </FlexContainer>

        {selectedChunk && (
          <FlexContainer
            gap={4}
            alignItems="center"
            flexWrap="wrap"
            flexDirection={isRtl ? 'row-reverse' : 'row'}
            style={{
              alignSelf: isRtl ? 'flex-end' : 'flex-start',
            }}
            className={selectedChunk.deleted ? css.deletedChunk : ''}
          >
            {selectedChunk.highlight.map((highlight, index) => {
              return (
                <EditableWord
                  key={index}
                  highlight={highlight}
                  chunk={selectedChunk}
                  isRtl={isRtl}
                  onTextChange={(newValue: string) => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = onTextChange({
                      newValue,
                      selectedChunk,
                      index,
                      chunks,
                      selectedChunkIndex,
                      allowedWidth,
                      fontParameters,
                      params,
                      highlight,
                    });

                    onChange(newChunks);
                  }}
                  onSplit={async () => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = await splitChunk(
                      chunks,
                      selectedChunkIndex,
                      index,
                    );
                    onChange(newChunks);
                  }}
                  onAddWord={async () => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = addWord(
                      chunks,
                      selectedChunkIndex,
                      index,
                    );
                    onChange(newChunks);
                  }}
                  onRemoveWord={async () => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = removeWord(
                      chunks,
                      selectedChunkIndex,
                      index,
                    );
                    onChange(newChunks);
                  }}
                  isLast={index === selectedChunk.highlight.length - 1}
                />
              );
            })}
          </FlexContainer>
        )}

        {!selectedChunk && (
          <p
            style={{
              color: '#ffffff',
              fontStyle: 'italic',
              fontSize: '14px',
            }}
          >
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_transcription_modal_placeholder'
              }
              defaultMessage="Select the part of the transcript you want to edit/customize"
            />
          </p>
        )}
      </FlexContainer>

      <Tooltip
        variant={selectedChunk?.deleted ? 'default' : 'warning'}
        tooltipComponent={
          selectedChunk?.deleted ? (
            <TranslatedMessage
              id="modes_caption_steps_customization_parts_transcription_tooltips_restoreChunk"
              defaultMessage="Restore chunk"
            />
          ) : (
            <TranslatedMessage
              id="modes_caption_steps_customization_parts_transcription_tooltips_deleteChunk"
              defaultMessage="Delete chunk"
            />
          )
        }
      >
        <ActionIcon
          variant="subtle"
          size={40}
          onClick={handleChunkDelete}
          disabled={!selectedChunk}
          color={selectedChunk?.deleted ? colors.gray300 : colors.error600}
        >
          {selectedChunk?.deleted ? (
            <IconRefreshCcw01 size="24" />
          ) : (
            <IconTrash01 size="24" />
          )}
        </ActionIcon>
      </Tooltip>
    </TranscriptChunkContainer>
  );
};
