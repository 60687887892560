import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {BRollsParameters} from './parameters/BRollsParameters';
import {BRollsTranscript} from './transcript/BRollsTranscript';

export const BRollsMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AutocutModes.BRoll.icon}
      text={AutocutModes.BRoll.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/broll"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            clickable: true,
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="broll"
                onValidateSections={() => navigate('./parameters')}
              />
            ),
          },
          {
            clickable: true,
            label: intl.formatMessage({
              id: 'modes_broll_steps_parameters_title',
              defaultMessage: 'B-Rolls Parameters',
            }),
            path: 'parameters',
            element: <BRollsParameters />,
          },
          {
            clickable: false,
            label: intl.formatMessage({
              id: 'modes_broll_steps_transcript_title',
              defaultMessage: 'Edit B-Rolls selection',
            }),
            path: 'choice',
            element: <BRollsTranscript />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
