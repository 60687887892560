import type {BleepEntry} from '../BleepSoundChoiceStep/BleepSoundChoiceStep';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Card} from '@autocut/designSystem/components/atoms/Card/Card';
import {IconCursor} from '@autocut/designSystem/components/atoms/Icon/editor/IconCursor';
import {IconCheck} from '@autocut/designSystem/components/atoms/Icon/general/IconCheck';
import {IconTrash01} from '@autocut/designSystem/components/atoms/Icon/general/IconTrash01';
import {IconXClose} from '@autocut/designSystem/components/atoms/Icon/general/IconXClose';
import {IconPauseCircle} from '@autocut/designSystem/components/atoms/Icon/media/IconPauseCircle';
import {IconPlayCircle} from '@autocut/designSystem/components/atoms/Icon/media/IconPlayCircle';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {BleepFileEnum} from '@autocut/enums/bleepFile.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {usePlayback} from '@autocut/hooks/usePlayback';
import {preload} from '@autocut/types/ElectronPreload';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useRef, useState} from 'react';

type BleepSoundEntryItemProps = {
  bleep: BleepEntry;
  onBleepUpdate: () => void;
};

export const BleepSoundEntryItem = ({
  bleep,
  onBleepUpdate,
}: BleepSoundEntryItemProps) => {
  const bleepFile = useAutoCutStore(
    state => state.ui.parameters.swear_word.bleepFile,
  );
  const [isRenaming, setIsRenaming] = useState(false);
  const tempNameRef = useRef('');
  const {playbackChunk, stopPlaybackChunk} = usePlayback({
    audioFilepath: bleep.fullpath,
    withTimecode: false,
  });
  const [isPlaying, setIsPlaying] = useState(false);

  const {fs, path} = preload;

  const validateRename = () => {
    if (!tempNameRef.current) {
      setIsRenaming(false);

      return;
    }

    const dirPath = path.dirname(bleep.fullpath);
    const extName = path.extname(bleep.fullpath);
    const newFullpath = `${path.join(dirPath, tempNameRef.current)}${extName}`;
    fs.renameSync(bleep.fullpath, newFullpath);
    onBleepUpdate();
    setIsRenaming(false);
  };

  const deleteBleep = () => {
    if (bleepFile === bleep.fullpath) {
      setAutocutStore(
        'ui.parameters.swear_word.bleepFile',
        BleepFileEnum.Muted,
      );
    }
    fs.rmSync(bleep.fullpath);
    onBleepUpdate();
  };

  return (
    <Card
      style={{
        width: '100%',
      }}
    >
      <FlexContainer
        gap={Spacing.s2}
        alignItems="center"
      >
        {isRenaming ? (
          <>
            <Input
              style={{height: 24, lineHeight: 'unset'}}
              onChange={value => (tempNameRef.current = value)}
              onKeyUp={event => {
                if (event.key === 'Enter') validateRename();
                if (event.key === 'Escape') setIsRenaming(false);
              }}
            />
            <ActionIcon
              size={20}
              onClick={() => validateRename()}
            >
              <IconCheck />
            </ActionIcon>
            <ActionIcon
              size={20}
              onClick={() => setIsRenaming(false)}
            >
              <IconXClose />
            </ActionIcon>
          </>
        ) : (
          <>
            <div style={{overflow: 'hidden', width: '100%'}}>
              <Text
                style={{
                  width: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {bleep.name}
              </Text>
            </div>

            {!isPlaying ? (
              <ActionIcon
                size={20}
                onClick={() => {
                  playbackChunk({onEnd: () => setIsPlaying(false)});
                  setIsPlaying(true);
                }}
                style={{flexShrink: 0}}
              >
                <IconPlayCircle />
              </ActionIcon>
            ) : (
              <ActionIcon
                size={20}
                color={colors.primary600}
                onClick={() => {
                  stopPlaybackChunk();
                  setIsPlaying(false);
                }}
                style={{flexShrink: 0}}
              >
                <IconPauseCircle />
              </ActionIcon>
            )}

            <ActionIcon
              onClick={() => setIsRenaming(true)}
              style={{flexShrink: 0}}
              size={20}
            >
              <IconCursor />
            </ActionIcon>
            <Tooltip
              tooltipPosition="left"
              variant="warning"
              tooltipComponent={
                <TranslatedMessage
                  id="modes_swearWords_parameters_bleepSoundManager_delete"
                  defaultMessage={'Delete'}
                />
              }
            >
              <ActionIcon
                onClick={() => deleteBleep()}
                style={{flexShrink: 0}}
                size={20}
                color={colors.error600}
              >
                <IconTrash01 />
              </ActionIcon>
            </Tooltip>
          </>
        )}
      </FlexContainer>
    </Card>
  );
};
