import type {XMLDocument} from '../class/_XMLDocument';

import {CaptionsXmlProjectTrackIndex} from '../class/_types';

export const getCaptionsTemplateTracks = (xmlDoc: XMLDocument) => {
  const captionsSequences = xmlDoc.getSequences()[0];
  const videoTracks = captionsSequences.getVideoTracks();

  const textBackgroundTrack =
    videoTracks[CaptionsXmlProjectTrackIndex.TEXT_BACKGROUND];
  const wordBackgroundTrack =
    videoTracks[CaptionsXmlProjectTrackIndex.WORD_BACKGROUND];
  const captionsTrack = videoTracks[CaptionsXmlProjectTrackIndex.CAPTIONS];
  const effectsTrack = videoTracks[CaptionsXmlProjectTrackIndex.EFFECTS];
  const floatingTextEffectTrack =
    videoTracks[CaptionsXmlProjectTrackIndex.FLOATING_TEXT_EFFECT];

  return {
    textBackgroundTrack,
    wordBackgroundTrack,
    captionsTrack,
    effectsTrack,
    floatingTextEffectTrack,
  };
};
