import type {TransitionSlugs} from '@autocut/pages/modes/captions/utils/transitions.utils';

import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

import './effects.css';

import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {CAPTIONS_EXAMPLE_TEXT} from '@autocut/pages/modes/captions/customization/parts/EditCaptionStyle/CustomizationModal/Parts/ExampleCaption/hooks/Draw/captionDrawHooks.utils';
import {CAPTIONS_TRANSITIONS} from '@autocut/pages/modes/captions/utils/transitions.utils';
import {useEffect, useState} from 'react';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

const DELAY_BETWEEN_TRANSITIONS_PREVIEW = '2s';
const TransitionCustomization = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();

  const enabled = value.transition.enabled;
  const effects = value.transition.effects;

  // Reset transition when effects change so that they begin at the same timing
  const [resetAnimationName, setResetAnimationName] = useState<
    'none' | undefined
  >('none');
  useEffect(() => {
    setResetAnimationName('none');
    setTimeout(() => {
      setResetAnimationName(undefined);
    }, 100);
  }, [effects]);

  const onEffectClick = (effect: TransitionSlugs) => {
    setValue(
      'transition.effects',
      effects.includes(effect)
        ? effects.filter(e => e !== effect)
        : [...effects, effect],
    );
  };

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked => setValue('transition.enabled', checked)}
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_transition_title'
          }
          defaultMessage="Transition"
        />
      }
    >
      {Object.keys(CAPTIONS_TRANSITIONS).map(effect => (
        <ParameterLayout
          key={effect}
          title={
            <TranslatedMessage
              defaultMessage={effect}
              id={`modes_caption_steps_customization_parts_style_modal_transition_${effect as TransitionSlugs}`}
            />
          }
        >
          <Switch
            checked={effects.includes(effect as TransitionSlugs)}
            onChange={() => onEffectClick(effect as TransitionSlugs)}
            size={16}
          />
        </ParameterLayout>
      ))}

      <div
        style={{
          gridColumnEnd: 'span 2',
          textAlign: 'center',
          alignSelf: 'center',
          fontSize: '20px',
          lineClamp: 1,
          height: '35px',
          textOverflow: 'clip',
          overflow: 'hidden',
          willChange: 'animation, filter, opacity, transform',
          animationName: resetAnimationName,
          animation: resetAnimationName
            ? undefined
            : effects
                .map(
                  effect =>
                    `infinite ${effect} ${DELAY_BETWEEN_TRANSITIONS_PREVIEW} linear`,
                )
                .join(', '),
        }}
      >
        {CAPTIONS_EXAMPLE_TEXT.split('.')[0] + '.'}
      </div>
    </ParametersGroupLayout>
  );
};

export default TransitionCustomization;
