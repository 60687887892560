import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {rgbToHex} from '@autocut/utils/color.utils';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

export const WordBoxParameters = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();
  const textColor = value.text.color;
  const wordBoxColor = value.animations.wordBox.color;
  const wordBoxEnabled = value.animations.wordBox.enabled;
  const wordBoxXPadding = value.animations.wordBox.xPadding;
  const wordBoxYPadding = value.animations.wordBox.yPadding;
  const wordBoxOpacity = value.animations.wordBox.opacity;
  const wordBoxRadius = value.animations.wordBox.radius;
  const highlightColor = value.animations.highlight.color;
  const highlightEnabled = value.animations.highlight.enabled;
  const font = value.text.font;
  const unsupportedFeatures = value.languageOfTranscription.unsupportedFeatures;

  const isNotSupported = unsupportedFeatures?.(font).wordBox.disabled;

  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_animation_wordBox'
          }
          defaultMessage="Box with text"
          valuePamareters={{
            color: rgbToHex(highlightEnabled ? highlightColor : textColor),
            backgroundColor: rgbToHex(wordBoxColor),
          }}
        />
      }
    >
      <Switch
        checked={wordBoxEnabled && !isNotSupported}
        onChange={() =>
          !isNotSupported &&
          setValue('animations.wordBox.enabled', !wordBoxEnabled)
        }
        size={16}
      />
      <ParametersGroupLayout
        status={wordBoxEnabled && !isNotSupported}
        hiddenContent={
          isNotSupported && (
            <InfoText style="warning">
              <FlexContainer
                flexDirection="column"
                gap={4}
              >
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_box_disabled'
                  }
                  defaultMessage={
                    'The font you have chosen does not support the box feature for the language you have selected.'
                  }
                />
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_box_tips'
                  }
                  defaultMessage={
                    'Pick a font that supports the language you have selected if you want to use this feature.'
                  }
                />
              </FlexContainer>
            </InfoText>
          )
        }
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_text_color'
              }
              defaultMessage="Color"
            />
          }
        >
          <ColorPicker
            value={wordBoxColor}
            onChange={color => setValue('animations.wordBox.color', color)}
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_paddingX'
              }
              defaultMessage="Padding x"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxXPadding) || !wordBoxXPadding ? 0 : wordBoxXPadding
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            formatValue={value => `${value}%`}
            onChange={value => setValue('animations.wordBox.xPadding', value)}
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_paddingY'
              }
              defaultMessage="Padding y"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxYPadding) || !wordBoxYPadding ? 0 : wordBoxYPadding
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            formatValue={value => `${value}%`}
            onChange={value => setValue('animations.wordBox.yPadding', value)}
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_opacity'
              }
              defaultMessage="Opacity"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxOpacity) || !wordBoxOpacity ? 0 : wordBoxOpacity
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            formatValue={value => `${value}%`}
            onChange={value => setValue('animations.wordBox.opacity', value)}
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_radius'
              }
              defaultMessage="Radius"
            />
          }
          wrap={true}
        >
          <Slider
            value={isNaN(wordBoxRadius) || !wordBoxRadius ? 0 : wordBoxRadius}
            min={0}
            max={100}
            variant="valueOnLeft"
            formatValue={value => `${value}%`}
            onChange={value => setValue('animations.wordBox.radius', value)}
          />
        </ParameterLayout>
      </ParametersGroupLayout>
    </ParameterLayout>
  );
};
