import type {StatType} from '@autocut/enums/statType.enum';
import type {Trait, Value} from '@kezios/july-sdk';

import {formatVersion} from '@autocut/components/VersionDisplay/VersionDisplay';
import {AUTOCUT_CONSTANTS} from '@autocut/constants/configs';
import logLevel from '@autocut/enums/logLevel.enum';
import {isPreloadReady, preload} from '@autocut/types/ElectronPreload';
import {manageError} from '@autocut/utils/manageError';
import {generateEntityName, July} from '@kezios/july-sdk';

import {CURRENT_ENV} from './currentEnv.utils';
import {FingerprintErrorFactory} from './errors/FingerprintErrorFactory';
import {logger} from './logger';
import {autocutStoreVanilla, setAutocutStore} from './zustand/zustand';

const COUNTRY_CHECK_EXPIRATION_TIME = 1000; //1000 * 60 * 60 * 24 * 2; // 2 days

export type Traits = Record<string, string | number | Date>;

export const getTransitionGroupId = (transition: string) => {
  switch (transition) {
    case 'j_cut':
      return 'transition_j_cut';
    case 'l_cut':
      return 'transition_l_cut';
    case 'both':
      return 'transition_both';
    default:
      return 'transition_none';
  }
};

export interface StatisticDto {
  value: number | string | Date;
  type: StatType;
  traits?: Partial<Trait> | undefined;
}

export const july = new July(
  AUTOCUT_CONSTANTS[CURRENT_ENV].JULY_API_KEY || '',
  AUTOCUT_CONSTANTS[CURRENT_ENV].JULY_ADDRESS,
);

/**
 * Initialize July with the last saved id to allow sending stats before login
 */
let isJulyInitialized = false;
export const initJuly = async () => {

  if (isJulyInitialized || !isPreloadReady()) return;
  const store = autocutStoreVanilla();

  if (typeof preload.electron.getIPInfos === 'function') {
    if (
      !store.user.countryInfos ||
      new Date(store.user.countryInfos.checkDate).getTime() <
        Date.now() + COUNTRY_CHECK_EXPIRATION_TIME
    ) {
      const result = await preload.electron.getIPInfos();
      if (result) {
        const {country, cc} = result;
        setAutocutStore('user.countryInfos', {
          name: country,
          code: cc,
          checkDate: new Date().toISOString(),
        });
        console.debug('Country infos refreshed', store.user.countryInfos);
      } else {
        console.error('Could not retrieve IP infos');
      }
    }
  }

  const julyId = store.user.julyId;
  if (!julyId) return;
  await july.user(julyId);
  isJulyInitialized = true;
};

export const createOrUpdateUser = async (traits?: Trait) => {
  const store = autocutStoreVanilla();
  const key = store.user.key;
  const scoreRef = store.user.additionalInformations?.scoreRef || undefined;

  if (!key) return;

  const userName = generateEntityName(
    key,
    AUTOCUT_CONSTANTS[CURRENT_ENV].JULY_SECRET_KEY,
    scoreRef,
  );

  setAutocutStore('user.julyId', userName);

  await july.user(userName, {
    ...traits,
    lastStart: new Date(),
  });
  isJulyInitialized = true;
};

export const sendStats = async (stat: StatisticDto) => {
  if (stat === undefined) {
    return;
  }

  await initJuly();

  const autocutStore = autocutStoreVanilla();

  const traitWithoutUndefined: Trait = {
    software: autocutStore.ui.host,
    version: formatVersion(autocutStore.ui),
    ...(autocutStore.user.countryInfos
      ? {
          country: autocutStore.user.countryInfos.name,
          countryCode: autocutStore.user.countryInfos.code,
        }
      : {}),
  };
  for (const key in stat.traits) {
    if (stat.traits[key]) {
      traitWithoutUndefined[key] = stat.traits[key] as Value;
    }
  }

  try {
    logger('statsUtils', logLevel.notice, 'Sending stat.', {stat});
    return await july.event(stat.type, stat.value, traitWithoutUndefined);
  } catch (error: any) {
    manageError({
      error: FingerprintErrorFactory(error, 'sendStats'),
      additionalData: stat,
      disableModal: true,
    });
  }

  return undefined;
};
