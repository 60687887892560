import {OldButton} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload} from '@autocut/types/ElectronPreload';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {TbTag} from 'react-icons/tb';

import css from './TrialExpiredModal.module.scss';

export type TrialExpiredModalProps = {
  closeModal?: () => void;
};

export const TrialExpiredModal = ({
  closeModal: closeModalProps,
}: TrialExpiredModalProps) => {
  return (
    <Modal
      icon={
        <TbTag
          strokeWidth={2}
          size="1.5rem"
        />
      }
      title={
        <p className={css.modalTitle}>
          <OldTranslatedMessage
            id="trial_expired_modal_title"
            defaultMessage="Your trial key has expired."
          />
        </p>
      }
      footer={
        <OldButton
          buttonType="primary"
          onClickFunction={async () => {
            await preload.electron.openLinkInBrowser(
              `${preload.electron.getSharedVariables().WEBSITE_URL}/?coupon=a3Z5Uumo72nw5cW#pricing`,
            );
          }}
        >
          <OldTranslatedMessage
            id="trial_expired_modal_button"
            defaultMessage="Get a subscription"
          />
        </OldButton>
      }
      closeModalFunction={closeModalProps || closeModal}
    >
      <FlexContainer flexDirection="column">
        <p>
          <OldTranslatedMessage
            id="trial_expired_modal_text1"
            defaultMessage="Your free trial has expired. Subscribe to AutoCut to keep using the extension."
          />
        </p>
        <br />
        <ol className={css.list}>
          <li>
            <p>
              <OldTranslatedMessage
                id="trial_expired_modal_text2"
                defaultMessage="Visit your account on your AutoCut dashboard at"
              />
              <span>
                <OldButton
                  buttonType="tertiary"
                  compact
                  color="#94a3b8"
                  className={css.link}
                  onClickFunction={async () => {
                    await preload.electron.openLinkInBrowser(
                      `${preload.electron.getSharedVariables().WEBSITE_URL}/?coupon=a3Z5Uumo72nw5cW#pricing`,
                    );
                  }}
                >
                  AutoCut.fr
                </OldButton>
              </span>
              .
            </p>
          </li>
          <li>
            <p>
              <OldTranslatedMessage
                id="trial_expired_modal_text3"
                defaultMessage="Subscribe to a license key."
              />
            </p>
          </li>
        </ol>
        <br />
        <p>
          <OldTranslatedMessage
            id="trial_expired_modal_text4"
            defaultMessage="Reach out to our support team if you need help: {supportEmail}"
            values={{
              supportEmail: 'contact@autocut.fr',
            }}
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};
