import {colors} from '@autocut/designSystem/colors';
import {IconArrowLeft} from '@autocut/designSystem/components/atoms/Icon/arrows/IconArrowLeft';
import '@autocut/designSystem/styles/index';
import ModalManager from '@autocut/modals/ModalManager';
import {LaunchManager} from '@autocut/pages/LaunchManager/LaunchManager';
import SafeApp from '@autocut/SafeApp';
import {CURRENT_ENV} from '@autocut/utils/currentEnv.utils';
import {Env} from '@autocut/utils/currentEnv.utils';
import {Console, Hook, Unhook} from 'console-feed';
import {useEffect, useState} from 'react';

import css from './App.module.scss';
import {Header} from './components/Header/Header';
import {ModeProvider} from './contexts/ModeProvider';
import {NotificationProvider} from './contexts/NotificationProvider.tsx/NotificationProvider';
import FlexContainer from './designSystem/components/molecules/FlexContainer';
import {BetaFeedbackAffix} from './modals/BetaFeedbackModal/BetaFeedbackAffix/BetaFeedbackAffix';
import {GamingTrialAffix} from './modals/GamingTrialModal/GamingTrialAffix/GamingTrialAffix';

export default function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  return (
    <>
      <ModalManager />
      <FlexContainer
        id="app"
        flexDirection="column"
        className={css.rootContainer}
      >
        <Header />
        <BetaFeedbackAffix />
        <GamingTrialAffix />
        <ModeProvider>
          <NotificationProvider>
            {!isInitialized ? (
              <LaunchManager onComplete={() => setIsInitialized(true)} />
            ) : (
              <SafeApp />
            )}
          </NotificationProvider>
        </ModeProvider>
        <ConsoleAffix key="never-re-render" />
      </FlexContainer>
    </>
  );
}

const ConsoleAffix = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [logs, setLogs] = useState([]);
  const [hiddenTypes, setHiddenTypes] = useState<
    ('log' | 'error' | 'warn' | 'info')[]
  >([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (CURRENT_ENV && CURRENT_ENV !== Env.Production) {
      const hookedConsole = Hook(
        window.console,
        //@ts-ignore
        log => setLogs(currLogs => [...currLogs, log].slice(-1000)),
        false,
      );
      return () => {
        Unhook(hookedConsole);
      };
    }
  }, []);

  return CURRENT_ENV && CURRENT_ENV !== Env.Production ? (
    <div
      className={css.consoleAffix}
      style={{height: isOpen ? '300px' : '0px'}}
    >
      <div className={css.consoleAffixHeader}>
        <div
          className={css.consoleAffixHeaderHandle}
          onClick={() => setIsOpen(!isOpen)}
        >
          Console
          <IconArrowLeft
            style={{
              transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
              transition: 'transform 0.3s ease-in-out 0.3s',
            }}
          />
        </div>
        <div className={css.consoleAffixHeaderFiltersContainer}>
          <div
            onClick={() =>
              setHiddenTypes(prev =>
                prev.includes('log')
                  ? prev.filter(t => t !== 'log')
                  : [...prev, 'log'],
              )
            }
            style={{
              background: hiddenTypes.includes('log')
                ? colors.gray100
                : undefined,
            }}
          >
            log
          </div>
          <div
            onClick={() =>
              setHiddenTypes(prev =>
                prev.includes('error')
                  ? prev.filter(t => t !== 'error')
                  : [...prev, 'error'],
              )
            }
            style={{
              background: hiddenTypes.includes('error')
                ? colors.gray100
                : undefined,
            }}
          >
            error
          </div>
          <div
            onClick={() =>
              setHiddenTypes(prev =>
                prev.includes('warn')
                  ? prev.filter(t => t !== 'warn')
                  : [...prev, 'warn'],
              )
            }
            style={{
              background: hiddenTypes.includes('warn')
                ? colors.gray100
                : undefined,
            }}
          >
            warn
          </div>
          <div
            onClick={() =>
              setHiddenTypes(prev =>
                prev.includes('info')
                  ? prev.filter(t => t !== 'info')
                  : [...prev, 'info'],
              )
            }
            style={{
              background: hiddenTypes.includes('info')
                ? colors.gray100
                : undefined,
            }}
          >
            info
          </div>
        </div>
        <div
          onClick={() => setLogs([])}
          style={{cursor: 'pointer', fontSize: '12px'}}
        >
          Clear
        </div>
        <input
          type="text"
          onChange={e => setSearch(e.target.value)}
          value={search}
          placeholder="Search"
        />
      </div>
      <div className={css.consoleAffixContent}>
        <Console
          logs={logs}
          variant="dark"
          filter={hiddenTypes}
          searchKeywords={search}
        />
      </div>
    </div>
  ) : null;
};
