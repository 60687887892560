import {InputRangeParameter} from '@autocut/components/InputRangeParamater/InputRangeParameter';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';

export const ZoomCoverageStep = () => {
  const {totalZoomPercentage} = useAutoCutStore(
    state => state.ui.parameters.zoom,
  );

  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id="modes_zoom_parameters_zoomCoverage"
          defaultMessage="Total zoom coverage"
        />
      }
      grow
    >
      <InputRangeParameter
        value={totalZoomPercentage}
        step={0.01}
        min={0}
        max={1}
        unit="%"
        unitFormatting={value => `${(value * 100).toFixed()}`}
        onChange={value =>
          setAutocutStore(
            'ui.parameters.zoom.totalZoomPercentage',
            parseFloat(value),
          )
        }
      />
    </ParameterLayout>
  );
};
