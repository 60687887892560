import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {HSLtoHex, rgbToHsl} from '@autocut/utils/color.utils';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

export const HighlightParameters = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();

  const highlightColor = value.animations.highlight.color;
  const highlightEnabled = value.animations.highlight.enabled;
  const revealTextEnabled = value.animations.highlight.revealText.enabled;
  const revealedTextColor = value.animations.highlight.revealText.color;
  const unrevealedTextColor = value.animations.highlight.revealText.color;

  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_animation_highlight_title'
          }
          defaultMessage="The <color>color</color>"
          valuePamareters={{
            color: HSLtoHex({
              ...rgbToHsl(highlightColor),
              l: Math.max(rgbToHsl(highlightColor).l, 0.3),
            }),
          }}
        />
      }
    >
      <Switch
        checked={highlightEnabled}
        onChange={(checked: boolean) =>
          setValue('animations.highlight.enabled', checked)
        }
        size={16}
      />
      <ParametersGroupLayout
        status={highlightEnabled}
        hiddenContent={null}
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_animation_highlight_color'
              }
              defaultMessage="Color"
            />
          }
        >
          <ColorPicker
            value={highlightColor}
            onChange={color => setValue('animations.highlight.color', color)}
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_animation_highlight_advancedColorOptions_title'
              }
              defaultMessage="Advanced color options"
              valuePamareters={{
                color: HSLtoHex({
                  ...rgbToHsl(highlightColor),
                  l: Math.max(rgbToHsl(highlightColor).l, 0.3),
                }),
              }}
            />
          }
        >
          <Switch
            checked={revealTextEnabled}
            onChange={(checked: boolean) =>
              setValue('animations.highlight.revealText.enabled', checked)
            }
            size={16}
          />
        </ParameterLayout>

        <ParameterLayout>
          <ParametersGroupLayout
            status={revealTextEnabled}
            hiddenContent={null}
          >
            <ParameterLayout
              title={
                <FlexContainer>
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_style_modal_animation_highlight_advancedColorOptions_revealedText'
                    }
                    defaultMessage="Color before highlight"
                  />
                </FlexContainer>
              }
            >
              <ColorPicker
                value={revealedTextColor}
                onChange={color =>
                  setValue('animations.highlight.revealText.color', color)
                }
              />
            </ParameterLayout>
            <ParameterLayout
              title={
                <FlexContainer>
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_style_modal_animation_highlight_advancedColorOptions_unrevealedText'
                    }
                    defaultMessage="Color after highlight"
                  />
                </FlexContainer>
              }
            >
              <ColorPicker
                value={unrevealedTextColor}
                onChange={color =>
                  setValue('animations.highlight.revealText.color', color)
                }
              />
            </ParameterLayout>
          </ParametersGroupLayout>
        </ParameterLayout>
      </ParametersGroupLayout>
    </ParameterLayout>
  );
};
