import {defaultFontSize} from '@autocut/enums/modes.enum';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';

export const resetFontSize = async () => {
  const fontSize = autocutStoreVanilla().ui.parameters.caption.text.fontSize;
  const timelineInfos = autocutStoreVanilla().onGoingProcess.timelineInfos;

  if (!timelineInfos)
    throw new IncrementalError(
      'TimelineInfos not initialized',
      'resetFontSize',
    );
  if (fontSize !== defaultFontSize) return;

  const sequenceWidth = timelineInfos.settings.width;
  setAutocutStore('ui.parameters.caption.text.fontSize', sequenceWidth / 10);
};
