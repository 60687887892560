import type {TranslationKeys} from '@autocut/contexts/LocaleContext';
import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import {z as zod} from 'zod';

import {fontValidationSchema} from './font.validationSchema';
import {languageOfTranscriptionValidationSchema} from './languageOfTranscription.validationSchema';
import {rgbValidationSchema} from './rgb.validationSchema';
import {textBoxValidationSchema} from './textBox.validationSchema';

export type CaptionsPreset = {
  id: string;
  title: string;
  customCSS?: string;
  params: CaptionsParameters;
  usageCount: number;
  thumbnailUrl?: string;
  videoUrl?: string;
  isPublic?: boolean;
};

export const captionValidationSchema = zod.object({
  enableSoundPlay: zod.boolean().optional(),
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  utils: zod.object({
    hasClosedInfoText: zod.boolean().optional(),
  }),
  position: zod.object({
    xPercentage: zod
      .number()
      .min(
        0,
        'modes_caption_error_validation_position_xPercentage_min' as TranslationKeys,
      )
      .max(
        1,
        'modes_caption_error_validation_position_xPercentage_max' as TranslationKeys,
      )
      .default(0.5),
    yPercentage: zod
      .number()
      .min(
        0,
        'modes_caption_error_validation_position_yPercentage_min' as TranslationKeys,
      )
      .max(
        1,
        'modes_caption_error_validation_position_yPercentage_max' as TranslationKeys,
      )
      .default(0.75),
    emojiXPercentage: zod
      .number()
      .min(
        0,
        'modes_caption_error_validation_position_emojiXPercentage_min' as TranslationKeys,
      )
      .max(
        1,
        'modes_caption_error_validation_position_emojiXPercentage_max' as TranslationKeys,
      )
      .default(0.5),
    emojiYPercentage: zod
      .number()
      .min(
        0,
        'modes_caption_error_validation_position_emojiYPercentage_min' as TranslationKeys,
      )
      .max(
        1,
        'modes_caption_error_validation_position_emojiYPercentage_max' as TranslationKeys,
      )
      .default(0.5),
  }),
  text: zod.object({
    color: rgbValidationSchema,
    fontSize: zod
      .number()
      .int(
        'modes_caption_error_validation_text_fontSize_int' as TranslationKeys,
      )
      .min(
        1,
        'modes_caption_error_validation_text_fontSize_min' as TranslationKeys,
      )
      .default(104),
    font: fontValidationSchema,
    maxLineHeight: zod.number().int().optional(),
    maxLineDescentHeight: zod.number().int().optional(),
  }),
  animations: zod.object({
    enabled: zod.boolean().default(false),
    highlight: zod.object({
      enabled: zod.boolean().default(false),
      color: rgbValidationSchema,
      revealText: zod.object({
        enabled: zod.boolean().default(false),
        color: rgbValidationSchema,
      }),
    }),
    wordBox: textBoxValidationSchema,
    boldWithText: zod.boolean().default(false),
    floatingText: zod.boolean().default(false),
  }),
  outline: zod.object({
    enabled: zod.boolean().default(false),
    color: rgbValidationSchema,
    width: zod
      .number()
      .int(
        'modes_caption_error_validation_outline_width_int' as TranslationKeys,
      )
      .min(
        0,
        'modes_caption_error_validation_outline_width_min' as TranslationKeys,
      )
      .default(0),
  }),
  box: textBoxValidationSchema,
  transition: zod.object({
    enabled: zod.boolean(),
    effects: zod
      .array(
        zod.enum(['zoomIn', 'blurIn'], {
          invalid_type_error: 'validation_error_caption_transition_effects',
        }),
      )
      .default([]),
  }),
  formating: zod.object({
    uppercase: zod.boolean().default(false),
    italic: zod.boolean().default(false),
    nbLines: zod
      .number()
      .int(
        'modes_caption_error_validation_formating_nbLines_int' as TranslationKeys,
      )
      .min(
        1,
        'modes_caption_error_validation_formating_nbLines_min' as TranslationKeys,
      )
      .default(1),
    maxWidth: zod
      .number()
      .int(
        'modes_caption_error_validation_formating_maxWidth_int' as TranslationKeys,
      )
      .min(
        0,
        'modes_caption_error_validation_formating_maxWidth_min' as TranslationKeys,
      )
      .default(80),
    glow: zod.object({
      enabled: zod.boolean().default(false),
      intensity: zod.number().min(0).default(0),
      color: rgbValidationSchema,
    }),
    removePunctuation: zod.boolean().default(false),
  }),
  postProcessing: zod.object({
    formattedNumbers: zod.number().optional(),
  }),
});

export const captionPresetTitleSchema = zod
  .string()
  .min(
    5,
    'modes_caption_error_validation_userPresets_titleTooShort' as TranslationKeys,
  )
  .max(
    25,
    'modes_caption_error_validation_userPresets_titleTooLong' as TranslationKeys,
  )
  .refine(value => value.trim().includes(' '), {
    message:
      'modes_caption_error_validation_userPresets_titleNoSpace' as TranslationKeys,
  });
