import type {ComponentPropsWithoutRef} from 'react';

export type IconComponentProps = Omit<
  ComponentPropsWithoutRef<'svg'>,
  'xmlns'
> & {size: number};

export const TransitionIcon = ({
  size,
  color,
  children,
  className,
  strokeWidth,
  fill,
  ...props
}: IconComponentProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 51 45"
      className={className}
      height={`${(size as number) / 16}rem`}
      width={`${(size as number) / 16}rem`}
      color={color || '#5865F2'}
      {...props}
    >
      <path
        d="M20.3807 4.75798L3.38072 1.46163C2.1464 1.22229 1 2.16775 1 3.42506V41.3055C1 42.6472 2.2949 43.6083 3.57907 43.2199L20.5791 38.0775C21.4228 37.8223 22 37.0447 22 36.1632V6.72141C22 5.76364 21.321 4.9403 20.3807 4.75798Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <path
        d="M30.6193 4.75798L47.6193 1.46163C48.8536 1.22229 50 2.16775 50 3.42506V41.3055C50 42.6472 48.7051 43.6083 47.4209 43.2199L30.4209 38.0775C29.5772 37.8223 29 37.0447 29 36.1632V6.72141C29 5.76364 29.679 4.9403 30.6193 4.75798Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <rect
        x="13"
        y="11"
        width="26.5874"
        height="25.5264"
        rx="1.5"
        fill={fill}
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M22.2047 21.1818V19.2727H29.79V21.1818H27.165V28H24.8297V21.1818H22.2047Z"
        fill="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
