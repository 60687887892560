import {ModalType} from '@autocut/enums/modals.enum';

import {isKeyValid} from '../auth.utils';
import {logout} from '../license/logout';
import {addModalToQueue} from '../modal/addModalToQueue';
import {autocutStoreVanilla} from '../zustand/zustand';

export const checkPeriodicKey = async () => {
  const {
    user: {license, key},
  } = autocutStoreVanilla();
  if (!key) return;

  let keyValid = false;

  try {
    const keyCheckResponse = await isKeyValid(key);

    keyValid = keyCheckResponse.result;
  } catch (e) {
    //Nothing, the user will be logged out

    logout('API returned an error', true);
    return;
  }

  if (license && license.type === 'trial' && license.left === 0) {
    addModalToQueue(ModalType.TrialExpired);
  }

  if (!keyValid) logout('API returned invalid key response', true);
};
