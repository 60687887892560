import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconPlayCircle = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9.5 9 .1-.9A.5.5 0 0 1 10 8l.7.4 4.8 3 .5.5a.5.5 0 0 1 0 .4l-.5.5-4.8 3-.7.4a.5.5 0 0 1-.4-.2l-.1-.9V9Z"
      />
    </IconComponent>
  );
};
