import type {
  CaptionChunk,
  CaptionChunkHighlight,
} from '@autocut/types/Captions';

import {EditableText} from '@autocut/components/EditableText/EditableText';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {type CSSProperties, useRef} from 'react';
import {FiPlusCircle} from 'react-icons/fi';
import {FiTrash} from 'react-icons/fi';

import css from './EditableWord.module.scss';

export const EditableWord = ({
  highlight,
  onTextChange,
  onSplit,
  onAddWord,
  onRemoveWord,
  isLast,
  isRtl,
}: {
  chunk: CaptionChunk;
  highlight: CaptionChunkHighlight;
  onTextChange: (word: string) => void;
  onSplit: () => Promise<void>;
  onAddWord: () => Promise<void>;
  onRemoveWord: () => Promise<void>;
  isLast: boolean;
  isRtl: boolean;
}) => {
  const iconRef = useRef<HTMLDivElement>(null);

  const iconAnimationVariables = isRtl
    ? {
        '--margin-left': '6px',
        '--margin-right': '0',
      }
    : {
        '--margin-left': '0',
        '--margin-right': '6px',
      };

  return (
    <FlexContainer
      flexDirection={isRtl ? 'row-reverse' : 'row'}
      gap={4}
      alignItems="center"
      className={css.word}
    >
      <EditableText
        initialValue={highlight.word}
        onChange={newValue => onTextChange(newValue)}
        filteredCharacters={['\n', '\r']}
        style={
          highlight.word.length
            ? undefined
            : {
                border: '1px solid white',
                opacity: 0.3,
                borderRadius: '4px',
                minWidth: '20px',
              }
        }
      />

      {!isLast && (
        <Tooltip
          tooltipComponent={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_transcription_tooltips_split'
              }
              defaultMessage="Split chunks"
            />
          }
        >
          <div
            ref={iconRef}
            className={css.splitIcon}
            onClick={onSplit}
            style={iconAnimationVariables as CSSProperties}
          >
            /
          </div>
        </Tooltip>
      )}

      <div className={css.menu}>
        {highlight.word.length !== 0 && (
          <MenuOption
            text="Add word"
            icon={
              <FiPlusCircle
                size={16}
                color="currentColor"
              />
            }
            onClick={onAddWord}
          />
        )}
        <MenuOption
          text="Remove word"
          icon={
            <FiTrash
              size={16}
              color="currentColor"
            />
          }
          onClick={onRemoveWord}
        />
      </div>
    </FlexContainer>
  );
};

const MenuOption = ({
  text,
  icon,
  onClick,
}: {
  text: React.ReactNode;
  icon: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <FlexContainer
      className={css.option}
      flexDirection="row"
      flexWrap="nowrap"
      gap={4}
      alignItems="center"
      onClick={onClick}
    >
      <div
        style={{
          width: '20px',
          height: '20px',
        }}
      >
        {icon}
      </div>
      {text}
    </FlexContainer>
  );
};
