import {formatVersion} from '@autocut/components/VersionDisplay/VersionDisplay';
import {GET_MAC_ID_COMMAND} from '@autocut/constants/constants';
import logLevel from '@autocut/enums/logLevel.enum';
import {SentryFingerPrintEnum} from '@autocut/enums/sentryFingerPrint.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {getHostName} from '@autocut/utils/system/hostname.system.utils';
import {getOS} from '@autocut/utils/system/os.system.utils';
import {getUUID} from '@autocut/utils/system/uuid.system.utils';

import {exec} from '../exec.utils';
import {host} from '../host';
import {autocutApi} from '../http.utils';
import {logger} from '../logger';
import {autocutStoreVanilla} from '../zustand/zustand';

export const askForTrialKey = async (email: string, locale: string) => {
  const uuid = await getUUID();
  if (uuid === undefined) {
    logger('askForTrialKey', logLevel.error, "Can't read the computer's UUID");

    throw new Error('cant_read_uuid_error');
  }

  let pcName = await getHostName();

  const platform = preload.os.platform();
  if (platform === 'darwin') {
    const {stdout} = await exec({
      command: GET_MAC_ID_COMMAND,
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.GET_MAC_ID,
        context: {shellCommand: GET_MAC_ID_COMMAND},
      },
    });
    pcName = stdout.trim();
  }

  if (pcName === undefined) {
    logger('askForTrialKey', logLevel.error, "Can't read the computer's name.");
    throw new Error('cant_read_host_error');
  }

  const autocutStore = autocutStoreVanilla();

  const params = {
    email: email,
    language: locale,
    pc_uuid: uuid,
    pc_name: pcName,
    os: getOS(),
    software: autocutStore.ui.host,
    hostVersion: (await host.misc.hostVersion()).join('.'),
    autocutVersion: formatVersion(autocutStore.ui),
  };

  logger(
    'askForTrialKey',
    logLevel.notice,
    'Asking server for new Trial key.',
    {
      params,
    },
  );
  const {data} = await autocutApi.get(`/keys/generate-trial-key`, {
    params,
  });

  if (data === undefined) {
    logger('askForTrialKey', logLevel.error, "Can't reach the server.");
    throw new Error('cant_reach_server_error');
  }

  if (data.message === 'OK' || data.message === 'OK2') {
    logger('askForTrialKey', logLevel.notice, 'Server has generated a key.');

    return true;
  } else {
    logger(
      'askForTrialKey',
      logLevel.warn,
      'Server has not generated the key',
      {data},
    );

    throw new Error(data.message);
  }
};
