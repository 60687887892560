import type {HTMLAttributes} from 'react';

import {range} from '@autocut/utils/math.utils';
import * as React from 'react';

import {Text} from '../../Text/Text';
import css from './dualSlider.module.css';

export type DualSliderProps = {
  min: number;
  max: number;
  lowerValue: number;
  upperValue: number;
  onChange: (lowerValue: number, upperValue: number) => void;
  formatValue?: (value: number) => string;
} & Omit<HTMLAttributes<HTMLInputElement>, 'min' | 'max' | 'onChange'>;

export const DualSlider = ({
  max,
  min,
  lowerValue,
  upperValue,
  onChange,
  formatValue,
  ...props
}: DualSliderProps) => {
  const lowerRef = React.useRef<HTMLInputElement | null>(null);
  const upperRef = React.useRef<HTMLInputElement | null>(null);
  const fillRef = React.useRef<HTMLDivElement | null>(null);
  const lowerCustomThumb = React.useRef<HTMLDivElement | null>(null);
  const upperCustomThumb = React.useRef<HTMLDivElement | null>(null);
  const lowerValueLabelRef = React.useRef<HTMLParagraphElement | null>(null);
  const upperValueLabelRef = React.useRef<HTMLParagraphElement | null>(null);

  React.useEffect(() => {
    onInputChange();
  }, [lowerValue, upperValue]);

  const onInputChange = () => {
    if (!lowerRef.current || !upperRef.current) return;

    let valUpper = parseFloat(upperRef.current.value);
    let valLower = parseFloat(lowerRef.current.value);

    if (valLower > valUpper) {
      const trade = valLower;
      valLower = valUpper;
      valUpper = trade;
    }

    const right = range(min, max, 0, 100, valUpper);
    const left = range(min, max, 0, 100, valLower);

    updateFill(left, right);
    updateCustomThumb(left, right);
    updateLabels(valLower, valUpper, left, right);
    onChange(valLower, valUpper);
  };

  const updateCustomThumb = (left: number, right: number) => {
    if (!lowerCustomThumb.current || !upperCustomThumb.current) return;

    lowerCustomThumb.current.style.left = `${left}%`;
    upperCustomThumb.current.style.left = `${right}%`;
  };

  const updateLabels = (
    valLower: number,
    valUpper: number,
    left: number,
    right: number,
  ) => {
    if (!lowerValueLabelRef.current || !upperValueLabelRef.current) return;

    lowerValueLabelRef.current.innerHTML = formatValue
      ? formatValue(valLower)
      : valLower.toString();
    upperValueLabelRef.current.innerHTML = formatValue
      ? formatValue(valUpper)
      : valLower.toString();
    lowerValueLabelRef.current.style.left = `${left}%`;
    upperValueLabelRef.current.style.left = `${right}%`;
  };

  const updateFill = (left: number, right: number) => {
    if (!fillRef.current) return;

    fillRef.current.style.left = `${left}%`;
    fillRef.current.style.width = `${right - left}%`;
  };

  return (
    <div className={css.container}>
      <div className={css.inputDualRange}>
        <input
          type="range"
          ref={lowerRef}
          onInput={onInputChange}
          className={`${css.range}`}
          min={min}
          max={max}
          value={lowerValue}
          {...props}
        />
        <input
          type="range"
          ref={upperRef}
          onInput={onInputChange}
          className={`${css.range}`}
          min={min}
          max={max}
          value={upperValue}
          {...props}
        />
        <div
          ref={fillRef}
          className={css.fill}
        ></div>
        <div className={css.customThumbsContainer}>
          <div
            ref={lowerCustomThumb}
            className={css.customThumb}
          />
          <div
            ref={upperCustomThumb}
            className={css.customThumb}
          />
        </div>
        {formatValue && (
          <div className={css.info}>
            <Text
              ref={lowerValueLabelRef}
              className={css.value}
              variant="textXs"
              color="white"
            >
              {formatValue ? formatValue(lowerValue) : lowerValue}
            </Text>
            <Text
              ref={upperValueLabelRef}
              className={css.value}
              variant="textXs"
              color="white"
            >
              {formatValue ? formatValue(upperValue) : upperValue}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
