import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Badge} from '@autocut/designSystem/components/atoms/Badge/Badge';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {type Preset} from '@autocut/types/Preset';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';

const podcastPresets: Preset<'podcast'>[] = [
  {
    id: 'calm',
    parameters: {
      minimumCamTime: 5,
      maximumCamTime: 60,
    },
  },
  {
    id: 'paced',
    parameters: {
      minimumCamTime: 4,
      maximumCamTime: 30,
    },
  },
  {
    id: 'energetic',
    parameters: {
      minimumCamTime: 3,
      maximumCamTime: 20,
    },
  },
  {
    id: 'hyperactive',
    parameters: {
      minimumCamTime: 1,
      maximumCamTime: 10,
    },
  },
];

export const CameraTimeStep = () => {
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast,
  );

  const applyPreset = (preset: Preset<'podcast'>) => {
    const updatedParameters = {
      ...podcastParameters,
      ...preset.parameters,
    };
    setAutocutStore('ui.parameters.podcast', updatedParameters);
  };

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="modes_podcast_steps_customization_steps_cameraTime_title"
          defaultMessage="Camera Angle Switch Settings"
        />
      }
      description={
        <TranslatedMessage
          id="modes_podcast_steps_customization_steps_cameraTime_description"
          defaultMessage="Adjust how the camera angles automatically switches between video tracks."
        />
      }
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s4}
        >
          <FormSection
            variant="secondary"
            title={
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_reactionShots_title"
                defaultMessage="Reaction Shots"
              />
            }
            description={
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_reactionShots_description"
                defaultMessage="When enabled, the system occasionally shows wide shot or participants who are not talking to add variety and a more natural feel. If not enable, there is no maximum shot duration."
              />
            }
          >
            <CheckBox
              size={20}
              variant="switch"
              onChange={value =>
                setAutocutStore(
                  'ui.parameters.podcast.enableReactionShots',
                  value,
                )
              }
              checked={podcastParameters.enableReactionShots}
            />
          </FormSection>
          <FormSection
            variant="secondary"
            title={
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_shotsDurations_title"
                defaultMessage="Shots Durations"
              />
            }
            description={
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_shotsDurations_description"
                defaultMessage="Choose how quickly the camera angles switches, from Calm (longer shots) to Hyperactive (rapid shots)."
              />
            }
          >
            <FlexContainer
              flexDirection="row"
              gap={Spacing.s2}
            >
              {podcastPresets.map(preset => (
                <Badge
                  hoverable={true}
                  onClick={() => applyPreset(preset)}
                  key={preset.id}
                >
                  <TranslatedMessage
                    id={
                      `modes_podcast_steps_customization_steps_cameraTime_shotsDurations_presets_items_${preset.id}` as TranslationKeys
                    }
                    defaultMessage="PRESET"
                  />
                </Badge>
              ))}
            </FlexContainer>
            <Slider
              variant="valueOnKnob"
              formatValue={value => `${value}s`}
              value={
                podcastParameters.enableReactionShots
                  ? [
                      podcastParameters.minimumCamTime,
                      podcastParameters.maximumCamTime,
                    ]
                  : podcastParameters.minimumCamTime
              }
              accentColor={colors.primary600}
              accentColorSide={
                podcastParameters.enableReactionShots ? 'left' : 'right'
              }
              onChange={(values: number | number[]) => {
                if (!Array.isArray(values))
                  setAutocutStore(
                    'ui.parameters.podcast.minimumCamTime',
                    values,
                  );
                else {
                  setAutocutStore(
                    'ui.parameters.podcast.maximumCamTime',
                    values[1],
                  );
                  setAutocutStore(
                    'ui.parameters.podcast.minimumCamTime',
                    values[0],
                  );
                }
              }}
              min={1}
              max={Math.max(100, podcastParameters.maximumCamTime + 10)}
            />
          </FormSection>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
