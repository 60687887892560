import type {Progress} from '@autocut/types/Progress';
import type {TrialTask} from '@autocut/types/TrialTask';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {GenerateZustandKeys} from '@autocut/utils/zustand/zustand';

import {defaultTrialTasks} from '@autocut/utils/game/trialGamfication.util';
import {
  syncToCookies,
  type ZustandSyncConfig,
} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_Game = 'game';

export type ZType_Game = {
  level: {
    currentProgress: Progress;
    xpGained: number;
    levelupAnimation?: {to: Progress; from: Progress};
    levelupAnimationQueued?: {to: Progress; from: Progress};
  };
  trial: {
    tasks: TrialTask[] | readonly TrialTask[];
    displayModal: boolean;
  };
};

export type ZKeys_Game = GenerateZustandKeys<ZBaseKey_Game, ZType_Game>;

export const zDefaultValues_Game: ZType_Game = {
  level: {
    currentProgress: {
      xp: 0,
      level: 0,
    },
    xpGained: 0,
    levelupAnimation: undefined,
    levelupAnimationQueued: undefined,
  },
  trial: {tasks: defaultTrialTasks, displayModal: false},
};

export const zSync_Game: ZustandSyncConfig<ZKeys_Game> = {
  'game.trial.tasks': {
    cookieName: 'trialTasks',
    defaultValue: defaultTrialTasks,
  },
};

export const zSubscribers_Game: AutoCutStateSubscriberConfig<ZKeys_Game> = {
  'game.trial.tasks': k => syncToCookies(k),
};
