import Modal from '@autocut/components/ModalLayout/BaseModal';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconInfoSquare} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoSquare';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {preload} from '@autocut/types/ElectronPreload';

export const ElectronVersionModal = () => {
  const {
    electron: {openLinkInBrowser},
  } = preload;
  const {title, description, ctaLink, ctaText} = useAutoCutStore(
    state => state.modal.data.electronVersionModal,
  );

  return (
    <Modal
      title={title}
      isBlocking
      icon={
        <IconInfoSquare
          size={24}
          color="white"
        />
      }
      footer={
        <Button
          color={colors.primary600}
          onClick={() => openLinkInBrowser(ctaLink)}
        >
          {ctaText}
        </Button>
      }
    >
      {description}
    </Modal>
  );
};
