import type {AutoCutState} from '@autocut/utils/zustand/zustand';
import type React from 'react';

import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {useMemo} from 'react';

import css from './VersionDisplay.module.scss';

type VersionDisplayProps = React.HTMLAttributes<HTMLParagraphElement>;

const PART_CONFIG = {
  aea: {addHost: false, color: '#c1f3fb'},
  front: {addHost: false, color: '#F4B29A'},
  compute: {addHost: false, color: '#ffd533'},
  hostClient: {addHost: true, color: '#BDBDFF'},
  hostServer: {addHost: false, color: '#f2d1e2'},
} as const satisfies Record<
  Exclude<keyof AutoCutState['ui']['versions'], 'host'>,
  {addHost: boolean; color: string}
>;

const compressVersion = (version: string) =>
  (version || '').replace('v', '').split('.').join('');

export const formatVersion = (ui: AutoCutState['ui']) =>
  `${compressVersion(ui.versions.aea)}.${compressVersion(ui.versions.front)}.${compressVersion(ui.versions.compute)}.${ui.host[0]}-${compressVersion(ui.versions.hostClient)}.${compressVersion(ui.versions.hostServer)}`;

export const VersionDisplay: React.FC<VersionDisplayProps> = props => {
  const {versions, host} = useAutoCutStore(state => ({
    versions: state.ui.versions,
    host: state.ui.host,
  }));

  const versionParts = useMemo(
    () =>
      Object.keys(versions)
        .map(key => {
          if (key === 'host') return;
          const config = PART_CONFIG[key as keyof typeof PART_CONFIG];
          const versionStr = compressVersion(
            versions[
              key as Exclude<keyof AutoCutState['ui']['versions'], 'host'>
            ],
          );
          const prefix = config.addHost ? `${host[0] || 'h'}-` : '';
          return (
            <span
              key={key}
              style={
                CURRENT_ENV !== Env.Production
                  ? {
                      backgroundColor: config.color,
                      padding: '2px 4px',
                      borderRadius: '4px',
                      color: 'var(--extension-bg-color)',
                    }
                  : undefined
              }
            >
              {prefix}
              {versionStr}
            </span>
          );
        })
        .filter(Boolean),
    [versions, host],
  );

  return (
    <p
      className={css.applicationVersion}
      {...props}
    >
      <i>
        v
        {versionParts.map((part, index) => (
          <>
            {part}
            {index < versionParts.length - 1 ? '.' : ''}
          </>
        ))}
      </i>
    </p>
  );
};
