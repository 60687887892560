export enum ModalType {
  'CancelCut' = 'Undo',
  'ContactUs' = 'Contact',
  'FeatureRequest' = 'Feature',
  'ReportIssue' = 'Logfile',
  'Review' = 'Review',
  'ExchangeRate' = 'Exchange',
  'Feedback' = 'Feedback',
  'TrialExpired' = 'TrialExpired',
  'EndProcess' = 'EndProcess',
  'EndCaptionsProcess' = 'EndCaptionsProcess',
  'Error' = 'Error',
  'BRollCredits' = 'BRollCredits',
  'CreateResizePreset' = 'CreateResizePreset',
  'StartUp' = 'StartUp',
  'UsageLimit' = 'UsageLimit',
  'Processing' = 'ProcessModal',
  'Onboarding' = 'Onboarding',
  'KeyExpired' = 'KeyExpired',
  'GamingTrial' = 'GamingTrial',
  'UnsupportedVersion' = 'UnsupportedVersion',
  'FormattedNumber' = 'FormattedNumber',
  'DeepgramError' = 'DeepgramError',
  'SearchPreset' = 'SearchPreset',
  'DeletePresets' = 'DeletePresets',
  'GenerateEmojis' = 'GenerateEmojis',
  'CreatePresetModal' = 'CreatePresetModal',
  'UsageStats' = 'UsageStats',
  'FontNotFound' = 'FontNotFound',
  'BetaOnboarding' = 'BetaOnboarding',
  'BetaFeedback' = 'BetaFeedback',
  'NoLink' = 'NoLink',
  'NotEnoughTracks' = 'NotEnoughTracks',
  'AppStoreDv' = 'AppStoreDv',
  'ManageBleeps' = 'ManageBleeps',
  'ElectronVersionModal' = 'ElectronVersionModal',
  'LaunchError' = 'LaunchError',
  'UpdateElectron' = 'UpdateElectron',
  'UpdateHost' = 'UpdateHost',
  'UpdateTimeout' = 'UpdateTimeout',
  'ManageSavedSwearWords' = 'ManageSavedSwearWords',
  'PProExtensionRenamed' = 'PProExtensionRenamed',
  'RestartPremierePro' = 'RestartPremierePro',
  'ManualUpdate' = 'ManualUpdate',
}
