import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconLogOut01 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 17 5-5m0 0-5-5m5 5H9m0-9H7.8c-1.7 0-2.5 0-3.2.3a3 3 0 0 0-1.3 1.3C3 5.3 3 6.1 3 7.8v8.4c0 1.7 0 2.5.3 3.2a3 3 0 0 0 1.3 1.3c.7.3 1.5.3 3.2.3H9"
      />
    </IconComponent>
  );
};
