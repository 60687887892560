import type {AutocutModeIds} from '@autocut/enums/modes.enum';

import {FooterLayout} from '@autocut/components/FooterLayout';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {getTimelineInfosHash} from '@autocut/pages/modes/ModeWrapper';
import {compute} from '@autocut/utils/compute.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useEffect, useState} from 'react';

import {SetSectionsStep} from './parts/SetSectionsStep/SetSectionsStep';
import {resetSelectedSections} from './sections.utils';
import css from './SectionsSelectionScreen.module.scss';

type SelectionScreenProps = {
  mode: AutocutModeIds;
  onValidateSections: () => void;
};

export const SectionsSelectionScreen = ({
  mode,
  onValidateSections: onValidateSections,
}: SelectionScreenProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedSections,
    deactivatedTrackIndexes,
    timelineInfos,
    isSelectionValid,
  } = useAutoCutStore(state => ({
    selectedSections: state.ui.selectedSections,
    deactivatedTrackIndexes: state.ui.deactivatedTrackIndexes,
    timelineInfos: state.onGoingProcess.timelineInfos,
    isSelectionValid: state.onGoingProcess.isSelectionValid,
  }));

  const numberOfTracks = timelineInfos?.audioTracks.length ?? 0;
  const isAllTrackDeactivated =
    !isLoading &&
    !!selectedSections.length &&
    deactivatedTrackIndexes.length === numberOfTracks;

  const init = async () => {
    const timelineInfos = await compute.timeline.getInfos();
    const newTimelineInfosHash = getTimelineInfosHash(timelineInfos);
    setAutocutStore('onGoingProcess.timelineInfos', timelineInfos);
    setAutocutStore('onGoingProcess.timelineInfosHash', newTimelineInfosHash);
    setAutocutStore('onGoingProcess.timelineId', timelineInfos.id);
    setAutocutStore('ui.selectedSections', []);
    setAutocutStore('ui.deactivatedTrackIndexes', []);
    setIsLoading(false);
  };

  useEffect(() => {
    resetSelectedSections();
    void init();
  }, []);

  return (
    <ModeLayout
      footer={
        <FooterLayout>
          <FlexContainer
            alignItems="center"
            flexDirection="column"
            gap={Spacing.s2}
          >
            {selectedSections.length === 0 && (
              <Text
                variant="textXs.medium"
                color={colors.error500}
              >
                <TranslatedMessage
                  id="modes_general_error_validation_sections_noSelection"
                  defaultMessage={'Please update your audio sections'}
                />
              </Text>
            )}

            {isAllTrackDeactivated && (
              <Text
                variant="textXs.medium"
                color={colors.error500}
              >
                <TranslatedMessage
                  id="modes_general_steps_transcription_noTrackSelected"
                  defaultMessage={'No track selected'}
                />
              </Text>
            )}

            {!isSelectionValid && !isAllTrackDeactivated && (
              <Text
                variant="textXs.medium"
                color={colors.error500}
              >
                <TranslatedMessage
                  id="modes_general_error_validation_sections_invalidSelection"
                  defaultMessage={'No audio clip found in your selection'}
                />
              </Text>
            )}

            <Button
              disabled={
                selectedSections.length === 0 ||
                !isSelectionValid ||
                isAllTrackDeactivated
              }
              isLoading={isLoading}
              onClick={onValidateSections}
              color={colors.primary600}
            >
              <TranslatedMessage
                id="modes_general_steps_select_cta"
                defaultMessage={'Validate markers'}
              />
            </Button>
          </FlexContainer>
        </FooterLayout>
      }
    >
      <FlexContainer
        flexDirection="column"
        justifyContent="space-between"
        gap={Spacing.s6}
        className={css.container}
      >
        <FlexContainer
          flexDirection="column"
          gap={16}
        >
          <SetSectionsStep mode={mode} />
          <Divider />
        </FlexContainer>
      </FlexContainer>
    </ModeLayout>
  );
};
