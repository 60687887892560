import type * as React from 'react';

import css from './ModeLayout.module.css';

type ModeLayoutProps = {
  children: React.ReactNode | JSX.Element | React.ReactNode[] | JSX.Element[];
  footer?: React.ReactNode | JSX.Element | React.ReactNode[] | JSX.Element[];
};

export const ModeLayout = ({children, footer}: ModeLayoutProps) => {
  return (
    <div className={css.root}>
      <div className={css.content}>{children}</div>
      {!!footer && <div className={css.footer}>{footer}</div>}
    </div>
  );
};
