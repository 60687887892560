import type {CaptionChunk} from '@autocut/types/Captions';
import type {CSSProperties} from 'react';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import {useRef} from 'react';
import {BsDot} from 'react-icons/bs';

import {Emoji} from '../EmojiSelector/EmojiPicker/EmojiPicker';
import css from './Chunk.module.scss';

const CAPTIONS_CONFIDENCE_THRESHOLD = 0.6;

export const Chunk = ({
  chunk,
  onSelect,
  onMerge,
  selected,
  isRtl,
  isLast,
}: {
  chunk: CaptionChunk;
  onSelect: () => Promise<void>;
  onMerge: () => void;
  selected: boolean;
  isRtl: boolean;
  isLast: boolean;
}) => {
  const iconRef = useRef<HTMLDivElement>(null);

  const classNames = [css.text];

  const isLowConfidence = chunk.highlight.some(
    highlight => highlight.confidence < CAPTIONS_CONFIDENCE_THRESHOLD,
  );

  if (isLowConfidence) classNames.push(css.lowConfidence);

  if (selected) classNames.push(css.selected);

  if (chunk.deleted) classNames.push(css.deleted);

  const textStyle: any = chunk.text.trim().length
    ? undefined
    : {
        border: '1px solid white',
        opacity: 0.3,
        borderRadius: '4px',
        minWidth: '20px',
        height: '100%',
      };

  const iconAnimationVariables = isRtl
    ? {
        '--margin-left': '-6px',
        '--margin-right': '0',
        justifyContent: 'flex-end',
      }
    : {
        '--margin-left': '0',
        '--margin-right': '-6px',
        justifyContent: 'flex-start',
      };

  return (
    <FlexContainer
      flexDirection={isRtl ? 'row-reverse' : 'row'}
      style={{
        textAlign: isRtl ? 'right' : 'left',
      }}
      alignItems="center"
      flexWrap="nowrap"
      gap={0}
      className={css.chunk}
    >
      <ConditionalWrap
        condition={isLowConfidence}
        wrap={children => (
          <Tooltip
            variant="warning"
            tooltipComponent={
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_transcription_tooltips_lowConfidence'
                }
                defaultMessage="Low confidence words"
              />
            }
          >
            {children}
          </Tooltip>
        )}
      >
        <FlexContainer
          flexDirection={isRtl ? 'row-reverse' : 'row'}
          alignItems="center"
          flexWrap="nowrap"
          gap={4}
          onClick={onSelect}
          className={classNames.join(' ')}
          style={textStyle}
        >
          {chunk.text}
          {chunk.emoji && chunk.emojiUrl ? (
            <Emoji
              emoji={{
                name: chunk.emoji,
                url: chunk.emojiUrl,
              }}
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          ) : null}
        </FlexContainer>
      </ConditionalWrap>
      {!isLast && (
        <Tooltip
          tooltipComponent={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_transcription_tooltips_merge'
              }
              defaultMessage="Merge chunks"
            />
          }
        >
          <div
            ref={iconRef}
            className={css.mergeIcon}
            onClick={onMerge}
            style={iconAnimationVariables as CSSProperties}
          >
            <BsDot strokeWidth="4px" />
          </div>
        </Tooltip>
      )}
    </FlexContainer>
  );
};
