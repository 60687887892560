import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {RadioForm} from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';

const RADIO_FORM_OPTIONS = (currentOption: 'remove' | 'disable') => [
  {
    name: 'disable',
    title: (
      <TranslatedMessage
        id="modes_podcast_steps_customization_steps_silentVideoClips_items_disable"
        defaultMessage="Disable"
      />
    ),
    value: false,
    defaultChecked: currentOption === 'disable',
  },
  {
    name: 'remove',
    title: (
      <TranslatedMessage
        id="modes_podcast_steps_customization_steps_silentVideoClips_items_remove"
        defaultMessage="Remove"
      />
    ),
    value: true,
    defaultChecked: currentOption === 'remove',
  },
];

export const SilentVideoClipsStep = () => {
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast,
  );

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="modes_podcast_steps_customization_steps_silentVideoClips_title"
          defaultMessage="Silent video clips"
        />
      }
      description={
        <TranslatedMessage
          id="modes_podcast_steps_customization_steps_silentVideoClips_description"
          defaultMessage="Choose whether you want to remove or to disable the unused parts of the podcast."
        />
      }
    >
      <PaddedSection>
        <RadioForm
          onChange={value =>
            setAutocutStore('ui.parameters.podcast.deleteUnusedClips', value)
          }
          options={RADIO_FORM_OPTIONS(
            podcastParameters.deleteUnusedClips ? 'remove' : 'disable',
          )}
          flexDirection="column"
        />
      </PaddedSection>
    </FormSection>
  );
};
