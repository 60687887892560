import {InfoText} from '@autocut/components/InfoText/InfoText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {closeModal} from '@autocut/utils/modal/closeModal';

export const RestartPremiereModal = () => {
  return (
    <Modal
      isBlocking
      title={
        <Text
          variant="displaySm.bold"
          color="white"
          style={{
            marginBottom: Spacing.s4,
          }}
        >
          <TranslatedMessage id="modals_restartPremiere_title" />
        </Text>
      }
      footer={
        <FlexContainer
          justifyContent="space-evenly"
          gap={Spacing.s4}
        >
          <Button
            variant="primary"
            color={colors.primary600}
            onClick={() => {
              closeModal();
            }}
            fullWidth
          >
            <TranslatedMessage id="modals_restartPremiere_buttons_ppro" />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={32}
      >
        <Text
          variant="textSm.medium"
          color={colors.gray300}
        >
          <TranslatedMessage id="modals_restartPremiere_body" />
        </Text>

        <InfoText style="warning">
          <TranslatedMessage id="modals_restartPremiere_warning" />
        </InfoText>
      </FlexContainer>
    </Modal>
  );
};
