import {autocutStoreVanilla} from '@autocut/utils/zustand/zustand';

import {type Camera} from './CamerasStep';

export const initCameras = () => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  const cameras: Camera[] = [];

  for (const [_, speaker] of speakers.entries()) {
    const speakerVideoTracks = speaker.videoTracks;

    for (const videoTrackId of speakerVideoTracks) {
      const cameraIndex = cameras.findIndex(
        camera => camera.videoTrack === videoTrackId,
      );

      if (cameraIndex === -1) {
        cameras.push({
          videoTrack: videoTrackId,
          speakers: [
            {name: speaker.name, audioTrack: speaker.audioTrack as number},
          ],
        });
      } else {
        cameras[cameraIndex].speakers.push({
          name: speaker.name,
          audioTrack: speaker.audioTrack as number,
        });
      }
    }
  }

  if (cameras.length === 0) {
    cameras.push({videoTrack: undefined, speakers: []});
  }

  return cameras;
};
