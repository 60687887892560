import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {TranscriptLanguage} from '@autocut/pages/TranscriptLanguage';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {ViralClipsSelector} from './selector/ViralClipsSelector';

export const ViralClipsMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AutocutModes.ViralClips.icon}
      text={AutocutModes.ViralClips.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/viral_clips"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            clickable: true,
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="viral_clips"
                onValidateSections={() => navigate('./language')}
              />
            ),
          },
          {
            clickable: false,
            label: intl.formatMessage({
              id: 'modes_general_steps_transcription_title',
              defaultMessage: 'Select Audio Clip Language',
            }),
            path: 'language',
            element: <TranscriptLanguage mode="viral_clips" />,
          },
          {
            label: intl.formatMessage({
              id: 'modes_viralClips_stepSelector',
              defaultMessage: 'Select viral clips',
            }),
            path: 'selector',
            element: <ViralClipsSelector />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
