import type {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';

import {preload, waitForPreload} from '@autocut/types/ElectronPreload';
import {initApi} from '@autocut/utils/http.utils';
import {initJuly} from '@autocut/utils/stats.utils';
import {syncAllStoreFromCookies} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';

export const preloadWait: LaunchStepHandler = async (
  setProgress,
): Promise<boolean> => {
  await waitForPreload('preloadWait setup');
  await setProgress?.(100);

  initApi();
  try {
    const logFilePath =
      preload.electron
        .getSharedVariables?.()
        ?.LOG_FILE_PATH?.replace(/\.log$/, '.front.log') || '';
    preload.logger.init(logFilePath);
    console.log = preload.logger.log;

    const aeaVersion = await preload.electron.getVersion();
    setAutocutStore('ui.versions.aea', aeaVersion);
    await syncAllStoreFromCookies();

    await initJuly();
  } catch (error) {
    console.error(error);
  }
  return !!preload.os;
};
