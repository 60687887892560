import type {ModeTitleProps} from '@autocut/components/ModeTitle/ModeTitle';
import type {JSONTimeline} from '@autocut/types/JSONTimeline';
import type {ReactNode} from 'react';
import type {IconType} from 'react-icons';

import {ModeTitle} from '@autocut/components/ModeTitle/ModeTitle';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {NotificationContext} from '@autocut/contexts/NotificationProvider.tsx/NotificationProvider';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {compute} from '@autocut/utils/compute.utils';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import hash from 'object-hash';
import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export type ModeWrapperProps = {
  Icon?: IconType;
  text: string;
  children: ReactNode;
  customHomeButtonHandler?: ModeTitleProps['customHomeButtonHandler'];
};

export const getTimelineInfosHash = (timelineInfos: JSONTimeline) => {
  return hash(timelineInfos.audioTracks);
};

export const ModeWrapper = ({
  Icon,
  text,
  children,
  customHomeButtonHandler,
}: ModeWrapperProps) => {
  const {displayNotification} = useContext(NotificationContext);
  const {deactivatedTrackIndexes, timelineInfos, isProcessing} =
    useAutoCutStore(state => ({
      deactivatedTrackIndexes: state.ui.deactivatedTrackIndexes,
      timelineInfos: state.onGoingProcess.timelineInfos,
      isProcessing: state.ui.process.isProcessing,
    }));
  const navigate = useNavigate();

  useEffect(() => {
    const onFocus = async () => {
      if (isProcessing || !timelineInfos?.id) return;

      const newTimelineInfos = await compute.timeline.getInfos();
      // We get directly from autocutStoreVanilla to avoid errors due to component re-rendering
      const currentTimelineInfosHash =
        autocutStoreVanilla().onGoingProcess.timelineInfosHash;
      const newTimelineInfosHash = getTimelineInfosHash(newTimelineInfos);

      if (currentTimelineInfosHash !== newTimelineInfosHash) {
        displayNotification(
          <Text color="white">
            <TranslatedMessage
              id="modes_general_steps_select_reset"
              defaultMessage={'Timeline modified, please reselect'}
            />
          </Text>,
        );
        setAutocutStore('onGoingProcess.timelineInfos', newTimelineInfos);
        setAutocutStore(
          'onGoingProcess.timelineInfosHash',
          newTimelineInfosHash,
        );
        setAutocutStore('onGoingProcess.timelineId', newTimelineInfos.id);
        setAutocutStore('ui.selectedSections', []);
        setAutocutStore('ui.deactivatedTrackIndexes', []);
        if (window.location.pathname !== '/homepage') {
          navigate('./selection');
        }
      }
    };

    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [
    deactivatedTrackIndexes,
    displayNotification,
    isProcessing,
    navigate,
    timelineInfos,
  ]);

  return (
    <>
      <ModeTitle
        Icon={Icon}
        text={text}
        customHomeButtonHandler={customHomeButtonHandler}
      />
      <div className="dashboardContainer">{children}</div>
    </>
  );
};
