import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {usePreviewFrames} from '@autocut/hooks/usePreviewFrames';
import {parseTimecodeFromString} from '@autocut/utils/time.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {nanoid} from 'nanoid';
import {useState} from 'react';
import {useIntl} from 'react-intl';

import {TimecodesStepItem} from './Item/TimecodesStepItem';

export const TimecodesStep = () => {
  const intl = useIntl();
  const chapters = useAutoCutStore(state => state.onGoingProcess.chapters);

  const {previewFrames, setPreviewFrames, updatePreviewFrame} =
    usePreviewFrames((chapters || []).map(chapter => chapter.timecodes));

  const [keyPrefix, setKeyPrefix] = useState(nanoid());

  const updateKeyPrefix = () => setKeyPrefix(nanoid());

  const handleUpdate = async (chapter: Chapter, index: number) => {
    if (!chapters) return;

    chapter.timecodes[0] = parseTimecodeFromString(chapter.timecodes[0]);
    chapter.timecodes[1] = parseTimecodeFromString(chapter.timecodes[1]);

    if (chapter.timecodes[0] !== chapters[index].timecodes[0]) {
      await updatePreviewFrame(chapter.timecodes, index);
    }

    const newChapters = [...chapters];
    newChapters[index] = chapter;

    setAutocutStore('onGoingProcess.chapters', newChapters);
  };

  const handleDelete = (index: number) => {
    if (!chapters) return;

    const newChapters = [...chapters];
    newChapters.splice(index, 1);
    const newPreviewFrames = [...previewFrames];
    newPreviewFrames.splice(index, 1);

    setPreviewFrames(newPreviewFrames);
    setAutocutStore('onGoingProcess.chapters', newChapters);
    updateKeyPrefix();
  };

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_chapters_steps_editChapters_title',
        defaultMessage: 'Edit Chapters',
      })}
      description={intl.formatMessage({
        id: 'modes_chapters_steps_editChapters_description',
        defaultMessage:
          'Edit the chapters of your video, you can change the timecodes and titles.',
      })}
    >
      {chapters?.map((chapter, index) => (
        <TimecodesStepItem
          key={`${keyPrefix}-${index}`}
          chapter={chapter}
          previewFrame={previewFrames[index]}
          onChange={chapter => handleUpdate(chapter, index)}
          onDelete={() => handleDelete(index)}
        />
      ))}
    </FormSection>
  );
};
