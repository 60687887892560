import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import {z as zod} from 'zod';

import {rgbValidationSchema} from './rgb.validationSchema';

export const textBoxValidationSchema = zod.object({
  enabled: zod.boolean().default(false),
  xPadding: zod
    .number()
    .int(
      'modes_caption_error_validation_textBox_xPadding_int' as TranslationKeys,
    )
    .min(
      0,
      'modes_caption_error_validation_textBox_xPadding_min' as TranslationKeys,
    )
    .default(0),
  yPadding: zod
    .number()
    .int(
      'modes_caption_error_validation_textBox_yPadding_int' as TranslationKeys,
    )
    .min(
      0,
      'modes_caption_error_validation_textBox_yPadding_min' as TranslationKeys,
    )
    .default(0),
  opacity: zod
    .number()
    .int(
      'modes_caption_error_validation_textBox_opacity_int' as TranslationKeys,
    )
    .min(
      0,
      'modes_caption_error_validation_textBox_opacity_min' as TranslationKeys,
    )
    .max(
      100,
      'modes_caption_error_validation_textBox_opacity_max' as TranslationKeys,
    )
    .default(75),
  color: rgbValidationSchema,
  radius: zod
    .number()
    .int('modes_caption_error_validation_textBox_radius_int' as TranslationKeys)
    .min(0, 'modes_caption_error_validation_textBox_radius' as TranslationKeys)
    .default(0),
});
