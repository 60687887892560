import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import {z as zod} from 'zod';

export const resizePresetValidationSchema = zod.object(
  {
    name: zod
      .string()
      .min(1, 'modes_resize_error_validation_preset_name' as TranslationKeys),
    width: zod
      .number()
      .min(1, 'modes_resize_error_validation_preset_width' as TranslationKeys),
    height: zod
      .number()
      .min(1, 'modes_resize_error_validation_preset_height' as TranslationKeys),
    icons: zod.array(zod.any()).optional(),
  },
  {
    required_error:
      'modes_resize_error_validation_preset_required' as TranslationKeys,
  },
);
