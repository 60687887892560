import type {
  CanvasFontParams,
  CaptionChunk,
  CaptionChunkHighlight,
} from '@autocut/types/Captions';

import {host} from '@autocut/utils/host';
import {getWordValue} from '@autocut/utils/process/captions/prepareCaptions/getWordValue';
import {autocutStoreVanilla} from '@autocut/utils/zustand/zustand';

import {getHighlightFromWord, getTextLineBreak} from './processCaptionsChunks';
import {splitTextIntoLines} from './splitTextIntoLines';

export const splitChunk = async (
  chunks: CaptionChunk[],
  chunkIndex: number,
  wordIndex: number,
) => {
  const sequenceWidth = parseInt(
    (await host.timeline.getTimelineFormat()).width,
  );
  const captionsParams = autocutStoreVanilla().ui.parameters.caption;

  const fontParameters: CanvasFontParams = {
    italic: captionsParams.formating.italic,
    fontSize: captionsParams.text.fontSize,
    fontFamily: captionsParams.text.font.fontFamily,
  };
  const allowedWidth =
    (captionsParams.formating.maxWidth / 100) * sequenceWidth;

  const lineBreak = getTextLineBreak();

  const newChunks = [...chunks];
  const chunk = newChunks[chunkIndex];
  if (chunk) {
    const newChunk = {...chunk};
    const allWords = newChunk.lines.flat();

    const newChunkHighlights = newChunk.highlight.slice(0, wordIndex + 1);
    const newChunkLastIndexEnd =
      newChunkHighlights[newChunkHighlights.length - 1].indexEnd;
    const newChunkHighlights2 = newChunk.highlight
      .slice(wordIndex + 1)
      .map(highlight => ({
        ...highlight,
        // Highlight indexStart and indexEnd are relative to the chunk text + 1 space
        indexStart: highlight.indexStart - (newChunkLastIndexEnd + 2),
        indexEnd: highlight.indexEnd - (newChunkLastIndexEnd + 2),
      }));

    const newChunkLines = splitTextIntoLines(
      newChunk.text
        .split(/\s/g)
        .slice(0, wordIndex + 1)
        .join(' '),
      {
        font: fontParameters,
        maxWidth: allowedWidth,
        uppercase: captionsParams.formating.uppercase,
      },
    );
    const updatedLinesPerWords = newChunkLines
      .map(line => allWords.slice(line.startIndex, line.endIndex + 1))
      .filter(words => words.length > 0);
    const newChunkText = updatedLinesPerWords
      .map(words => words.map(word => getWordValue(word)).join(' '))
      .join(lineBreak);
    const newChunkNbLines = updatedLinesPerWords.length;

    const newChunkLines2 = splitTextIntoLines(
      newChunk.text
        .split(/\s/g)
        .slice(wordIndex + 1)
        .join(' '),
      {
        font: fontParameters,
        maxWidth: allowedWidth,
        uppercase: captionsParams.formating.uppercase,
      },
    );
    const updatedLinesPerWords2 = newChunkLines2
      .map(line =>
        allWords.slice(wordIndex + 1).slice(line.startIndex, line.endIndex + 1),
      )
      .filter(words => words.length > 0);
    const newChunkText2 = updatedLinesPerWords2
      .map(words => words.map(word => getWordValue(word)).join(' '))
      .join(lineBreak);
    const newChunkNbLines2 = updatedLinesPerWords2.length;

    const newUpdatedChunk: CaptionChunk = {
      ...chunk,
      text: newChunkText,
      end: newChunkHighlights[newChunkHighlights.length - 1]?.end,
      lines: updatedLinesPerWords,
      nbLines: newChunkNbLines,
    };
    let lastEnd = newChunkHighlights[0]?.start || 0;
    let lastIndexEnd = -2;
    newUpdatedChunk.highlight = updatedLinesPerWords.flatMap(
      (line, lineIndex) => {
        let currentLine = '';
        return line
          .map(word => {
            if (!word) return null;
            const res = getHighlightFromWord(
              word,
              lastEnd,
              lastIndexEnd,
              currentLine,
              lineIndex,
            );
            currentLine = currentLine + res.word + ' ';
            lastEnd = res.end;
            lastIndexEnd = res.indexEnd;
            return res;
          })
          .filter(Boolean) as CaptionChunkHighlight[];
      },
    );

    const newUpdatedChunk2: CaptionChunk = {
      ...chunk,
      text: newChunkText2,
      start: newChunkHighlights2[0].start,
      lines: updatedLinesPerWords2,
      nbLines: newChunkNbLines2,
    };
    let lastEnd2 = newChunkHighlights2[0]?.start || 0;
    let lastIndexEnd2 = -2;
    newUpdatedChunk2.highlight = updatedLinesPerWords2.flatMap(
      (line, lineIndex) => {
        let currentLine = '';
        return line
          .map(word => {
            if (!word) return null;
            const res = getHighlightFromWord(
              word,
              lastEnd2,
              lastIndexEnd2,
              currentLine,
              lineIndex,
            );
            currentLine = currentLine + res.word + ' ';
            lastEnd2 = res.end;
            lastIndexEnd2 = res.indexEnd;

            return res;
          })
          .filter(Boolean) as CaptionChunkHighlight[];
      },
    );

    const newChunks: CaptionChunk[] = [
      ...chunks.slice(0, chunkIndex),
      newUpdatedChunk,
      newUpdatedChunk2,
      ...chunks.slice(chunkIndex + 1),
    ];

    return newChunks;
  }

  return newChunks;
};
