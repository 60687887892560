import type {ModalProps} from '@autocut/components/ModalLayout/BaseModal';
import type {AutocutModeIds} from '@autocut/enums/modes.enum';

import {OldButton} from '@autocut/components/Button/Button';
import ConfettiBackground from '@autocut/components/ConfettiBackground/ConfettiBackground';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconRefreshCw02} from '@autocut/designSystem/components/atoms/Icon/arrows/IconRefreshCw02';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {preload} from '@autocut/types/ElectronPreload';
import {handleRestore} from '@autocut/utils/backup';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {getModeById} from '@autocut/utils/modes.utils';
import {sendStats} from '@autocut/utils/stats.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {Suspense, useEffect} from 'react';
import {IoCheckmarkSharp} from 'react-icons/io5';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import css from './EndProcessModal.module.css';

const defaultEndProcessConfig = {
  goToNextMode: 'silence',
  restoreSequence: true,
  rateAutoCut: true,
  timeTaken: true,
  timeSaved: true,
};

export const EndProcessModal = ({children, ...props}: Partial<ModalProps>) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {logMessage} = useLogger();

  const {
    mode,
    durationProcessMinutes: minutesTaken,
    durationProcessSeconds: secondsTaken,
    xpGained,
    currentProgress,
    license,
    displayTrialTaskModal,
    isRestorable,
  } = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    durationProcessMinutes: state.ui.process.durationProcessMinutes,
    durationProcessSeconds: state.ui.process.durationProcessSeconds,
    xpGained: state.game.level.xpGained,
    currentProgress: state.game.level.currentProgress,
    license: state.user.license,
    displayTrialTaskModal: state.game.trial.displayModal,
    isRestorable: state.backup.isRestorable,
  }));

  const minutesSaved = Math.floor((xpGained * 60) / 100);
  const secondsSaved = Math.floor(((xpGained * 60) / 100 - minutesSaved) * 60);

  const displayNextMode =
    mode.endProcessConfig?.goToNextMode === false ||
    (typeof mode.endProcessConfig?.goToNextMode === 'string' &&
      !license?.features?.includes(
        mode.endProcessConfig.goToNextMode as AutocutModeIds,
      ))
      ? false
      : mode.endProcessConfig.goToNextMode;

  const displayRestoreSequence =
    typeof mode.endProcessConfig?.restoreSequence === 'boolean'
      ? mode.endProcessConfig.restoreSequence && isRestorable
      : defaultEndProcessConfig.restoreSequence && isRestorable;

  const displayRateAutoCut =
    typeof mode.endProcessConfig?.rateAutoCut === 'boolean'
      ? mode.endProcessConfig.rateAutoCut
      : defaultEndProcessConfig.rateAutoCut;

  const displayTimeTaken =
    typeof mode.endProcessConfig?.timeTaken === 'boolean'
      ? mode.endProcessConfig.timeTaken
      : defaultEndProcessConfig.timeTaken;

  const displayTimeSaved =
    typeof mode.endProcessConfig?.timeSaved === 'boolean'
      ? mode.endProcessConfig.timeSaved
      : defaultEndProcessConfig.timeSaved;

  useEffect(() => {
    logMessage(logLevel.info, 'Process ended. Redirecting to homepage.');
    navigate('/homepage');
  }, []);

  const handleUndo = async () => {
    await handleRestore();

    await sendStats({
      type: StatType.UNDO_PROCESS,
      value: 1,
      traits: {mode: mode.statCategory},
    });

    setAutocutStore('ui.process.isPaused', false);
    closeModal();
  };

  const handleNextMode = () => {
    navigate(
      getModeById(displayNextMode as AutocutModeIds)?.path || '/homepage',
    );

    setAutocutStore('ui.process.isPaused', false);
    closeModal();
  };

  return (
    <Modal
      fullWidth
      title={intl.formatMessage({
        id: 'modals_endProcess_title',
        defaultMessage: 'Process completed!',
      })}
      icon={
        <div className={css.iconContainer}>
          <IoCheckmarkSharp
            color="white"
            strokeWidth={2}
          />
        </div>
      }
      closeModalFunction={() => {
        if (displayTrialTaskModal) addModalToQueue(ModalType.GamingTrial);
        closeModal();
      }}
      extraChildren={
        <Suspense fallback={<div />}>
          <ConfettiBackground />
        </Suspense>
      }
      footer={
        <FlexContainer
          className={css.footerContainer}
          justifyContent="space-between"
          gap="8px"
          style={{width: '100%'}}
        >
          {displayRestoreSequence && (
            <Button
              variant="secondary"
              size="md"
              onClick={async () => {
                await handleUndo();
              }}
            >
              <Text variant="textSm">
                <FlexContainer
                  alignItems="center"
                  justifyContent="center"
                  gap={Spacing.s2}
                >
                  <IconRefreshCw02 size={18} />
                  <TranslatedMessage
                    id="modals_endProcess_restoreBackup"
                    defaultMessage="Restore backup"
                  />
                </FlexContainer>
              </Text>
            </Button>
          )}
          {displayNextMode && (
            <Button
              onClick={handleNextMode}
              variant="primary"
              size="md"
              color={colors.primary600}
            >
              <Text variant="textSm">
                <TranslatedMessage
                  id={
                    `modals_endProcess_nextMode_${displayNextMode}_button` as any
                  }
                  defaultMessage="modals_endProcess_nextMode_silence_button"
                />
              </Text>
            </Button>
          )}
        </FlexContainer>
      }
      {...props}
    >
      <FlexContainer
        flexDirection="column"
        alignItems="flex-start"
        gap="12px"
      >
        {(displayTimeSaved || displayTimeTaken) && (
          <div className={css.timeInfos}>
            {displayTimeTaken && (
              <p>
                <TranslatedMessage
                  id={'modals_endProcess_time'}
                  defaultMessage="Process completed in {minutes} minutes and {seconds} seconds !"
                  values={{
                    minutes: minutesTaken,
                    seconds: secondsTaken,
                  }}
                />
              </p>
            )}
            {displayTimeSaved && (
              <>
                <p>
                  <TranslatedMessage
                    id={'modals_endProcess_timeSaved'}
                    defaultMessage="You saved: <b>{minutesSaved}</b> minute(s) and <b>{secondsSaved}</b> second(s)"
                    values={{
                      minutesSaved,
                      secondsSaved,
                    }}
                  />
                </p>
                <p>
                  <TranslatedMessage
                    id={'modals_endProcess_timeSavedAllTime'}
                    defaultMessage="Since using AutoCut, you saved : {hourSaved} hour(s) and {minutesSaved} minute(s) ! 🎉"
                    values={{
                      hourSaved: currentProgress?.level,
                      minutesSaved: ~~((currentProgress.xp * 60) / 100),
                    }}
                  />
                </p>
              </>
            )}
          </div>
        )}
        {children}
        {displayRateAutoCut && (
          <FlexContainer flexDirection="column">
            <p>
              <TranslatedMessage
                id={'modals_endProcess_feedback'}
                defaultMessage="Feel free to give us feedback on the quality of the result:"
              />
            </p>
            <OldButton
              buttonType="tertiary"
              compact
              onClickFunction={() => {
                logMessage(logLevel.info, 'Open Senja in browser');

                void preload.electron.openLinkInBrowser(
                  'https://senja.io/p/autocut/r/ikmgDE',
                );
              }}
            >
              <TranslatedMessage
                id={'modals_endProcess_review'}
                defaultMessage="Grade AutoCut !"
              />
            </OldButton>
          </FlexContainer>
        )}
        {displayNextMode && (
          <p>
            <TranslatedMessage
              id={`modals_endProcess_nextMode_${displayNextMode}_text` as any}
              defaultMessage="If you want, you can now use AutoCut Silences for automatically removing silences."
            />
          </p>
        )}
      </FlexContainer>
    </Modal>
  );
};
