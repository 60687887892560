import type {CaptionChunk} from '@autocut/types/Captions';
import type {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';

import {FingerprintErrorFactory} from '@autocut/utils/errors/FingerprintErrorFactory';
import {autocutApi} from '@autocut/utils/http.utils';
import {manageError} from '@autocut/utils/manageError';

import {ANIMATED_EMOJI_SIZE} from '../customization/parts/EmojiSelector/EmojiPicker/EmojiPickerVariant';

export const generateAnimatedEmojisFromTranscription = async ({
  transcription,
}: {
  transcription: CaptionChunk[];
}) => {
  const newTranscription = [...transcription];
  const textArray = transcription.flatMap(chunk => chunk.text);

  const {data = []} = await autocutApi
    .post('openAI/animatedEmojis', {
      text: textArray,
    })
    .catch((error: AutoCutApiError) => {
      manageError({
        error: FingerprintErrorFactory(
          error,
          'generateEmojisFromTranscription',
        ),
      });

      return {} as never;
    });

  data.forEach((chunk: {text: string; emoji: string; emojiUrl: string}) => {
    if (!chunk.emoji || !chunk.emojiUrl) return;

    const textIndex = textArray.findIndex(text => text.includes(chunk.text));
    if (textIndex === -1) return;

    newTranscription[textIndex].emoji = chunk.emoji;
    newTranscription[textIndex].emojiUrl = chunk.emojiUrl;
    newTranscription[textIndex].emojiSize = ANIMATED_EMOJI_SIZE;
  });

  return newTranscription;
};
