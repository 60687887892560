import type {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';

import {preload, waitForPreload} from '@autocut/types/ElectronPreload';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';

import {autocutApi} from '../http.utils';

export const versionValidation: LaunchStepHandler = async (): Promise<
  boolean | string
> => {
  await waitForPreload('versionValidation');

  const {electron, os} = preload;

  const electronVersion = electron.getVersion();
  const platform = os.platform();

  try {
    const response = await autocutApi.post<
      | {result: true}
      | {
          result: false;
          modal?: {
            title?: string;
            description?: string;
            ctaText?: string;
            ctaLink?: string;
          };
        }
    >('/modals/check-electron-version', {
      electronVersion,
      platform,
    });

    if (!response.data || response.data.result) {
      return true;
    }

    const currentElectronVersionModal =
      autocutStoreVanilla().modal.data.electronVersionModal;
    setAutocutStore('modal.data.electronVersionModal', {
      ...currentElectronVersionModal,
      ...(response.data?.modal || {}),
    });

    return 'invalidVersion';
  } catch (error) {
    return false;
  }
};
