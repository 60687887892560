import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {rgbToHex} from '@autocut/utils/color.utils';
import chroma from 'chroma-js';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

const TextFormatCustomization = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();

  const uppercase = value.formating.uppercase;
  const italic = value.formating.italic;
  const removePunctuation = value.formating.removePunctuation;
  const glowEnabled = value.formating.glow.enabled;
  const glowIntensity = value.formating.glow.intensity;
  const glowColor = value.formating.glow.color;

  return (
    <FormSection
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_formating_title'
          }
          defaultMessage="Text formating"
        />
      }
    >
      <ParametersGroupLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_removePunctuation'
              }
              defaultMessage="Remove punctuation"
            />
          }
        >
          <Switch
            checked={removePunctuation}
            onChange={(checked: boolean) =>
              setValue('formating.removePunctuation', checked)
            }
            size={16}
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_uppercase'
              }
              defaultMessage="Uppercase"
            />
          }
        >
          <Switch
            checked={uppercase}
            onChange={(checked: boolean) =>
              setValue('formating.uppercase', checked)
            }
            size={16}
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_italic'
              }
              defaultMessage="Italic"
            />
          }
        >
          <Switch
            checked={italic}
            onChange={(checked: boolean) =>
              setValue('formating.italic', checked)
            }
            size={16}
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_glow_title'
              }
              defaultMessage="Glow"
              valuePamareters={{
                color: chroma(rgbToHex(glowColor)).luminance(0.3).hex(),
              }}
            />
          }
        >
          <Switch
            checked={glowEnabled}
            onChange={(checked: boolean) =>
              setValue('formating.glow.enabled', checked)
            }
            size={16}
          />
          <ParametersGroupLayout
            status={glowEnabled}
            hiddenContent={null}
          >
            <ParameterLayout
              title={
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_formating_glow_color'
                  }
                  defaultMessage="Color"
                />
              }
            >
              <ColorPicker
                value={glowColor}
                onChange={color => setValue('formating.glow.color', color)}
              />
            </ParameterLayout>
            <ParameterLayout
              title={
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_formating_glow_intensity'
                  }
                  defaultMessage="Intensity"
                />
              }
              wrap={true}
            >
              <Slider
                value={
                  isNaN(glowIntensity) || !glowIntensity ? 0 : glowIntensity
                }
                onChange={value => setValue('formating.glow.intensity', value)}
                min={0}
                max={250}
                variant="valueOnLeft"
              />
            </ParameterLayout>
          </ParametersGroupLayout>
        </ParameterLayout>
      </ParametersGroupLayout>
    </FormSection>
  );
};

export default TextFormatCustomization;
