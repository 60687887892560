import type {XMLDocument} from './_XMLDocument';
import type {XMLVideoMediaSource} from './_XMLMediaSource';

import {XMLVideoClip} from './_XMLVideoClip';

export class XMLProjectItem {
  private audioClipChannelGroups: Element;
  private clipProjectItem: Element;

  private document: XMLDocument;

  private getAudioClipChannelGroups = () => {
    const audioChannelGroupsRef = this.masterClip.querySelector(
      'AudioClipChannelGroups',
    );
    if (!audioChannelGroupsRef)
      throw new Error('AudioClipChannelGroups not found');

    const audioChannelGroupsId =
      audioChannelGroupsRef?.getAttribute('ObjectRef') ?? '';
    return this.document.findElementWithObjectId(
      ['ClipChannelGroupVectorSerializer'],
      audioChannelGroupsId,
    );
  };
  private getClipProjectItem = () => {
    return this.document.findElementWithObjectId(
      ['ClipProjectItem'],
      this.objectUID,
    );
  };
  private getLoggingInfo = () => {
    const loggingInfoRef = this.masterClip.querySelector('LoggingInfo');
    if (!loggingInfoRef) throw new Error('LoggingInfo not found');
    const loggingInfoId = loggingInfoRef?.getAttribute('ObjectRef') ?? '';
    return this.document.findElementWithObjectId(
      ['ClipLoggingInfo'],
      loggingInfoId,
    );
  };
  private getMasterClip = () => {
    const masterClipRef = this.clipProjectItem.querySelector('MasterClip');
    if (!masterClipRef) throw new Error('MasterClip not found');
    const masterClipId = masterClipRef?.getAttribute('ObjectURef') ?? '';
    return this.document.findElementWithObjectId(['MasterClip'], masterClipId);
  };
  private getMasterClipVideoClip = () => {
    // It might have multipe clips but for now, I've only worked with master clip that had one
    const videoClipRef = this.masterClip.querySelector('Clip');
    if (!videoClipRef) throw new Error('Clip not found');
    const videoClipId = videoClipRef?.getAttribute('ObjectRef') ?? '';
    const videoClip = this.document.findElementWithObjectId(
      ['VideoClip'],
      videoClipId,
    );
    if (!videoClip) return;
    return new XMLVideoClip({element: videoClip, document: this.document});
  };
  private loggingInfo: Element;

  private refElement: Element;

  public masterClip: Element;

  public name: string;

  public objectUID: string;

  public videoClip?: XMLVideoClip;

  constructor({
    refElement,
    document,
    clipProjectItem,
    masterClip,
    loggingInfo,
    videoClip,
    audioClipChannelGroups,
  }: {
    refElement: Element;
    document: XMLDocument;
    clipProjectItem?: Element;
    masterClip?: Element;
    loggingInfo?: Element;
    videoClip?: XMLVideoClip;
    audioClipChannelGroups?: Element;
  }) {
    this.objectUID = refElement.getAttribute('ObjectURef') ?? '';
    this.refElement = refElement;

    this.document = document;

    clipProjectItem = clipProjectItem ?? this.getClipProjectItem();
    if (!clipProjectItem) throw new Error('ClipProjectItem not found');
    this.clipProjectItem = clipProjectItem;
    this.name = this.clipProjectItem.querySelector('Name')?.textContent ?? '';

    masterClip = masterClip ?? this.getMasterClip();
    if (!masterClip) throw new Error('MasterClip not found');
    this.masterClip = masterClip;

    loggingInfo = loggingInfo ?? this.getLoggingInfo();
    if (!loggingInfo) throw new Error('LoggingInfo not found');
    this.loggingInfo = loggingInfo;

    this.videoClip = videoClip ?? this.getMasterClipVideoClip();

    audioClipChannelGroups =
      audioClipChannelGroups ?? this.getAudioClipChannelGroups();
    if (!audioClipChannelGroups)
      throw new Error('AudioClipChannelGroups not found');
    this.audioClipChannelGroups = audioClipChannelGroups;

    return this;
  }

  public clone({
    newName,
    newObjectUID,
    newSource,
    index = 0,
  }: {
    newName: string;
    newObjectUID?: string;
    newSource?: XMLVideoMediaSource;
    index?: number;
  }) {
    const newProjectItemUID = newObjectUID ?? this.document.getNewObjectUID();
    const newRefElement = this.refElement.cloneNodeWithNewId(newProjectItemUID);
    newRefElement.setAttribute('Index', index.toString());

    const newClipProjectItem =
      this.clipProjectItem.cloneNodeWithNewId(newProjectItemUID);

    const masterClipRef = newClipProjectItem.querySelector('MasterClip');
    if (!masterClipRef) throw new Error('MasterClip not found');
    const newMasterClipId = this.document.getNewObjectUID();
    masterClipRef.setAttribute('ObjectURef', newMasterClipId);
    const newMasterClip = this.masterClip.cloneNodeWithNewId(newMasterClipId);

    const newLoggingInfoId = this.document.getNewObjectId();
    const newLoggingInfoRef = newMasterClip.querySelector('LoggingInfo');
    if (!newLoggingInfoRef) throw new Error('LoggingInfo not found');
    newLoggingInfoRef.setAttribute('ObjectRef', newLoggingInfoId);
    const newLoggingInfo =
      this.loggingInfo.cloneNodeWithNewId(newLoggingInfoId);

    const newVideoClipId = this.document.getNewObjectId();
    const newVideoClipRef = newMasterClip.querySelector('Clip');
    if (!newVideoClipRef) throw new Error('Clip not found');
    newVideoClipRef.setAttribute('ObjectRef', newVideoClipId);
    const newVideoClip = this.videoClip?.clone({
      newObjectID: newVideoClipId,
      newSource,
    });

    const newAudioClipChannelGroupsId = this.document.getNewObjectId();
    const newAudioClipChannelGroupsRef = newMasterClip.querySelector(
      'AudioClipChannelGroups',
    );
    if (!newAudioClipChannelGroupsRef)
      throw new Error('AudioClipChannelGroups not found');
    newAudioClipChannelGroupsRef.setAttribute(
      'ObjectRef',
      newAudioClipChannelGroupsId,
    );
    const newAudioClipChannelGroups =
      this.audioClipChannelGroups.cloneNodeWithNewId(
        newAudioClipChannelGroupsId,
      );

    const newXmlProjectItem = new XMLProjectItem({
      refElement: newRefElement,
      clipProjectItem: newClipProjectItem,
      masterClip: newMasterClip,
      document: this.document,
      loggingInfo: newLoggingInfo,
      videoClip: newVideoClip,
      audioClipChannelGroups: newAudioClipChannelGroups,
    });

    newXmlProjectItem.rename(newName);

    return newXmlProjectItem;
  }

  public rename(newName: string) {
    this.name = newName;

    const name = this.masterClip.querySelector('Name');
    if (!name) throw new Error('Name not found');
    name.textContent = newName;

    const clipName = this.clipProjectItem.querySelector('Name');
    if (!clipName) throw new Error('ClipName not found');
    clipName.textContent = newName;

    const loggingClipName = this.loggingInfo.querySelector('ClipName');
    if (!loggingClipName) throw new Error('LoggingClipName not found');
    loggingClipName.textContent = newName;
  }
}
