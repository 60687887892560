import type {TranslationKeys} from '@autocut/contexts/LocaleContext';
import type {TrialTask} from '@autocut/types/TrialTask';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload} from '@autocut/types/ElectronPreload';
import {FaCheck} from 'react-icons/fa6';

import css from './TaskList.module.css';

export type TaskListProps = {
  tasks: TrialTask[];
};

type ItemProps = TaskListProps['tasks'][number];

const Item = ({
  textId,
  amount,
  completed,
  link = '',
  isAnimated = false,
}: ItemProps) => {
  return (
    <FlexContainer
      gap={8}
      alignItems="center"
    >
      <div className={css.iconContainer}>
        <div className={css.icon}>
          <div>+{amount}</div>
        </div>
        {completed && (
          <div
            className={`${css.icon} ${css.completed} ${
              isAnimated && css.animated
            }`}
          >
            <div>
              <FaCheck color="white" />
            </div>
          </div>
        )}
      </div>

      <p>
        {
          <TranslatedMessage
            id={
              `modals_gamifiedTrial_taskList_tasks_${textId}` as TranslationKeys
            }
            defaultMessage={textId}
            values={{
              link: chunk => (
                <span
                  className={css.link}
                  onClick={() => preload.electron.openLinkInBrowser(link)}
                >
                  {chunk}
                </span>
              ),
            }}
          />
        }
      </p>
    </FlexContainer>
  );
};

export const TaskList = ({tasks}: TaskListProps) => {
  return (
    <FlexContainer
      gap={10}
      flexDirection="column"
      className={css.container}
    >
      <p>
        <TranslatedMessage
          id="modals_gamifiedTrial_taskList_title"
          defaultMessage="Task Mastery: {completed}/{maximum} Completed"
          values={{
            completed: tasks.filter(task => task.completed).length,
            maximum: tasks.length,
          }}
        />
      </p>
      <FlexContainer
        gap={8}
        flexDirection="column"
      >
        {tasks.map((task, index) => (
          <Item
            key={index}
            {...task}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};
