import type React from 'react';

import {forwardRef, useEffect, useRef} from 'react';

export const EditableText = forwardRef<
  HTMLDivElement,
  {
    initialValue: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    filteredCharacters?: string[];
    style?: React.CSSProperties;
    className?: HTMLDivElement['className'];
    validate?: (value: string) => boolean;
  }
>(
  (
    {
      initialValue,
      onChange,
      disabled = false,
      filteredCharacters = [],
      style,
      className,
      validate,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const textRef = (ref as React.RefObject<HTMLDivElement>) || internalRef;

    useEffect(() => {
      const currentRef = textRef.current;
      if (!currentRef) return;

      currentRef.textContent = initialValue;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (!currentRef) return;

        const newValue = currentRef.textContent ?? '';
        const filteredValue = getFilteredValue(newValue);

        currentRef.textContent = filteredValue;
        if (e.key === 'Enter') {
          e.preventDefault();
          handleChange();
          currentRef.blur();
        }
      };

      currentRef.addEventListener('keydown', handleKeyDown);

      return () => {
        currentRef.removeEventListener('keydown', handleKeyDown);
      };
    }, [initialValue]);

    const getFilteredValue = (value: string) => {
      if (filteredCharacters.length === 0) return value;

      return value.replace(new RegExp(filteredCharacters.join('|'), 'g'), '');
    };

    const handleChange = () => {
      if (!textRef.current) return;
      const newValue = textRef.current.textContent ?? '';
      const filteredValue = getFilteredValue(newValue);

      const isValid = validate ? validate(filteredValue) : true;

      if (!isValid) {
        textRef.current.textContent = initialValue;
        return;
      }

      textRef.current.textContent = filteredValue;
      onChange(filteredValue);
    };

    return (
      <div
        ref={textRef}
        className={className}
        contentEditable={!disabled}
        onBlur={handleChange}
        style={style}
      />
    );
  },
);
