import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconUser01 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke="currentColor"
        d="M20 21c0-1.4 0-2-.2-2.7a4 4 0 0 0-2.6-2.6c-.6-.2-1.3-.2-2.7-.2h-5c-1.4 0-2 0-2.7.2a4 4 0 0 0-2.6 2.6C4 19 4 19.6 4 21M16.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
      />
    </IconComponent>
  );
};
