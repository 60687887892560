import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconMinus} from '@autocut/designSystem/components/atoms/Icon/general/IconMinus';
import PopupPortal from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';
import {useRef, useState} from 'react';
import {BsEmojiSmile} from 'react-icons/bs';

import {Emoji} from './EmojiPicker/EmojiPicker';
import {EmojiPickerVariant} from './EmojiPicker/EmojiPickerVariant';
import css from './EmojiSelector.module.scss';

export const EmojiSelector = ({
  selectedEmoji,
  onEmojiSelect,
  onDeleteEmoji,
  disabled,
}: {
  selectedEmoji?: {url: string; name: string};
  onEmojiSelect: (emoji: {url: string; name: string; size: number}) => void;
  onDeleteEmoji: () => void;
  disabled?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div style={{position: 'relative'}}>
      <ActionIcon
        ref={ref}
        variant="subtle"
        onClick={() => setIsOpened(opened => !opened)}
        size={40}
        disabled={disabled}
      >
        {selectedEmoji ? (
          <Emoji
            emoji={selectedEmoji}
            style={{
              width: '1.5rem',
              aspectRatio: '1',
            }}
          />
        ) : (
          <BsEmojiSmile
            color={colors.gray300}
            size="1.5rem"
          />
        )}
      </ActionIcon>
      {selectedEmoji && (
        <div
          className={css.deleteEmoji}
          onClick={event => {
            event.stopPropagation();
            onDeleteEmoji();
            setIsOpened(false);
          }}
        >
          <IconMinus size={16} />
        </div>
      )}

      {isOpened && (
        <PopupPortal
          target={ref}
          position="bottom-right"
          onClickOutside={() => setIsOpened(false)}
          containerStyle={{
            top: '100px',
            left: '100px',
          }}
        >
          <EmojiPickerVariant
            onEmojiClick={emoji => {
              onEmojiSelect(emoji);
              setIsOpened(false);
            }}
            width="360px"
            maxHeight="200px"
          />
        </PopupPortal>
      )}
    </div>
  );
};
