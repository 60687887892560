import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconPlus = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 5v14m-7-7h14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
