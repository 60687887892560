export const StatType = {
  TRIAL_PROMO_CLICKED: 'TRIAL_PROMO_CLICKED',
  STEPPER: 'STEPPER',
  HARD_RESET: 'HARD_RESET',

  // Process related events
  PROCESS_STARTED: 'PROCESS_STARTED',
  PROCESS_ENDED: 'PROCESS_ENDED',
  UNDO_PROCESS: 'UNDO_PROCESS',

  // Silence related events
  SILENCE_NOISE_LEVEL_CALCULATED: 'SILENCE_NOISE_LEVEL_CALCULATED',

  // Onboarding related events
  ON_BOARDING_COMPLETED: 'ON_BOARDING_COMPLETED',
  ON_BOARDING_SKIPPED: 'ON_BOARDING_SKIPPED',
  ON_BOARDING_STARTED: 'ON_BOARDING_STARTED',

  // Setups related events
  HOST_CONNECTED: 'HOST_CONNECTED',
  SETUP_COMPLETED: 'SETUP_COMPLETED',
  SETUP_STARTED: 'SETUP_STARTED',

  // Captions related events
  CAPTIONS_BACK_TO_EDITOR: 'CAPTIONS_BACK_TO_EDITOR',
  CAPTIONS_LOAD_PREVIOUS_TRANSCRIPT: 'CAPTIONS_LOAD_PREVIOUS_TRANSCRIPT',

  // Error related events
  ERROR: 'ERROR',
  UPDATE_TIMEOUT: 'UPDATE_TIMEOUT',
} as const satisfies Record<string, Uppercase<string>>;

export type StatType = (typeof StatType)[keyof typeof StatType];
