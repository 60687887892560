import {autocutStoreVanilla, setAutocutStore} from '../zustand/zustand';

/**
 * This function closes the current modal.
 * It will display the next modal in the queue if there is one.
 */
export const closeModal = (modalName?: string) => {
  console.log('[MODAL] Closing modal', modalName);

  const store = autocutStoreVanilla();
  if (!!modalName && modalName !== store.modal.openedModalName) {
    setAutocutStore(
      'modal.modalQueue',
      store.modal.modalQueue.filter(m => m !== modalName),
    );
    return;
  }

  setAutocutStore('modal.openedModalName', '');
};
