import {StatType} from '@autocut/enums/statType.enum';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {manageError} from '@autocut/utils/manageError';

import {FingerprintErrorFactory} from '../errors/FingerprintErrorFactory';
import {getModeStatTrait} from '../modes.utils';
import {sendStats} from '../stats.utils';
import {autocutStoreVanilla} from '../zustand/zustand';

export const handleProcessError = async (err: unknown) => {
  const autocutState = autocutStoreVanilla();
  const mode = autocutState.ui.process.mode;
  const statCategory = getModeStatTrait(mode);

  manageError({
    error: FingerprintErrorFactory(
      err instanceof IncrementalError
        ? err
        : `An error has occured during process (${mode.id}): ` + err,
      `handleProcessError(${mode.id})`,
    ),
  });

  await sendStats({
    type: StatType.ERROR,
    value: 1,
    traits: {
      type: 'PROCESS_ERROR',
      mode: statCategory,
      fatal: 1,
    },
  });
};
