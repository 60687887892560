import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {ModeWrapper} from '@autocut/pages/modes/ModeWrapper';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import RepeatLanguage from './Steps/Language/RepeatLanguage';
import {RepeatTranscriptStep} from './Steps/Transcript/RepeatTranscriptStep';

export const RepeatMode = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ModeWrapper
      Icon={AutocutModes.Repeat.icon}
      text={AutocutModes.Repeat.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/repeat"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="repeat"
                onValidateSections={() => navigate('./language')}
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_repeat_steps_language_title',
              defaultMessage: 'Select clips & language',
            }),
            clickable: true,
            path: 'language',
            element: <RepeatLanguage />,
          },
          {
            label: intl.formatMessage({
              id: 'repeat_step_transcription' as any,
              defaultMessage: 'Check transcription',
            }),
            path: 'transcript',
            element: <RepeatTranscriptStep />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
