import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconSave03 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7 3h8.7l.5.3.7.6 3.2 3.2.6.7.2.6.1 1V17m-8.5-7H8.6l-1-.1a1 1 0 0 1-.5-.4L7 8.4V6.5M13.5 21v-4.4l-.1-1a1 1 0 0 0-.4-.5l-1.1-.1H8.6l-1 .1a1 1 0 0 0-.5.4L7 16.6V21m10.5-10.8v7.6c0 1.1 0 1.7-.2 2.1a2 2 0 0 1-.9.9c-.4.2-1 .2-2.1.2H6.2c-1.1 0-1.7 0-2.1-.2a2 2 0 0 1-.9-.9c-.2-.4-.2-1-.2-2.1V9.7c0-1.1 0-1.7.2-2.1.2-.4.5-.7.9-.9.4-.2 1-.2 2.1-.2h8.1l.3.1.4.4 2 2 .4.4v.3l.1.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
