import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {useIntl} from 'react-intl';

export const AppStoreDvModal = () => {
  const intl = useIntl();

  const onClick = async () => {
    const {
      electron: {openLinkInBrowser},
    } = preload;

    await openLinkInBrowser(
      'https://www.blackmagicdesign.com/products/davinciresolve/',
    );
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modals_appStoreDv_title',
        defaultMessage:
          'AutoCut is not compatible with DaVinci Resolve installed from App Store',
      })}
      footer={
        <FlexContainer
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="tertiary"
            color="white"
            fullWidth={false}
            onClick={onClick}
          >
            <TranslatedMessage
              id="modals_appStoreDv_cta"
              defaultMessage="Access to DaVinci Resolve official website"
            />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
      >
        <Text variant="textSm">
          <TranslatedMessage
            id="modals_appStoreDv_text_firstLine_text"
            defaultMessage="To use AutoCut with DaVinci Resolve, you have to install DaVinci
          Resolve from their official website: "
          />
          <Button
            variant="tertiary"
            color={colors.primary600}
            fullWidth={false}
            style={{color: colors.primary600}}
            onClick={onClick}
          >
            <TranslatedMessage
              id="modals_appStoreDv_text_firstLine_cta"
              defaultMessage="Official DaVinci Resolve website"
            />
          </Button>
        </Text>
        <Text variant="textSm">
          <TranslatedMessage
            id="modals_appStoreDv_text_secondLine"
            defaultMessage="In addition, even DaVinci Resolve offers more features on their
          official version from their website than on the App Store version."
          />
        </Text>
      </FlexContainer>
    </Modal>
  );
};
