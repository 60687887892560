import type * as React from 'react';

import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

export type MenuItemProps = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export const MenuItem = ({icon, label, onClick}: MenuItemProps) => {
  return (
    <ActionIcon
      variant="subtle"
      onClick={onClick}
      style={{
        borderRadius: 0,
        aspectRatio: 'unset',
        padding: '8px 16px',
        justifyContent: 'flex-start',
      }}
      color={colors.gray300}
    >
      <FlexContainer
        alignItems="center"
        gap={Spacing.s2}
      >
        <div style={{flexShrink: 0}}>{icon}</div>
        <Text
          style={{whiteSpace: 'nowrap', flexShrink: 0}}
          variant="textXs.medium"
        >
          {label}
        </Text>
      </FlexContainer>
    </ActionIcon>
  );
};
