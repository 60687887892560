import type {BRoll} from '@autocut/types/BRolls';

import type {PexelVideoRef} from '../PexelPreview/PexelPreview';

import {Card} from '@autocut/designSystem/components/atoms/Card/Card';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {TranscriptChunkContainer} from '@autocut/pages/modes/captions/customization/parts/TranscriptChunkContainer/TranscriptChunkContainer';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useRef} from 'react';
import {FaRegTrashAlt} from 'react-icons/fa';

import {VideoContainer} from '../VideoContainer/VideoContainer';
import css from './BRollsTranscriptItem.module.scss';

export type BRollsTranscriptItemProps = {
  bRoll: BRoll;
  openChoiceModal: (bRoll: BRoll) => void;
};

export const BRollsTranscriptItem = ({
  bRoll,
  openChoiceModal,
}: BRollsTranscriptItemProps) => {
  const videoRef = useRef<PexelVideoRef>(null);

  const onMouseEnter = async () => {
    await videoRef.current?.play();
    videoRef.current?.zoom();
  };

  const onMouseLeave = () => {
    videoRef.current?.pause();
    videoRef.current?.unzoom();
  };

  const onVideoDelete = () => {
    setAutocutStore('onGoingProcess.bRolls', state =>
      (state.onGoingProcess.bRolls as BRoll[]).map(currentBRoll => {
        if (currentBRoll.id === bRoll.id) {
          return {
            ...currentBRoll,
            video: undefined,
          };
        }
        return currentBRoll;
      }),
    );
  };
  return (
    <TranscriptChunkContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={css.bRollItemContainer}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <FlexContainer
        alignItems="center"
        gap={12}
      >
        <VideoContainer
          ref={videoRef}
          onDelete={onVideoDelete}
          onClick={() => {
            openChoiceModal(bRoll);
          }}
          video={bRoll.video}
        />
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          gap={8}
        >
          <FlexContainer
            gap={Spacing.s1}
            alignItems="center"
          >
            <Card className={css.timecodeContainer}>
              <Text variant="textSm.semibold">{bRoll.timecodes[0]}</Text>
            </Card>
            <Text variant="textSm.semibold">-</Text>
            <Card className={css.timecodeContainer}>
              <Text variant="textSm.semibold">{bRoll.timecodes[1]}</Text>
            </Card>
          </FlexContainer>
          <p>{bRoll.transcript}</p>
        </FlexContainer>
      </FlexContainer>
      {bRoll.video !== undefined && (
        <FaRegTrashAlt
          className={css.trashIcon}
          onClick={onVideoDelete}
          size={16}
          color="#98A2B3"
        />
      )}
    </TranscriptChunkContainer>
  );
};
