import type {NeedingTranscriptionModes} from '@autocut/types/Transcription';

import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {LANGUAGE_TO_MODEL} from '@autocut/constants/languageToModel';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {StatType} from '@autocut/enums/statType.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {sendStats} from '@autocut/utils/stats.utils';
import {useEffect} from 'react';
import {FiAlertTriangle} from 'react-icons/fi';

export const DeepgramErrorModal = () => {
  const currentTranscription = useAutoCutStore(
    state => state.ui.currentTranscription,
  );

  const parameters = getParametersForMode<NeedingTranscriptionModes>();
  const hasAlternativeProvider = Boolean(
    currentTranscription.modeId &&
      LANGUAGE_TO_MODEL[parameters?.languageOfTranscription.value][
        currentTranscription.usedModel + 1
      ],
  );
  useEffect(() => {
    void sendStats({
      type: StatType.ERROR,
      value: 1,
      traits: {
        mode: currentTranscription?.modeId,
        type: 'DEEPGRAM_ERROR_MODAL',
      },
    });
  }, []);

  return (
    <Modal
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <TranslatedMessage
            id="modals_deepgramError_title"
            defaultMessage="A translation error has occurred"
          />
        </p>
      }
      icon={
        <FiAlertTriangle
          size="1.5rem"
          color="red"
        />
      }
    >
      <p
        style={{
          color: 'white',
          padding: '16px',
          fontSize: '13px',
          lineHeight: '23px',
          fontWeight: 'bold',
        }}
      >
        <TranslatedMessage
          id="modals_deepgramError_text"
          defaultMessage="An error occurred while translating the audio. It may be related to a temporary issue with our AI model, the quality of the audio or the language spoken but we have no further information for now. {br}. Please wait a few minutes and try again. If the problem persists, please contact us on discord or by email at contact@autocut.fr."
        />
      </p>

      {hasAlternativeProvider && currentTranscription.modeId && (
        <FlexContainer
          flexDirection="column"
          gap={24}
          alignItems="flex-start"
          style={{
            paddingInline: '16px',
          }}
        >
          <p
            style={{
              color: 'white',
              fontSize: '13px',
              lineHeight: '23px',
              fontWeight: 'bold',
            }}
          >
            <TranslatedMessage
              id="modals_deepgramError_retry"
              defaultMessage="You can also retry the transcription with one of our other AI."
            />
          </p>
        </FlexContainer>
      )}
    </Modal>
  );
};
