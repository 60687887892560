import type {CaptionCanvasObjectHook} from '../captionHooks.type';

import {useCanvasObject} from '../captionDrawHooks.utils';
import {useDrawBackground} from './useDrawBackground';
import {useEditCaptionStyle} from '../../../../../../EditCaptionStyleContext';

export const useBackgroundCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawBackground(args);
  const {
    tempParameters: {value},
  } = useEditCaptionStyle();

  return useCanvasObject({
    name: 'background',
    paths: {
      x: 'position.xPercentage',
      y: 'position.yPercentage',
      enabled: 'box.enabled',
    },
    ...drawReturn,
    ...args,
  });
};
