import type {CSSProperties} from 'react';

import {colors} from '@autocut/designSystem/colors';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';

import {computePositionValues} from '../../sections.utils';
import css from './TimelineSectionned.module.scss';

type TimelineSectionnedProps = {
  style?: CSSProperties;
  disabled?: boolean;
};

export const TimelineSectionned = ({
  style,
  disabled = false,
}: TimelineSectionnedProps) => {
  const {selectedSections, timelineInfos} = useAutoCutStore(state => ({
    selectedSections: state.ui.selectedSections,
    timelineInfos: state.onGoingProcess.timelineInfos,
  }));

  const svgPattern = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
  <defs>
      <pattern id="background" patternUnits="userSpaceOnUse" width="32" height="32">
      <rect width="4" height="10" x="0" y="22" ry="4" fill="#344054"/>
      <rect width="4" height="5" x="0" y="27" fill="#344054"/>
      <rect width="4" height="21" x="8" y="11" ry="4" fill="#344054"/>
      <rect width="4" height="16" x="8" y="16" fill="#344054"/>
      <rect width="4" height="25" x="16" y="7" ry="4" fill="#344054"/>
      <rect width="4" height="22" x="16" y="10" fill="#344054"/>
      <rect width="4" height="15" x="24" y="17" ry="4" fill="#344054"/>
      <rect width="4" height="10" x="24" y="23" fill="#344054"/>
      </pattern>
    </defs>
    <rect width="32" height="32" fill="url(#background)"/>
</svg>
`;

  const encodedSvgPattern = `url('data:image/svg+xml;utf8,${encodeURIComponent(svgPattern)}')`;

  return (
    <>
      <div
        className={css.container}
        style={style}
      >
        {timelineInfos
          ? selectedSections.map(section => {
              if (disabled) return;

              const timelineStartFrame = timelineInfos.start;
              const timelineEndFrame =
                timelineInfos.start + timelineInfos.duration;
              const {sectionLengthPercentage, sectionStartPercentage} =
                computePositionValues({
                  section,
                  timelineEndFrame,
                  timelineStartFrame,
                });

              return (
                <div
                  key={section[0]}
                  className={css.section}
                  style={{
                    backgroundColor: colors.gray300,
                    backgroundImage: encodedSvgPattern,
                    clipPath: `polygon(${sectionStartPercentage}% 0%, ${sectionStartPercentage + sectionLengthPercentage}% 0%, ${sectionStartPercentage + sectionLengthPercentage}% 100%, ${sectionStartPercentage}% 100%)`,
                  }}
                />
              );
            })
          : null}
        <div
          className={css.section}
          style={{
            backgroundImage: encodedSvgPattern,
          }}
        />
      </div>
    </>
  );
};
