import type {StepperRouterStep} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import type {Locale} from '@autocut/contexts/LocaleContext';
import type {AutocutModesKeys} from '@autocut/enums/modes.enum';
import type {ResizePreset} from '@autocut/types/Resize';
import type {NeedingTranscriptionModes} from '@autocut/types/Transcription';
import type {AutocutParameters} from '@autocut/utils/parameters.utils';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {
  AutoCutState,
  GenerateZustandKeys,
} from '@autocut/utils/zustand/zustand';

import {AutocutModes, type AutocutModeState} from '@autocut/enums/modes.enum';
import {
  syncToCookies,
  type ZustandSyncConfig,
} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_UI = 'ui';

export type ZType_UI = {
  language: Locale;
  host: 'ppro' | 'davinci';
  versions: {
    aea: string;
    front: string;
    compute: string;
    hostClient: string;
    hostServer: string;
    host: number[];
  };
  parameters: AutocutParameters;
  cutButtonMessage?: string;
  currentTranscription: {
    modeId?: NeedingTranscriptionModes;
    usedModel: number;
  };
  swearWords: {
    swearWordsDictionary: string[];
  };
  podcast: {
    hasEnoughClips: boolean;
  };
  resize: {
    customPresets: Omit<ResizePreset, 'icons'>[];
  };
  broll: {
    usersToCredit: {
      id: number;
      name: string;
      url: string;
    }[];
  };
  selection: {
    isLoading: boolean;
  };
  process: {
    mode: AutocutModeState;
    isProcessing: boolean;
    isPaused: boolean;
    durationProcessMinutes: number;
    durationProcessSeconds: number;
    numberOfCutsDone: number;
  };
  isTourOpened: boolean;
  currentErrorId: string;
  currentSteps?: StepperRouterStep<any>[];
  deactivatedTrackIndexes: number[];
  selectedSections: number[][];
  isPinned: boolean;
  appUUID: string;
};

export type ZKeys_UI = GenerateZustandKeys<ZBaseKey_UI, ZType_UI>;

export const zDefaultValues_UI: ZType_UI = {
  language: 'EN',
  host: '' as 'ppro' | 'davinci',
  versions: {
    aea: '000',
    front: import.meta.env.VITE_AUTOCUT_FRONT_VERSION,
    compute: '000',
    hostClient: '000',
    hostServer: '000',
    host: [0, 0, 0],
  },
  parameters: Object.keys(AutocutModes).reduce((acc, mode) => {
    return {
      ...acc,
      [AutocutModes[mode as AutocutModesKeys].id]:
        AutocutModes[mode as AutocutModesKeys].defaultParameters,
    };
  }, {}) as AutoCutState['ui']['parameters'],
  cutButtonMessage: '',
  currentTranscription: {
    usedModel: 0,
  },
  swearWords: {
    swearWordsDictionary: [],
  },
  podcast: {
    hasEnoughClips: false,
  },
  currentErrorId: '',
  resize: {
    customPresets: [],
  },
  broll: {
    usersToCredit: [],
  },
  isTourOpened: false,
  process: {
    mode: AutocutModes.Legacy,
    isProcessing: false,
    isPaused: false,
    durationProcessMinutes: 0,
    durationProcessSeconds: 0,
    numberOfCutsDone: 0,
  },
  selection: {
    isLoading: false,
  },
  deactivatedTrackIndexes: [],
  selectedSections: [],
  isPinned: true,
  appUUID: '',
};

export const zSync_UI: ZustandSyncConfig<ZKeys_UI> = {
  'ui.language': {
    cookieName: 'language',
    defaultValue: 'EN',
  },
  'ui.swearWords.swearWordsDictionary': {
    cookieName: 'swearWordsDictionary',
    defaultValue: [],
  },
  'ui.resize.customPresets': {
    cookieName: 'resizeCustomPresets',
    defaultValue: [],
  },
  'ui.host': {
    cookieName: 'lastHost',
    defaultValue: [],
  },
  'ui.isPinned': {
    cookieName: 'isPinned',
    defaultValue: true,
  },
  //Modes parameters
  'ui.parameters.broll': {
    cookieName: 'brollParams',
    defaultValue: AutocutModes.BRoll.defaultParameters,
  },
  'ui.parameters.caption': {
    cookieName: 'captionsParams',
    defaultValue: AutocutModes.Captions.defaultParameters,
  },
  'ui.parameters.podcast': {
    cookieName: 'podcastParams',
    defaultValue: AutocutModes.Podcast.defaultParameters,
  },
  'ui.parameters.resize': {
    cookieName: 'resizeParams',
    defaultValue: AutocutModes.Resize.defaultParameters,
  },
  'ui.parameters.silence': {
    cookieName: 'cuttingParams',
    defaultValue: AutocutModes.Legacy.defaultParameters,
  },
  'ui.parameters.repeat': {
    cookieName: 'repeatParams',
    defaultValue: AutocutModes.Repeat.defaultParameters,
  },
  'ui.parameters.swear_word': {
    cookieName: 'swearWordParams',
    defaultValue: AutocutModes.SwearWords.defaultParameters,
  },
  'ui.parameters.chapters': {
    cookieName: 'chaptersParams',
    defaultValue: AutocutModes.Chapters.defaultParameters,
  },
  'ui.parameters.viral_clips': {
    cookieName: 'viralClipsParams',
    defaultValue: AutocutModes.ViralClips.defaultParameters,
  },
  'ui.parameters.zoom': {
    cookieName: 'zoomParams',
    defaultValue: AutocutModes.Zoom.defaultParameters,
  },
  'ui.appUUID': {
    cookieName: 'appUUID',
  },
};

export const zSubscribers_UI: AutoCutStateSubscriberConfig<ZKeys_UI> = {
  'ui.language': k => syncToCookies(k),
  'ui.swearWords.swearWordsDictionary': k => syncToCookies(k),
  'ui.resize.customPresets': k => syncToCookies(k),
  'ui.host': k => syncToCookies(k),
  'ui.isPinned': k => syncToCookies(k),
  //Modes parameters
  'ui.parameters.broll': k => syncToCookies(k),
  'ui.parameters.caption': k => syncToCookies(k),
  'ui.parameters.podcast': k => syncToCookies(k),
  'ui.parameters.resize': k => syncToCookies(k),
  'ui.parameters.silence': k => syncToCookies(k),
  'ui.parameters.swear_word': k => syncToCookies(k),
  'ui.parameters.zoom': k => syncToCookies(k),
  'ui.parameters.chapters': k => syncToCookies(k),
  'ui.parameters.viral_clips': k => syncToCookies(k),
};
