import type {CaptionCanvasObjectHook} from '../captionHooks.type';

import {useCanvasObject} from '../captionDrawHooks.utils';
import {useDrawText} from './useDrawText';

export const useTextCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawText(args);

  return useCanvasObject({
    name: 'highlightText',
    paths: {
      x: 'position.xPercentage',
      y: 'position.yPercentage',
    },
    ...drawReturn,
    ...args,
  });
};
