import {ModalType} from '@autocut/enums/modals.enum';
import {syncSelectedFont} from '@autocut/utils/fonts.utils';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {displayPriorityModal} from '@autocut/utils/modal/displayPriorityModal';
import {handleCaptionsProcess} from '@autocut/utils/process/captions/handleCaptionsProcess';
import {useIntl} from 'react-intl';

export const useHandleAddCaptions = () => {
  const intl = useIntl();

  const addCaptions = async () => {
    const syncedFont = syncSelectedFont();
    if (!syncedFont) {
      displayPriorityModal(ModalType.FontNotFound);
      return;
    }

    await handleCaptionsProcess(intl);
    await handleTrialTask('captions');
  };

  return {addCaptions};
};
