import {TextWithHelper} from '@autocut/components/TextWithHelper';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';

import css from './ZoomConstraintStep.module.css';

export const ZoomConstraintStep = () => {
  const zoomParameters = useAutoCutStore(state => state.ui.parameters.zoom);

  return (
    <FlexContainer
      justifyContent="flex-start"
      alignItems="center"
      gap={24}
      style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s4}}
    >
      <div className={css.title}>
        <TextWithHelper
          helperId="modes_zoom_parameters_constraint_helper"
          helperDefault="If yes, no zoom will span over more than one clip. If no, your whole selection will be processed as one single audio file and zooms will ignore individuals clips in the timeline."
        >
          <TranslatedMessage
            id="modes_zoom_parameters_constraint_title"
            defaultMessage="Constraint zooms to clip"
          />
        </TextWithHelper>
      </div>
      <div className={css.input}>
        <CheckBox
          variant="box"
          size={16}
          checked={zoomParameters.constraintZoom}
          onChange={(value: boolean) =>
            setAutocutStore('ui.parameters.zoom.constraintZoom', value)
          }
        />
      </div>
    </FlexContainer>
  );
};
