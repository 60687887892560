import type {StartUpModal} from '@autocut/types/StartUpModal';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {GenerateZustandKeys} from '@autocut/utils/zustand/zustand';

import {
  syncToCookies,
  type ZustandSyncConfig,
} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_Modals = 'modal';

export type ZType_Modals = {
  openedModalName: string;
  modalQueue: string[];
  startUpModals: StartUpModal[];
  data: {
    electronVersionModal: {
      title: string;
      description: string;
      ctaText: string;
      ctaLink: string;
    };
    hostUpdate: {
      hostName: 'ppro' | 'davinci';
    };
  };
  dontShowAgain: {
    noLinkModal: boolean;
    checkTranscriptModal: boolean;
  };
};

export type ZKeys_Modals = GenerateZustandKeys<ZBaseKey_Modals, ZType_Modals>;

export const zDefaultValues_Modals: ZType_Modals = {
  openedModalName: '',
  modalQueue: [],
  startUpModals: [],
  data: {
    electronVersionModal: {
      title: 'You need to update manually',
      description:
        'We detect that you are using an old version of AutoCut that is no longer functioning. Please update to the new version by downloading and installing AutoCut from the link below:',
      ctaText: 'Download Update',
      ctaLink: 'https://www.autocut.com/download/',
    },
    hostUpdate: {
      hostName: 'ppro',
    },
  },
  dontShowAgain: {
    noLinkModal: false,
    checkTranscriptModal: false,
  },
};

export const zSync_Modals: ZustandSyncConfig<ZKeys_Modals> = {
  'modal.dontShowAgain.noLinkModal': {
    cookieName: 'dontShowAgainNoLinkModal',
    defaultValue: zDefaultValues_Modals.dontShowAgain.noLinkModal,
  },
  'modal.dontShowAgain.checkTranscriptModal': {
    cookieName: 'dontShowAgainCheckTranscriptModal',
    defaultValue: zDefaultValues_Modals.dontShowAgain.checkTranscriptModal,
  },
};

export const zSubscribers_Modals: AutoCutStateSubscriberConfig<ZKeys_Modals> = {
  'modal.dontShowAgain.noLinkModal': k => syncToCookies(k),
  'modal.dontShowAgain.checkTranscriptModal': k => syncToCookies(k),
};
