import {type ZustandSyncConfig} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

//

import type {DeepTypeSearch, GenerateKeys} from '@autocut/utils/type.utils';
import type {ZType_Backup} from '@autocut/utils/zustand/backup.zustand';
import type {ZType_Dev} from '@autocut/utils/zustand/dev.zustand';
import type {ZType_Game} from '@autocut/utils/zustand/game.zustand';
import type {ZType_Misc} from '@autocut/utils/zustand/misc.zustand';
import type {ZType_User} from '@autocut/utils/zustand/user.zustand';

import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {isFunction} from '@autocut/utils/type.utils';
import {zDefaultValues_Backup} from '@autocut/utils/zustand/backup.zustand';
import {zDefaultValues_Dev} from '@autocut/utils/zustand/dev.zustand';
import {zDefaultValues_Game} from '@autocut/utils/zustand/game.zustand';
import {zDefaultValues_Misc} from '@autocut/utils/zustand/misc.zustand';
import {
  zDefaultValues_Modals,
  type ZType_Modals,
} from '@autocut/utils/zustand/modals.zustand';
import {
  zDefaultValues_OnGoingProcess,
  type ZType_OnGoingProcess,
} from '@autocut/utils/zustand/onGoingProcess.zustand';
import {
  zDefaultValues_UI,
  type ZType_UI,
} from '@autocut/utils/zustand/ui.zustand';
import {zDefaultValues_User} from '@autocut/utils/zustand/user.zustand';
import {createObject} from '@autocut/utils/zustand/utils/objectManagement.zustand.utils';
import {onStateUpdateConfigs} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import {createStore} from '@udecode/zustood';

// ======== Types ========
export type AutoCutStateKeys = GenerateKeys<AutoCutState>;
export type GenerateZustandKeys<
  BasePath extends string,
  T,
> = `${BasePath extends '' ? '' : `${BasePath}.`}${GenerateKeys<T>}`;
export type AutoCutStateValueType<K extends AutoCutStateKeys> = DeepTypeSearch<
  AutoCutState,
  K
>;
export type ProgressState = {
  percentage: number;
  current: number;
  max: number;
};

export type AutoCutStateCookiesSyncConfig = ZustandSyncConfig<AutoCutStateKeys>;

export type AutoCutState = {
  backup: ZType_Backup;
  modal: ZType_Modals;
  dev: ZType_Dev;
  game: ZType_Game;
  misc: ZType_Misc;
  onGoingProcess: ZType_OnGoingProcess;
  ui: ZType_UI;
  user: ZType_User;
};

const getDefaultStorage = () =>
  ({
    backup: zDefaultValues_Backup,
    dev: zDefaultValues_Dev,
    game: zDefaultValues_Game,
    misc: zDefaultValues_Misc,
    onGoingProcess: zDefaultValues_OnGoingProcess,
    modal: zDefaultValues_Modals,
    ui: zDefaultValues_UI,
    user: zDefaultValues_User,
  }) as AutoCutState;

export const autocutStore = createStore('autoCutStore')(getDefaultStorage());

export const autocutStoreHooked = autocutStore.useStore;
export const autocutStoreVanilla = (): AutoCutState =>
  autocutStore.store.getState();

export const setAutocutStore = <K extends AutoCutStateKeys>(
  key: K,
  valueOrFunction:
    | AutoCutStateValueType<K>
    | ((state: AutoCutState) => AutoCutStateValueType<K>),
) => {
  const getValue = isFunction(valueOrFunction)
    ? valueOrFunction
    : () => valueOrFunction;

  // The typing allow to end the key with .undefined, next line is a safeguard
  key = key.replace(/\.undefined$/, '') as K;

  const keys = key.split('.') as string[];
  const value = getValue(autocutStoreVanilla());

  const actualState = autocutStoreVanilla();

  autocutStore.set[keys[0] as keyof AutoCutState](
    createObject(actualState, keys, value)[keys[0]],
  );

  const keyToSync = Object.keys(onStateUpdateConfigs).find(parentKey =>
    key.startsWith(parentKey),
  ) as AutoCutStateKeys;

  const onUpdate = onStateUpdateConfigs[keyToSync];
  const callStack = onUpdate
    ? Array.isArray(onUpdate)
      ? onUpdate
      : [onUpdate]
    : [];
  callStack.forEach(call => call?.(keyToSync));
};

if (CURRENT_ENV !== Env.Production) {
  //@ts-ignore
  window.zustand = () => autocutStoreVanilla();
}
