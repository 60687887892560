import type {CaptionsPreset} from '@autocut/types/CaptionsParameters';
import type {AutoCutApiError} from '@autocut/utils/errors/AutoCutApiError';

import {FingerprintErrorFactory} from '@autocut/utils/errors/FingerprintErrorFactory';
import {autocutApi} from '@autocut/utils/http.utils';
import {manageError} from '@autocut/utils/manageError';

import {decodeCaptionParams} from './applyPresets';

export const getCaptionsPresetsFromKey = async () => {
  const res = await autocutApi
    .get(`/captions-presets/from-key`)
    .catch((error: AutoCutApiError) => {
      manageError({
        error: FingerprintErrorFactory(error, 'getCaptionsPresetsFromKey'),
      });
      return null;
    });

  if (res?.status === 200) {
    const captionsPresets: CaptionsPreset[] = res.data.map(
      ({
        id,
        title,
        params,
        usageCount,
        videoUrl,
        thumbnailUrl,
        isPublic,
      }: Omit<CaptionsPreset, 'params'> & {params: string}) => {
        const decodedParams = decodeCaptionParams(params);
        return {
          id,
          title,
          params: decodedParams,
          usageCount,
          videoUrl,
          thumbnailUrl,
          isPublic,
        };
      },
    );

    return captionsPresets;
  }

  return null;
};
