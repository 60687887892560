import type React from 'react';

import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

type CaptionsTranscriptStepContainerProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>;

export const CaptionsTranscriptStepContainer = ({
  title,
  subtitle,
  children,
  ...props
}: CaptionsTranscriptStepContainerProps) => {
  return (
    <FlexContainer
      {...props}
      flexDirection="column"
      gap={4}
      alignItems="flex-start"
      style={{
        padding: 16,

        width: '100%',
      }}
    >
      <FormSection
        title={title}
        description={subtitle}
        variant="secondary"
      >
        <PaddedSection>{children}</PaddedSection>
      </FormSection>
    </FlexContainer>
  );
};
