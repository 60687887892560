import LogoUrl from '@autocut/assets/images/logo.svg';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload, waitForPreload} from '@autocut/types/ElectronPreload';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {KebabMenu} from '../KebabMenu';
import {SelectLang} from '../SelectLang/SelectLang';
import {VersionDisplay} from '../VersionDisplay/VersionDisplay';
import css from './header.module.scss';
import {HeaderButton} from './HeaderButton';
const Logo = new URL(LogoUrl, import.meta.url).href;
import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconHandDrawnArrow} from '@autocut/designSystem/components/atoms/Icon/arrows/IconHandDrawnArrow';
import {IconCode02} from '@autocut/designSystem/components/atoms/Icon/development/IconCode02';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {FaMinus} from 'react-icons/fa';
import {GoPin} from 'react-icons/go';
import {IoMdClose} from 'react-icons/io';
import {useLocation} from 'react-router-dom';

import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';

export const Header = () => {
  const navigate = useNavigate();

  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  const showDevButton = CURRENT_ENV && CURRENT_ENV !== Env.Production;

  return (
    <FlexContainer
      flexDirection={isMac ? 'row' : 'row-reverse'}
      justifyContent="space-between"
      alignItems="center"
      className={css.headerRootContainer}
    >
      <WindowManagementButtons isMac={isMac} />

      <FlexContainer
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        gap={8}
        className={css.applicationLogoAndNameContainer}
      >
        <img
          src={Logo}
          className={css.autocutLogo}
          alt="autocut_logo"
        />
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          gap={1}
        >
          <Text className={css.applicationName}>
            {CURRENT_ENV === Env.Staging
              ? 'AutoStg'
              : CURRENT_ENV === Env.Development
                ? 'AutoDev'
                : 'AutoCut'}
          </Text>
          <VersionDisplay />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        flexDirection={isMac ? 'row' : 'row-reverse'}
        flexWrap="nowrap"
        alignItems="center"
        gap={12}
        className={css.undraggable}
      >
        {showDevButton && (
          <ActionIcon
            variant="subtle"
            color={location.pathname === '/dev' ? colors.primary500 : 'white'}
            onClick={() => {
              if (location.pathname === '/dev') {
                navigate(-1);
              } else {
                navigate('/dev');
              }
            }}
            size={32}
          >
            <IconCode02 size={24} />
          </ActionIcon>
        )}
        <SelectLang />
        <KebabMenu />
      </FlexContainer>
    </FlexContainer>
  );
};

const WindowManagementButtons = ({isMac}: {isMac: boolean}) => {
  const isPinned = useAutoCutStore(state => state.ui.isPinned);

  const [hasHovered, setHasHovered] = useState(false);
  const location = useLocation();

  const handlePin = () => {
    const value = preload.electron.togglePinWindow();
    setAutocutStore('ui.isPinned', value);
  };

  const isHomepage = location.pathname === '/homepage';

  useEffect(() => {
    const init = async () => {
      await waitForPreload();

      /**
       * The default state is pinned, so we need to unpin it
       * if the user
       */
      setTimeout(() => {
        const isPinned = autocutStoreVanilla().ui.isPinned;
        if (!isPinned) {
          handlePin();
        }
      }, 500);
    };

    void init();
  }, []);

  return (
    <FlexContainer
      flexDirection={isMac ? 'row' : 'row-reverse'}
      flexWrap="nowrap"
      alignItems="center"
      gap={8}
      style={{
        zIndex: 100000, // Allow to close AutoCut when the modal is open
      }}
    >
      <HeaderButton
        colors={{mac: '#ff6159', windows: '#ff6159'}}
        onClick={() => window.close()}
        icon={IoMdClose}
        isMac={isMac}
      />
      <HeaderButton
        colors={{mac: '#ffbd2e', windows: 'transparent'}}
        onClick={() => preload.electron.minimize()}
        icon={FaMinus}
        isMac={isMac}
      />
      <div style={{position: 'relative'}}>
        <HeaderButton
          colors={{
            mac: isPinned ? '#d444f1' : '#0ba5ec',
            windows: 'transparent',
          }}
          onClick={handlePin}
          icon={GoPin}
          forcedIcon={isPinned}
          isMac={isMac}
          onMouseEnter={() => setHasHovered(true)}
        />

        {isHomepage && !hasHovered && (
          <FlexContainer
            style={{
              position: 'absolute',
              width: '50px',
              top: '25px',
              transform: isMac ? undefined : 'translateX(-50%)',
              color: 'white',
            }}
            flexDirection={isMac ? 'row' : 'row-reverse'}
            gap={0}
          >
            <IconHandDrawnArrow
              size={50}
              style={{
                transform: isMac ? undefined : 'rotateY(180deg)',
              }}
            />
            <Text
              variant="textXs.semibold"
              color="white"
              style={{
                transform: isMac
                  ? 'translate(50%, 50%)'
                  : 'translate( -50%, 50%)',
                textAlign: 'center',
                position: 'absolute',
                bottom: '0',
              }}
            >
              <TranslatedMessage
                id="homepage_header_pinWindow"
                defaultMessage="Stay on top"
              />
            </Text>
          </FlexContainer>
        )}
      </div>
    </FlexContainer>
  );
};
