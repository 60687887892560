import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import {z as zod} from 'zod';

export const rgbValidationSchema = zod.object({
  r: zod
    .number()
    .min(0, 'modes_general_error_validation_rgb' as TranslationKeys)
    .max(255, 'modes_general_error_validation_rgb' as TranslationKeys)
    .default(255),
  g: zod
    .number()
    .min(0, 'modes_general_error_validation_rgb' as TranslationKeys)
    .max(255, 'modes_general_error_validation_rgb' as TranslationKeys)
    .default(255),
  b: zod
    .number()
    .min(0, 'modes_general_error_validation_rgb' as TranslationKeys)
    .max(255, 'modes_general_error_validation_rgb' as TranslationKeys)
    .default(255),
});
