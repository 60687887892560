import type {TAutoCutApiError} from './errors/AutoCutApiError';

import {isPreloadReady, preload} from '@autocut/types/ElectronPreload';
import axios from 'axios';

import json from '../../package.json';
import {AutoCutApiError} from './errors/AutoCutApiError';

export let accessTokensModule: {
  accessToken: string;
  refreshToken: string;
};
let areInterceptorsInitialized = false;

const autocutApi = new Proxy(
  axios.create({
    headers: {
      'AutoCut-Client': `Extension-${json.version}`,
    },
  }),
  {
    get(target, prop) {
      if (prop !== 'defaults' && target.defaults.baseURL === undefined) {
        initApi();
      }

      return Reflect.get(target, prop);
    },
  },
);

export const initApi = () => {
  if (autocutApi.defaults.baseURL) return;

  const apiURL =
    preload.electron.getSharedVariables?.()?.API_URL ||
    'https://api.autocut.fr'; //TEMP fix to handle the update of AEA when getSharedVariables has been added

  console.log('Init API with URL: ', apiURL);

  autocutApi.defaults.baseURL = apiURL;
};

export const initInterceptors = () => {
  if (areInterceptorsInitialized) return;

  autocutApi.interceptors.request.use((config: any) => {
    // Modify the request config
    config.headers.Authorization = `Bearer ${accessTokensModule.accessToken}`;
    return config;
  });

  autocutApi.interceptors.response.use(
    response => response,
    async (error: TAutoCutApiError) => {
      const originalRequest = error.config as any;
      const apiError = new AutoCutApiError(error, originalRequest.url ?? '?');

      if (
        apiError.status === 403 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        const newTokens = await refreshAccessToken(
          accessTokensModule.refreshToken,
        );

        originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
        setTokens(newTokens);

        return axios.request(originalRequest);
      }

      return Promise.reject(apiError);
    },
  );

  areInterceptorsInitialized = true;
};

export const setTokens = (accessTokens: {
  accessToken: string;
  refreshToken: string;
}) => {
  accessTokensModule = accessTokens;
};

const refreshAccessToken = async (
  refreshToken: string,
): Promise<{
  accessToken: string;
  refreshToken: string;
}> => {
  try {
    const response = await autocutApi.post(`/auth/refresh`, {
      refreshToken,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const http = axios;

export {autocutApi, http};
