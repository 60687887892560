import type {CaptionChunk} from '@autocut/types/Captions';

import type {getSelectedTransitions} from './_getSelectedTransitions';
import type {ResizedXMLTemplates} from './_resizeTemplate';

import {secondToTick} from '../temp/_ticks.temp';

export const handleTextTransitions = ({
  chunk,
  chunkIndex,
  templates,
  selectedTransitions,
}: {
  chunk: CaptionChunk;
  chunkIndex: number;
  templates: ResizedXMLTemplates;
  selectedTransitions: ReturnType<typeof getSelectedTransitions>;
}) => {
  if (!selectedTransitions.length) return;

  const {start, end} = chunk;

  templates.effectsClip.clone({
    startTick: secondToTick(start).toString(),
    endTick: secondToTick(end).toString(),
    index: chunkIndex,
    copiedVideoFilterComponents: selectedTransitions,
  });
};
