import type {AutocutModesKeys} from '@autocut/enums/modes.enum';

import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconRefreshCcw01} from '@autocut/designSystem/components/atoms/Icon/arrows/IconRefreshCcw01';
import {IconBarChart04} from '@autocut/designSystem/components/atoms/Icon/charts/IconBarChart04';
import {IconMail01} from '@autocut/designSystem/components/atoms/Icon/communication/IconMail01';
import {IconMessagePlusSquare} from '@autocut/designSystem/components/atoms/Icon/communication/IconMessagePlusSquare';
import {IconOpenedBook} from '@autocut/designSystem/components/atoms/Icon/education/IconOpenedBook';
import {IconDotsVertical} from '@autocut/designSystem/components/atoms/Icon/general/IconDotsVertical';
import {IconLogOut01} from '@autocut/designSystem/components/atoms/Icon/general/IconLogOut01';
import {IconUser01} from '@autocut/designSystem/components/atoms/Icon/users/IconUser01';
import {IconStars02} from '@autocut/designSystem/components/atoms/Icon/weather/IconStars02';
import PopupPortal from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {preload} from '@autocut/types/ElectronPreload';
import {logout} from '@autocut/utils/license/logout';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {getModeByLocation} from '@autocut/utils/modes.utils';
import {parameterReset} from '@autocut/utils/observers/parameterReset';
import {logOutSentryUser} from '@autocut/utils/sentry.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import css from './kebabMenu.module.css';
import {MenuItem} from './MenuItem';

export const KebabMenu = () => {
  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  const isLogged = useAutoCutStore(state => state.user.isLogged);
  const {logMessage} = useLogger();
  const navigate = useNavigate();
  const location = useLocation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const intl = useIntl();

  const handleLogOut = () => {
    logMessage(logLevel.info, 'User Logged out.');
    logout("User clicked on 'Log out' button in the kebab menu.");
    logOutSentryUser();
    navigate('/');
  };

  const looseFocus = () => {
    setIsPopupOpen(false);
  };

  return (
    <div
      ref={ref}
      style={{position: 'relative'}}
    >
      <ActionIcon
        onClick={() => !isPopupOpen && setIsPopupOpen(true)}
        size={32}
        variant="filled"
        color={colors.gray800}
        secondaryColor={colors.gray300}
        style={{
          borderRadius: '50%',
          pointerEvents: isPopupOpen ? 'none' : 'auto',
        }}
      >
        <IconDotsVertical size={20} />
      </ActionIcon>
      {isPopupOpen && (
        <PopupPortal
          target={ref}
          onClickOutside={() => setIsPopupOpen(false)}
          position={isMac ? 'bottom-left' : 'bottom-right'}
          containerStyle={{
            transform: `translateY(4px) ${isMac ? 'translateX(calc(-100%))' : undefined}`,
          }}
        >
          <FlexContainer
            flexDirection="column"
            className={css.menu}
          >
            <MenuItem
              icon={
                <IconUser01
                  size={16}
                  color="white"
                />
              }
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_account',
                defaultMessage: 'Manage licence key',
              })}
              onClick={() => {
                looseFocus();
                logMessage(logLevel.info, 'User went to account page.');
                void preload.electron.openLinkInBrowser(
                  preload.electron.getSharedVariables().WEBAPP_URL,
                );
              }}
            />
            <MenuItem
              icon={
                <IconBarChart04
                  size={16}
                  color="white"
                />
              }
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_usageStatistics',
                defaultMessage: 'Usage statistics',
              })}
              onClick={() => {
                looseFocus();
                addModalToQueue(ModalType.UsageStats);
              }}
            />
            <MenuItem
              icon={
                <IconOpenedBook
                  size={16}
                  color="white"
                />
              }
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_academy',
                defaultMessage: 'Help',
              })}
              onClick={() => {
                looseFocus();
                logMessage(logLevel.info, 'User went to academy');

                void preload.electron.openLinkInBrowser(
                  preload.electron.getSharedVariables().ACADEMY_URL,
                );
              }}
            />
            <MenuItem
              icon={
                <IconMessagePlusSquare
                  size={16}
                  color="white"
                />
              }
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_review',
                defaultMessage: 'Review AutoCut',
              })}
              onClick={() => {
                looseFocus();
                logMessage(logLevel.info, 'User opened Senja in browser.');
                void preload.electron.openLinkInBrowser(
                  'https://senja.io/p/autocut/r/ikmgDE',
                );
              }}
            />
            <MenuItem
              icon={
                <IconMail01
                  size={16}
                  color="white"
                />
              }
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_bug',
                defaultMessage: 'Bug report',
              })}
              onClick={() => {
                looseFocus();
                logMessage(logLevel.info, 'User opened bug report modal.');
                addModalToQueue(ModalType.ReportIssue);
              }}
            />
            <MenuItem
              icon={
                <IconStars02
                  size={16}
                  color="white"
                />
              }
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_featureRequest',
                defaultMessage: 'Feature request',
              })}
              onClick={() => {
                looseFocus();
                logMessage(logLevel.info, 'User opened feature request modal.');
                addModalToQueue(ModalType.FeatureRequest);
              }}
            />

            <MenuItem
              label={intl.formatMessage({
                id: 'globals_defaults_components_KebabMenu_settings',
                defaultMessage: 'Reset settings',
              })}
              onClick={() => {
                looseFocus();

                const mode = getModeByLocation(location.pathname);

                if (!mode) {
                  Object.keys(AutocutModes).map((modeKey: string) => {
                    const mode = AutocutModes[modeKey as AutocutModesKeys];

                    setAutocutStore(
                      mode.parametersPath,
                      mode.defaultParameters as any,
                    );
                  });

                  return;
                }

                parameterReset.reset();

                setAutocutStore(
                  mode.parametersPath,
                  mode.defaultParameters as any,
                );
              }}
              icon={
                <IconRefreshCcw01
                  size={16}
                  color="white"
                />
              }
            />

            {isLogged && (
              <MenuItem
                icon={
                  <IconLogOut01
                    size={16}
                    color="white"
                  />
                }
                label={intl.formatMessage({
                  id: 'globals_defaults_components_KebabMenu_logout',
                  defaultMessage: 'Logout',
                })}
                onClick={() => {
                  looseFocus();
                  handleLogOut();
                }}
              />
            )}
          </FlexContainer>
        </PopupPortal>
      )}
    </div>
  );
};
