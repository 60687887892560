import {compute} from '@autocut/utils/compute.utils';
import {convertFramesToSeconds} from '@autocut/utils/frames';
import {host} from '@autocut/utils/host';
import {autocutStoreVanilla} from '@autocut/utils/zustand/zustand';

export const PRECISION = 0.05;

export const getSilenceIntervals = (filePath: string) => {
  return compute.utils.getSilenceIntervals({
    filePath,
    ...autocutStoreVanilla().ui.parameters.silence,
  });
};

export const renderTimelineForPreview = async () => {
  const autocutStore = autocutStoreVanilla();
  const {firstInPoint, lastOutPoint} = {
    firstInPoint: autocutStore.ui.selectedSections[0][0],
    lastOutPoint: autocutStore.ui.selectedSections.at(-1)?.[1],
  };

  if (firstInPoint === undefined || lastOutPoint === undefined) return;

  const [[inPointSeconds, outPointSeconds]] = await convertFramesToSeconds([
    [firstInPoint, lastOutPoint],
  ]);

  const [resultAudioPath] = await compute.timeline.render({
    descriptor: [[inPointSeconds, outPointSeconds]],
    convertToMono: true,
  });

  const duration = outPointSeconds - inPointSeconds;

  return {
    resultAudioPath,
    duration,
    inPoint: inPointSeconds,
    outPoint: outPointSeconds,
  };
};

export const getDbValues = async (
  filePath: string | undefined,
  start: number,
  end: number,
) => {
  if (!filePath) return [];

  const dbValues = await compute.utils.getDbValuesBetween({
    filePath,
    start,
    end,
    precision: PRECISION,
  });

  return dbValues;
};

export const calculatePreviewInterval = (
  previous: {
    startTime: number;
    endTime: number;
  },
  duration: number,
  direction: 'previous' | 'next',
) => {
  if (
    (previous.startTime === 0 && direction === 'previous') ||
    (previous.endTime >= duration && direction === 'next')
  ) {
    return [previous.startTime, previous.endTime];
  }

  if (direction === 'previous' && previous.startTime <= 5) {
    return [0, 10];
  }

  if (direction === 'next' && previous.endTime >= duration - 5) {
    const endTime = Math.ceil(duration / 5) * 5;
    return [endTime - 10, endTime];
  }

  const newStartTime =
    direction === 'previous' ? previous.startTime - 5 : previous.startTime + 5;
  const newEndTime =
    direction === 'previous' ? previous.endTime - 5 : previous.endTime + 5;

  return [newStartTime, newEndTime];
};

export const getPreviewIntervalOnCurrentTimecode = async ({
  duration,
  inPoint,
}: {
  duration: number;
  inPoint: number;
}) => {
  const currentTimecode = await host.timeline.getCurrentTimecode();
  const [[currentTimecodeSeconds, _]] = await convertFramesToSeconds([
    [currentTimecode, 0],
  ]);
  const currentTimecodeSecondsInTimeline = currentTimecodeSeconds - inPoint;
  if (currentTimecodeSecondsInTimeline < 5) {
    return [0, 10];
  }

  if (currentTimecodeSecondsInTimeline + 5 > duration) {
    const endTime = Math.ceil(duration / 5) * 5;
    return [endTime - 10, endTime];
  }

  return [
    currentTimecodeSecondsInTimeline - 5,
    currentTimecodeSecondsInTimeline + 5,
  ];
};

export const calculateSilencesInPreview = (
  silenceIntervals: number[][],
  start: number,
  end: number,
) => {
  if (!silenceIntervals) return [];

  const silencesInPreview = [];

  for (const [silenceStart, silenceEnd] of silenceIntervals) {
    if (silenceStart <= end && silenceEnd >= start) {
      silencesInPreview.push([silenceStart, silenceEnd]);
    }
  }

  if (silencesInPreview.length === 0) return [];

  if (silencesInPreview[0][0] < start) {
    silencesInPreview[0][0] = start;
  }

  if (silencesInPreview[silencesInPreview.length - 1][1] > end) {
    silencesInPreview[silencesInPreview.length - 1][1] = end;
  }

  const temp = silencesInPreview.map(silence =>
    silence.map(value => (value - start) / (end - start)),
  );

  return temp;
};
