import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

const BoxesCustomization = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();

  const xPadding = value.box.xPadding;
  const yPadding = value.box.yPadding;
  const opacity = value.box.opacity;
  const color = value.box.color;
  const enabled = value.box.enabled;
  const radius = value.box.radius;
  const font = value.text.font;
  const unsupportedFeatures = value.languageOfTranscription.unsupportedFeatures;

  const isNotSupported = unsupportedFeatures?.(font).wordBox.disabled;
  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled && !isNotSupported}
      onStatusChange={checked =>
        !isNotSupported && setValue('box.enabled', checked)
      }
      hiddenContent={
        isNotSupported && (
          <InfoText style="warning">
            <FlexContainer
              flexDirection="column"
              gap={4}
            >
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_style_modal_box_disabled'
                }
                defaultMessage={
                  'The font you have chosen does not support the box feature for the language you have selected.'
                }
              />
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_style_modal_box_tips'
                }
                defaultMessage={
                  'Pick a font that supports the language you have selected if you want to use this feature.'
                }
              />
            </FlexContainer>
          </InfoText>
        )
      }
      title={
        <TranslatedMessage
          id={'modes_caption_steps_customization_parts_style_modal_box_title'}
          defaultMessage="Text box"
        />
      }
    >
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_text_color'
            }
            defaultMessage="Color"
          />
        }
      >
        <ColorPicker
          value={color}
          onChange={color => setValue('box.color', color)}
        />
      </ParameterLayout>
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_box_paddingX'
            }
            defaultMessage="Padding x"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={isNaN(xPadding) || !xPadding ? 0 : xPadding}
          min={0}
          max={100}
          formatValue={value => `${value}%`}
          onChange={value => setValue('box.xPadding', value)}
        />
      </ParameterLayout>

      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_box_paddingY'
            }
            defaultMessage="Padding y"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={isNaN(yPadding) || !yPadding ? 0 : yPadding}
          min={0}
          max={100}
          formatValue={value => `${value}%`}
          onChange={value => setValue('box.yPadding', value)}
        />
      </ParameterLayout>

      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_box_opacity'
            }
            defaultMessage="Opacity"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={isNaN(opacity) || !opacity ? 0 : opacity}
          min={0}
          max={100}
          formatValue={value => `${value}%`}
          onChange={value => setValue('box.opacity', value)}
        />
      </ParameterLayout>
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_box_radius'
            }
            defaultMessage="Radius"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={isNaN(radius) || !radius ? 0 : radius}
          min={0}
          max={100}
          formatValue={value => `${value}%`}
          onChange={value => setValue('box.radius', value)}
        />
      </ParameterLayout>
    </ParametersGroupLayout>
  );
};

export default BoxesCustomization;
