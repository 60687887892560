import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconGift01} from '@autocut/designSystem/components/atoms/Icon/general/IconGift01';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';

import css from './GamingTrialAffix.module.css';

export const GamingTrialAffix = () => {
  const {openedModalName, license} = useAutoCutStore(state => ({
    openedModalName: state.modal.openedModalName,
    license: state.user.license,
  }));

  if (!license || license.type !== 'trial') return null;

  return openedModalName.length ? null : (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={4}
      className={css.container}
    >
      <ActionIcon
        variant="primary"
        size={40}
        onClick={() => addModalToQueue(ModalType.GamingTrial)}
      >
        <IconGift01
          size={24}
          color="white"
        />
      </ActionIcon>
      <Text
        variant="textXs.medium"
        color="white"
        style={{maxWidth: '100%', textAlign: 'center', fontStyle: 'italic'}}
      >
        <TranslatedMessage
          id="homepage_affix_gamifiedFreeTrial_text"
          defaultMessage="{daysLeft} days left"
          values={{daysLeft: license.left}}
        />
      </Text>
    </FlexContainer>
  );
};
