import {FingerprintErrorFactory} from './errors/FingerprintErrorFactory';
import {clamp} from './math.utils';
import {getParametersForMode} from './parameters.utils';
import {setAutocutStore} from './zustand/zustand';

export const updateCoverage = (
  propertyName: 'smoothZoomPercentage' | 'dynamicZoomPercentage',
  newValue: number,
) => {
  const {smoothZoomPercentage, dynamicZoomPercentage} =
    getParametersForMode('zoom');
  const newCoverageValues = computeNewCoverage(
    {
      smoothZoomPercentage,
      dynamicZoomPercentage,
    },
    propertyName,
    newValue,
  );

  setAutocutStore(
    'ui.parameters.zoom.smoothZoomPercentage',
    newCoverageValues.smoothZoomPercentage,
  );
  setAutocutStore(
    'ui.parameters.zoom.dynamicZoomPercentage',
    newCoverageValues.dynamicZoomPercentage,
  );
};

const computeNewCoverage = (
  properties: {
    smoothZoomPercentage: number;
    dynamicZoomPercentage: number;
  },
  propertyName: 'smoothZoomPercentage' | 'dynamicZoomPercentage',
  newValue: number,
) => {
  const totalValue = 1;
  const difference = newValue - properties[propertyName];
  const remainingValue = totalValue - newValue;
  let totalOtherPropertiesValue = 0; // Sum of the values of other properties

  if (newValue < 0 || newValue > 1) {
    throw FingerprintErrorFactory(
      'computeNewCoverage',
      'New value is out of bounds. It should be between 0 and 1.',
    );
  }

  // Calculate the total value of other properties
  for (const prop in properties) {
    if (prop !== propertyName) {
      totalOtherPropertiesValue +=
        properties[prop as 'smoothZoomPercentage' | 'dynamicZoomPercentage'];
    }
  }

  // Update the target property
  properties[propertyName] = newValue;

  // Update the remaining properties proportionately
  for (const prop in properties) {
    if (prop !== propertyName) {
      const key = prop as 'smoothZoomPercentage' | 'dynamicZoomPercentage';
      if (totalOtherPropertiesValue === 0) {
        // Avoid division by zero
        properties[key] = remainingValue / (Object.keys(properties).length - 1);
      } else {
        const currentProportion = properties[key] / totalOtherPropertiesValue;
        properties[key] -= difference * currentProportion;
      }
      properties[key] = clamp(properties[key], 0, 1);
    }
  }

  return properties;
};
