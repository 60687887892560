import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {AiOutlineZoomIn} from 'react-icons/ai';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {ZoomCustomizationStep} from './ZoomCustomizationStep';

export const ZoomMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AiOutlineZoomIn}
      text="Auto Zoom"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/zoom"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="zoom"
                onValidateSections={() => navigate('./customization')}
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_customization_title',
              defaultMessage: 'Customize parameters',
            }),
            path: 'customization',
            element: <ZoomCustomizationStep />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
