import type {StepperPointProps} from './StepperPoint/StepperPoint';

import {type CSSProperties, Fragment, useRef} from 'react';

import css from './Stepper.module.scss';
import StepperPoint from './StepperPoint/StepperPoint';

export type StepperStep<T> = {
  additionnalData?: T;
  onClick?: (step: StepperStep<T>, index: number) => void;
} & Omit<StepperPointProps, 'onClick' | 'active' | 'size' | 'labelAlign'>;

export type StepperProps<T> = {
  activeIndex: number;
  steps: StepperStep<T>[];
  barHeight?: number;
} & Omit<StepperPointProps, 'active' | 'onClick' | 'label'>;

const Stepper = <T,>({
  activeIndex,
  steps,
  barHeight,
  color = 'var(--gray-500)',
  activeColor = 'white',
  ...pointProps
}: StepperProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={css.stepContainer}
      style={
        {
          '--size': `${pointProps.size ?? 24}px`,
          '--nb-displayed-step': 3,
          '--nb-step': steps.length,
          justifyContent: steps.length === 1 ? 'center' : undefined,
        } as CSSProperties
      }
    >
      {steps.length === 1 ? <div /> : null}
      {steps.map((step, index) => (
        <Fragment key={index}>
          <StepperPoint
            active={activeIndex >= index}
            current={activeIndex === index - 1}
            onClick={
              step.onClick ? () => step.onClick?.(step, index) : undefined
            }
            color={color}
            activeColor={activeColor}
            label={step.label}
            className={`${
              steps.length <= 3
                ? undefined
                : index === activeIndex
                  ? css.firstLabeled
                  : index === activeIndex + 1
                    ? undefined
                    : index === activeIndex + 2
                      ? css.lastLabeled
                      : css.hidden
            } ${step.className ?? ''}`}
            style={
              {
                '--num-step': index + 1,
                ...(step.style || {}),
              } as CSSProperties
            }
            {...pointProps}
          />
          {index < steps.length - 1 && (
            <div
              className={`${css.bar} ${index === activeIndex ? css.animated : ''}`}
              style={
                {
                  '--animation-duration': '1s',
                  '--size': `${pointProps.size ?? 24}px`,
                  '--active-color': activeColor,
                  '--color': activeIndex > index ? activeColor : color,
                  '--bar-height': barHeight ? `${barHeight}px` : undefined,
                  '--min-width':
                    activeIndex === index || activeIndex === index - 1
                      ? '100px'
                      : undefined,
                } as CSSProperties
              }
            />
          )}
        </Fragment>
      ))}
      {steps.length === 1 ? <div /> : null}
    </div>
  );
};

export default Stepper;
