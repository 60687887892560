import type React from 'react';

import {useClickOutside} from '@autocut/hooks/useClickOutside';
import {useScroll} from '@autocut/hooks/useScroll';
import {nanoid} from 'nanoid';
import {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import css from './PopupPortal.module.css';

export type PopupPortalProps = {
  target: React.RefObject<HTMLElement> | HTMLElement;
  children: React.ReactNode;
  position?:
    | 'right'
    | 'bottom'
    | 'left'
    | 'top'
    | 'bottom-right'
    | 'top-right'
    | 'bottom-left';
  onClickOutside?: () => void;
  containerStyle?: React.CSSProperties;
  offset?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const PopupPortal = ({
  target,
  children,
  position = 'bottom',
  offset = 0,
  onClickOutside,
  containerStyle,
  className,
  ...props
}: PopupPortalProps) => {
  const {addOnScroll, removeOnScroll} = useScroll();
  const targetElement = 'current' in target ? target.current : target;
  const popupRef = useClickOutside<HTMLDivElement>(
    () => onClickOutside?.(),
    true,
  );

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [transform, setTransform] = useState('translate(0, 0)');

  const randomId = useRef(nanoid()).current;

  useEffect(() => {
    const setPosition = () => {
      const targetRect = targetElement?.getBoundingClientRect();
      if (!targetRect) return;

      let top = targetRect?.top + window.scrollY;
      let left = targetRect?.left + window.scrollX;
      let transform = '';

      const topOffset = document.getElementById('modal')?.offsetTop || 0;

      switch (position) {
        case 'right':
          left = targetRect?.left + window.scrollX + targetRect?.width + offset;
          top =
            targetRect?.top +
            window.scrollY +
            targetRect?.height / 2 -
            topOffset;
          transform = `translateY(-50%)`;
          break;
        case 'left':
          left = targetRect?.left + window.scrollX - offset;
          top =
            targetRect?.top +
            window.scrollY +
            targetRect?.height / 2 -
            topOffset;
          transform = `translateX(-100%) translateY(-50%)`;
          break;
        case 'top':
          top = targetRect?.top + window.scrollY - offset - topOffset;
          transform = `translateY(-100%)`;
          break;
        case 'bottom':
          top =
            targetRect?.top +
            window.scrollY +
            targetRect?.height +
            offset -
            topOffset;
          break;
        case 'bottom-right':
          top =
            targetRect?.top +
            window.scrollY +
            targetRect?.height +
            Math.floor(offset / 2);
          left =
            targetRect?.left +
            window.scrollX +
            Math.floor(offset / 2) -
            topOffset;
          transform = `translateX(100%) translateY(-50%)`;
          break;
        case 'top-right':
          top =
            targetRect?.top +
            window.scrollY -
            Math.floor(offset / 2) -
            topOffset;
          left = targetRect?.left + window.scrollX + Math.floor(offset / 2);
          transform = `translateX(100%) translateY(-100%)`;
          break;
        case 'bottom-left':
          top =
            targetRect?.top +
            window.scrollY +
            targetRect?.height +
            Math.floor(offset / 2) -
            topOffset;
          left = targetRect?.left + window.scrollX - Math.floor(offset / 2);
          transform = `translateX(-100%)`;
          break;
      }

      setTop(top);
      setLeft(left);
      setTransform(transform);
    };

    setPosition();
    addOnScroll(randomId, setPosition);
    return () => removeOnScroll(randomId);
  }, [position, targetElement]);

  return ReactDOM.createPortal(
    <div
      id="portal"
      ref={popupRef}
      className={`${css.root} ${className}`}
      {...props}
      style={{
        zIndex: 10,
        top,
        left,
        transform,
        ...containerStyle,
      }}
    >
      {children}
    </div>,
    document.getElementById('modal') ||
      document.getElementById('app') ||
      document.body,
  );
};

export default PopupPortal;
