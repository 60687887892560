import {preload} from '@autocut/types/ElectronPreload';

const getDirPath = async () => {
  const homeDir = preload.os.homedir();
  const platform = preload.os.platform();
  let dirPath;

  switch (platform) {
    case 'darwin': {
      dirPath = preload.path.join(
        homeDir,
        'Library',
        'Caches',
        'fr.toolpad.autoCut',
      );
      break;
    }

    case 'win32': {
      dirPath = preload.path.join(
        homeDir,
        'AppData',
        'Roaming',
        'fr.toolpad.autoCut',
      );
      break;
    }

    default:
      dirPath = preload.path.join(homeDir, '.logs', 'fr.toolpad.autoCut');
      break;
  }

  try {
    if (!preload.fs.existsSync(dirPath)) {
      preload.fs.mkdirSync(dirPath);
    }
  } catch (err: any) {}

  return dirPath;
};

export const markAutoCutV2 = async () => {
  try {
    const dirPath = await getDirPath();
    const path = preload.path.join(dirPath, 'AutoCutV2Flag.txt');

    preload.fs.writeFileSync(path, 'AutoCutV2Flag');
  } catch {}
};

export const markAutoCutV2Renamed = async () => {
  try {
    const dirPath = await getDirPath();
    const path = preload.path.join(dirPath, 'AutoCutV2RenamedFlag.txt');

    preload.fs.writeFileSync(path, 'AutoCutV2Flag');
  } catch {}
};
