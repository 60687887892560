import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

const OutlineCustomization = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();

  const enabled = value.outline.enabled;
  const color = value.outline.color;
  const width = value.outline.width;

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked => setValue('outline.enabled', checked)}
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_outline_title'
          }
          defaultMessage="Outline"
        />
      }
    >
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_text_color'
            }
            defaultMessage="Color"
          />
        }
      >
        <ColorPicker
          value={color}
          onChange={color => setValue('outline.color', color)}
        />
      </ParameterLayout>
      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_outline_width'
            }
            defaultMessage="Width"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={width}
          min={0}
          max={100}
          formatValue={value => `${value}px`}
          onChange={value => setValue('outline.width', value)}
        />
      </ParameterLayout>
    </ParametersGroupLayout>
  );
};

export default OutlineCustomization;
