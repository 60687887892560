import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconStars02 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke="currentColor"
        d="M4.5 22v-5m0-10V2M2 4.5h5m-5 15h5M13 3l-1.7 4.5-.7 1.4a3 3 0 0 1-.7.7l-1.4.7L4 12l4.5 1.7 1.4.7.7.7.7 1.4L13 21l1.7-4.5.7-1.4a3 3 0 0 1 .7-.7l1.4-.7L22 12l-4.5-1.7-1.4-.7a3 3 0 0 1-.7-.7l-.7-1.4L13 3Z"
      />
    </IconComponent>
  );
};
