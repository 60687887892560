import type {TranslationKeys} from '@autocut/contexts/LocaleContext';
import type {AutocutModeIds} from '@autocut/enums/modes.enum';
import type {parametersValidationSchema} from '@autocut/validationSchemas/parameters.validationSchema';
import type {z as zod} from 'zod';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';

import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import css from './PresetCard.module.scss';

export type Preset<T extends AutocutModeIds> = {
  id: string;
  parameters: Partial<zod.infer<(typeof parametersValidationSchema)[T]>>;
};

type PresetCardProps<T extends AutocutModeIds> = {
  mode: T;
  preset: Preset<T>;
};

export const PresetCard = <T extends AutocutModeIds>({
  preset,
  mode,
}: PresetCardProps<T>) => {
  const currentParameters = useAutoCutStore(state => state.ui.parameters[mode]);

  const applyPreset = () => {
    const updatedParameters = {
      ...currentParameters,
      ...preset.parameters,
    };
    setAutocutStore(
      `ui.parameters.${mode}`,
      updatedParameters as zod.infer<(typeof parametersValidationSchema)[T]>,
    );
  };

  return (
    <div
      className={css.presetCard}
      onClick={applyPreset}
    >
      <FlexContainer
        flexDirection="row"
        flexWrap="nowrap"
        gap={10}
        onClick={applyPreset}
      >
        <p className={css.presetText}>
          <TranslatedMessage
            id={
              `modes_zoom_parameters_defaultsPresets_${preset.id}` as TranslationKeys
            }
            defaultMessage={'PRESET'}
          />
        </p>
      </FlexContainer>
    </div>
  );
};
