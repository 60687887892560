import type {FlexContainerProps} from '../FlexContainer';

import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useState} from 'react';

import {RadioButton} from '../../atoms/RadioButton/RadioButton';
import FlexContainer from '../FlexContainer';

export type RadioFormProps<T> = {
  options: RadioButton.Props<T>[];
  onChange: (value: T) => void;
} & FlexContainerProps;

export const RadioForm = <T,>({
  options,
  onChange,
  ...props
}: RadioFormProps<T>) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  );

  return (
    <FlexContainer
      flexDirection="column"
      gap={Spacing.s1}
      {...props}
    >
      {options.map((option, index) => (
        <RadioButton
          key={index}
          checked={
            selectedIndex === index ||
            (selectedIndex === undefined && option.defaultChecked)
          }
          onClick={() => {
            onChange(option.value);
            setSelectedIndex(index);
          }}
          {...option}
        />
      ))}
    </FlexContainer>
  );
};
