export const snakeToCamel = (snakeStr: string) => {
  return snakeStr
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};

export const getVersionParts = (version: string) => {
  const [fullVersion, preRelease, major, minor, patch] = version.match(
    /^(?:pre\(([0-9a-f]{8})\)\.)?v?(\d*?)\.(\d*?)\.(\d*?)$/,
  ) || [version, null, null, null, null];

  return {
    fullVersion,
    preRelease,
    major,
    minor,
    patch,
  };
};

export const isVersionEquals = (
  version1: string,
  version2: string,
  ignorePreRelease: boolean = false,
) => {
  const {
    major: major1,
    minor: minor1,
    patch: patch1,
    preRelease: preRelease1,
  } = getVersionParts(version1);
  const {
    major: major2,
    minor: minor2,
    patch: patch2,
    preRelease: preRelease2,
  } = getVersionParts(version2);
  return (
    major1 === major2 &&
    minor1 === minor2 &&
    patch1 === patch2 &&
    (ignorePreRelease || preRelease1 === preRelease2)
  );
};
