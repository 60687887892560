import type {AutoCutApiError} from '../errors/AutoCutApiError';

import {manageError} from '@autocut/utils/manageError';

import {FingerprintErrorFactory} from '../errors/FingerprintErrorFactory';
import {autocutApi} from '../http.utils';
import {setAutocutStore} from '../zustand/zustand';

export const getScoreFromApi = async () => {
  const res = await autocutApi.get(`/score`).catch((error: AutoCutApiError) => {
    manageError({
      error: FingerprintErrorFactory(error, 'getScoreFromApi'),
    });
    return;
  });

  if (res?.status === 200) {
    setAutocutStore('game.level.currentProgress', res.data);

    return res.data;
  }
};
