import logLevel from '@autocut/enums/logLevel.enum';

import {reportSentryError} from '../sentry.utils';
import {IncrementalError} from './IncrementalError';

type ErrorSource = 'davinci' | 'premiere' | 'compute';

export type ComputeErrorInterface = {
  taskId: string;
  error: IncrementalError & {from: ErrorSource};
};

export class ComputeError extends IncrementalError {
  from: ErrorSource;
  taskId: string;

  constructor({taskId, error}: ComputeErrorInterface) {
    super(error, error.fingerprint);

    this.taskId = taskId;
    this.from = error.from;
  }

  reportToSentry() {
    console.log('REPORTING SENTRY ERROR', this.fingerprint, this.toJSON());

    reportSentryError({
      fingerPrint: this.fingerprint
        .replace(/\(/g, '( - ')
        .replace(/\)/g, ' - )')
        .split(' - ')
        .filter(Boolean)
        .map(fingerprint => fingerprint.trim() + ' '),
      error: this,
      context: this.toJSON(),
      level: logLevel.error,
      scopeModifier: scope => {
        scope.setTransactionName(this.name);
      },
      additionalLogFiles: [`autocut.compute.${this.taskId}.log`],
    });
  }

  toJSON() {
    return {
      fingerprint: this.fingerprint,
      cause: this.cause,
      name: this.name,
      stack: this.stack,
      message: this.message,
      taskId: this.taskId,
      from: this.from,
    };
  }

  toString() {
    return `ComputeError : [${this.fingerprint}] ${this.message}\n${this.stack}`;
  }
}
