import {host} from '@autocut/utils/host';
import {getBlobUrlFromFilepath} from '@autocut/utils/previewFrame';
import {useCallback, useEffect, useRef} from 'react';

import {useAutoCutStore} from './useAutoCutStore';

export type PlaybackChunkArgs = {
  startSecond?: number;
  endSecond?: number;
  onEnd?: () => any;
};

type UsePlaybackProps = {
  audioFilepath?: string;
  withTimecode?: boolean;
};

export const usePlayback = ({
  audioFilepath,
  withTimecode = true,
}: UsePlaybackProps) => {
  const audioRef = useRef<HTMLAudioElement>();
  const initializedRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const currentOnEndRef = useRef<() => void>();
  const {timelineInfos, selectedSections} = useAutoCutStore(state => ({
    timelineInfos: state.onGoingProcess.timelineInfos,
    selectedSections: state.ui.selectedSections,
  }));

  useEffect(() => {
    const init = async () => {
      if ((!timelineInfos && withTimecode) || !audioFilepath) {
        console.error('No timelineInfos - Could not initialized usePlayback');
        return;
      }

      const blobUrl = getBlobUrlFromFilepath(audioFilepath);
      audioRef.current = new Audio(blobUrl);
      audioRef.current.addEventListener('ended', () => {
        clearTimeout(timeoutRef.current);
        if (currentOnEndRef.current) {
          currentOnEndRef.current();
          currentOnEndRef.current = () => {};
        }
      });

      initializedRef.current = true;
    };

    void init();
  }, [audioFilepath, timelineInfos, withTimecode]);

  const playbackChunk = useCallback(
    ({
      startSecond = 0,
      endSecond = 9999,
      onEnd = () => {},
    }: PlaybackChunkArgs = {}) => {
      if (!initializedRef.current) {
        console.error('Tried to play a chunk before initialization');
        return;
      }

      if (currentOnEndRef.current) {
        currentOnEndRef.current();
        currentOnEndRef.current = () => {};
      }
      clearTimeout(timeoutRef.current);

      if (withTimecode && timelineInfos) {
        const chunkStartFrame = startSecond * timelineInfos.settings.framerate;
        void host.timeline.setCurrentTimecode({
          frames: chunkStartFrame + selectedSections[0][0],
        });
      }

      if (audioRef.current) {
        const duration = (endSecond - startSecond) * 1000;
        audioRef.current.currentTime = startSecond;
        void audioRef.current.play();

        currentOnEndRef.current = onEnd;
        timeoutRef.current = setTimeout(() => {
          audioRef.current?.pause();
          onEnd();
          currentOnEndRef.current = () => {};
        }, duration);
      }
    },
    [timelineInfos],
  );

  const stopPlaybackChunk = useCallback(() => {
    clearTimeout(timeoutRef.current);
    if (currentOnEndRef.current) {
      currentOnEndRef.current();
      currentOnEndRef.current = () => {};
    }
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  return {playbackChunk, stopPlaybackChunk};
};
