import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {useEffect} from 'react';

export const useNoLink = () => {
  const {host, hostVersion, dontShowNoLinkModal} = useAutoCutStore(state => ({
    hostVersion: state.ui.versions.host,
    host: state.ui.host,
    dontShowNoLinkModal: state.modal.dontShowAgain.noLinkModal,
  }));

  useEffect(() => {
    if (
      host &&
      hostVersion &&
      host === 'davinci' &&
      hostVersion[0] < 19 &&
      dontShowNoLinkModal !== true
    ) {
      addModalToQueue(ModalType.NoLink);
    }
  }, [host, hostVersion]);
};
