import type {ModalType} from '@autocut/enums/modals.enum';

import {setAutocutStore} from '@autocut/utils/zustand/zustand';

/**
 * This function adds a modal to the end of the queue.
 * It will not display the modal immediately, but will wait for the current modal to be closed.
 */
export const addModalToQueue = (
  modal: ModalType | ModalType[],
  first: boolean = false,
) => {
  console.log('[MODAL] Adding modal to queue: ', modal);
  const modals = Array.isArray(modal) ? modal : [modal];
  setAutocutStore('modal.modalQueue', s => {
    return first
      ? [...modals, ...s.modal.modalQueue]
      : [...s.modal.modalQueue, ...modals];
  });
};
