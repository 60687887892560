import type {OS_MAC, OS_WINDOWS} from '@autocut/constants/constants';
import type {Licence} from '@autocut/types/IsKeyValidResponse';
import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {GenerateZustandKeys} from '@autocut/utils/zustand/zustand';
import type {CaptionsPreset} from '@autocut/validationSchema/caption.validationSchema';

import {getOS} from '@autocut/utils/system/os.system.utils';
import {DEFAULT_UUID} from '@autocut/utils/system/uuid.system.utils';
import {
  syncToCookies,
  type ZustandSyncConfig,
} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_User = 'user';

export type ZType_User = {
  julyId?: string;
  isLogged: boolean;
  key?: string;
  license?: Licence;
  accessToken?: string;
  isBetaOnboarded: boolean;
  isBetaFeedbackOnboarded?: boolean;
  additionalInformations?: {
    displayOnboarding?: boolean;
    latestInvoice?: string;
    scoreRef?: string;
  };
  countryInfos?: {
    name: string;
    code: string;
    checkDate: string;
  };
  captions: {
    userPresets: CaptionsPreset[];
  };
  clientInfos: {
    uuid: string;
    os?: typeof OS_WINDOWS | typeof OS_MAC;
    hostname: string;
  };
};

export type ZKeys_User = GenerateZustandKeys<ZBaseKey_User, ZType_User>;

export const zDefaultValues_User: ZType_User = {
  isLogged: false,
  key: undefined,
  license: undefined,
  accessToken: undefined,
  isBetaOnboarded: false,
  isBetaFeedbackOnboarded: false,
  captions: {
    userPresets: [],
  },
  clientInfos: {
    hostname: '',
    os: getOS(),
    uuid: DEFAULT_UUID,
  },
};

export const zSync_User: ZustandSyncConfig<ZKeys_User> = {
  'user.key': {cookieName: 'userKey'},
  'user.isBetaOnboarded': {cookieName: 'isBetaOnboarded'},
  'user.isBetaFeedbackOnboarded': {cookieName: 'isBetaFeedbackOnboarded'},
  'user.julyId': {cookieName: 'julyId'},
  'user.countryInfos': {cookieName: 'countryInfos'},
};

export const zSubscribers_User: AutoCutStateSubscriberConfig<ZKeys_User> = {
  'user.key': k => syncToCookies(k),
  'user.isBetaOnboarded': k => syncToCookies(k),
  'user.isBetaFeedbackOnboarded': k => syncToCookies(k),
  'user.julyId': k => syncToCookies(k),
  'user.countryInfos': k => syncToCookies(k),
};
