import type {CanvasFontParams} from '@autocut/types/Captions';

export const generateCanvasFontParam = ({
  italic,
  fontSize,
  fontFamily,
}: CanvasFontParams) => {
  const fontParamArray: string[] = [];

  if (italic) {
    fontParamArray.push('italic');
  } else {
    fontParamArray.push('normal');
  }

  fontParamArray.push(``);
  fontParamArray.push(`${fontSize}px`);
  fontParamArray.push(`${fontFamily}`);

  const fontParam = fontParamArray.join(' ');
  return fontParam;
};
