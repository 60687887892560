import type {CSSProperties} from 'react';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

type TimelineLegendProps = {
  style?: CSSProperties;
};

export const TimelineLegend = ({style}: TimelineLegendProps) => {
  return (
    <FlexContainer
      style={style}
      flexDirection="column"
      justifyContent="center"
      gap={Spacing.s1}
    >
      <FlexContainer
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '90%',
          justifyContent: 'flex-start',
        }}
        gap={Spacing.s1}
      >
        <div
          style={{
            width: 14,
            height: 14,
            margin: '0 4px',
            backgroundColor: colors.gray300,
          }}
        />
        <Text variant="textXs">
          <TranslatedMessage
            id="modes_general_steps_select_legend_active"
            defaultMessage={': Analyzed sections'}
          />
        </Text>
      </FlexContainer>
      <FlexContainer
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '90%',
          justifyContent: 'flex-start',
        }}
        gap={Spacing.s1}
      >
        <div
          style={{
            width: 14,
            height: 14,
            border: `solid 2px ${colors.gray300}`,
            margin: '0 4px',
          }}
        />
        <Text variant="textXs">
          <TranslatedMessage
            id="modes_general_steps_select_legend_disabled"
            defaultMessage={': Ignored sections for audio analyzing)'}
          />
        </Text>
      </FlexContainer>
      <FlexContainer
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '90%',
          justifyContent: 'flex-start',
        }}
        gap={Spacing.s1}
      >
        <div
          style={{
            width: 14,
            height: 14,
            borderLeft: `dashed 2px ${colors.primary600}`,
            margin: '0 4px',
          }}
        />
        <Text variant="textXs">
          <TranslatedMessage
            id="modes_general_steps_select_legend_affected"
            defaultMessage={'Sections affected by processes'}
          />
        </Text>
      </FlexContainer>
    </FlexContainer>
  );
};
