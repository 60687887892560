import type {TranslationKeys} from '@autocut/contexts/LocaleContext';

import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import {isEqual} from 'lodash';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';

import {TextWithHelper} from '../TextWithHelper';
import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import css from './numberInput.module.css';

const AUTHORISED_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export type NumberInputProps = {
  value: string;
  unitId?:
    | 'globals_units_second'
    | 'globals_units_millisecond'
    | 'globals_units_percentage'
    | 'globals_units_px'
    | TranslationKeys;
  targetedParameter: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  titleId?: TranslationKeys;
  helperId?: TranslationKeys;
  helperDefault?: string;
  left?: boolean;
  disabled?: boolean;
  inputContainerStyle?: React.CSSProperties;
};

export const NumberInputComponent = ({
  value,
  unitId = 'globals_units_second',
  onChange,
  onBlur,
  titleId,
  helperId,
  helperDefault,
  disabled = false,
  inputContainerStyle,
}: NumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState('0ch');

  useEffect(() => {
    updateValue(value);
  }, [value]);

  useEffect(() => {
    updateInputWidth(value);
  }, []);

  const updateInputWidth = (value: string) =>
    setInputWidth(`${value.length}ch`);

  const updateValue = (value: string) => {
    const REMOVE_MULTIPLE_DOTS = /\.(.*)/;
    value = value.replace(
      REMOVE_MULTIPLE_DOTS,
      (match, group) => `.${group.replace(/\./g, '')}`,
    );

    const REMOVE_LEADING_ZERO = /^0+(?=\d)/g;
    value = value.replace(REMOVE_LEADING_ZERO, '');

    for (const c of value) {
      if (!AUTHORISED_CHARS.includes(c)) {
        value = value.replace(c, '');
      }
    }

    value = value === '' ? '0' : value;

    switch (unitId) {
      case 'globals_units_millisecond':
        onChange(`${parseFloat(value) / 1000}`);
        break;

      default:
        onChange(value);
        break;
    }

    updateInputWidth(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(',', '.');

    updateValue(newValue);
  };

  return (
    <div className={css.container}>
      {titleId && (
        <div className={css.header}>
          <ConditionalWrap
            condition={helperId !== undefined && helperDefault !== undefined}
            wrap={children => (
              <TextWithHelper
                helperId={helperId as TranslationKeys}
                helperDefault={helperDefault as string}
              >
                {children}
              </TextWithHelper>
            )}
          >
            <TranslatedMessage
              id={titleId}
              defaultMessage={titleId}
            />
          </ConditionalWrap>
        </div>
      )}

      <div
        className={`${css.inputContainer} ${disabled ? css.disabled : 'azd'}`}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        style={inputContainerStyle}
      >
        <input
          ref={inputRef}
          style={{width: inputWidth}}
          className={css.input}
          type="text"
          value={value}
          placeholder="0.001"
          onFocus={() => inputRef.current?.setSelectionRange(0, value.length)}
          onChange={handleChange}
          onBlur={() => {
            if (value === '') {
              updateValue('0');
            }
            onBlur && onBlur(value);
          }}
          disabled={disabled}
        />
        <div>
          <TranslatedMessage
            id={unitId}
            defaultMessage={unitId}
          />
        </div>
      </div>
    </div>
  );
};

const compareProps = (
  prevProps: NumberInputProps,
  nextProps: NumberInputProps,
) => {
  return (
    isEqual(prevProps.value, nextProps.value) &&
    isEqual(prevProps.disabled, nextProps.disabled)
  );
};

export const NumberInput = React.memo(NumberInputComponent, compareProps);
