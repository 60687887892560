import type {AxiosError} from 'axios';

import {IncrementalError} from './IncrementalError';

export type TAutoCutApiError = AxiosError<{
  name: string;
  message?: string;
  description?: string;
}>;

export class AutoCutApiError extends IncrementalError {
  description?: string;
  status: number;

  constructor(baseError: TAutoCutApiError, fingerprint: string) {
    const response = baseError.response;
    if (response) {
      super(
        response.data.message ??
          `An error occured with status code ${response.status}. Please contact us on Discord or at contact@autocut.fr.`,
        fingerprint,
      );
      this.name = response.data.name ?? this.name;
      this.status = response.status;
      this.description = response.data.description;
    } else {
      super('UNKNWON_API_ERROR', fingerprint);
      this.name = 'UNKNWON_API_ERROR';
      this.status = 0;
      this.message = `Unknown error occured during API request. Please contact us on Discord or at contact@autocut.fr.\n${
        baseError.message ?? ''
      }`;
    }
  }

  toJSON() {
    return {
      fingerprint: this.fingerprint,
      cause: this.cause,
      name: this.name,
      stack: this.stack,
      message: this.message,
      description: this.description,
      status: this.status,
    };
  }

  toString() {
    return `AutoCutApiError : [${this.fingerprint}] ${this.message}\n${this.stack}`;
  }
}
