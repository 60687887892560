import type React from 'react';

import {colors} from '@autocut/designSystem/colors';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

import {Text} from '../../atoms/Text/Text';
import FlexContainer from '../FlexContainer';
import css from './InputLabel.module.scss';

export namespace InputLabel {
  export type Props = {
    title?: React.ReactNode;
    description?: React.ReactNode;
  };
}

export const InputLabel = ({title, description}: InputLabel.Props) => {
  return (
    <FlexContainer
      className={css.container}
      gap={Spacing.s1}
      alignItems="flex-end"
      flexWrap="wrap"
    >
      {title && (
        <Text
          className={`${css.text} ${css.title}`}
          variant="textSm"
          color="white"
        >
          {title}
        </Text>
      )}
      {description && (
        <Text
          className={`${css.text} ${css.description}`}
          variant="textXs"
          color={colors.gray400}
        >
          {description}
        </Text>
      )}
    </FlexContainer>
  );
};
