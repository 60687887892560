import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconInfoSquare = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 16v-4m0-4h0M8 21h11l2-2V5l-2-2H5L3 5v14l2 2h3Z"
      />
    </IconComponent>
  );
};
