import type {CaptionChunk} from '@autocut/types/Captions';
import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import type {ProcessProgress} from '@autocut/utils/process/progress';

import type {TimelinePpro} from './_pproTimeline.temp';

import {compute} from '@autocut/utils/compute.utils';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';

import {prepareCaptionsXml} from '../utils/_prepareCaptionsXml';

export type NeededCaptionsParameters = {
  mogrtOptimalScale: number;
  timeline: TimelinePpro;
  trackIndex: number;
};

export const handleProcessCaptions = async (
  props: {
    chunks: CaptionChunk[];
    parameters: CaptionsParameters;
    xmlProjectPath: string;
  },
  progress: ProcessProgress,
) => {
  const getParameters = compute.process.prepareCaptions();
  updateProcessStep(progress, 'caption_prepare_timeline', {
    progress: {
      computeTaskId: getParameters.requestId,
    },
    countFor: 3, // Number of steps in compute
  });
  const data = await getParameters;
  endProcessStep(progress, 'caption_prepare_timeline');

  const result = await prepareCaptionsXml({
    ...data,
    ...props,
  });
  endProcessStep(progress, 'caption_generate_sequence');

  const importCaptions = compute.process.importCaptions(result);
  updateProcessStep(progress, 'caption_import_sequence', {
    progress: {
      computeTaskId: importCaptions.requestId,
    },
    countFor: 3, // Number of steps in compute
  });
  await importCaptions;
  endProcessStep(progress, 'caption_import_sequence');
};
