import type {XMLDocument} from './_XMLDocument';

import {XMLTrack} from './_XMLTrack';
import './_prototypes';

export class XMLSequence {
  private audioTrackGroup: Element;
  private name: string;
  private objectUId: string;
  private sequence: Element;
  private videoTrackGroup: Element;
  public parentDocument: XMLDocument;
  public videoTracks: XMLTrack[] = [];

  constructor(sequence: Element, document: XMLDocument) {
    this.objectUId = sequence.getAttribute('ObjectUID') ?? '';
    this.name = sequence.querySelector('Name')?.textContent ?? '';
    this.parentDocument = document;
    this.sequence = sequence;

    const trackGroupIds = Array.from(
      sequence.querySelectorAll('TrackGroups TrackGroup Second') ?? [],
    ).map(trackGroup => trackGroup.getAttribute('ObjectRef') ?? '');
    const [videoTrackGroupId, audioTrackGroupId] = trackGroupIds;

    const videoTrackGroup = this.parentDocument.findElementWithObjectId(
      ['VideoTrackGroup'],
      videoTrackGroupId,
    );
    if (!videoTrackGroup) throw new Error('VideoTrackGroup not found');
    this.videoTrackGroup = videoTrackGroup;

    const audioTrackGroup = this.parentDocument.findElementWithObjectId(
      ['AudioTrackGroup'],
      audioTrackGroupId,
    );
    if (!audioTrackGroup) throw new Error('AudioTrackGroup not found');
    this.audioTrackGroup = audioTrackGroup;
  }

  public exists() {
    return !!this.parentDocument.findElementWithObjectId(
      ['Sequence'],
      this.objectUId,
    );
  }

  public getAudioTracks() {
    const audioClipTrackIds = Array.from(
      this.audioTrackGroup?.querySelector('TrackGroup Tracks')?.children ?? [],
    )
      .map(track => ({
        trackId: track.getAttribute('ObjectURef') ?? '',
        trackIndex: parseInt(track.getAttribute('Index') ?? ''),
      }))
      .sort((a, b) => a.trackIndex - b.trackIndex);
    const audioClipTracks = audioClipTrackIds.map(audioClipTrackId =>
      this.parentDocument.findElementWithObjectId(
        ['AudioClipTrack'],
        audioClipTrackId.trackId,
      ),
    );

    return audioClipTracks;
  }

  public getVideoTracks() {
    if (this.videoTracks.length) return this.videoTracks;

    const videoClipTrackIds = Array.from(
      this.videoTrackGroup?.querySelector('TrackGroup Tracks')?.children ?? [],
    )
      .map(track => ({
        trackId: track.getAttribute('ObjectURef') ?? '',
        trackIndex: parseInt(track.getAttribute('Index') ?? ''),
      }))
      .sort((a, b) => a.trackIndex - b.trackIndex);
    const videoClipTracks = videoClipTrackIds
      .map(videoClipTrackId => {
        const videoClipTrack = this.parentDocument.findElementWithObjectId(
          ['VideoClipTrack'],
          videoClipTrackId.trackId,
        );
        if (!videoClipTrack) return;
        return new XMLTrack(videoClipTrack, this);
      })
      .filter(Boolean) as XMLTrack[];

    this.videoTracks = videoClipTracks;

    return videoClipTracks;
  }

  public rename(newName: string) {
    const name = this.sequence.querySelector('Name');
    if (!name) throw new Error('Name not found');
    name.textContent = newName;
  }

  public resize([sequenceWidth, sequenceHeight]: [number, number]) {
    const frameRect = this.videoTrackGroup.querySelector('FrameRect');
    if (!frameRect) throw new Error('FrameRect not found');
    const newFrameRectTextContent = `0,0,${sequenceWidth},${sequenceHeight}`;

    frameRect.textContent = newFrameRectTextContent;

    return true;
  }
}
