import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconRocket02 = (props: IconComponentProps) => {
  return (
    <IconComponent
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m12 15-3-3m3 3 4-2m-4 2v5l4-2v-5m-7-1 2-4a13 13 0 0 1 11-6c0 3-1 7-6 11m-7-1H4l2-4h5m-6 8c-2 2-2 5-2 5s3 0 5-2l-1-2a2 2 0 0 0-2-1Z"
      />
    </IconComponent>
  );
};
