import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Alert} from '@autocut/designSystem/components/atoms/Alert/Alert';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {IconInfoCircle} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoCircle';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useCheckDeactivatedTracks} from '@autocut/hooks/useCheckDeactivatedTracks';
import {compute} from '@autocut/utils/compute.utils';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {host} from '@autocut/utils/host';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {DynamicZoomStep} from './Parts/DynamicZoomStep/DynamicZoomStep';
import {PreviewZoomStep} from './Parts/PreviewZoomStep/PreviewZoomStep';
import {SmoothZoomStep} from './Parts/SmoothZoomStep/SmoothZoomStep';
import {ZoomConstraintStep} from './Parts/ZoomConstraintStep/ZoomConstraintStep';
import {ZoomCoverageStep} from './Parts/ZoomCoverageStep/ZoomCoverageStep';
import {ZoomPresetStep} from './Parts/ZoomPresetStep/ZoomPresetStep';
import {ZoomTypesStep} from './Parts/ZoomTypesStep/ZoomTypesStep';

export const ZoomCustomizationStep = () => {
  const {allTracksDeactivated, isInitialized} = useCheckDeactivatedTracks();
  const intl = useIntl();
  const {parameters, isPreviewProcessing} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.zoom,
    isPreviewProcessing: state.onGoingProcess.preview.isProcessing,
  }));

  const handleClick = async () => {
    await handleProcessBase(
      {
        executeProcess: async (_, progress) => {
          let zoomLayerPath;
          if ((await host.misc.version()).hostName === 'ppro') {
            const zoomLayerResource =
              resourcesConfigs()['adjustment_layer_prproj']();
            const {isExist, outputFilePath} =
              await zoomLayerResource.existCheck();
            if (isExist) {
              zoomLayerPath = outputFilePath;
            } else {
              zoomLayerPath = await zoomLayerResource.downloadMethod();
            }
          }

          const zoomProcess = compute.process.zoom({
            ...parameters,
            adjustmentLayerPath: zoomLayerPath,
          });

          updateProcessStep(progress, 'zoom_mainProcess', {
            progress: {
              computeTaskId: zoomProcess.requestId,
            },
            countFor: 14, // Number of steps in compute
          });

          const result = await zoomProcess;

          endProcessStep(progress, 'zoom_mainProcess');

          return result;
        },
      },
      {
        processTitleNameKey: 'modes_zoom_title',
        processSteps: [
          {
            id: 'zoom_mainProcess',
            translationKey: 'progress_steps_zoom_mainProcess',
            progress: '',
          },
        ],
      },
    )(intl);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <Button
              ref={buttonRef}
              disabled={isDisabled || isLoading || allTracksDeactivated}
              isLoading={isPreviewProcessing || isLoading || !isInitialized}
              color={colors.primary600}
              onClick={async () =>
                handleClickWithValidation(async () => {
                  await handleClick();
                  await handleTrialTask('zoom');
                })
              }
            >
              <TranslatedMessage
                id="modes_zoom_footerButton"
                defaultMessage="Zoom"
              />
            </Button>
          )}
          errorId={
            allTracksDeactivated
              ? 'modes_general_steps_transcription_noTrackSelected'
              : undefined
          }
        />
      }
    >
      <Alert
        icon={
          <IconInfoCircle
            size={18}
            color="gray.300"
          />
        }
      >
        <TranslatedMessage
          id="modes_zoom_infoText"
          defaultMessage="AutoZoom will automatically add zoom to each clip selected based on decibels."
        />
      </Alert>

      <PreviewZoomStep />

      <Divider />

      <ZoomPresetStep />

      <FlexContainer
        style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s4}}
      >
        <ParametersGroupLayout
          withPaddedSection={false}
          contentContainerStyle={{
            borderLeft: 'initial',
            paddingLeft: 'initial',
          }}
        >
          <ZoomTypesStep />
          <ZoomCoverageStep />
        </ParametersGroupLayout>
      </FlexContainer>

      <SmoothZoomStep />
      <DynamicZoomStep />
      <ZoomConstraintStep />
    </ModeLayout>
  );
};
