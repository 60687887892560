import type {CaptionChunk} from '@autocut/types/Captions';

import type {XMLDocument} from '../class/_XMLDocument';
import type {XMLTrackItem} from '../class/_XMLTrackItem';

import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import logLevel from '@autocut/enums/logLevel.enum';
import {
  CAPTIONS_EMOJIS_FOLDER_PATH,
  CAPTIONS_RESOURCES_FOLDER_PATH,
} from '@autocut/enums/resources.enum';
import {
  localMotionEffectName,
  localPositionEffectName,
  localScaleEffectName,
} from '@autocut/enums/videoEffect.enum';
import {logger} from '@autocut/utils/logger';

import {CaptionsXmlProjectTrackIndex} from '../class/_types';
import {secondToTick} from '../temp/_ticks.temp';

export const BASE_EMOJI_SIZE = 64;
export const EMOJI_SIZE_FACTOR = 1.5;

export const handleEmojis = async ({
  xmlDocument,
}: {
  xmlDocument: XMLDocument;
}) => {
  const emojiTrack = xmlDocument.getSequences()[0].getVideoTracks()[
    CaptionsXmlProjectTrackIndex.EMOJI
  ];
  const emojiClip = emojiTrack.getClips()[0];

  let index = 0;

  const emojisBinProjectItem = xmlDocument
    .getBinProjectItems()
    .find(binProjectItem => binProjectItem.name === 'emojis');
  const emojiMediaName = 'emoji.png';
  const emojiMediaSource = xmlDocument.medias.find(
    media => media.title === emojiMediaName,
  );

  const addEmojiChunk = async (
    chunk: CaptionChunk,
    {
      emojiProperties,
    }: {
      emojiProperties: EmojiParameters;
    },
  ) => {
    if (!chunk || !chunk.emojiUrl || !chunk.emoji || !chunk.emojiPath) return;

    try {
      const newEmojiMediaName = chunk.emoji;
      const newEmojiMediaRelativePath =
        `./${CAPTIONS_RESOURCES_FOLDER_PATH}/${CAPTIONS_EMOJIS_FOLDER_PATH}/` +
        newEmojiMediaName;

      const newEmojiMediaSource = emojiMediaSource?.clone({
        newName: newEmojiMediaName,
        newPath: chunk.emojiPath,
        newRelativePath: newEmojiMediaRelativePath,
      });
      const newEmojiItem = emojisBinProjectItem?.cloneItem({
        newName: newEmojiMediaName,
        newSource: newEmojiMediaSource,
      });

      const newEmojiClip = emojiClip.clone({
        startTick: secondToTick(chunk.start).toString(),
        endTick: secondToTick(chunk.end).toString(),
        index,
        copiedVideoFilterComponents: [
          localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
        ],
      });
      newEmojiClip.changeSource(newEmojiItem);
      // Start of the webp clip must be 0 because the animation start at 0.
      // If not set to 0, the webp emoji will not be displayed correctly.
      newEmojiClip.updateVideoClipTimes({
        startTick: '0',
      });
      await updateEmojiScaleAndPosition(newEmojiClip, emojiProperties);

      index++;
    } catch (e) {
      logger(
        'captions',
        logLevel.error,
        'ERROR WHILE ADDING PNG ' +
          chunk.emoji +
          ' : ' +
          chunk.emojiUrl +
          ' TO CHUNK',
        {chunk},
      );
    }
  };

  const removeOriginalEmojiClip = () => {
    emojiClip.remove();
  };

  return {
    addEmojiChunk,
    removeOriginalEmojiClip,
  };
};

export const updateEmojiScaleAndPosition = async (
  emojiClip: XMLTrackItem,
  emojiProperties: EmojiParameters,
) => {
  if (emojiClip) {
    const emojiScale = getEmojiScale(
      emojiProperties.fontSize,
      emojiProperties.size,
    );
    emojiClip.updateMotionParam(
      localScaleEffectName[CAPTIONS_XML_PROJECT_LOCALE],
      emojiScale,
    );

    const emojiPosition = emojiProperties.position;
    emojiClip.updateMotionParam(
      localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
      [emojiPosition.x, emojiPosition.y],
    );
  }
};

type EmojiParameters = {
  position: {x: number; y: number};
  fontSize: number;
  size?: number;
};

export const getEmojiScale = (fontSize = 104, emojiSize = BASE_EMOJI_SIZE) => {
  return fontSize * EMOJI_SIZE_FACTOR * (BASE_EMOJI_SIZE / emojiSize);
};
