import {preload} from '@autocut/types/ElectronPreload';
import {host} from '@autocut/utils/host';
import {getBlobUrlFromFilepath} from '@autocut/utils/previewFrame';
import {useEffect, useState} from 'react';

export const usePreviewFrames = (timecodes: [string, string][]) => {
  const [previewFrames, setPreviewFrames] = useState<string[]>([]);

  const initPreviewFrames = async () => {
    for (const [index, timecode] of timecodes.entries()) {
      await updatePreviewFrame(timecode, index);
    }
  };

  const updatePreviewFrame = async (
    timecode: [string, string],
    index: number,
  ) => {
    if (!timecodes.length) return;

    await host().timeline.setCurrentTimecode({
      timecode: timecode[0],
    });
    const framePath = await host().timeline.exportFrame();

    if (!preload.fs.existsSync(framePath))
      await new Promise(resolve => setTimeout(resolve, 1500));

    const blobUrl = !!framePath?.length
      ? getBlobUrlFromFilepath(framePath)
      : '';

    setPreviewFrames(prev => [
      ...prev.slice(0, index),
      blobUrl,
      ...prev.slice(index + 1),
    ]);
  };

  useEffect(() => {
    setPreviewFrames(new Array(timecodes.length).fill(''));
    void initPreviewFrames();
  }, []);

  return {
    previewFrames,
    setPreviewFrames,
    updatePreviewFrame,
  };
};
