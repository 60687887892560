import type {AnimatedCanvasType} from '@autocut/pages/modes/captions/utils/canvas/classes/animatedCanvas.class.utils';
import type {DraggableCanvasObject} from '@autocut/pages/modes/captions/utils/canvas/classes/draggableCanvas.class.utils';
import type {CanvasObjectModifier} from '@autocut/pages/modes/captions/utils/canvas/modifier.canvas.utils';
import type {PickPartial} from '@autocut/utils/type.utils';

import {AnimatedCanvas} from '@autocut/pages/modes/captions/utils/canvas/classes/animatedCanvas.class.utils';
import {DraggableCanvas} from '@autocut/pages/modes/captions/utils/canvas/classes/draggableCanvas.class.utils';
import {CURRENT_ENV} from '@autocut/utils/currentEnv.utils';
import {host} from '@autocut/utils/host';
import {ppcm} from '@autocut/utils/math.utils';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useBackgroundCanvasObject} from './hooks/Draw/Background/useBackgroundCanvasObject';
import {useCaptionsTextMetrics} from './hooks/Draw/captionDrawHooks.utils';
import {useEmojiCanvasObject} from './hooks/Draw/Emoji/useEmojiCanvasObject';
import {useWordBoxesCanvasObject} from './hooks/Draw/HighlightBoxes/useWordBoxesCanvasObject';
import {useTextCanvasObject} from './hooks/Draw/Text/useTextCanvasObject';
import {
  BLUR_IN_ANIMATION_LENGTH_FRAME,
  useBlurInCanvasObjectModifier,
} from './hooks/Modifiers/Floating/useBlurCanvasObjectModifier';
import {
  FLOATING_ANIMATION_LENGTH_FRAME,
  useFloatingCanvasObjectModifier,
} from './hooks/Modifiers/Floating/useFloatingCanvasObjectModifier';
import {
  useZoomInCanvasObjectModifier,
  ZOOM_IN_ANIMATION_LENGTH_FRAME,
} from './hooks/Modifiers/Floating/useZoomCanvasObjectModifier';
import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';

const forcedXPercentage = 0.5;

export const HIGHLIGHT_DURATION_FRAMES = 20;
export const CAPTIONS_PREVIEW_FRAME_RATE = 30;

export const ExampleCaption = ({
  boundingBox,
  debug,
}: {
  boundingBox: {
    width: number;
    height: number;
  };
  debug?: boolean;
}) => {
  const {
    tempParameters: {value},
  } = useEditCaptionStyle();
  const maxWidth = value.formating.maxWidth;

  const [sequenceFormat, setSequenceFormat] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const init = async () => {
      return await host.timeline.getTimelineFormat();
    };

    void init().then(res => {
      const sequenceWidth = parseInt(res.width);
      const sequenceHeight = parseInt(res.height);

      setSequenceFormat({width: sequenceWidth, height: sequenceHeight});
    });
  }, []);

  const xFactor = sequenceFormat.width / boundingBox.width;
  const yFactor = sequenceFormat.height / boundingBox.height;

  const metrics = useCaptionsTextMetrics({
    xFactor,
    yFactor,
    providedWidth: ((maxWidth / 100) * sequenceFormat.width) / xFactor,
  });
  const nbWords = metrics.lines.reduce((sum, line) => sum + line.nbWords, 0);
  const highlightAnimation = nbWords * HIGHLIGHT_DURATION_FRAMES;

  const canvasObjectHooksParam = {
    xFactor,
    yFactor,
    metrics,
    debug,
    boundingBox,
    forcedX: forcedXPercentage * boundingBox.width,
  };

  const emojiCanvasObject = useEmojiCanvasObject({
    ...canvasObjectHooksParam,
    zIndex: 10,
  });
  const backgroundCanvasObject = useBackgroundCanvasObject({
    ...canvasObjectHooksParam,
    zIndex: 0,
  });
  const textCanvasObject = useTextCanvasObject({
    ...canvasObjectHooksParam,
    zIndex: 9,
  });
  const wordBoxCanvasObject = useWordBoxesCanvasObject({
    ...canvasObjectHooksParam,
    zIndex: 8,
  });
  const floatingTextAnimation = useFloatingCanvasObjectModifier({debug});
  const zoomInAnimation = useZoomInCanvasObjectModifier({
    loop: {enabled: true, loopLengthInFrames: highlightAnimation},
  });
  const blurInAnimation = useBlurInCanvasObjectModifier({
    loop: {enabled: true, loopLengthInFrames: highlightAnimation},
  });

  const floatingTextAnimationDuration = floatingTextAnimation.enabled
    ? FLOATING_ANIMATION_LENGTH_FRAME
    : 0;
  const zoomInAnimationDuration = blurInAnimation.enabled
    ? ZOOM_IN_ANIMATION_LENGTH_FRAME
    : 0;
  const blurInAnimationDuration = blurInAnimation.enabled
    ? BLUR_IN_ANIMATION_LENGTH_FRAME
    : 0;
  const animationDuration = ppcm(
    highlightAnimation,
    floatingTextAnimationDuration,
    zoomInAnimationDuration,
    blurInAnimationDuration,
  );
  const animationDurationSec = animationDuration / CAPTIONS_PREVIEW_FRAME_RATE;

  const allEffectsModifier: CanvasObjectModifier = useCallback(
    c =>
      blurInAnimation.modifier(
        zoomInAnimation.modifier(floatingTextAnimation.modifier(c)),
      ),
    [floatingTextAnimation, zoomInAnimation],
  );

  const objects: PickPartial<
    Omit<DraggableCanvasObject, 'isDragging' | 'id'>,
    'zIndex' | 'forcedX' | 'forcedY'
  >[] = useMemo(
    () => [
      allEffectsModifier(emojiCanvasObject),
      allEffectsModifier(backgroundCanvasObject),
      allEffectsModifier(textCanvasObject),
      allEffectsModifier(wordBoxCanvasObject),
    ],
    [
      allEffectsModifier,
      backgroundCanvasObject,
      emojiCanvasObject,
      textCanvasObject,
      wordBoxCanvasObject,
    ],
  );

  return (
    <DisplayComponent
      objects={objects}
      animationDuration={animationDurationSec}
      boundingBox={boundingBox}
      debug={debug}
    />
  );
};

const DisplayComponent = ({
  objects,
  animationDuration,
  boundingBox,
  debug,
}: {
  objects: PickPartial<
    Omit<DraggableCanvasObject, 'isDragging' | 'id'>,
    'zIndex' | 'forcedX' | 'forcedY'
  >[];
  animationDuration: number;
  boundingBox: {
    width: number;
    height: number;
  };
  debug?: boolean;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const draggableCanvasRef = useRef<AnimatedCanvasType<DraggableCanvas>>();

  useEffect(() => {
    //To make hot reload works
    if (draggableCanvasRef.current && CURRENT_ENV === 'development')
      draggableCanvasRef.current.start();

    return () => {
      draggableCanvasRef.current?.destroy();
    };
  }, []);

  useEffect(() => {
    if (draggableCanvasRef.current) {
      draggableCanvasRef.current.duration = animationDuration;
    }
  }, [animationDuration]);

  useEffect(() => {
    const currentCanvas = canvasRef.current;
    if (!currentCanvas || !!draggableCanvasRef.current) return;
    const canvas = new (AnimatedCanvas(DraggableCanvas, {
      animation: {
        fps: CAPTIONS_PREVIEW_FRAME_RATE,
        duration: animationDuration,
        loop: true,
      },
      showFps: CURRENT_ENV === 'development',
    }))(canvasRef, debug);
    draggableCanvasRef.current = canvas;

    draggableCanvasRef.current.setObjects(objects);
    draggableCanvasRef.current.start();

    draggableCanvasRef.current.draw();
  }, [canvasRef.current]);

  useEffect(() => {
    if (!draggableCanvasRef.current) return;

    objects.forEach((object, index) => {
      draggableCanvasRef.current?.updateObjectByIndex(index, object);
    });
  }, [objects]);

  return (
    <canvas
      ref={canvasRef}
      width={boundingBox.width}
      height={boundingBox.height}
    />
  );
};
