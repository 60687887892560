import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {preload} from '@autocut/types/ElectronPreload';

export const UpdateElectronModal = () => {
  return (
    <Modal
      noHeader
      isBlocking
      footer={
        <Button
          color={colors.primary600}
          onClick={() => preload.updater.quitAndInstall()}
        >
          <TranslatedMessage id="launchManager_steps_update_modal_cta" />
        </Button>
      }
    >
      <FlexContainer
        flexDirection="column"
        justifyContent="space-between"
        gap={8}
        style={{padding: `0px ${Spacing.s4}`}}
      >
        <h1 style={{textAlign: 'center', marginBottom: Spacing.s6}}>
          <TranslatedMessage id="launchManager_steps_update_modal_title" />
        </h1>
        <h2>
          <TranslatedMessage id="launchManager_steps_update_modal_subtitle" />
        </h2>
        <p>
          <TranslatedMessage id="launchManager_steps_update_modal_body" />
        </p>
      </FlexContainer>
    </Modal>
  );
};
