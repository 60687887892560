import type {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';

import {preload, waitForPreload} from '@autocut/types/ElectronPreload';

export enum checkForUpdatesErrors {
  TIMEOUT = 'timeout',
  FAIL = 'fail',
}

export const checkForUpdates: LaunchStepHandler = async (
  setProgress,
): Promise<boolean | string> => {
  await waitForPreload();

  let updateInfos: ReturnType<Preload['updater']['getInfos']> = {
    status: 'checking',
  };

  return new Promise(async resolve => {
    let timeoutId = setTimeout(() => {
      resolve(checkForUpdatesErrors.TIMEOUT);
    }, 60000); // 1 minute timeout for the update
    let isDownloading = false;
    do {
      updateInfos = preload.updater.getInfos();
      // console.log('Waiting for update', updateInfos);

      switch (updateInfos.status) {
        case 'up-to-date':
          clearTimeout(timeoutId);
          await setProgress?.(100);
          resolve(true);
          break;

        case 'downloading':
          if (!isDownloading) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              resolve(checkForUpdatesErrors.TIMEOUT);
            }, 600000); // 10 minutes timeout for the download

            isDownloading = true;
          }
          await setProgress?.(50);
          break;

        case 'ready':
          clearTimeout(timeoutId);
          await setProgress?.(100);
          resolve(false);
          break;

        case 'error':
          //If we have an error during the update, we allow using the app. The error will be send to Sentry
          await setProgress?.(100);
          resolve(checkForUpdatesErrors.FAIL);
          break;

        default:
          break;
      }
      await new Promise(resolve => setTimeout(resolve, 100));
    } while (['checking', 'downloading'].includes(updateInfos.status));

    resolve(true);
  });
};
