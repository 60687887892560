import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import css from './Timeline.module.css';

export type TimelineProps = {
  leftDescription: string;
  rightDescription: string;
  totalElements: number;
  currentElement: number;
  animateLast?: boolean;
};

export const Timeline = ({
  leftDescription,
  rightDescription,
  totalElements,
  currentElement,
  animateLast = false,
}: TimelineProps) => {
  return (
    <FlexContainer
      gap={8}
      flexDirection="column"
    >
      <FlexContainer
        gap={4}
        className={css.pillsContainer}
        style={{
          // @ts-ignore
          '--pillSize': `${100 / totalElements}%`,
        }}
      >
        {Array.from({length: totalElements}).map((_, index) => (
          <div
            key={index}
            className={`${css.pill} ${
              index < currentElement ? css.pillActive : ''
            } ${
              animateLast && index === currentElement - 1 ? css.pillAnimate : ''
            }`}
          >
            {animateLast && index === currentElement - 1 && (
              <div className={css.fireworkContainer}>
                <div className={css.firework} />
              </div>
            )}
          </div>
        ))}
      </FlexContainer>
      <FlexContainer
        justifyContent="space-between"
        className={css.descriptionContainer}
      >
        <p style={{maxWidth: `${100 / totalElements}%`}}>{leftDescription}</p>
        <p style={{maxWidth: `${100 / totalElements}%`}}>{rightDescription}</p>
      </FlexContainer>
    </FlexContainer>
  );
};
