import {InfoText} from '@autocut/components/InfoText/InfoText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {useState} from 'react';
import {useIntl} from 'react-intl';

export const NoLinkModal = () => {
  const intl = useIntl();

  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <Modal
      isBlocking
      title={intl.formatMessage({
        id: 'modals_noLinkModal_title',
        defaultMessage: 'Please update DaVinci Resolve',
      })}
      footer={
        <FlexContainer
          flexDirection="column"
          gap={8}
          style={{marginTop: 8}}
        >
          <FlexContainer
            gap={4}
            alignItems="center"
            onClick={() => {
              setDontShowAgain(prev => !prev);
            }}
            style={{cursor: 'pointer'}}
          >
            <CheckBox
              size={16}
              checked={dontShowAgain}
              onChange={() => {
                setDontShowAgain(prev => !prev);
              }}
            />
            <TranslatedMessage
              id={'modals_noLinkModal_checkBox'}
              defaultMessage="Don't show this message again"
            />
          </FlexContainer>

          <Button
            onClick={async () => {
              setAutocutStore('modal.dontShowAgain.noLinkModal', dontShowAgain);
              closeModal();
            }}
            color={colors.primary600}
          >
            <TranslatedMessage
              id={'modals_noLinkModal_cta'}
              defaultMessage="Close"
            />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
      >
        <InfoText style="warning">
          <TranslatedMessage
            id="modals_noLinkModal_text"
            defaultMessage="While you can still use AutoCut, you won't be able to retain the links between track items in your timeline after running a process."
          />
        </InfoText>
      </FlexContainer>
    </Modal>
  );
};
