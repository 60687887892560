import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {TranscriptLanguage} from '@autocut/pages/TranscriptLanguage';
import {BiMessageSquareX} from 'react-icons/bi';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {SwearWordsCustomization} from './SwearWordsCustomization';

export const SwearWordsMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={BiMessageSquareX}
      text="AutoProfanity Filter"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/swear_word"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="swear_word"
                onValidateSections={() => navigate('./language')}
              />
            ),
          },
          {
            clickable: false,
            label: intl.formatMessage({
              id: 'modes_general_steps_transcription_title',
              defaultMessage: 'Select Audio Clip Language',
            }),
            path: 'language',
            element: <TranscriptLanguage mode="swear_word" />,
          },
          {
            clickable: false,
            label: intl.formatMessage({
              id: 'modes_general_steps_customization_title',
              defaultMessage: 'Customize parameters',
            }),
            path: 'customization',
            element: <SwearWordsCustomization />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
