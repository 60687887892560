import type {ChangeEvent, CSSProperties, HTMLAttributes} from 'react';

import type {SliderVariant} from './variants';

import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

import FlexContainer from '../../molecules/FlexContainer';
import {Text} from '../Text/Text';
import {DualSlider} from './DualSlider/DualSlider';
import css from './Slider.module.scss';

export type SliderProps<T extends number | [number, number]> = {
  value: T;
  onChange: (value: T) => void;
  min?: number;
  max?: number;
  formatValue?: (value: number) => string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  variant?: SliderVariant;
  accentColor?: string;
  accentColorSide?: 'left' | 'right';
  withSteps?: boolean;
  width?: string | number;
  textInTooltip?: string;
} & Omit<HTMLAttributes<HTMLInputElement>, 'min' | 'max' | 'onChange'>;

export const Slider = <T extends number | [number, number]>({
  value,
  onChange,
  min = 0,
  max = 100,
  iconLeft: IconLeft,
  iconRight: IconRight,
  variant = 'noValue',
  formatValue,
  style,
  accentColor,
  accentColorSide = 'left',
  width,
  withSteps = false,
  textInTooltip,
  ...props
}: SliderProps<T>) => {
  if (Array.isArray(value)) {
    return (
      <DualSlider
        min={min}
        max={max}
        lowerValue={value[0]}
        upperValue={value[1]}
        onChange={(lowerValue: number, upperValue: number) =>
          onChange([lowerValue, upperValue] as T)
        }
        formatValue={formatValue}
        {...props}
      />
    );
  }
  const range = max - min;

  const percentage = (((value as number) - min) * 100) / range;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = parseInt(e.target.value);
    onChange(currentValue as T);
  };

  return (
    <FlexContainer
      data-variant={variant}
      gap={Spacing.s1}
      className={`${css.container} ${textInTooltip ? css.withTooltip : ''}`}
      justifyContent="center"
      alignItems="center"
      flexShrink={false}
      style={{
        ...(width && {width: 'fit-content'}),

        ...(textInTooltip
          ? ({'--tooltip-text': `"${textInTooltip}"`} as CSSProperties)
          : {}),
      }}
    >
      <FlexContainer
        flexShrink={false}
        alignItems="center"
        justifyContent="center"
      >
        {IconLeft}
      </FlexContainer>
      {variant === 'valueOnLeft' && (
        <Text
          className={css.value}
          variant="textXs"
          color="white"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 0,
          }}
        >
          {formatValue ? formatValue(value) : value}
        </Text>
      )}
      <FlexContainer
        flexDirection="column"
        className={css.innerContainer}
        style={{width}}
      >
        <input
          className={css.root}
          type="range"
          onChange={handleChange}
          min={min}
          max={max}
          style={
            {
              ...style,
              ...(accentColor && {
                '--accent-color': accentColor,
                '--percentage': `${percentage}%`,
                '--percentage-value': percentage / 100,
              }),
              ...(accentColorSide === 'left'
                ? {
                    '--slider-background-color':
                      'linear-gradient(to right, var(--accent-color) 0%, var(--accent-color) var(--percentage), var(--gray-700) var(--percentage), var(--gray-700) 100%)',
                  }
                : {
                    '--slider-background-color':
                      'linear-gradient(to right, var(--gray-700) 0%, var(--gray-700) var(--percentage), var(--accent-color) var(--percentage), var(--accent-color) 100%)',
                  }),
            } as CSSProperties
          }
          {...props}
          value={value}
        />
        {withSteps && (
          <FlexContainer
            justifyContent="space-between"
            className={css.dots}
          >
            {Array.from({length: max - min + 1}).map((_, index) => (
              <div
                key={`dot-${index}`}
                className={css.dot}
              />
            ))}
          </FlexContainer>
        )}

        {variant === 'valueOnKnob' && (
          <Text
            className={`${css.value} ${css.onKnob}`}
            style={{
              left: `${percentage}%`,
              display: 'flex',
              alignItems: 'center',
              gap: 0,
              width: `${(formatValue ? formatValue(value) : value.toString()).length}ch`,
            }}
            variant="textXs"
            color="white"
          >
            {formatValue ? formatValue(value) : value}
          </Text>
        )}
      </FlexContainer>
      {variant === 'valueOnRight' && (
        <Text
          className={css.value}
          variant="textXs"
          color="white"
        >
          {formatValue ? formatValue(value) : value}
        </Text>
      )}
      <FlexContainer
        flexShrink={false}
        alignItems="center"
        justifyContent="center"
      >
        {IconRight}
      </FlexContainer>
    </FlexContainer>
  );
};
