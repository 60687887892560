import {preload} from '@autocut/types/ElectronPreload';

import {FingerprintErrorFactory} from './errors/FingerprintErrorFactory';

export const getBlobUrlFromFilepath = (outpath: string) => {
  try {
    const data = preload.fs.readFileSync(outpath);
    const blob = new Blob([data]);
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  } catch (err: any) {
    throw FingerprintErrorFactory(err, 'getBlobUrlFromFilepath');
  }
};
