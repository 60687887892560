import type {TranslationKeys} from '@autocut/contexts/LocaleContext';
import type {ProcessStep} from '@autocut/utils/process/progress';

import {FromBottomModalLayout} from '@autocut/components/FromBottomModalLayout/FromBottomModalLayout';
import {LoaderAutocut} from '@autocut/components/LoaderAutocut/LoaderAutocut';
import Progress from '@autocut/components/Progress/Progress';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {subscribeTaskProgress} from '@autocut/utils/compute.utils';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {getHostNameForTranslation} from '@autocut/utils/languages.utils';
import {useEffect, useState} from 'react';

import css from './ProcessModal.module.css';

export const ProcessModal = () => {
  const [progressPercent, setProgressPercent] = useState<number | null>(null);
  const [progressText, setProgressText] = useState<string>('');
  const [subStepIndex, setSubStepIndex] = useState<number>(0);
  const [stepTitleKey, setStepTitleKey] = useState<TranslationKeys>();
  const [
    processNameKey,
    currentStep,
    currentStepIndex,
    nbStepTotal,
    debugMode,
  ] = useAutoCutStore(state => {
    const errorRes = [
      `modes_${state.ui.process.mode.id}_name` as TranslationKeys,
      undefined,
      -1,
      -1,
    ] as const;
    const progress = state.onGoingProcess.progress;
    if (!progress) return errorRes;
    const currentStepIndex = Math.min(
      progress.steps.length - 1,
      Math.max(
        0,
        progress.steps.findIndex(step => !step.progress.finished),
      ),
    );
    if (currentStepIndex === -1) return errorRes;
    return [
      progress.processNameKey,
      progress.steps[currentStepIndex],
      progress.steps
        .slice(0, currentStepIndex)
        .reduce((acc, step) => acc + (step.countFor || 1), 0),
      progress.steps.reduce((acc, step) => acc + (step.countFor || 1), 0),
      state.dev.debugCompute,
    ];
  });
  const [syncStepIndex, setSyncStepIndex] = useState<number>(currentStepIndex);
  const [syncStep, setSyncStep] = useState<ProcessStep | null>(
    currentStep ?? null,
  );

  useEffect(() => {
    if (!currentStep) return;

    if (currentStep?.progress?.computeTaskId) {
      const {unsubscribe} = subscribeTaskProgress(
        currentStep.progress.computeTaskId,
        steps => {
          const currentSubStepIndex = steps.findIndex(
            step => !step.progress.finished,
          );
          if (currentSubStepIndex === -1) {
            unsubscribe();
            return;
          }

          const currentSubStep = steps[currentSubStepIndex];

          const prog = currentSubStep.progress;

          if (prog.nbSteps < 2) {
            setProgressPercent(null);
            setProgressText('');
          } else {
            setProgressPercent((100 * prog.currentStep) / prog.nbSteps);
            setProgressText(`${prog.currentStep.toFixed(0)} / ${prog.nbSteps}`);
          }

          setSyncStep(currentStep);
          setSyncStepIndex(currentStepIndex);
          setSubStepIndex(currentSubStepIndex);
          setStepTitleKey(
            `progress_steps_${currentSubStep.id}` as TranslationKeys,
          );
        },
      );

      return unsubscribe;
    } else {
      const checkInterval = setInterval(() => {
        const stepProgress = currentStep.progress;
        if (!stepProgress) return;
        if (
          typeof stepProgress.currentStep === 'number' &&
          typeof stepProgress.nbSteps === 'number'
        ) {
          if (stepProgress.nbSteps < 2) {
            setProgressPercent(null);
            setProgressText('');
          } else {
            setProgressPercent(
              (100 * stepProgress.currentStep) / stepProgress.nbSteps,
            );
            setProgressText(
              `${stepProgress.currentStep.toFixed(0)} / ${stepProgress.nbSteps}`,
            );
          }
        } else {
          setProgressPercent(null);
          setProgressText(stepProgress.currentStep || '');
        }
        setSyncStep(currentStep);
        setSyncStepIndex(currentStepIndex);
        setSubStepIndex(0);
        setStepTitleKey(currentStep.translationKey);
      }, 250);

      return () => clearInterval(checkInterval);
    }
  }, [
    currentStep,
    currentStep?.progress?.currentStep,
    currentStepIndex,
    currentStep?.progress?.computeTaskId,
  ]);

  if (!syncStep || syncStepIndex === -1) {
    return null;
  }

  return (
    <FromBottomModalLayout>
      <FlexContainer
        className={css.container}
        flexDirection="column"
        alignItems="center"
        gap={36}
      >
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={16}
        >
          <LoaderAutocut height={100} />
          <div className={css.modeTitle}>
            <TranslatedMessage id={processNameKey} />
          </div>
          {debugMode && CURRENT_ENV !== Env.Production ? (
            <p style={{textAlign: 'center'}}>
              Debug mode is ON
              <br />
              Go to <span style={{fontWeight: 'bold'}}>
                chrome://inspect/
              </span>{' '}
              to continue
            </p>
          ) : null}
        </FlexContainer>

        <FlexContainer
          className={css.currentStep}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={8}
        >
          <h2>
            {syncStepIndex + 1 + subStepIndex}/{nbStepTotal}
          </h2>
          {stepTitleKey ? (
            <h4>
              <TranslatedMessage
                id={stepTitleKey}
                values={{host: getHostNameForTranslation()}}
              />
            </h4>
          ) : null}
          {progressText ? <p>{progressText}</p> : null}
          {progressPercent && (
            <Progress
              key={syncStep.id}
              progress={progressPercent}
              width="min(max(50vw, 95%))"
              height="8px"
              color="#e65019"
            />
          )}
        </FlexContainer>
        <div className={css.warning}>
          <TranslatedMessage
            id="progress_footer_warning"
            defaultMessage="Please do not do anything on DaVinci Resolve, it will cause some issues"
            values={{
              host: getHostNameForTranslation(),
            }}
          />
        </div>
      </FlexContainer>
    </FromBottomModalLayout>
  );
};
