import type {IconComponentProps} from '../Icon';

import {IconComponent} from '../Icon';

export const IconEdit02 = (props: IconComponentProps) => {
  return (
    <IconComponent
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="m18 10-4-4M2.5 21.5l3.4-.4.8-.1.5-.2.6-.6L21 7a2.8 2.8 0 1 0-4-4L3.8 16.2l-.6.6a2 2 0 0 0-.2.5l-.1.8-.4 3.4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
