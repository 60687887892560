import type {ColorKeys} from '@autocut/designSystem/colors';
import type {CSSProperties, PropsWithChildren} from 'react';

import {colors} from '@autocut/designSystem/colors';
import {forwardRef} from 'react';

import css from './ActionIcon.module.scss';

type ActionIconProps = PropsWithChildren<{
  variant?: 'primary' | 'filled' | 'subtle' | 'outline' | 'transparent';
  color?: CSSProperties['color'] | ColorKeys;
  secondaryColor?: CSSProperties['color'] | ColorKeys;
  onClick: () => void;
  style?: CSSProperties;
  disabled?: boolean;
  size?: number;
}>;

export const ActionIcon = forwardRef<HTMLDivElement, ActionIconProps>(
  ({
    children,
    variant = 'transparent',
    color = colors.gray400,
    secondaryColor = 'white',
    style,
    size,
    onClick,
    disabled,
  }) => {
    return (
      <div
        className={css.actionIcon}
        data-variant={variant}
        data-disabled={disabled}
        style={
          {
            '--color': color,
            aspectRatio: '1',
            '--secondaryColor': secondaryColor,
            width: size,
            height: size,
            ...style,
          } as CSSProperties
        }
        onClick={() => (disabled ? null : onClick())}
      >
        {children}
      </div>
    );
  },
);
