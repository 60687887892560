import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {CiMobile1} from 'react-icons/ci';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {SectionsSelectionScreen} from '../../SectionsSelectionScreen/SectionsSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {ResizeCustomizationStep} from './customization/ResizeCustomizationStep';

export const ResizeMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={CiMobile1}
      text="AutoResize"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/resize"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            path: 'selection',
            element: (
              <SectionsSelectionScreen
                mode="resize"
                onValidateSections={() => navigate('./customization')}
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_customization_title',
              defaultMessage: 'Customize parameters',
            }),
            path: 'customization',
            element: <ResizeCustomizationStep />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
