import {ModalType} from '@autocut/enums/modals.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {displayPriorityModal} from '@autocut/utils/modal/displayPriorityModal';
import {isVersionEquals} from '@autocut/utils/string.utils';
import axios from 'axios';

import {getInstallResourceAtSpecificPathConfig} from '../downloadableRessources.utils';

const EXTENSION_FOLDER_NAME = {
  [Env.Production]: 'com.autocut.ppro',
  [Env.Staging]: 'com.autocut.ppro.staging',
  [Env.Development]: 'com.autocut.ppro.staging',
} as const;

export const getPproExtension = () => {
  return getInstallResourceAtSpecificPathConfig({
    resourceName: 'ppro_extension',
    mac: {
      extractSubfolder: 'CEP/extensions/' + EXTENSION_FOLDER_NAME[CURRENT_ENV],
      folderPath: preload.path.join(
        preload.electron
          .getPath('appData')
          .replace(/\/AutoCut[^\/]*(\/)?$/, ''),
        'Adobe',
      ),
      downloadUrl: preload.path.joinUrl(
        preload.electron.getSharedVariables().PPRO_DOWNLOAD_URL,
        'latest',
      ),
      versionFilename: 'CSXS/manifest.xml',
    },
    windows: {
      extractSubfolder: 'CEP/extensions/' + EXTENSION_FOLDER_NAME[CURRENT_ENV],
      folderPath: preload.path.join(
        preload.os.homedir(),
        'AppData',
        'Roaming',
        'Adobe',
      ),
      downloadUrl: preload.path.joinUrl(
        preload.electron.getSharedVariables().PPRO_DOWNLOAD_URL,
        'latest',
      ),
      versionFilename: 'CSXS/manifest.xml',
    },
    createFolderIfNotExist: false,
    isOutdated: async versionFileContent => {
      const currentVersion = versionFileContent.match(
        /ExtensionBundleVersion="(\d*\.\d*\.\d*)"/,
      )?.[1];
      console.debug('ppro_extension', 'currentVersion', currentVersion);

      //If the name contains "BETA", we display the modal about the extension renaming
      const currentName = versionFileContent.match(
        /ExtensionBundleName="(.*)"/,
      )?.[1];
      if (currentName?.toLowerCase().includes('beta')) {
        displayPriorityModal(ModalType.PProExtensionRenamed);
      }

      if (!currentVersion) return false;
      const response = await axios.get(
        preload.electron.getSharedVariables().UPDATER_URL + 'ppro/version',
      );
      const data = response.data;
      console.debug('ppro_extension', 'lastest version', data);
      const outdated = !isVersionEquals(currentVersion, data);
      return outdated;
    },
  });
};
