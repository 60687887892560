import type {ComponentPropsWithoutRef} from 'react';

import css from './Range.module.css';

const Range = ({
  className,
  inputProps,
}: {
  className?: string;
  inputProps?: Omit<ComponentPropsWithoutRef<'input'>, 'type'>;
}) => {
  return (
    <div className={`${css.range_container} ${className || ''}`}>
      <input
        type="range"
        className={`${css.range_input} ${inputProps?.className || ''}`}
        {...inputProps}
      />
    </div>
  );
};

export default Range;
