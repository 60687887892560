import type {ChangeEvent} from 'react';
import type React from 'react';

import type {InputVariant} from './variants';

import {colors} from '@autocut/designSystem/colors';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';

import {FormSection} from '../../layout/FormSection/FormSection';
import {Text} from '../Text/Text';
import css from './Input.module.scss';

export namespace Input {
  export type Props = {
    variant?: InputVariant;
    animate?: boolean;
    className?: string;
    style?: React.CSSProperties;
    unit?: React.ReactNode;
    onChange: (value: string) => void;
    label?: {
      text: FormSection.Props['title'];
      helper: FormSection.Props['description'];
    };
    rightIcon?: JSX.Element;
  } & Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'className' | 'style' | 'onChange'
  >;
}

export const Input = ({
  variant = 'primary',
  animate = false,
  className,
  style,
  onChange = (value: string) => console.log(value),
  unit,
  label,
  type = 'text',
  rightIcon,
  ...props
}: Input.Props) => {
  const valueWidth = String(props.value).length;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <ConditionalWrap
      condition={!!label}
      wrap={children => (
        <FormSection
          title={label?.text}
          description={label?.helper}
        >
          {children}
        </FormSection>
      )}
    >
      <div className={css.container}>
        <input
          data-variant={variant}
          type={type}
          className={`${css.root} ${className} ${animate ? css.animated : ''}`}
          onChange={handleChange}
          onWheel={e => {
            if (type === 'number') e.currentTarget.blur();
          }}
          style={style}
          {...props}
        />
        {unit && (
          <Text
            variant="textXs"
            color={colors.gray400}
            className={css.unit}
            style={{left: `calc(${valueWidth}ch + ${Spacing.s4})`}}
          >
            {unit}
          </Text>
        )}
        {rightIcon && <div className={css.rightIcon}>{rightIcon}</div>}
      </div>
    </ConditionalWrap>
  );
};
