import type {Utterance, WordBase} from '@autocut/types/Deepgram';
import type {
  SelectionCoordinatesType,
  WordCoordinatesType,
} from '@autocut/utils/repeat/selection';

import {ContextualMenu} from '@autocut/components/ContextualMenu';
import {ContextualMenuItem} from '@autocut/components/ContextualMenuItem';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {usePlayback} from '@autocut/hooks/usePlayback';
import {useSelection} from '@autocut/hooks/useSelection';
import {SilenceStep} from '@autocut/pages/modes/legacy/Parts/SilenceStep/SilenceStep';
import {TranscriptSentence} from '@autocut/pages/modes/repeat/Steps/Transcript/Parts/TranscriptSentence';
import {compute} from '@autocut/utils/compute.utils';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {
  getSelectionDirection,
  SelectionDirection,
} from '@autocut/utils/repeat/selection';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';
import * as React from 'react';
import {FaPlay} from 'react-icons/fa6';
import {HiScissors} from 'react-icons/hi';
import {useIntl} from 'react-intl';

import css from './RepeatTranscriptStep.module.css';

type ToggleIsCutArgs = {
  transcript: Array<Utterance[]>;
  selectionCoordinates: SelectionCoordinatesType;
};

export const RepeatTranscriptStep = () => {
  const intl = useIntl();

  const [isHoveringButton, setIsHoveringButton] = React.useState(false);
  const {
    selectionCoordinates,
    setSelectionCoordinates,
    contextualMenuPosition,
    contextualMenuVisibility,
    setContextualMenuVisibility,
  } = useSelection(isHoveringButton);
  const {transcript, host, audioFilepath} = useAutoCutStore(state => ({
    transcript: state.onGoingProcess.repeatUtterances ?? ([] as Utterance[][]),
    audioFilepath: state.onGoingProcess.audioFilepath,
    host: state.ui.host,
  }));
  const {playbackChunk} = usePlayback({audioFilepath});

  const toggleIsCut = (args: ToggleIsCutArgs): void => {
    const {transcript, selectionCoordinates} = args;
    const {startWordCoordinates, endWordCoordinates} = selectionCoordinates;
    const direction = getSelectionDirection(selectionCoordinates);
    const startIndex =
      direction === SelectionDirection.FORWARD
        ? startWordCoordinates
        : endWordCoordinates;
    const endIndex =
      direction === SelectionDirection.FORWARD
        ? endWordCoordinates
        : startWordCoordinates;

    for (let g = startIndex.groupIndex; g <= endIndex.groupIndex; g++) {
      const startU =
        g === startIndex.groupIndex ? startIndex.utteranceIndex : 0;
      const endU =
        g === endIndex.groupIndex
          ? endIndex.utteranceIndex
          : transcript[g].length - 1;

      for (let u = startU; u <= endU; u++) {
        const startW =
          g === startIndex.groupIndex && u === startU
            ? startIndex.wordIndex
            : 0;
        const endW =
          g === endIndex.groupIndex && u === endU
            ? endIndex.wordIndex
            : transcript[g][u].words.length - 1;

        for (let w = startW; w <= endW; w++) {
          transcript[g][u].words[w].isCut = !transcript[g][u].words[w].isCut;
        }
      }
    }
  };

  const onCutToggleFromMenu = (event: Event) => {
    event.stopPropagation();
    const newTranscript = [...transcript];

    if (!selectionCoordinates) return;
    const {startWordCoordinates, endWordCoordinates} = selectionCoordinates;

    const selectionDirection = getSelectionDirection(selectionCoordinates);

    if (selectionDirection === SelectionDirection.FORWARD) {
      toggleIsCut({transcript: newTranscript, selectionCoordinates});
    } else {
      const coordoninates: SelectionCoordinatesType = {
        startWordCoordinates: endWordCoordinates,
        endWordCoordinates: startWordCoordinates,
      };
      toggleIsCut({
        transcript: newTranscript,
        selectionCoordinates: coordoninates,
      });
    }

    setAutocutStore('onGoingProcess.repeatUtterances', newTranscript);
    setSelectionCoordinates(undefined);
    setContextualMenuVisibility(false);
  };

  const onCutToggle = ({transcript, selectionCoordinates}: ToggleIsCutArgs) => {
    const newTranscript = [...transcript];
    toggleIsCut({transcript: newTranscript, selectionCoordinates});
    setAutocutStore('onGoingProcess.repeatUtterances', newTranscript);
  };

  const onPlayClickFromMenu = async (event: Event) => {
    event.stopPropagation();

    if (!selectionCoordinates) return;
    const {startWordCoordinates, endWordCoordinates} = selectionCoordinates;

    const selectionDirection = getSelectionDirection(selectionCoordinates);

    let startWord: WordBase;
    let endWord: WordBase;
    if (selectionDirection === SelectionDirection.FORWARD) {
      startWord = getWordByCoordinate(startWordCoordinates);
      endWord = getWordByCoordinate(endWordCoordinates);
    } else {
      endWord = getWordByCoordinate(startWordCoordinates);
      startWord = getWordByCoordinate(endWordCoordinates);
    }

    setSelectionCoordinates(undefined);
    setContextualMenuVisibility(false);
    void playbackChunk({startSecond: startWord.start, endSecond: endWord.end});
  };

  const onPlayClick = async (
    selectionCoordinates: SelectionCoordinatesType,
  ) => {
    const {startWordCoordinates, endWordCoordinates} = selectionCoordinates;
    const startWord = getWordByCoordinate(startWordCoordinates);
    const endWord = getWordByCoordinate(endWordCoordinates);
    void playbackChunk({startSecond: startWord.start, endSecond: endWord.end});
  };

  const getWordByCoordinate = ({
    groupIndex,
    utteranceIndex,
    wordIndex,
  }: WordCoordinatesType): WordBase => {
    return transcript[groupIndex][utteranceIndex].words[wordIndex];
  };

  const handleClick = handleProcessBase(
    {
      executeProcess: async (_, progress) => {
        const repeatProcess = compute.process.repeat.cutSequence({
          transcript,
          silencesManagement:
            autocutStoreVanilla().ui.parameters.repeat.silences,
        });

        updateProcessStep(progress, 'repeat_mainProcess', {
          progress: {
            computeTaskId: repeatProcess.requestId,
          },
          countFor: host === 'davinci' ? 6 : 3, // Number of steps in compute
        });

        const result = await repeatProcess;

        endProcessStep(progress, 'repeat_cutSequence');

        return result;
      },
    },
    {
      processTitleNameKey: 'modes_repeat_title',
      processSteps: [
        {
          id: 'repeat_mainProcess',
          translationKey: 'progress_steps_repeat_mainProcess',
          progress: '',
        },
      ],
    },
  );

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <Button
              ref={buttonRef}
              isLoading={isLoading}
              disabled={isDisabled}
              color={colors.primary600}
              onClick={async () => {
                handleClickWithValidation(async () => {
                  await handleClick(intl);
                  await handleTrialTask('repeat');
                });
              }}
            >
              <TranslatedMessage
                id="modes_repeat_steps_transcript_cta"
                defaultMessage="Generate transcript"
              />
            </Button>
          )}
        />
      }
    >
      <SilenceStep modeId="repeat" />

      <div style={{height: 8}} />

      <ParametersGroupLayout
        contentContainerStyle={{paddingLeft: 0, gap: 0}}
        title={intl.formatMessage({
          id: 'modes_repeat_steps_transcript_title',
          defaultMessage: 'Fix transcription',
        })}
      >
        <div className={css.mainContainer}>
          <ContextualMenu
            isVisible={contextualMenuVisibility}
            left={contextualMenuPosition.left}
            top={contextualMenuPosition.top}
            setIsHovering={setIsHoveringButton}
          >
            <ContextualMenuItem
              label={intl.formatMessage({
                id: 'modes_repeat_steps_transcript_contextual_cut',
                defaultMessage: 'Cut/Uncut',
              })}
              onClick={onCutToggleFromMenu}
            />
            <ContextualMenuItem
              label={intl.formatMessage({
                id: 'modes_repeat_steps_transcript_contextual_play',
                defaultMessage: 'Play',
              })}
              onClick={onPlayClickFromMenu}
            />
          </ContextualMenu>
          {transcript.map((utterances, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <div className={css.group}>
                {utterances.map((utterance, utteranceIndex) => {
                  return (
                    <div
                      className={css.sentenceAndScissors}
                      key={utteranceIndex}
                    >
                      <div
                        onClick={() =>
                          onCutToggle({
                            transcript,
                            selectionCoordinates: {
                              startWordCoordinates: {
                                groupIndex,
                                utteranceIndex,
                                wordIndex: 0,
                              },
                              endWordCoordinates: {
                                groupIndex,
                                utteranceIndex,
                                wordIndex: utterance.words.length - 1,
                              },
                            },
                          })
                        }
                        className={css.scissors}
                      >
                        <HiScissors size={12} />
                      </div>
                      <div
                        onClick={() =>
                          onPlayClick({
                            startWordCoordinates: {
                              groupIndex,
                              utteranceIndex,
                              wordIndex: 0,
                            },
                            endWordCoordinates: {
                              groupIndex,
                              utteranceIndex,
                              wordIndex: utterance.words.length - 1,
                            },
                          })
                        }
                        className={css.scissors}
                      >
                        <FaPlay
                          size={10}
                          style={{transform: 'translate(1px)'}}
                        />
                      </div>
                      <span className={css.sentence}>
                        <TranscriptSentence
                          words={utterance.words}
                          groupIndex={groupIndex}
                          utteranceIndex={utteranceIndex}
                          selectionCoordinates={selectionCoordinates}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className={css.separator}></div>
            </React.Fragment>
          ))}
        </div>
      </ParametersGroupLayout>
    </ModeLayout>
  );
};
