import type {AutoCutStateSubscriberConfig} from '@autocut/utils/zustand/utils/subscribers.zustand.utils';
import type {GenerateZustandKeys} from '@autocut/utils/zustand/zustand';

import {
  syncToCookies,
  type ZustandSyncConfig,
} from '@autocut/utils/zustand/utils/cookieSync.zustand.utils';

export type ZBaseKey_Dev = 'dev';

export type ZType_Dev = {
  debugCompute: boolean;
  logs: ['log' | 'info' | 'warn' | 'error', any[]][];
};

export type ZKeys_Dev = GenerateZustandKeys<ZBaseKey_Dev, ZType_Dev>;

export const zDefaultValues_Dev: ZType_Dev = {
  debugCompute: false,
  logs: [],
};

export const zSync_Dev: ZustandSyncConfig<ZKeys_Dev> = {
  'dev.debugCompute': {cookieName: 'debugCompute'},
};

export const zSubscribers_Dev: AutoCutStateSubscriberConfig<ZKeys_Dev> = {
  'dev.debugCompute': k => syncToCookies(k),
};
