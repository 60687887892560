import {preload, waitForPreload} from '@autocut/types/ElectronPreload';

export const isDvAppStore = async () => {
  await waitForPreload();

  const {fs, path, os} = preload;

  if (os.platform() !== 'darwin') return false;

  const freeAppStorePath = path.join(
    '/',
    'Applications',
    'DaVinci Resolve.app',
  );
  const studioAppStorePath = path.join(
    '/',
    'Applications',
    'DaVinci Resolve Studio.app',
  );
  return fs.existsSync(freeAppStorePath) || fs.existsSync(studioAppStorePath);
};
