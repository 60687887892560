import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';

import {useEditCaptionStyle} from '../../../EditCaptionStyleContext';
import {HighlightParameters} from './HighlightParameters';
import {WordBoxParameters} from './WordBoxParameters';

const AnimationsCustomization = () => {
  const {
    tempParameters: {value, setValue},
  } = useEditCaptionStyle();

  const enabled = value.animations.enabled;
  const floatingText = value.animations.floatingText;

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked => setValue('animations.enabled', checked)}
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_animation_title'
          }
          defaultMessage="Animation"
        />
      }
    >
      <HighlightParameters />

      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_animation_floatingText'
            }
            defaultMessage="Floating text"
          />
        }
      >
        <Switch
          checked={floatingText}
          onChange={value => setValue('animations.floatingText', value)}
          size={16}
        />
      </ParameterLayout>

      <WordBoxParameters />
    </ParametersGroupLayout>
  );
};

export default AnimationsCustomization;
