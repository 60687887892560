import type {IconType} from 'react-icons';

import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconArrowLeft} from '@autocut/designSystem/components/atoms/Icon/arrows/IconArrowLeft';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useNavigate} from 'react-router-dom';

import css from './ModeTitle.module.scss';

export type ModeTitleProps = {
  Icon?: IconType;
  text: string;
  withHomeButton?: boolean;
  customHomeButtonHandler?: () => void;
};

export const ModeTitle = ({
  Icon,
  text,
  withHomeButton = true,
  customHomeButtonHandler,
}: ModeTitleProps) => {
  const navigate = useNavigate();
  const {isProcessing} = useAutoCutStore(state => ({
    isProcessing: state.ui.process.isProcessing,
  }));

  const handleOnClick = () => {
    if (isProcessing) return;
    navigate('/homepage');
  };

  return (
    <FlexContainer
      className={css.mainContainer}
      alignItems="center"
      justifyContent="space-between"
    >
      {withHomeButton && (
        <ActionIcon
          onClick={customHomeButtonHandler || handleOnClick}
          disabled={isProcessing}
          color="white"
          variant="subtle"
          size={32}
        >
          <IconArrowLeft size={24} />
        </ActionIcon>
      )}

      <FlexContainer
        className={css.titleContainer}
        alignItems="center"
        justifyContent="center"
        gap={16}
      >
        {Icon && <Icon size={24} />}
        <div className={css.title}>{text}</div>
      </FlexContainer>

      <div className={css.placeHolder} />
    </FlexContainer>
  );
};
