export const createObject = (
  current: any,
  keys: string[],
  value: any,
  depth = 0,
): any => {
  if (keys.length === 1) {
    return {...((depth > 0 ? current : undefined) || {}), [keys[0]]: value};
  }

  return {
    ...((depth > 0 ? current : undefined) || {}),
    [keys[0]]: createObject(current[keys[0]], keys.slice(1), value, depth + 1),
  };
};

export function deepFind(obj: any, path: string) {
  const paths = path.split('.');
  let current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}

export const deepSet = (obj: any, paths: string[], value: any): any => {
  const currentKey = paths.shift();

  if (currentKey === undefined) return obj;

  if (paths.length === 0) {
    obj[currentKey] = value;
    return obj;
  }

  if (!(currentKey in obj)) throw new Error('Invalid path');

  return deepSet(obj[currentKey], paths, value);
};

export const deepGet = (obj: any, paths: string[]): any => {
  const currentKey = paths.shift();

  if (currentKey === undefined) return obj;

  if (!(currentKey in obj)) throw new Error('Invalid path');

  return deepGet(obj[currentKey], paths);
};
