import type React from 'react';

import {closeModal} from '@autocut/utils/modal/closeModal';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {useEffect} from 'react';
import {RxCross1} from 'react-icons/rx';

import css from './BaseModal.module.scss';

export type ModalProps = {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  noHeader?: boolean;
  children?: React.ReactNode;
  extraChildren?: React.ReactNode;
  footer?: React.ReactNode;
  closeModalFunction?: () => any;
  shouldScroll?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  isBlocking?: boolean;
  className?: string;
  variant?: 'default' | 'outline';
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>;

const Modal = ({
  title,
  icon,
  noHeader = false,
  children,
  extraChildren,
  footer,
  closeModalFunction = () => closeModal(),
  shouldScroll = true,
  fullScreen = false,
  fullWidth = false,
  fullHeight = false,
  isBlocking,
  className,
  variant = 'default',
  ...props
}: ModalProps) => {
  useEffect(() => {
    // @ts-ignore
    disableBodyScroll(document);

    return () => {
      // @ts-ignore
      enableBodyScroll(document);
    };
  }, []);

  return (
    <div
      id="modal"
      className={`${css.container} ${fullScreen && css.fullScreen} ${
        fullWidth && css.fullWidth
      } ${fullHeight && css.fullHeight} ${className || ''}`}
      {...props}
    >
      <div
        className={css.background}
        onClick={!isBlocking ? closeModalFunction : undefined}
      >
        {extraChildren}
      </div>

      <div
        className={css.modal}
        data-variant={variant}
      >
        <div className={css.blob} />
        <div className={css.body}>
          {!noHeader && (
            <div className={css.header}>
              <div className={css.title}>
                {icon && icon}
                {title}
              </div>
            </div>
          )}
          {!isBlocking ? (
            <div
              className={css.closeButton}
              onClick={closeModalFunction}
            >
              <RxCross1
                style={{
                  pointerEvents: 'none',
                }}
              />
            </div>
          ) : null}
          <div
            className={`${css.content} ${shouldScroll ? css.shouldScroll : ''}`}
            style={{
              overflowY: shouldScroll ? 'auto' : 'hidden',
            }}
          >
            {children}
          </div>
          <div
            className={css.footer}
            style={{
              paddingRight: shouldScroll ? '20px' : '0px',
            }}
          >
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
