import {
  CAPTIONS_FOLDER_PATH,
  CAPTIONS_RESOURCES_FOLDER_PATH,
} from '@autocut/enums/resources.enum';
import {preload} from '@autocut/types/ElectronPreload';

import {XMLDocument} from './class/_XMLDocument';

export const initCaptionXMLTemplate = async (xmlProjectPath: string) => {
  // ===== PARSING TEMPLATE PROJECT =====
  const xmlDoc = new XMLDocument(xmlProjectPath);

  // ===== UPDATE RELATIVE PATHS =====
  xmlDoc.medias.forEach(media => {
    if (media.relativePath)
      media.changeFilePath(
        preload.path.join(
          preload.electron.getPath('appData'),
          CAPTIONS_FOLDER_PATH,
          CAPTIONS_RESOURCES_FOLDER_PATH,
          media.relativePath?.textContent ?? '',
        ),
      );
  });

  return xmlDoc;
};
