import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import type {PropsWithChildren} from 'react';

import type {CaptionParametersKeys} from './editCaptionStyle.utils';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {useNotification} from '@autocut/contexts/NotificationProvider.tsx/NotificationProvider';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {cloneDeep} from 'lodash';
import {createContext, useContext, useState} from 'react';

import {processCaptionsChunksState} from '../../../utils/processCaptionsChunks';
import {CaptionsPreviewModal} from './CaptionsPreviewModal/CaptionsPreviewModal';
import {CaptionsCustomizationModal} from './CustomizationModal/CaptionsCustomizationModal';
import {chunkNeedsUpdate} from './editCaptionStyle.utils';

type EditCaptionStyleProps = {
  tempParameters: {
    value: CaptionsParameters;
    setValue: (key: CaptionParametersKeys, value: any) => void;
  };
  customizationModal: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
  previewModal: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
};

const EditCaptionStyleContext = createContext<EditCaptionStyleProps>({
  tempParameters: {
    value: {} as CaptionsParameters,
    setValue: () => {},
  },
  customizationModal: {
    isOpen: false,
    open: () => {},
    close: () => {},
  },
  previewModal: {
    isOpen: false,
    open: () => {},
    close: () => {},
  },
});

export const EditCaptionStyleProvider = ({children}: PropsWithChildren) => {
  const [isCustomizationModalOpen, setIsCustomizationModalOpen] =
    useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const {previousParameters, chunks} = useAutoCutStore(state => ({
    previousParameters: state.ui.parameters.caption,
    chunks: state.onGoingProcess.caption?.chunks,
  }));

  const [tempParameters, setTempParameters] = useState<CaptionsParameters>(
    cloneDeep(previousParameters),
  );

  const {displayNotification} = useNotification();

  const onClose = async () => {
    const needsUpdate = chunkNeedsUpdate(previousParameters, tempParameters);
    setAutocutStore('ui.parameters.caption', cloneDeep(tempParameters));

    if (needsUpdate) {
      await processCaptionsChunksState(
        chunks?.flatMap(chunk => chunk.lines.flat()) ?? [],
      );
      displayNotification(
        <TranslatedMessage
          id="modes_caption_steps_customization_parts_style_notification"
          defaultMessage="You have changed major parameters. The split of your captions have been recalculated."
        />,
        3000,
      );
    }
  };

  console.log('isCustomizationModalOpen', isCustomizationModalOpen);

  const closeCustomizationModal = async () => {
    await onClose();
    setIsCustomizationModalOpen(false);
  };

  const closePreviewModal = async () => {
    await onClose();
    setIsPreviewModalOpen(false);
  };

  const updateParameterValue = (key: CaptionParametersKeys, value: any) => {
    // If no key is provided, we update the whole parameters
    if (!key) {
      setTempParameters(value);
      return;
    }

    const newParameters = cloneDeep(tempParameters);
    const splittedKey = key.split('.');
    const lastKey = splittedKey.pop();
    if (!lastKey) return;

    const parent = splittedKey.reduce(
      (acc, current) => (acc as any)[current],
      newParameters,
    );
    (parent as any)[lastKey] = value;
    setTempParameters(newParameters);
  };

  return (
    <EditCaptionStyleContext.Provider
      value={{
        tempParameters: {
          value: tempParameters,
          setValue: updateParameterValue,
        },
        customizationModal: {
          isOpen: isCustomizationModalOpen,
          open: () => setIsCustomizationModalOpen(true),
          close: closeCustomizationModal,
        },
        previewModal: {
          isOpen: isPreviewModalOpen,
          open: () => setIsPreviewModalOpen(true),
          close: closePreviewModal,
        },
      }}
    >
      <CaptionsCustomizationModal />
      <CaptionsPreviewModal />
      {children}
    </EditCaptionStyleContext.Provider>
  );
};

export const useEditCaptionStyle = () => useContext(EditCaptionStyleContext);
