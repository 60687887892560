import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {host} from '@autocut/utils/host';

export const YoutubeButton = () => {
  const chapters = useAutoCutStore(state => state.onGoingProcess.chapters);

  const onClick = async () => {
    if (!chapters) {
      return;
    }

    const framerate = await host.timeline.getFrameRate();
    const startFrame = await host.timeline.getStartFrame();
    const startSeconds = Math.floor(startFrame / framerate);

    const chaptersForYoutube = chapters
      .map(chapter => {
        const seconds = chapter.timecodes.map(timecode => {
          const [hours, minutes, seconds, _] = timecode.split(':').map(Number);
          return hours * 3600 + minutes * 60 + seconds - startSeconds;
        });
        const youtubeTimecodes = seconds.map(
          second =>
            `${Math.floor(second / 60)
              .toFixed(0)
              .padStart(2, '0')}:${(second % 60).toFixed(0).padStart(2, '0')}`,
        );
        return `${youtubeTimecodes[0]} - ${chapter.title}`;
      })
      .join('\n');

    const blob = new Blob([chaptersForYoutube], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'chapters.txt';
    link.click();
    link.remove();
  };
  return (
    <Button
      fullWidth={false}
      variant="secondary"
      onClick={onClick}
    >
      <TranslatedMessage
        id="modes_chapters_steps_exportChapters_cta"
        defaultMessage="Export chapters for Youtube"
      />
    </Button>
  );
};
