import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';

export const handleAddSpeaker = () => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore('ui.parameters.podcast.speakers', [
    ...speakers,
    {name: '', videoTracks: [], audioTrack: undefined, boostValue: 0},
  ]);
};

export const handleRemoveSpeakerByIndex = (index: number) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.filter((_, i) => i !== index),
  );
};

export const handleAudioTrackUpdate = (
  index: number,
  audioTrack: number | undefined,
) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.map((speaker, i) =>
      i === index ? {...speaker, audioTrack} : speaker,
    ),
  );
};

export const handleNameUpdate = (index: number, name: string) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.map((speaker, i) => (i === index ? {...speaker, name} : speaker)),
  );
};

export const handleBoostUpdate = (index: number, boostValue: number) => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;

  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.map((speaker, i) =>
      i === index ? {...speaker, boostValue} : speaker,
    ),
  );
};

export const handleBoostReset = () => {
  const speakers = autocutStoreVanilla().ui.parameters.podcast.speakers;
  setAutocutStore(
    'ui.parameters.podcast.speakers',
    speakers.map(speaker => ({...speaker, boostValue: 0})),
  );
};
