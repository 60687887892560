import {preload} from '@autocut/types/ElectronPreload';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';
import axios from 'axios';

import {getInstallResourceAtSpecificPathConfig} from '../downloadableRessources.utils';

export const getLuaScripts = () =>
  getInstallResourceAtSpecificPathConfig({
    resourceName: 'lua_scripts',
    mac: {
      versionFilename: 'version',
      folderPath: preload.path.join(getResourceFolderPath(), 'lua'),
      downloadUrl: preload.path.joinUrl(
        preload.electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
          'autocut-lua-remoteContent.zip',
      ),
    },
    windows: {
      versionFilename: 'version',
      folderPath: preload.path.join(getResourceFolderPath(), 'lua'),
      downloadUrl: preload.path.joinUrl(
        preload.electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
          '/autocut-lua-remoteContent.zip',
      ),
    },
    isOutdated: async versionFileContent => {
      const response = await axios.get(
        preload.path.joinUrl(
          preload.electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
            'version',
        ),
      );
      const data = response.data;
      const outdated = data.toString() !== versionFileContent.toString();
      return outdated;
    },
  });
