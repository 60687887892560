import type {CaptionChunk} from '@autocut/types/Captions';

import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {
  getCurrentTranscript,
  updateCurrentTranscriptName,
} from '@autocut/utils/transcript/transcriptFiles.utils';
import {setAutocutStore} from '@autocut/utils/zustand/zustand';
import {createContext, useContext, useEffect, useState} from 'react';

import {useHandleAddCaptions} from '../../useHandleAddCaptions';
import {CheckTranscriptModal} from '../CheckTranscriptModal';
import {EditTranscriptModal} from './EditTranscriptModal';

type EditTranscriptModalContextType = {
  currentTranscriptName: string;
  updateTranscriptName: (name: string) => void;
  hasCheckedTranscript: boolean;
  editTranscriptModal: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    localeCaptionChunks: CaptionChunk[];
    setLocaleCaptionChunks: (chunks: CaptionChunk[]) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
  };
  checkTranscriptModal: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    validate: (dontShowAgain: boolean) => Promise<void>;
  };
};

const EditTranscriptModalContext =
  createContext<EditTranscriptModalContextType>({
    currentTranscriptName: '',
    updateTranscriptName: () => {},
    hasCheckedTranscript: false,
    editTranscriptModal: {
      isOpen: false,
      open: () => {},
      close: () => {},
      localeCaptionChunks: [],
      setLocaleCaptionChunks: () => {},
      isLoading: false,
      setIsLoading: () => {},
    },
    checkTranscriptModal: {
      isOpen: false,
      open: () => {},
      close: () => {},
      validate: async () => {},
    },
  });

export const EditTranscriptModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {addCaptions} = useHandleAddCaptions();

  const {captionChunks, dontShowCheckTranscriptModal} = useAutoCutStore(
    state => ({
      captionChunks: state.onGoingProcess.caption?.chunks,
      dontShowCheckTranscriptModal:
        state.modal.dontShowAgain.checkTranscriptModal,
    }),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasCheckedTranscriptModalOpen, setHasCheckedTranscriptModalOpen] =
    useState(false);
  const [currentTranscriptName, setCurrentTranscriptName] = useState(
    getCurrentTranscript()?.name ?? '',
  );
  const [hasCheckedTranscript, setHasCheckedTranscript] = useState(
    dontShowCheckTranscriptModal,
  );
  const [localeCaptionChunks, setLocaleCaptionChunks] = useState(
    getCurrentTranscript()?.chunks ?? captionChunks ?? [],
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLocaleCaptionChunks(captionChunks ?? []);
  }, [captionChunks]);

  const updateTranscriptName = (name: string) => {
    setCurrentTranscriptName(name);
    updateCurrentTranscriptName(name);
  };

  const openEditTranscriptModal = () => {
    setHasCheckedTranscriptModalOpen(false);
    setHasCheckedTranscript(true);
    setIsModalOpen(true);
  };

  const closeEditTranscriptModal = () => {
    setIsModalOpen(false);
    setCurrentTranscriptName(getCurrentTranscript()?.name ?? '');
  };

  const openHasCheckedTranscriptModal = () => {
    setHasCheckedTranscriptModalOpen(true);
  };

  const closeHasCheckedTranscriptModal = () => {
    setHasCheckedTranscriptModalOpen(false);
  };

  const validateTranscript = async (dontShowAgain: boolean) => {
    setHasCheckedTranscript(true);
    setHasCheckedTranscriptModalOpen(false);
    setAutocutStore('modal.dontShowAgain.checkTranscriptModal', dontShowAgain);
    await addCaptions();
  };

  return (
    <EditTranscriptModalContext.Provider
      value={{
        currentTranscriptName,
        updateTranscriptName,
        editTranscriptModal: {
          isOpen: isModalOpen,
          open: openEditTranscriptModal,
          close: closeEditTranscriptModal,
          localeCaptionChunks,
          setLocaleCaptionChunks,
          isLoading,
          setIsLoading,
        },
        hasCheckedTranscript,
        checkTranscriptModal: {
          isOpen: hasCheckedTranscriptModalOpen,
          open: openHasCheckedTranscriptModal,
          close: closeHasCheckedTranscriptModal,
          validate: validateTranscript,
        },
      }}
    >
      <CheckTranscriptModal />
      <EditTranscriptModal />
      {children}
    </EditTranscriptModalContext.Provider>
  );
};

export const useEditTranscriptModal = () =>
  useContext(EditTranscriptModalContext);
