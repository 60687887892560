import type {XMLDocument} from '../class/_XMLDocument';
import type {TimelinePpro} from '../temp/_pproTimeline.temp';

export const handleResizeSequence = ({
  xmlDoc,
  timeline,
}: {
  xmlDoc: XMLDocument;
  timeline: TimelinePpro;
}) => {
  const xmlSequence = xmlDoc.getSequences()[0];
  xmlSequence.resize([timeline.settings.width, timeline.settings.height]);

  return xmlSequence;
};
