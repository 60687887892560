import type {XMLDocument} from './_XMLDocument';

import {preload} from '@autocut/types/ElectronPreload';

export class XMLVideoMediaSource {
  private actualMediaFilePath: Element;
  private element: Element;
  private filePath: Element;
  public document: XMLDocument;
  getMedia = () => {
    const mediaRef = this.element.querySelector('Media');
    const mediaUID = mediaRef?.getAttribute('ObjectURef') ?? '';
    return this.document.findElementWithObjectId(['Media'], mediaUID);
  };
  public media: Element;
  public metadataId: string;
  public objectID: string;
  public relativePath: Element | null;
  public title: string;

  public videoStream: Element;

  constructor({
    element,
    document,
    media,
    videoStream,
  }: {
    element: Element;
    document: XMLDocument;
    media?: Element;
    videoStream?: Element;
  }) {
    this.objectID = element.getAttribute('ObjectID') ?? '';
    this.element = element;
    this.document = document;

    media = media ?? this.getMedia();
    if (!media) throw new Error('Media not found');
    this.media = media;

    this.title = this.media.querySelector('Title')?.textContent ?? '';

    this.relativePath = this.media.querySelector('RelativePath');

    const filePath = this.media.querySelector('FilePath');
    if (!filePath) throw new Error('FilePath not found');
    this.filePath = filePath;

    const actualMediaFilePath = this.media.querySelector('ActualMediaFilePath');
    if (!actualMediaFilePath) throw new Error('ActualMediaFilePath not found');
    this.actualMediaFilePath = actualMediaFilePath;

    this.metadataId =
      this.media.querySelector('ContentAndMetadataState')?.textContent ?? '';

    videoStream = videoStream ?? this.getVideoStream();
    if (!videoStream) throw new Error('VideoStream not found');
    this.videoStream = videoStream;
  }

  private getVideoStream() {
    const videoStreamRef = this.media.querySelector('VideoStream');
    if (!videoStreamRef) throw new Error('VideoStream not found');
    const videoStreamId = videoStreamRef?.getAttribute('ObjectRef') ?? '';
    return this.document.findElementWithObjectId(
      ['VideoStream'],
      videoStreamId,
    );
  }

  public changeFilePath(filePath: string) {
    if (this.filePath?.textContent) this.filePath.textContent = filePath;
    if (this.actualMediaFilePath?.textContent)
      this.actualMediaFilePath.textContent = filePath;
  }

  public clone({
    newName,
    newPath,
    newRelativePath,
    newObjectUID,
  }: {
    newName: string;
    newPath: string;
    newRelativePath: string;
    newObjectUID?: string;
  }) {
    const newVideoMediaSource = this.element.cloneNodeWithNewId(
      newObjectUID ?? this.document.getNewObjectId(),
    );
    const newMediaId = this.document.getNewObjectUID();
    const newMediaRef = newVideoMediaSource.querySelector('Media');
    if (newMediaRef) newMediaRef.setAttribute('ObjectURef', newMediaId);
    const newMedia = this.media.cloneNodeWithNewId(newMediaId);

    const newVideoStreamId = this.document.getNewObjectId();
    const newVideoStreamRef = newMedia.querySelector('VideoStream');
    if (newVideoStreamRef)
      newVideoStreamRef.setAttribute('ObjectRef', newVideoStreamId);
    const newVideoStream =
      this.videoStream.cloneNodeWithNewId(newVideoStreamId);

    const newMetadataId = this.document.getNewObjectUID();
    const contentAndMetadataState = newMedia.querySelector(
      'ContentAndMetadataState',
    );
    if (contentAndMetadataState)
      contentAndMetadataState.textContent = newMetadataId;
    const encodedMetadataId = preload.buffer.encodeToBase64([
      new Uint8Array(preload.buffer.from(newMetadataId, 'utf16le')),
    ]);

    const modificationState = newMedia.querySelector('ModificationState');
    if (modificationState) modificationState.textContent = encodedMetadataId;

    const fileKey = newMedia.querySelector('FileKey');
    if (fileKey) fileKey.textContent = this.document.getNewObjectUID();

    const implementationID = newMedia.querySelector('ImplementationID');
    if (implementationID)
      implementationID.textContent = this.document.getNewObjectUID();

    const title = newMedia.querySelector('Title');
    if (title) title.textContent = newName;

    const relativePath = newMedia.querySelector('RelativePath');
    if (relativePath) relativePath.textContent = newRelativePath;

    const filePath = newMedia.querySelector('FilePath');
    if (filePath) filePath.textContent = newPath;

    const actualMediaFilePath = newMedia.querySelector('ActualMediaFilePath');
    if (actualMediaFilePath) actualMediaFilePath.textContent = newPath;

    return new XMLVideoMediaSource({
      element: newVideoMediaSource,
      document: this.document,
      media: newMedia,
      videoStream: newVideoStream,
    });
  }
}
