import type {ProcessTranscriptFunction} from '@autocut/types/Transcription';

import {compute} from '@autocut/utils/compute.utils';
import {FingerprintErrorFactory} from '@autocut/utils/errors/FingerprintErrorFactory';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {
  autocutStoreVanilla,
  setAutocutStore,
} from '@autocut/utils/zustand/zustand';

export const handleSwearWordsProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    try {
      const processProgress = initProcessProgress(
        'swearWords_transcript',
        'progress_steps_transcript_mainProcess',
      );
      addProcessSteps(processProgress, [
        {
          id: 'swearWords_transcript_getLanguage',
          translationKey: 'progress_steps_swearWords_transcript_getLanguage',
          progress: 1,
        },
        {
          id: 'swearWords_transcript_mainProcess',
          translationKey: 'progress_steps_transcript_mainProcess',
          progress: '',
          countFor: 4,
        },
        {
          id: 'swearWords_transcript_getSwearWords',
          translationKey: 'progress_steps_swearWords_transcript_getSwearWords',
          progress: 1,
        },
      ]);

      startProcessProgress(processProgress);

      const parameters = getParametersForMode('swear_word');

      const language = getTranscriptionLanguage({
        languageOfTranscriptionValue: parameters.languageOfTranscription.value,
        usedModel,
      });
      endProcessStep(processProgress, 'swearWords_transcript_getLanguage');

      const transcriptProcess = compute.transcription({
        type: 'utterances',
        skipEmbedding: true,
        language,
      });

      updateProcessStep(processProgress, 'swearWords_transcript_mainProcess', {
        progress: {
          computeTaskId: transcriptProcess.requestId,
        },
      });

      const {transcript, filePath} = await transcriptProcess;

      setAutocutStore('onGoingProcess.audioFilepath', filePath);

      endProcessStep(processProgress, 'swearWords_transcript_mainProcess');

      const swearWords = await compute.process.swearWords.getSwearWords({
        utterances: transcript,
        language: language.value,
      });

      // Remove swearWords that are in dictionary
      const dictionary =
        autocutStoreVanilla().ui.swearWords.swearWordsDictionary;
      const filteredSwearWords = swearWords
        .map(swearWord =>
          swearWord.words.filter(
            word => !dictionary.includes(word.word.toLowerCase()),
          ),
        )
        .flat();

      setAutocutStore('onGoingProcess.swearWords.utterances', transcript);
      setAutocutStore('onGoingProcess.swearWords.gptWords', filteredSwearWords);

      console.log({swearWords, transcript});

      endProcessStep(processProgress, 'swearWords_transcript_getSwearWords');
    } catch (error: any) {
      throw FingerprintErrorFactory(error, 'handleSwearWordsProcessTranscript');
    }
  };
