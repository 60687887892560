import {preload} from '@autocut/types/ElectronPreload';

import {formatSourceTextData} from '../_formatSourceTextData';

const XMLVideoComponentParamType = {
  '2': {
    name: 'slider',
    expectedType: 'number',
  },
  '5': {
    name: 'color',
    expectedType: 'string',
  },
  '6': {
    name: 'double-slider',
    expectedType: 'object',
  },
  '8': {
    name: 'slider',
    expectedType: 'number',
  },
  '9': {
    name: 'source-text',
    expectedType: 'object',
  },
  '11': {
    name: 'title',
    expectedType: 'string',
  },
  '23': {
    name: 'text',
    expectedType: 'object',
  },
} as const;

export const updateComponentParam = (
  paramElement: Element,
  newValue: any,
  currentValue: any,
) => {
  const parameterControlType = paramElement.querySelector(
    'ParameterControlType',
  )?.textContent as keyof typeof XMLVideoComponentParamType;

  const parameterTypeName =
    XMLVideoComponentParamType[parameterControlType].name;

  if (!parameterControlType || !parameterTypeName) {
    console.error(
      `Error when updating mogrt param : no parameter control type found for param ${JSON.stringify(
        paramElement,
      )}`,
    );
    return;
  }

  if (
    typeof newValue !==
    XMLVideoComponentParamType[parameterControlType].expectedType
  ) {
    console.error(
      `Error when updating mogrt param : the new value is not of the expected type ${XMLVideoComponentParamType[parameterControlType].expectedType}`,
    );
    return;
  }

  switch (parameterTypeName) {
    case 'color':
      const formattedColor =
        '0xff00' +
        newValue
          .match(/.{2}/g)
          ?.map((hex: string) => hex + '00')
          .join('');

      const color = BigInt(formattedColor).toString();
      return color;

    case 'slider':
      const number = newValue.toString();
      if (!number.includes('.')) {
        return number + '.0';
      }
      return number;

    case 'double-slider':
      const [xPosition, yPosition] = newValue;

      return `${xPosition.toString()}:${yPosition.toString()}`;

    case 'source-text':
      const encodedText = formatSourceTextData(newValue);

      return encodedText;
    case 'title':
      console.error('You cannot update a title param');
      break;

    case 'text':
      try {
        const decodedCurrentValue = currentValue.length
          ? JSON.parse(
              preload.buffer.from(currentValue, 'base64').toString('utf16le'),
            )
          : {};

        const {
          fontEditValue,
          fontFSAllCapsValue,
          fontFSBoldValue,
          fontFSItalicValue,
          fontFSSmallCapsValue,
          fontSizeEditValue,
          fontTextRunLength,
          textEditValue,
        } = newValue;

        const text = {
          ...decodedCurrentValue,
          fontEditValue: fontEditValue
            ? [fontEditValue]
            : decodedCurrentValue.fontEditValue,
          fontFSAllCapsValue: fontFSAllCapsValue
            ? [fontFSAllCapsValue]
            : decodedCurrentValue.fontFSAllCapsValue,
          fontFSBoldValue: fontFSBoldValue
            ? [fontFSBoldValue]
            : decodedCurrentValue.fontFSBoldValue,
          fontFSItalicValue: fontFSItalicValue
            ? [fontFSItalicValue]
            : decodedCurrentValue.fontFSItalicValue,
          fontFSSmallCapsValue: fontFSSmallCapsValue
            ? [fontFSSmallCapsValue]
            : decodedCurrentValue.fontFSSmallCapsValue,
          fontSizeEditValue: fontSizeEditValue
            ? [fontSizeEditValue]
            : decodedCurrentValue.fontSizeEditValue,
          fontTextRunLength: fontTextRunLength
            ? [fontTextRunLength]
            : decodedCurrentValue.fontTextRunLength,
          textEditValue: textEditValue || decodedCurrentValue.textEditValue,
        };

        const encodedText = preload.buffer.encodeToBase64([
          new Uint8Array(preload.buffer.from(JSON.stringify(text), 'utf16le')),
        ]);

        return encodedText;
      } catch (e) {
        console.error('Could not update text param: ', e);
      }
  }
};
