import {Card} from '@autocut/designSystem/components/atoms/Card/Card';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {IconTrash01} from '@autocut/designSystem/components/atoms/Icon/general/IconTrash01';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

import css from './PresetCard.module.scss';

export type PresetCardProps = {
  icon: React.ReactNode[];
  title: string;
  width: number;
  height: number;
  selected: boolean;
  onClick: () => void;
  onDelete?: () => void;
};

export const CustomCard = ({
  children,
  selected,
  onClick,
}: {
  children: React.ReactNode;
  selected: boolean;
  onClick?: () => void;
}) => {
  return (
    <Card
      hoverable
      selected={selected}
      className={css.root}
      onClick={onClick}
    >
      {children}
    </Card>
  );
};

export const PresetCard = ({
  icon,
  title,
  width,
  height,
  selected,
  onClick,
  onDelete,
}: PresetCardProps) => {
  return (
    <CustomCard
      selected={selected}
      onClick={onClick}
    >
      <FlexContainer
        className={css.container}
        flexDirection="column"
        justifyContent="space-between"
      >
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <FlexContainer
            gap={Spacing.s1}
            justifyContent="space-between"
          >
            <FlexContainer gap={Spacing.s1}>{icon}</FlexContainer>
            <CheckBox
              size={16}
              checked={selected}
              variant="box"
            />
          </FlexContainer>
          <Text variant="textXs">{title}</Text>
        </FlexContainer>

        <FlexContainer
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="text2Xs.bold">
            {width}x{height}
          </Text>
          {onDelete && (
            <IconTrash01
              className={css.deleteIcon}
              onClick={() => onDelete()}
              size={16}
            />
          )}
        </FlexContainer>
      </FlexContainer>
    </CustomCard>
  );
};
